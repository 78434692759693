import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, timer, Subscription } from 'rxjs';
import { BuildDetailsService } from './build-details.service';
import { ClientBuildDetailsHttpService } from './build-details.http.service';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKeys } from '../store/models/store.models';

@Injectable({ providedIn: 'root' })
export class UpToDateBuildService implements OnDestroy {
  private buildIsUpToDateSubject = new BehaviorSubject<boolean>(true);
  private pollSubscription: Subscription;
  constructor(
    private buildDetailsService: BuildDetailsService,
    private clientBuildDetailsHttpService: ClientBuildDetailsHttpService,
    private _storageService: BrowserStorageService,
  ) {
    this.pollForBuildNumber();
  }

  private readonly POLL_INTERVAL: number = 60000;

  ngOnDestroy(): void {
    if (this.pollSubscription) {
      this.pollSubscription.unsubscribe();
    }
  }

  public get buildIsUpToDate(): Observable<boolean> {
    return this.buildIsUpToDateSubject;
  }

  public updateBuildIsUpToDate(status: boolean): void {
    this.buildIsUpToDateSubject.next(status);
  }

  private pollForBuildNumber(): void {
    this.pollSubscription = timer(this.POLL_INTERVAL, this.POLL_INTERVAL).subscribe(() => {
      if (this._storageService.get(StorageKeys.RELOAD_ON_NEXT_ROUTE) === null) {
        this.checkBuildNumber();
      }
    });
  }

  private checkBuildNumber(): void {
    this.clientBuildDetailsHttpService.fetchServerBuildDetails().subscribe({
      next: response => this.handleBuildNumberResponse(response),
      error: error => this.handleBuildNumberError(error),
    });
  }

  private handleBuildNumberResponse(buildNumberServer: string): void {
    this.buildDetailsService.buildDetails.buildNumberServer = buildNumberServer;

    const buildNumberClient = this._storageService.get(StorageKeys.BUILD, false);

    if (buildNumberClient !== buildNumberServer) {
      this.updateBuildIsUpToDate(false);
    } else {
      this.updateBuildIsUpToDate(true);
      this._storageService.set(StorageKeys.BUILD, buildNumberServer, false);
    }
  }

  private handleBuildNumberError(error: any): void {
    console.log('Failed to fetch build number from server', { error });
  }

  /*
  private pollForBuildNumber() {
    const pollInterval = 10000;
    this.pollSubscription = timer(pollInterval, pollInterval).subscribe(() => {
      if (this._storageService.get(StorageKeys.RELOAD_ON_NEXT_ROUTE) === null) {
        this.clientBuildDetailsHttpService.fetchServerBuildDetails().subscribe(response => {
          const buildNumberServer = response;
          this.buildDetailsService.buildDetails.buildNumberServer = response;

          console.log('bnc', this._storageService.get(StorageKeys.BUILD, false));
          console.log('bns', buildNumberServer);

          if (this._storageService.get(StorageKeys.BUILD, false) !== buildNumberServer) {
            this.updateBuildIsUpToDate(false);
          } else {
            this.updateBuildIsUpToDate(true);
            this._storageService.set(StorageKeys.BUILD, buildNumberServer, false);
          }
        });
      }
    });
  }

  */
}
