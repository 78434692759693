import { IShoppingTaxPrice, ShoppingDeliveryMethodEnum } from './shopping.model';
import { ILocationV3Address, IPosition } from './location.model';
import { IAmount, ICommonImage, IOpeningTime, IPicture } from './common.model';
import { IJobCardJobOffer, IJobCardMerchantFee, IJobCardMerchantPhoneNumber } from './job-card.model';

export enum ActivityHistoryType {
  SERVICE = 'SERVICE',
  PRODUCT = 'PRODUCT',
  GROCERIES = 'GROCERIES',
  AIRTIMEDATA = 'AIRTIMEDATA',
  ELECTRICITY = 'ELECTRICITY',
  TRAVEL = 'TRAVEL',
  MOVIES = 'MOVIES',
  VOUCHER = 'VOUCHER',
  TAKEAWAY = 'TAKEAWAY',
  TABLE_RESERVATION = 'TABLE_RESERVATION',
}

export interface IActivityHistoryItem {
  id: string;
  sku: string;
  name: string;
  quantity: number;
  image: ICommonImage;
  pricePerUnit: IAmount;
  totalPrice: IShoppingTaxPrice;
  tags: IActivityHistoryItemTag[];
  note: string;
  modifiers: string;
  cancelledBySupplier: boolean;
  canReturn: boolean;
  canCancel: boolean;
  canWarranty: boolean;
  vouchers?: IPurchasedVoucherResponse[];
  vouchersProvisioned?: boolean;
  slug: string; // todo add ORCH
}

export interface IActivityHistoryItemTag {
  image: string;
  label: string;
  name: string;
}

export interface IActivityHistoryOrderDetail {
  orderId: string;
  restaurantBranchId: string;
  completed: boolean;
  status: ActivityHistoryOrderStatus;
  type: ActivityHistoryType;
  totalAmount: IAmount;
  feesTotal: IAmount;
  itemsTotal: IAmount;
  totalReserved: IAmount;
  totalPaid: IAmount;
  paidPoints: number;
  obtainedPoints: number;
  orderDate: string;
  fulfillments?: IActivityHistoryFulfillment[];
  serviceDetails?: IActivitytHistoryServiceDetail;
  documents: IActivityHistoryDocument[];
  note?: string;
  canCancelOrder: boolean;
  paymentMethod: ActivityHistoryOrderPaymentMethod;
  customerFees?: IOrderFee[];
  openingHours?: IOpeningTime;
  phoneNumber?: string;
  receiptItems?: IActivityHistoryOrderDetailReceiptItem[];
  fundingFlowId?: number;
  paymentDueDate?: string;
  paymentId?: string;
  canRetryPayment?: boolean;
}

export interface IActivityHistoryOrderDetailReceiptItem {
  label: string;
  amount: IAmount;
}

export interface IActivitytHistoryServiceDetail {
  jobName: string;
  jobType: string;
  jobStatus: string;
  urgent: boolean;
  jobAddressId: string;
  jobAddressName: string;
  startedOn: string;
  completedOn: string;
  chatRoomId: string;
  merchantId: string;
  merchantName: string;
  showReviewButton: boolean;
  steps?: IActivityHistoryStep[];
  heynedService: boolean;
}

export interface IActivityHistoryDocument {
  id: string;
  name: string;
  type: ActivityHistoryDocumentType;
  fileType: ActivityHistoryFileType;
  sizeBytes: number;
  url: string;
}

export enum ActivityHistoryDocumentType {
  INVOICE = 'INVOICE',
  REFUND = 'REFUND',
}

export enum ActivityHistoryFileType {
  PDF = 'PDF',
}

export enum ActivityHistoryOrderStatus {
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  UNPAID = 'UNPAID',
}

export enum ActivityHistoryStepStatus {
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
  TODO = ' TODO',
  CANCELLED = 'CANCELLED',
}

export enum ActivityHistoryFulfillmentStatus {
  ONGOING = 'ONGOING',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export interface IActivityHistoryFulfillment {
  id: string;
  buyerFirstName: string;
  buyerLastName: string;
  fullfilledBy: string;
  fullfilledByName: string;
  status: ActivityHistoryFulfillmentStatus;
  deliveryType: ShoppingDeliveryMethodEnum;
  expectedDelivery: string;
  expectedDeliveryShowTime: boolean;
  deliveredOn: string;
  deliveryBy: string;
  deliveryAddress: ILocationV3Address;
  deliveryLocation: IPosition;
  deliveryPhone: string;
  deliveryNote: string;
  deliveryOptionInfo: IDeliveryOptionInfo;
  deliveryOptions: IActivityHistoryFulfillmentDeliveryOption[];
  totalAmount: IAmount;
  itemsTotal: IAmount;
  feesTotal: IAmount;
  totalReserved: IAmount;
  totalPaid: IAmount;
  paidPoints: number;
  obtainedPoints: number;
  fees: IActivityHistoryOrderDetailFee[];
  packages: IActivityHistoryPackage[];
  items: IActivityHistoryItem[];
  steps: IActivityHistoryStep[];
  airtimedataDetails?: IActivityHistoryDataOrderDetail;
  electricityDetails?: IActivityHistoryElectricityOrderDetail;
  moviesDetails?: IActivityHistoryMovieOrderDetail;
  travelDetails?: IActivityHistoryTravelOrderDetail;
  voucherDetails?: IActivityHistoryVoucherOrderDetail;
  tableDetails?: IActivityHistoryTableReservationOrderDetail;
  canReleasePayment: boolean;
  canReturn: boolean;
  canCancel: boolean;
  requiresReturnPickupAddress: boolean;
  returns: IActivityHistoryReturn[];
  recipientsFirstName?: string;
  recipientsLastName?: string;
  recipientsPhone?: string;
  recipientsEmail?: string;
  paymentMethod?: ActivityHistoryOrderPaymentMethod;
  documents: IActivityHistoryDocument[];
}

export interface IDeliveryOptionInfo {
  deliveryMethod: string;
  deliveryByAvo: boolean;
  deliveryBySeller: boolean;
}

export interface IActivityHistoryReturn {
  rmaId: string;
  createdAt: string;
  active: boolean;
}

export interface IActivityHistoryPackage {
  id: string;
  status: ActivityHistoryPackageStatus;
  items: IActivityHistoryItem[];
  sentOn: string;
  delivered: string;
  pickupPIN: string;
  driver: IActivityHistoryDriver;
  canTrackDriver: boolean;
}

export enum ActivityHistoryPackageStatus {
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export interface IActivityHistoryDriver {
  id: string;
  rating: number;
  name: string;
  phone: string;
  location: IPosition;
  photo: ICommonImage;
}

export interface IActivityHistoryFulfillmentDeliveryOption {
  id: string;
  label: string;
  description: string;
}

export interface IActivityHistoryStep {
  order: number;
  label: string;
  description: string;
  status: ActivityHistoryStepStatus;
  payments: IActivityHistoryStepPayment[];
}

export interface IActivityHistoryStepPayment {
  date: string;
  description: string;
  amount: IAmount;
  paymentStatus: ActivityHistoryPaymentStatus;
}

export enum ActivityHistoryPaymentStatus {
  UNPAID = 'UNPAID',
  RESERVED = 'RESERVED',
  READY_FOR_RELEASE = 'READY_FOR_RELEASE',
  PAID = 'PAID',
  INVALID = 'INVALID',
}

export interface IActivityHistoryDataOrderDetail {
  provider: string;
  bundle: string;
  recipientName: string;
  recipientPhone: string;
  description: string;
}

export interface IActivityHistoryElectricityOrderDetail {
  voucherCode: string;
  meterNumber: string;
  recipientName: string;
  recipientPhone: string;
  description: string;
}

export interface IActivityHistoryTravelOrderDetail {
  dateFrom: string;
  dateTo: string;
  travellers: IActivityHistoryTraveller[];
}

export interface IActivityHistoryTraveller {
  id: string;
  name: string;
}

export interface IActivityHistoryOrderDetailFee {
  type: ActivityHistoryFeeType;
  amount: IAmount;
}

export enum ActivityHistoryFeeType {
  SHIPPING = 'SHIPPING',
  CONCIERGE = 'CONCIERGE',
  RIDER_TIP = 'RIDER_TIP',
}

export interface IActivityHistoryCinemaLocation {
  latitude: number;
  longitude: number;
}

export interface IActivityHistoryMovieOrderDetail {
  image: ICommonImage;
  cinemaName: string;
  cinemaAddress: ILocationV3Address;
  cinemaLocation: IActivityHistoryCinemaLocation;
  movie: IActivityHistoryMovieDetail;
  movieName: string;
  movieDetails: string;
  qrCode: string;
  bookingId: string;
  tickets: IActivityHistoryTicket[];
}

export interface IActivityHistoryVoucherOrderDetail {
  productName: string;
  voucherImage: ICommonImage;
  voucherName: string;
  howtoredeem: string;
  code: string;
  expiresAt?: string;
  shareTemplate?: string;
  pdfVoucherAvailable?: boolean;
  voucherSerial?: string;
}

export interface IActivityHistoryTicket {
  seat: string;
  price: IAmount;
  qrCode: string;
}

export interface IActivityHistoryMovieDetail {
  id: string;
  name: string;
  image: string;
  rating: string;
  nextShow: string;
  duration: number;
  genres: IActivityHistoryMovieGenre[];
  language: string;
  is4D: boolean;
  is3D: boolean;
}

export interface IActivityHistoryMovieGenre {
  name: string;
}

export enum ActivityHistoryTableReservationStatus {
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  COUNTER_PROPOSAL = 'COUNTER_PROPOSAL',
  REJECTED = 'REJECTED',
}

export interface IActivityHistoryTableReservationOrderDetail {
  status: ActivityHistoryTableReservationStatus;
  restaurantId: string;
  restaurantName: string;
  restaurantAddress: ILocationV3Address;
  restaurantLocation: IPosition;
  name: string;
  phone: string;
  email: string;
  dateTime: string;
  tableOption?: string;
  occasion?: string;
  note?: string;
  personCount: number;
  counterProposedDates?: string[];
}

export enum ActivityHistoryOrderPaymentMethod {
  ESCROW = 'ESCROW',
  TRANSACTION = 'TRANSACTION',
  PAYMENT_CARD = 'PAYMENT_CARD',
  PAYMENT_CARD_DPO = 'PAYMENT_CARD_DPO',
  WALLET = 'WALLET',
  EFT = 'EFT',
  AMEX = 'AMEX',
}

export interface IActivityHistoryCancelOrderReason {
  id: string;
  name: string;
}

export interface IOrderDetailRequest {
  orderId: string;
}

export interface IActivityHistoryRatingRequest {
  delivery: IActivityHistoryRatingData;
  order: IActivityHistoryRatingData;
  nps: number;
}

export interface IActivityHistoryRatingData {
  rating: number;
  review: string;
}

export interface IActivityHistoryCancelRequest {
  cancelReason: string;
}

export interface IActivityHistoryCancelReasonsResponse {
  cancelReasons: string[];
  cancelFee: IAmount;
}

export interface ICustomerOrderMerchantDetailRequest {
  jobId: string;
  merchantId: string;
}

export interface IJobCardMerchantResponse {
  id: string;
  name: string;
  picture: string;
  rating: number;
  numberOfReviews: number;
  description: string;
  offers: IJobCardJobOffer[];
  pictures: IPicture[];
  distance?: number;
  fees?: IJobCardMerchantFee[];
  phoneNumbers?: IJobCardMerchantPhoneNumber[];
}

export interface IOrderFee {
  amount: IAmount;
  type: OrderFee;
}

export enum OrderFee {
  ESCROW = 'ESCROW',
  SUCCESS_FEE = 'SUCCESS_FEE',
  PICKUP_FEE = 'PICKUP_FEE ',
}

export interface IPurchasedVoucherResponse {
  id: string;
  lineItemId: string;
  orderNumber: string;
  name: string;
  amount: IAmount;
  provisioned: boolean;
  purchasedOn: string;
  voucherCode: string;
  voucherSerial: string;
  voucherImageUrl: string;
  expiresAt: string;
  howToRedeem: string;
  shareTemplate: string;
  pdfVoucherTemplateAvailable: boolean;
  referenceNumber: string;
}
