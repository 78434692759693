import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as LocationActions from './location.actions';
import { LocationEntity, ILocationV3 } from './location.models';

export const LOCATION_FEATURE_KEY = 'location';

export interface LocationState extends EntityState<LocationEntity> {
  locations?: ILocationV3[];
  currentLocation?: ILocationV3;
  isGoogleMapsApiLoaded: boolean;
}

export interface LocationPartialState {
  readonly [LOCATION_FEATURE_KEY]: LocationState;
}

export const locationAdapter: EntityAdapter<LocationEntity> = createEntityAdapter<LocationEntity>();

export const LocationInitialState: LocationState = locationAdapter.getInitialState({ isGoogleMapsApiLoaded: false });

const _locationReducer = createReducer(
  LocationInitialState,
  on(LocationActions.fetchCurrentLocationResponseAction, (state, { data }) => ({
    ...state,
    currentLocation: data,
  })),
  on(LocationActions.setCurrentLocationResponseAction, (state, { data }) => ({
    ...state,
    currentLocation: data,
  })),
  on(LocationActions.fetchNearestCurrentLocationResponseAction, (state, { data }) => ({
    ...state,
    currentLocation: data,
  })),
  on(LocationActions.fetchLocationsResponseAction, (state, { data }) => ({
    ...state,
    locations: data,
  })),
  on(LocationActions.loadGoogleMapsAPIResponseAction, state => ({ ...state, isGoogleMapsApiLoaded: true })),
);

export function locationReducer(state: LocationState | undefined, action: Action) {
  return _locationReducer(state, action);
}
