import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../init/app.config';
import { Observable } from 'rxjs';
import { ISetCurrentLocation, ILocationV3, INewLocationV3, IPosition } from '../store/location/location.models';

@Injectable({
  providedIn: 'root',
})
export class ProfileLocationService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  public fetchLocations(): Observable<ILocationV3[]> {
    return this.httpClient.get<ILocationV3[]>(`${this.appConfig.apiUrl}/profile/v3/customer/location`);
  }

  public deleteLocation(locationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.appConfig.apiUrl}/profile/v3/customer/location/${locationId}`);
  }

  public addLocation(request: INewLocationV3): Observable<ILocationV3> {
    return this.httpClient.post<ILocationV3>(`${this.appConfig.apiUrl}/profile/v3/customer/location`, request);
  }

  public editLocation(locationId: string, request: INewLocationV3): Observable<ILocationV3> {
    return this.httpClient.put<ILocationV3>(
      `${this.appConfig.apiUrl}/profile/v3/customer/location/${locationId}`,
      request,
    );
  }

  public setCurrentLocation(request: ISetCurrentLocation): Observable<ILocationV3> {
    return this.httpClient.post<ILocationV3>(`${this.appConfig.apiUrl}/profile/v3/customer/currentLocation`, request);
  }

  public fetchCurrentLocation(): Observable<ILocationV3> {
    return this.httpClient.get<ILocationV3>(`${this.appConfig.apiUrl}/profile/v3/customer/currentLocation`);
  }

  public fetchNearestLocation(position: IPosition): Observable<ILocationV3> {
    return this.httpClient.get<ILocationV3>(
      `${this.appConfig.apiUrl}/profile/v3/customer/currentLocation/nearest?${new URLSearchParams(position as any)}`,
    );
  }
}
