import { Injectable } from '@angular/core';
import { TaskType } from '../models/store.models';
import { select, Store } from '@ngrx/store';
import { v4 } from 'uuid';
import * as LocationActions from './location.actions';
import { IAddressForm, ILocationV3, INewLocationV3, IPosition, ISetCurrentLocation } from './location.models';
import * as LocationSelectors from './location.selectors';

@Injectable({ providedIn: 'root' })
export class LocationFacade {
  constructor(private readonly store: Store) {}

  getCurrentLocation$ = this.store.pipe(select(LocationSelectors.getLiveLocation));
  getLocations$ = this.store.pipe(select(LocationSelectors.getLocations));
  getIsGoogleMapsAPILoaded$ = this.store.pipe(select(LocationSelectors.isGoogleMapsAPILoaded));

  loadGoogleMapsAPI(onSuccess?: () => void): void {
    this.store.dispatch(
      LocationActions.loadGoogleMapsAPIRequestAction({
        onSuccess,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchLocations(onSuccess?: (data: ILocationV3[]) => void, onError?: () => void) {
    this.store.dispatch(
      LocationActions.fetchLocationsRequestAction({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSuccess,
        onError,
      }),
    );
  }

  deleteLocation(locationId: string, onSucceed?: () => void, onError?: () => void) {
    this.store.dispatch(
      LocationActions.deleteLocationRequestAction({
        locationId,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSucceed,
        onError,
      }),
    );
  }

  addLocation(payload: INewLocationV3, onSucceed?: (data: ILocationV3) => void, onError?: () => void) {
    this.store.dispatch(
      LocationActions.addLocationRequestAction({
        payload,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSucceed,
        onError,
      }),
    );
  }

  editLocation(
    locationId: string,
    payload: INewLocationV3,
    onSucceed?: (data: ILocationV3) => void,
    onError?: () => void,
  ) {
    this.store.dispatch(
      LocationActions.editLocationRequestAction({
        locationId,
        payload,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSucceed,
        onError,
      }),
    );
  }

  setCurrentLocation(payload: ISetCurrentLocation, onSuccess?: () => void, onError?: () => void) {
    this.store.dispatch(
      LocationActions.setCurrentLocationRequestAction({
        payload,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchCurrentLocation(onSuccess?: () => void, onError?: () => void) {
    this.store.dispatch(
      LocationActions.fetchCurrentLocationRequestAction({
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchNearestCurrentLocation(payload: IPosition) {
    this.store.dispatch(
      LocationActions.fetchNearestCurrentLocationRequestAction({
        payload,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchSearchLocationByQuery(
    payload: string,
    onSucceed: (query: string, response: ILocationV3[]) => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      LocationActions.fetchGooglePredictionsRequestAction({
        payload,
        onSucceed,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  findPlaceByAddressString(
    payload: string,
    onSucceed?: (response: IAddressForm, json?: any) => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      LocationActions.fetchPlaceByAddressStringRequestAction({
        payload,
        onSucceed,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  findPlaceByCoordinates(latLng: google.maps.LatLng, onSuccess?: (response: IAddressForm) => void) {
    this.store.dispatch(
      LocationActions.fetchPlaceByCoordinatesRequestAction({
        latLng,
        onSuccess,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  saveCurrentLocation(data: ILocationV3) {
    this.store.dispatch(LocationActions.fetchCurrentLocationResponseAction({ data }));
  }
}
