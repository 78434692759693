import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as vouchersActions from './vouchers-categories.actions';
import { catchError, finalize, map, mergeMap, of } from 'rxjs';
import { httpErrorAction } from '../application/application.actions';
import { TasksFacade } from '../tasks/tasks.facade';
import { TaskType } from '../models/store.models';
import { VouchersCategoriesService } from '../../services/vouchers-categories.service';

@Injectable()
export class VouchersCategoriesEffects {
  constructor(
    private readonly actions$: Actions,
    private vouchersCategories: VouchersCategoriesService,
    private tasksFacade: TasksFacade,
  ) {}

  vouchersCategoriesByTopEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vouchersActions.fetchCategoriesByTopRequestAction),
      mergeMap(({ taskMetadata }) =>
        this.vouchersCategories.fetchCategoriesByTop().pipe(
          map(data =>
            vouchersActions.fetchCategoriesByTopResponseAction({ data, taskMetadata: { type: TaskType.SUCCESS } }),
          ),
          catchError(error => {
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
}
