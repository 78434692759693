export interface IRouteData {
  /** Page title */
  title?: string;

  /** Main content area horizontal padding */
  paddedContentX?: boolean;

  /** Main content area vertical padding */
  paddedContentY?: boolean;

  /** Highlighted section in sticky bottom navigation on mobile */
  bottomNavigationSection?: BottomNavigationSection;

  /** Header settings */
  header?: IHeader;

  /** Sidenav type */
  sidenav?: SidenavType;

  /** Sidenav link */
  sidenavLink?: SidenavItemId;

  browsingCategory?: boolean;
}

export type LoggerContext = { [x: string]: any };

export interface IDatadogRemoteConfig {
  rumEnabled: boolean;
  rumSampleRate: number;
  trackInteractions: boolean;
  sessionReplayEnabled: boolean;
  logsEnabled: boolean;
  logsSampleRate: number;
  replaySampleRate: number;
}

export enum BottomNavigationSection {
  HOME = 'HOME',
  WALLET = 'WALLET',
  DISCOVER = 'DISCOVER',
  INBOX = 'INBOX',
  PROFILE = 'PROFILE',
  HIDDEN = 'HIDDEN',
}

/**
 * Mobile is default and must be set as default values.
 * If you need to have different options on desktop, just set that specific property in desktop object.
 */
export interface IHeader {
  mobile?: IHeaderData;
  tablet?: IHeaderData;
  desktop?: IHeaderData;
}

export interface IHeaderData {
  type?: HeaderType;
  hasSearchWidget?: boolean;
  hasGlobalCart?: boolean;
  hasLocationWidget?: boolean;
  hasUserWidget?: boolean;
  hasSupportWidget?: boolean;

  // todo(lukas.jurygacek): probably logical duplicity for contentBackButton
  hasArrowBack?: boolean;
  hasBottomNavigation?: boolean;
  hasTimer?: boolean;

  /** Options for back button located in content instead of header */
  contentBackButton?: IContentBackButton;
}

export enum HeaderType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  NONE = 'NONE',
}

export enum SidenavType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  NONE = 'NONE',
}

export enum SidenavItemId {
  HOME = 'HOME',
  WALLET = 'WALLET',
  INBOX = 'INBOX',
  PROFILE = 'PROFILE',
  SHOP = 'SHOP',
  PREPAIDS_VOUCHERS = 'PREPAIDS_VOUCHERS',
  TAKEAWAYS = 'TAKEAWAYS',
  LIQUOR = 'LIQUOR',
  GROCERIES = 'GROCERIES',
  BANKING = 'BANKING',
  REWARDS = 'REWARDS',
  AUTO = 'AUTO',
  TRAVEL = 'TRAVEL',
}

export interface IContentBackButton {
  visible: boolean;
  hasText?: boolean;
}

export interface ISearchOptions {
  vertical?: string;
  verticals?: IEcommerceSearchVertical[];
  storeId?: string;
  storeLocationId?: string;
  storeName?: string;
  categoryId?: string;
  category?: string;
}

export interface IEcommerceSearchVertical {
  vertical?: string;
  label?: string;
  iconUrl?: string;
  totalResults?: number;
}

export interface IAmount {
  formatted?: string;
  amount: number;
  currency?: string;
  currencyCode: string;
  currencySymbol?: string;
}

export interface ICountryCode {
  countryCode: string;
}

export enum EnvironmentEnum {
  TEST = 'test',
  STAGE = 'stage',
  PREVIEW = 'preview',
  PROD = 'prod',
}

export interface IPrepaidsResponse {
  subcategories?: IPrepaidsSubcategory[];
}

export interface IGreenbacksStatusRespone {
  level: number;
}

export interface IPrepaidsSubcategory {
  code: string;
  iconUrl?: string;
  name: string;
  subcategories?: IPrepaidsSubcategory[];
  products?: IPrepaidsProduct[];
}

export interface IPrepaidsProduct {
  sku: string;
  skuForAdobe: string;
  productType?: string;
  name?: string;
  description?: string;
  customAmount?: boolean;
  minAmount?: IAmount;
  maxAmount?: IAmount;
  amount?: IAmount;
  suggestedCustomAmount?: IAmount[];
}

export enum PrepaidPages {
  PREPAIDS_LANDING = 'PREPAIDS_LANDING',
  PROVIDERS_LIST = 'PROVIDERS_LIST',
  SUBCATEGORIES_LIST = 'SUBCATEGORIES_LIST',
  PRODUCTS_LIST = 'PRODUCTS_LIST',
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
}

export enum ProviderElectricity {
  code = 'ELECTR_B2B',
  name = 'Electricity',
  productType = 'ELECTRICITY',
}

export enum CategorySlug {
  categorySlug = 'department',
}
