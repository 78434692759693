import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACTIVITY_HISTORY_FEATURE_KEY, IActivityHistoryState } from './activity-history.reducer';

const getState = createFeatureSelector<IActivityHistoryState>(ACTIVITY_HISTORY_FEATURE_KEY);

export const getOngoingOrders = createSelector(getState, state => state.ongoingActivityHistory);
export const getAOrderDetail = createSelector(getState, state => state.orderDetail);
export const getRma = createSelector(getState, state => state.rma);
export const getRmaList = createSelector(getState, state => state.rmaList);
export const getRmaCancelReasons = createSelector(getState, state => state.rmaCancelReasons);
export const getRmaDetail = createSelector(getState, state => state.rmaDetail);
