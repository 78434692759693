import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LAYOUT_FEATURE_KEY, LayoutState } from './layout.reducer';
import { CommonUtility } from '../../utils/common-utility';
import cloneDeep from 'lodash-es/cloneDeep';

export const getLayoutState = createFeatureSelector<LayoutState>(LAYOUT_FEATURE_KEY);

export const getTitle = createSelector(getLayoutState, state => state.title);
export const isSidenavExpanded = createSelector(getLayoutState, state => state.sidenavExpanded);
export const isPaddedContentX = createSelector(getLayoutState, state => state.paddedContentX);
export const isPaddedContentY = createSelector(getLayoutState, state => state.paddedContentY);
export const isContentBackdrop = createSelector(getLayoutState, state => state.contentBackdrop);
export const isContentLightBackdrop = createSelector(getLayoutState, state => state.contentLightBackdrop);
export const getBottomNavigationSection = createSelector(getLayoutState, state => state.bottomNavigationSection);
export const getSidenav = createSelector(getLayoutState, state => state.sidenav);
export const getSidenavLink = createSelector(getLayoutState, state => state.sidenavLink);
export const getCurrentDevice = createSelector(getLayoutState, state => state.currentDevice);
export const getOnBackButtonCallback = createSelector(getLayoutState, state => state.onBackButtonCallback);

export const getHeader = createSelector(getLayoutState, state => {
  let output = cloneDeep(state.header.mobile);

  if (['tablet', 'desktop'].includes(state.currentDevice) && state.header.tablet) {
    output = CommonUtility.addPropertiesToObject(output, state.header.tablet);
  }

  if (state.currentDevice === 'desktop' && state.header.desktop) {
    output = CommonUtility.addPropertiesToObject(output, state.header.desktop);
  }
  return output;
});
