import { ITranslateVariables } from '@avo/shared/avo-translate';
import { IMoney } from '../models/store.models';
import { IAmount } from '../../model/core.model';

export interface CartEntity {
  id: string | number;
  name: string;
}

export interface IAddItemRequest {
  buyNow?: boolean;
  vertical: CartVertical;
  sku: string;
  quantity: number;
  modifiers?: IModifierDraft[];
  substitution?: CartItemSubstitution;
  customAmount?: number;
  note?: string;
  meterNumber?: string;
  targetPhoneNumber?: string;
  description?: string;
  recipientName?: string;
  includeAddonSkus?: string[];
  addonTo?: string;
  trackingId?: string;
  queryId?: string;
  fromGenAIRecommender?: boolean;
  addingFromProductDetail?: boolean;
}

export interface IAddItemResponse {
  cartId: string;
}

export interface IUpdateItemQuantityRequest {
  quantity: number;
}

export interface IFetchCartSummary {
  totalItems: number;
}

export interface IUpdateDeliveryRequest {
  method?: CartDeliveryMethod;
  schedule?: boolean;
  timeslot?: string;
  tipAmount?: number;
}

export interface IUpdateDeliveryAddressRequest {
  locationId: string;
}

export interface IUpdateDeliveryInstructionsRequest {
  instruction?: CartDeliveryInstruction;
  instructionText?: string;
  contactDetails?: string;
  giftCartData?: IGiftCart;
}

export interface BusinessDetailsRequest {
  businessName: string;
  businessVatNumber?: string;
}

export interface IGiftCart {
  name?: string;
  phone?: string;
  message?: string;
}

export interface IPayResponse {
  paymentId: string;
  orderIds: string[];
}

export interface IFetchLiveStatusResponse {
  fulfilments: ICartLiveStatusFulfilment[];
  enablePayment: boolean;
  enableCheckout: boolean;
  roadblocks: CartRoadblock[];
}

export enum CartItemSubstitution {
  LET_SHOPPER_CHOOSE = 'LET_SHOPPER_CHOOSE',
  DO_NOT_SUBSTITUTE = 'DO_NOT_SUBSTITUTE',
}

export interface ICartGlobal {
  cartId: string;
  iconUrl: string;
  label: string;
  total: IMoney;
  items: ICartGlobalItem[];
}

export interface ICartGlobalItem {
  text: string;
  quantity: number;
  size?: string;
  color?: ICartItemColor;
}

export interface ICartItemColor {
  label: string;
  hexCode: string;
}

export interface ICart {
  cartId: string;
  vertical: CartVertical;
  deliveryAddress: ICartDeliveryAddress;
  fulfilments: ICartFulfilment[];
  outOfStockItems?: ICartItem[];
  roadblocks?: CartRoadblock[];
  deliveryInstruction: CartDeliveryInstruction;
  availableDeliveryInstructions: CartDeliveryInstruction[];
  deliveryInstructionNote: string;
  deliveryContact: string;
  giftCartData?: IGiftCart;
  enableCheckout: boolean;
  enablePayment: boolean;
  creditOnly: boolean;
  enablePromoCodes: boolean;
  promoCodes: ICartPromoCode[];
  receiptItems: ICartReceiptItem[];
  showDeliveryContactField: boolean;
  showDeliveryInstructionNoteField: boolean;
  total: IMoney;
  singlePromoOnly?: boolean;
  tvLicenseData?: ICartTvLicense;
  loanData?: ICartLoad;
  orderAmountLimit: IMoney;
  insuranceData?: ICartInsuranceData;
}

export interface ICartInsuranceData {
  complete: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  saId: string;
  passport: string;
}

export enum CartVertical {
  SHOPPING = 'SHOPPING',
  TAKEAWAYS = 'TAKEAWAYS',
  LIQUOR = 'LIQUOR',
  GROCERIES = 'GROCERIES',
  VOUCHERS = 'VOUCHERS',
  VAS = 'VAS',
  TRAVEL = 'TRAVEL',
  ESHOP = 'ESHOP',
}

export interface ICartLoad {
  term: number;
  monthlyPayment: IAmount;
  loanAmount: IAmount;
  totalAmountRepayable: IAmount;
}

export interface ICartTvLicense {
  complete: boolean;
  licenseHolder: string;
  licenseNumber: string;
}

export interface ICartDeliveryAddress {
  locationId: string;
  locationName: string;
  addressString: string;
}

export enum CartRoadblock {
  INCOMPATIBLE_CREDIT_ONLY_ITEMS = 'INCOMPATIBLE_CREDIT_ONLY_ITEMS',
  AWAITING_STOCK_CONFIRMATION = 'AWAITING_STOCK_CONFIRMATION',
  PURCHASE_OVER_LIMIT = 'PURCHASE_OVER_LIMIT',
  MISSING_DELIVERY_ADDRESS = 'MISSING_DELIVERY_ADDRESS',
  LIMITED_DELIVERY_TIME = 'LIMITED_DELIVERY_TIME',
}

export interface ICartFulfilment {
  fulfilmentId: string;
  fulfilledBy: string;
  fulfilledByNote: string;
  stores: ICartStore[];
  fees: ICartFee[];
  selectedDeliveryMethod: ICartDeliveryMethod;
  availableDeliveryMethods: ICartDeliveryMethod[];
  selectedTip: ICartTipOption;
  availableTipOptions: ICartTipOption[];
  availableInLocation: boolean;
  driverStatus?: CartDriverStatus;
  roadblocks?: CartFulfilmentRoadblock[];
}

export enum CartFulfilmentRoadblock {
  DRIVER_CHECK_PENDING = 'DRIVER_CHECK_PENDING',
  DRIVER_CHECK_FAILED = 'DRIVER_CHECK_FAILED',
}

export enum CartDriverStatus {
  WAITING = 'WAITING',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export interface ICartStore {
  iconUrl: string;
  label: string;
  total: IMoney;
  items: ICartItem[];
  storeId: string;
  storeStatus: CartStoreStatus;
  opensAt: string;
}

export enum CartStoreStatus {
  OPENED = 'OPENED',
  CLOSING_SOON = 'CLOSING_SOON',
  CLOSED = 'CLOSED',
}

export interface ICartItem {
  itemId: string;
  adobeSku?: string;
  sku: string;
  imageUrl: string;
  title: string;
  description: string;
  price: IMoney;
  quantity: number;
  quantityLimitedTo: number;
  substitutionOption: CartItemSubstitution;
  roadblocks?: CartItemRoadblock[];
  size?: string;
  color?: ICartItemColor;
  addonToItemId?: string;
  recommendedAddons?: ICartItemAddon[];
  addons?: ICartItem[];
}

export interface ICartItemAddon {
  checkedByDefault: boolean;
  imageUrl: string;
  price: IMoney;
  priceBeforeDiscount?: IMoney;
  sku: string;
  title: string;
}

export enum CartItemRoadblock {
  LIMITED_QUANTITY = 'LIMITED_QUANTITY',
  LIMITED_QUANTITY_CUSTOM_AMOUNT = 'LIMITED_QUANTITY_CUSTOM_AMOUNT',
  ITEM_AVAILABLE_VIA_APP_ONLY = 'ITEM_AVAILABLE_VIA_APP_ONLY',
  ITEM_OUT_OF_STOCK = 'ITEM_OUT_OF_STOCK',
  TV_LICENSE_REQUIRED = 'TV_LICENSE_REQUIRED',
  LIMITED_TO_CREDIT_ONLY = 'LIMITED_TO_CREDIT_ONLY',
  ITEM_NO_LONGER_AVAILABLE = 'ITEM_NO_LONGER_AVAILABLE',
  MISSING_SUBSTITUTION_OPTION = 'MISSING_SUBSTITUTION_OPTION',
  INSURANCE_DATA = 'INSURANCE_DATA',
}

export interface ICartFee {
  feeType: CartFee;
  note: string;
  amount: IMoney;
}

export enum CartFee {
  DELIVERY = 'DELIVERY',
  CONCIERGE = 'CONCIERGE',
  ESCROW = 'ESCROW',
}

export interface ICartDeliveryMethod {
  deliveryMethod: CartDeliveryMethod;
  scheduled: boolean;
  deliveryTimespan: ICartDeliveryTimespan;
  scheduledFor: ICartTimeSlot;
  enableDriverTip: boolean;
}

export enum CartDeliveryMethod {
  TO_DOOR = 'TO_DOOR',
  STORE_PICKUP = 'STORE_PICKUP',
  DIGITAL_DELIVERY = 'DIGITAL_DELIVERY',
}

export interface ICartDeliveryTimespan {
  from: number;
  to: number;
  units: CartTimespanUnit;
}

export enum CartTimespanUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export interface ICartTimeSlot {
  timeslotId: string;
  from: string;
  to: string;
}

export interface ICartTipOption {
  label: string;
  amount: IMoney;
}

export enum CartDeliveryInstruction {
  MEET_AT_DOOR = 'MEET_AT_DOOR',
  LEAVE_AT_GATE = 'LEAVE_AT_GATE',
  GIFT_CART = 'GIFT_CART',
}

export interface ICartPromoCode {
  promoCode: string;
  iconUrl?: string;
  title: string;
  description?: string;
  appliedDiscount?: IMoney;
  reasonForNotApplied?: string;
  applied: boolean;
  valid: boolean;
  selected?: boolean;
}

export interface ICartReceiptItem {
  label: string;
  note: ITranslateVariables;
  amount: IMoney;
}

export interface IModifierDraft {
  modifierId: string;
  items: IModifierItemDraft[];
}

export interface IModifierItemDraft {
  itemId: string;
  modifiers?: IModifierDraft[];
  quantity: number;
  note?: string;
  price?: number;
}

export interface ICartLiveStatusFulfilment {
  fulfilmentId: string;
  stores: ICartLiveStatusStore[];
  driverStatus: CartDriverStatus;
}

export interface ICartLiveStatusStore {
  storeId: string;
  storeStatus: CartStoreStatus;
  opensAt: string;
  items: ICartItem[];
}

export interface IApplyPromoCodesRequest {
  promoCodes: string[];
}

export interface ITermAgreementSuccessFailureUrls {
  redirectSuccessUrl: string;
  redirectFailUrl: string;
}

export interface ITermAgreementSuccessFailureUrls {
  redirectSuccessUrl: string;
  redirectFailUrl: string;
}

export interface ITermAgreementPaymentResponse {
  completionUrl: string;
  orderNumber: string;
  orderNumbers: string[];
  paymentId: string;
  pointsEarned: number;
  termAgreementResponse: ITermAgreementResponse;
  verticals: string[];
}

export interface ITermAgreementResponse {
  termAgreementPaymentId: string;
  forcedStatus?: ITermAgreementPaymentStatuses;
}

export enum ITermAgreementPaymentStatuses {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
}

export interface IInsuranceData {
  consentToShareData: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  saId?: string;
  passportNumber?: string;
}
