import { UiBadgeColor } from '../badge';

export interface IUiCardProductData {
  sku: string;
  slug: string;
  adobeSku?: string;
  title: string;
  image: string;
  price: number;
  currencyCode: string;
  priceBeforeDiscount?: number;
  description?: string;
  rating?: number;
  points?: number;
  trackingId?: string;
  bigButtonType?: UiCardBigButtonType;
  bigButtonDisabled?: boolean;
  creditOnlyItem?: boolean;
  monthlyRepayment?: IMoney;
  totalCreditPayment?: IMoney;
  spinner?: boolean;
  minPrice?: IMoney;
  maxPrice?: IMoney;
  tags?: IUiTagData[];
  greenbacksData?: IUiGreenbacksData | null;
  isPrepaid?: boolean;
  vertical?: SearchVertical;
  queryId?: string;
  termAgreement?: boolean;
  termInMonths?: number;
}

export enum SearchVertical {
  ALL = 'ALL',
  ESHOP = 'ESHOP',
  TAKEAWAYS = 'TAKEAWAYS',
  GROCERIES = 'GROCERIES',
  VOUCHERS = 'VOUCHERS',
  VAS = 'VAS',
  LIQUOR = 'LIQUOR',
  PREPAIDS = 'PREPAIDS',
  BANKING = 'BANKING',
  SERVICES = 'SERVICES',
  TRAVEL = 'TRAVEL',
  HEALTH = 'HEALTH',
  PHARMACY = 'PHARMACY',
}

export enum UiCardProductType {
  GRID = 'GRID',
  LIST = 'LIST',
  MINI = 'MINI',
}

export enum UiCardBigButtonType {
  ADD_TO_CART = 'ADD_TO_CART',
  BUY_NOW = 'BUY_NOW',
  DETAIL = 'DETAIL',
  TOP_UP = 'TOP_UP',
  CUSTOMIZE = 'CUSTOMIZE',
}

interface IMoney {
  formatted?: string;
  amount: number;
  currencyCode: string;
}

export enum UiCardProductImageFit {
  COVER = 'COVER',
  CONTAIN = 'CONTAIN',
}

export enum Position {
  LABEL = 'LABEL',
  ICON_TOP_LEFT = 'ICON_TOP_LEFT',
  ICON_TOP_RIGHT = 'ICON_TOP_RIGHT',
}

export interface IUiTagData {
  label: string;
  color: UiBadgeColor;
  position?: Position;
  icon?: string;
  textColor?: UiBadgeColor;
}

export interface IUiGreenbacksData {
  greenbacksUser: boolean;
  price: IMoney;
  percentDiscount: number;
  discount: IMoney;
}
