import { Action, createReducer, on } from '@ngrx/store';
import { fetchFeatureFlagsResponseAction } from './feature-flags.actions';
import { FeatureFlag } from '../../model/feature-flags.model';

export interface FeatureFlagsState {
  featureFlags: FeatureFlag[];
}

const initialState: FeatureFlagsState = {
  featureFlags: [],
};

export const FEATURE_FLAGS_FEATURE_KEY = 'featureFlags';

const _featureFlagsReducer = createReducer(
  initialState,
  on(fetchFeatureFlagsResponseAction, (state, { response }) => {
    return { ...state, featureFlags: response };
  }),
);

export function featureFlagsReducer(state: FeatureFlagsState | undefined, action: Action) {
  return _featureFlagsReducer(state, action);
}
