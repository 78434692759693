import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GROCERIES_FEATURE_KEY, GroceriesState } from './groceries.reducer';

export const getGroceriesState = createFeatureSelector<GroceriesState>(GROCERIES_FEATURE_KEY);

export const getCart = createSelector(getGroceriesState, state => state?.cart);

export const getCartCount = createSelector(getGroceriesState, state =>
  state?.cart?.fulfillments?.reduce(
    (acc, fullfillment) =>
      acc +
      fullfillment.malls?.reduce(
        (acc, mall) => acc + mall.stores?.reduce((acc, store) => acc + (store.items?.length ?? 0), 0),
        0,
      ),
    0,
  ),
);
