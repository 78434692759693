import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, of, map, catchError } from 'rxjs';
import { environment } from '@avo/environment/customer/environment';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKeys } from '../store/models/store.models';

@Injectable({
  providedIn: 'root',
})
export class ClientBuildDetailsHttpService {
  constructor(private httpClient: HttpClient, private browserStorageService: BrowserStorageService) {}

  private _storageBuildNumber: '';

  fetchServerBuildDetails(): Observable<any> {
    const url = `https://${environment.domainUrl}/build.json`;
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache',
    });
    return this.httpClient.get<any>(url, { headers }).pipe(
      map(response => {
        return response.buildNumber;
      }),
      catchError(error => {
        return observableThrowError(() => error);
      }),
    );
  }

  convertUrlToJSON(url: string): string {
    if (!url.endsWith('.html')) {
      throw new Error(`Invalid URL: ${url} does not end with '.html'`);
    }
    return url.replace('.html', '.json');
  }

  fetchClientBuildDetails(): Observable<any> {
    // Check if there is a buildNumber in localStorage, if yes return it, else return an error
    this._storageBuildNumber = this.browserStorageService.get(StorageKeys.BUILD, false);

    if (this._storageBuildNumber) {
      return of(this._storageBuildNumber);
    } else {
      return of('0');
    }
  }
}
