import { Pipe, PipeTransform } from '@angular/core';
import { AppConfig } from '../init/app.config';
import { IPrepaidsSubcategory, PrepaidPages, ProviderElectricity } from '../model/core.model';
import { CommonUtility } from '../utils/common-utility';
import { UrlRoutes } from '../routes/routes';

@Pipe({
  name: 'prepaidCategoryUrl',
  standalone: true,
})
export class PrepaidCategoryUrlPipe implements PipeTransform {
  constructor(private appConfig: AppConfig) {}
  /**
   * Generates url based on input params on prepaids pages
   * @param subcategory subcategory code
   * @param currentLocation page enum where the link is positioned
   * @param urlCode code of provider (MTN, )
   * @param subcategoryCode
   * @returns
   */
  transform(
    subcategory: IPrepaidsSubcategory | undefined,
    currentLocation: PrepaidPages,
    urlCode?: string,
    subcategoryCode?: string,
  ): string {
    if (currentLocation === PrepaidPages.PREPAIDS_LANDING || currentLocation === PrepaidPages.PROVIDERS_LIST) {
      if (urlCode === ProviderElectricity.code) {
        return CommonUtility.format(`${UrlRoutes.prepaids.providers.product}`, this.appConfig.prepaidElectricitySku);
      } else {
        return CommonUtility.format(`${UrlRoutes.prepaids.providers.products}`, urlCode);
      }
    } else {
      if (subcategoryCode) {
        return CommonUtility.format(
          `${UrlRoutes.prepaids.providers.subcategoryChild}`,
          urlCode,
          subcategoryCode,
          subcategory?.code,
        );
      } else {
        return CommonUtility.format(`${UrlRoutes.prepaids.providers.subcategory}`, urlCode, subcategory?.code);
      }
    }
  }
}
