import { Pipe, PipeTransform } from '@angular/core';
import { IPrepaidsSubcategory, ProviderElectricity } from '../model/core.model';
import { UrlRoutes } from '../routes/routes';
import { CommonUtility } from '../utils/common-utility';

@Pipe({ name: 'prepaidBackUrl', standalone: true })
export class PrepaidBackUrlPipe implements PipeTransform {
  /** Returns prepaids home for electricity otherwise returns either [code]/subcategories or [code]/products */
  transform(provider: IPrepaidsSubcategory | undefined): string {
    if (provider.code === ProviderElectricity.code) {
      return UrlRoutes.prepaids.home;
    } else {
      return CommonUtility.format(`${UrlRoutes.prepaids.providers.products}`, provider.code);
    }
  }
}
