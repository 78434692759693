import configuration from './../../apps/customer/shell/src/configurations/test/configuration.json';
import { IEnvironmentAvo3 } from '@avo/shared/core';

const getEnv = (): IEnvironmentAvo3 => {
  if (window.location.hostname.indexOf('localhost') >= 0) {
    return {
      production: false,
      baseUrl: 'http://localhost:4200',
      environment: 'dev',
      domainUrl: 'test.avo.africa',
      maintenanceUrl: '#',
      adobeAnalyticsUrl: 'https://assets.adobedtm.com/6422e0f550a2/c602394421e9/launch-b49ed4cf5fe2-development.min.js',
      buildNumberUrl: 'https://test.avo.africa/build.html',
      remotes: {
        'mfe-dashboard': 'http://localhost:4300/remoteEntry.js',
        'mfe-auth': 'http://localhost:4301/remoteEntry.js',
        'mfe-ecommerce': 'http://localhost:4302/remoteEntry.js',
        'mfe-wallet': 'http://localhost:4324/remoteEntry.js',
        'mfe-banking': 'http://localhost:4306/remoteEntry.js',
        'mfe-avo2': 'http://localhost:4323/remoteEntry.js',
        'mfe-prepaids': 'http://localhost:4307/remoteEntry.js',
        'mfe-payments': 'http://localhost:4326/remoteEntry.js',
        'mfe-profile': 'http://localhost:4322/remoteEntry.js',
        'mfe-inbox': 'http://localhost:4330/remoteEntry.js',
        'mfe-rewards': 'http://localhost:4309/remoteEntry.js',
      },
      datadog: {
        applicationId: 'aaea0409-4dbc-4f0b-b28c-4341e17bbbf5',
        clientToken: 'pub38bdae3b6e9f2c24e00c4cc079aaa3bd',
        site: 'datadoghq.eu',
        service: 'pwa-customer-3.0',
        remoteConfigUrl: 'https://test-cdn.avo.africa/config/datadog/customer-pwa.json',
      },
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
        vapidKey: 'BFjEpnD3Lc2Nlt9ms6lOk1aWNaIrKfpOsKyhw2dNnf7kFxNBJEO1MikDzLeiZDfvokGIs6-PTaOozScUkxGycVI',
      },
      facebookClientId: '2325335491101194',
      appleClientId: 'test.avo.africa',
      googleClientId: '181910611316-6rjoo9g3rhurbrhgughfquidhhbhn5ie.apps.googleusercontent.com',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
    };
  } else if (window.location.hostname.indexOf('test') >= 0) {
    return {
      production: true,
      environment: 'test',
      domainUrl: 'test.avo.africa',
      baseUrl: configuration.baseUrl,
      maintenanceUrl: 'https://test-localisation-cdn.avo.africa/maintenance-main-on.json',
      adobeAnalyticsUrl: 'https://assets.adobedtm.com/6422e0f550a2/c602394421e9/launch-b49ed4cf5fe2-development.min.js',
      buildNumberUrl: 'https://test.avo.africa/build.html',
      remotes: {
        'mfe-dashboard': configuration.remotes['mfe-dashboard'],
        'mfe-auth': configuration.remotes['mfe-auth'],
        'mfe-ecommerce': configuration.remotes['mfe-ecommerce'],
        'mfe-wallet': configuration.remotes['mfe-wallet'],
        'mfe-banking': configuration.remotes['mfe-banking'],
        'mfe-avo2': configuration.remotes['mfe-avo2'],
        'mfe-prepaids': configuration.remotes['mfe-prepaids'],
        'mfe-payments': configuration.remotes['mfe-payments'],
        'mfe-profile': configuration.remotes['mfe-profile'],
        'mfe-inbox': configuration.remotes['mfe-inbox'],
        'mfe-rewards': configuration.remotes['mfe-rewards'],
      },
      datadog: {
        applicationId: 'aaea0409-4dbc-4f0b-b28c-4341e17bbbf5',
        clientToken: 'pub38bdae3b6e9f2c24e00c4cc079aaa3bd',
        site: 'datadoghq.eu',
        service: 'pwa-customer-3.0',
        remoteConfigUrl: 'https://test-cdn.avo.africa/config/datadog/customer-pwa.json',
      },
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
        vapidKey: 'BFjEpnD3Lc2Nlt9ms6lOk1aWNaIrKfpOsKyhw2dNnf7kFxNBJEO1MikDzLeiZDfvokGIs6-PTaOozScUkxGycVI',
      },
      facebookClientId: '2325335491101194',
      appleClientId: 'test.avo.africa',
      googleClientId: '181910611316-6rjoo9g3rhurbrhgughfquidhhbhn5ie.apps.googleusercontent.com',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
    };
  } else if (window.location.hostname.indexOf('stage') >= 0) {
    return {
      production: true,
      environment: 'stage',
      domainUrl: 'stage.avo.africa',
      baseUrl: 'https://stage.avo.africa',
      maintenanceUrl: 'https://stage-localisation-cdn.avo.africa/maintenance-main-on.json',
      adobeAnalyticsUrl: 'https://assets.adobedtm.com/6422e0f550a2/c602394421e9/launch-dc9453bc33a2-staging.min.js',
      buildNumberUrl: 'https://stage.avo.africa/build.html',
      remotes: {
        'mfe-dashboard': 'https://stage.avo.africa/mfe-dashboard/remoteEntry.js',
        'mfe-auth': 'https://stage.avo.africa/mfe-auth/remoteEntry.js',
        'mfe-ecommerce': 'https://stage.avo.africa/mfe-ecommerce/remoteEntry.js',
        'mfe-wallet': 'https://stage.avo.africa/mfe-wallet/remoteEntry.js',
        'mfe-banking': 'https://stage.avo.africa/mfe-banking/remoteEntry.js',
        'mfe-avo2': 'https://stage.avo.africa/mfe-avo2/remoteEntry.js',
        'mfe-prepaids': 'https://stage.avo.africa/mfe-prepaids/remoteEntry.js',
        'mfe-payments': 'https://stage.avo.africa/mfe-payments/remoteEntry.js',
        'mfe-profile': 'https://stage.avo.africa/mfe-profile/remoteEntry.js',
        'mfe-inbox': 'https://stage.avo.africa/mfe-inbox/remoteEntry.js',
        'mfe-rewards': 'https://stage.avo.africa/mfe-rewards/remoteEntry.js',
      },
      datadog: {
        applicationId: 'aaea0409-4dbc-4f0b-b28c-4341e17bbbf5',
        clientToken: 'pub38bdae3b6e9f2c24e00c4cc079aaa3bd',
        site: 'datadoghq.eu',
        service: 'pwa-customer-3.0',
        remoteConfigUrl: 'https://stage-cdn.avo.africa/config/datadog/customer-pwa.json',
      },
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
        vapidKey: 'BFjEpnD3Lc2Nlt9ms6lOk1aWNaIrKfpOsKyhw2dNnf7kFxNBJEO1MikDzLeiZDfvokGIs6-PTaOozScUkxGycVI',
      },
      facebookClientId: '2325335491101194',
      appleClientId: 'stage.avo.africa',
      googleClientId: '181910611316-6rjoo9g3rhurbrhgughfquidhhbhn5ie.apps.googleusercontent.com',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
    };
  } else if (window.location.hostname.indexOf('preview') >= 0) {
    return {
      production: true,
      environment: 'preview',
      domainUrl: 'preview.avo.africa',
      baseUrl: 'https://preview.avo.africa',
      maintenanceUrl: 'https://preview-localisation-cdn.avo.africa/maintenance-main-on.json',
      adobeAnalyticsUrl: 'https://assets.adobedtm.com/6422e0f550a2/c602394421e9/launch-e3b6a56f5897.min.js',
      buildNumberUrl: 'https://preview.avo.africa/build.html',
      remotes: {
        'mfe-dashboard': 'https://preview.avo.africa/mfe-dashboard/remoteEntry.js',
        'mfe-auth': 'https://preview.avo.africa/mfe-auth/remoteEntry.js',
        'mfe-ecommerce': 'https://preview.avo.africa/mfe-ecommerce/remoteEntry.js',
        'mfe-wallet': 'https://preview.avo.africa/mfe-wallet/remoteEntry.js',
        'mfe-banking': 'https://preview.avo.africa/mfe-banking/remoteEntry.js',
        'mfe-avo2': 'https://preview.avo.africa/mfe-avo2/remoteEntry.js',
        'mfe-prepaids': 'https://preview.avo.africa/mfe-prepaids/remoteEntry.js',
        'mfe-payments': 'https://preview.avo.africa/mfe-payments/remoteEntry.js',
        'mfe-profile': 'https://preview.avo.africa/mfe-profile/remoteEntry.js',
        'mfe-inbox': 'https://preview.avo.africa/mfe-inbox/remoteEntry.js',
        'mfe-rewards': 'https://preview.avo.africa/mfe-rewards/remoteEntry.js',
      },
      datadog: {
        applicationId: 'aaea0409-4dbc-4f0b-b28c-4341e17bbbf5',
        clientToken: 'pub38bdae3b6e9f2c24e00c4cc079aaa3bd',
        site: 'datadoghq.eu',
        service: 'pwa-customer-3.0',
        remoteConfigUrl: 'https://cdn.avo.africa/config/datadog/customer-pwa.json',
      },
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
        vapidKey: 'BFjEpnD3Lc2Nlt9ms6lOk1aWNaIrKfpOsKyhw2dNnf7kFxNBJEO1MikDzLeiZDfvokGIs6-PTaOozScUkxGycVI',
      },
      facebookClientId: '2325335491101194',
      appleClientId: 'preview.avo.africa',
      googleClientId: '181910611316-6rjoo9g3rhurbrhgughfquidhhbhn5ie.apps.googleusercontent.com',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
    };
  } else {
    return {
      production: true,
      environment: 'prod',
      domainUrl: 'www.avo.africa',
      baseUrl: 'https://www.avo.africa',
      maintenanceUrl: 'https://localisation-cdn.avo.africa/maintenance-main-on.json',
      adobeAnalyticsUrl: 'https://assets.adobedtm.com/6422e0f550a2/c602394421e9/launch-e3b6a56f5897.min.js',
      buildNumberUrl: 'https://www.avo.africa/build.html',
      remotes: {
        'mfe-dashboard': 'https://www.avo.africa/mfe-dashboard/remoteEntry.js',
        'mfe-auth': 'https://www.avo.africa/mfe-auth/remoteEntry.js',
        'mfe-ecommerce': 'https://www.avo.africa/mfe-ecommerce/remoteEntry.js',
        'mfe-wallet': 'https://www.avo.africa/mfe-wallet/remoteEntry.js',
        'mfe-banking': 'https://www.avo.africa/mfe-banking/remoteEntry.js',
        'mfe-avo2': 'https://www.avo.africa/mfe-avo2/remoteEntry.js',
        'mfe-prepaids': 'https://www.avo.africa/mfe-prepaids/remoteEntry.js',
        'mfe-payments': 'https://www.avo.africa/mfe-payments/remoteEntry.js',
        'mfe-profile': 'https://www.avo.africa/mfe-profile/remoteEntry.js',
        'mfe-inbox': 'https://www.avo.africa/mfe-inbox/remoteEntry.js',
        'mfe-rewards': 'https://www.avo.africa/mfe-rewards/remoteEntry.js',
      },
      datadog: {
        applicationId: 'aaea0409-4dbc-4f0b-b28c-4341e17bbbf5',
        clientToken: 'pub38bdae3b6e9f2c24e00c4cc079aaa3bd',
        site: 'datadoghq.eu',
        service: 'pwa-customer-3.0',
        remoteConfigUrl: 'https://cdn.avo.africa/config/datadog/customer-pwa.json',
      },
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
        vapidKey: 'BFjEpnD3Lc2Nlt9ms6lOk1aWNaIrKfpOsKyhw2dNnf7kFxNBJEO1MikDzLeiZDfvokGIs6-PTaOozScUkxGycVI',
      },
      facebookClientId: '2325335491101194',
      appleClientId: 'avo.africa',
      googleClientId: '181910611316-6rjoo9g3rhurbrhgughfquidhhbhn5ie.apps.googleusercontent.com',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
    };
  }
};
export const environment = getEnv();
