import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { UiToastComponent } from './ui-toast.component';
import { UiToastInput } from './ui-toast.model';

@Injectable({ providedIn: 'root' })
export class UiToastService {
  constructor(private matSnackBar: MatSnackBar) {}

  open = (config: MatSnackBarConfig<UiToastInput>): MatSnackBarRef<UiToastComponent> =>
    this.matSnackBar.openFromComponent(UiToastComponent, {
      duration: 4000,
      verticalPosition: 'top',
      ...config,
    });
}
