import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable()
export class SessionIdInterceptor implements HttpInterceptor {
  private readonly sessionService = inject(SessionService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (!request.url.includes('loc-saas') && !request.url.includes('configuration.json')) {
      request = request.clone({
        headers: request.headers.set('X-Avo-SessionId', this.sessionService.sessionId),
      });
    }

    return next.handle(request);
  }
}
