import { inject, Injectable } from '@angular/core';
import { AppConfig } from '../../configs/app.config';
import { CommonUtility } from '../../utils/commonUtility';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { SessionService } from '@avo/shared/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private readonly appConfig = inject(AppConfig);
  private readonly http = inject(HttpClient);
  private readonly sessionService = inject(SessionService);

  init(apiaryBaseUrl: string, appType: string, baseUrl?: string): Promise<void> {
    return new Promise<void>((resolve: any, reject: any): any => {
      const sessionId = this.sessionService.sessionId;

      // there has to be any type because we don't want to import shared/core library
      this.http
        .get<any>(baseUrl ? `${baseUrl}/configuration.json` : 'configuration.json')
        .pipe(
          tap(file => {
            if (file && file.apiUrl) {
              const authUrl = `${file.apiUrl}/users/auth`;
              const onboardingUrl = `${file.apiUrl}/users/v1`;
              const onboardingV2Url = `${file.apiUrl}/users/v2`;
              const transactionSigningUrl = `${file.apiUrl}/users/v1`;

              this.appConfig.isDevelopmentUrl = window.location.hostname.includes('localhost');
              this.appConfig.appType = appType;
              this.appConfig.backendConfig = {
                apiUrl: file.apiUrl,
                apiV3Url: file.apiUrl,
                translateUrl: CommonUtility.getTranslateUrl(file.apiUrl),
                authUrl,
                onboardingUrl,
                onboardingV2Url,
                transactionSigningUrl,
                apiaryUrl: apiaryBaseUrl,
                sessionId,
                websocketUrl: `${file.websocketUrl}/chat?token=`,
              };
              this.setAvoBaseUrl();
              // This is requirement from business
              console.log('Session ID: ', sessionId);
            }
            resolve();
          }),
          catchError(() => reject()),
        )
        .subscribe();
    });
  }

  private setAvoBaseUrl() {
    const environment = CommonUtility.getEnvironment();
    this.appConfig.avoBusinessBaseUrl = `https://${environment === 'prod' ? '' : environment + '.'}avobusiness.africa`;
  }
}
