export interface IAmount {
  amount: number;
  currency?: string;
  currencyCode?: string;
  currencySymbol?: string;
}

export interface ISelect {
  id: string;
  text: string;
}

export interface IPicture {
  id: string;
  url: string;
}

export interface IPhonePipeParam {
  prefix: string;
  maxLength: number;
  formInputTransform?: boolean;
}

export interface ISaIdPipeParam {
  maxLength: number;
}

export interface ICommonOnChangeOutput {
  name: string;
  value?: any;
  id?: any;
  item?: any;
}

export enum Day {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface ICommonDaysWithOpeningHours {
  openingHours: DaysWithOpeningHours;
  note?: string;
}

export interface ICommonImage {
  variants: ICommonImageVariant[];
  altText?: string;
  recommended?: ICommonImageVariant;
}

export interface ICommonImageVariant {
  url: string;
  height: number;
  width: number;
  usePercentage?: boolean;
  autoFit?: boolean;
}

export enum ChartColor {
  ORANGE = '#fa9c00',
  VIOLET = '#daa0fe',
  YELLOW = '#c9e000',
  GREEN = '#25ab53',
  GREY = '#666666',
  BLUE = '#0052ff',
  RED = '#db504a',
  BLUE_DARK = '#212f3c',
  GREEN_DARK = '#145a32',
  BLUE_LIGHT = '#5dade2',
  PINK = '#fadbd8',
  BROWN = '#a04000',
  TURQUOISE = '#16A085',
  GREY_LIGHT = '#f8f8f8',
}

export enum SaveAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export enum WeekDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface ISingleDayOpeningHours {
  opened?: boolean;
  openedFrom?: string;
  openedTo?: string;
}

export type DaysWithOpeningHours = {
  [K in WeekDays]: ISingleDayOpeningHours;
};

export interface ITaxPrice {
  withoutTax: IAmount;
  withTax: IAmount;
  tax: IAmount;
}

export interface IAccordionItem {
  title: string;
  subtitle: string;
}

export interface IEnvironment {
  baseUrl?: string;
  production: boolean;
  googleMapApiKey: string;
  adobe: string;
  showEnrollment?: boolean;
  clientId: string;
  scopes: string;
  responseType: string;
  translateApplicationName: string;
  groceriesUrl: string;
  cardTopupSuccessCallbackUrl: string;
  cardTopupFailCallbackUrl: string;
  cardTopupTryAgainCallbackUrl: string;
  cardTopupErrorCallbackUrl: string;
  addCardTopupSuccessCallbackUrl: string;
  addCardTopupFailCallbackUrl: string;
  addCardTopupTryAgainCallbackUrl: string;
  addCardTopupErrorCallbackUrl: string;
  cardPaymentSuccessCallbackUrl: string;
  cardPaymentFailCallbackUrl: string;
  cardPaymentTryAgainCallbackUrl: string;
  cardPaymentErrorCallbackUrl: string;
  apiaryBaseUrl: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  appType: string;
  nedbankMoneyAndroidStoreLink: string;
  nedbankMoneyIosStoreLink: string;
  nedbankMoneyHuawaiGalleryStoreLink: string;
  facebookClientId: string;
  appleClientId: string;
  googleClientId: string;
  datadog?: IDatadog;
}
export interface IOpeningTime {
  opened: boolean;
  openedFrom: string;
  openedTo: string;
}

export interface IDatadog {
  applicationId: string;
  clientToken: string;
  site: string;
  service: string;
}
