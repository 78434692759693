import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

/**
 * Interface for the 'Application' data
 */
export interface ApplicationEntity {
  id: string | number; // Primary ID
  name: string;
}

export interface ITaskAction extends Action {
  taskMetadata: ITask;
}

export interface ITask {
  taskId?: string;
  type: TaskType;
  action?: ITaskAction;
}

export enum TaskType {
  STARTED = 'STARTED',
  STARTED_FULLSCREEN = 'STARTED_FULLSCREEN',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  FAILED_SILENT = 'FAILED_SILENT',
  FAILED_CUSTOM = 'FAILED_CUSTOM',
}

export interface IApplicationMaintenanceResponse {
  title: string;
  description?: string;
  expectedEndTs?: string;
  imageUrl?: string;
  retrySeconds: number;
  maintenancePageON?: boolean;
}

export interface IMoney {
  formatted?: string;
  amount: number;
  currencyCode: string;
}

export interface ITaskErrorStacktrace {
  originalTask: ITask;
  error: IErrorResponse & HttpErrorResponse;
  type?: TaskType;
}

export interface IErrorResponse {
  code: string;
  messageCode: string;
  message: string;
}

export interface IExceptionErrorTypeResponse {
  type: string;
  title: string;
  status: string;
  classificationId: string;
  service: string;
  icon: string;
  supportsRetry: boolean;
  active: boolean;
  exceptionAttributes: [
    {
      attribute: string;
      value: string;
    },
  ];
}

export enum StorageKeys {
  TOKEN = 'accessToken',
  RELOAD_ON_NEXT_ROUTE = 'reloadOnNextRoute',
  REFRESH_TOKEN = 'refreshToken',
  DEVICE_ID = 'deviceId',
  SESSION_ID = 'avoSessionId',
  HEY_JUDE_RETURN_URL = 'heyJudeReturnUrl',
  PUSH_PERMISSIONS = 'pushPermissions',
  COOKIES = '__cookies__',
  AUTH_BLOCKED = 'authBlocked',
  PUSH_TOKEN = 'pushToken',
  COUNTRY_CODE = 'countryCode',
  USERNAME = 'username',
  SAA_LAST_MEMBERSHIP_NUMBER = 'saaLastMembershipNumber',
  AWAITING_PAYMENT = 'awaitingPayment',
  AVO_POINTS_CARD_TOGETHER_COACH_MARK = 'avoPointsCardTogetherCoachMark',
  GENERIC_ADDRESS_MODAL_DISPLAY_DATE = 'genericAddressModalDisplayDate',
  CMPID = 'cmpid',
  RELOAD_STEP = 'reloadStep',
  BUILD = 'build',
  REFRESH_RETURN_URL = 'refreshReturnUrl',
}

export interface ITransactionSigningNextStepResponse {
  authorized: boolean;
  signingToken?: string;
  method: TransactionSigningNextStep;
  pinMethod?: ITransactionSigningAppMethod;
  deviceMethod?: ITransactionSigningAppMethod;
  otpMethod?: ITransactionSigningOtpMethod;
  tcsMethod?: ITransactionSigningTcsMethod;
  approveItMethod?: ITransactionSigningApproveitMethod;
}

export enum TransactionSigningNextStep {
  PIN = 'PIN',
  DEVICE = 'DEVICE',
  OTP = 'OTP',
  OUTSTANDING_TCS = 'OUTSTANDING_TCS',
  APPROVEIT = 'APPROVEIT',
}

export enum TransactionSigningOtpChannel {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  BANK_SMS = 'BANK_SMS',
}

export interface ITransactionSigningApproveitMethod {
  timeToWait: number;
  sentToNumber: string;
  tryAgain?: boolean;
}

export interface ITransactionSigningAppMethod {
  challenge: string;
  allowedKeyTypes: KeyTypeTransactionSigning[];
}

export interface ITransactionSigningOtpMethod {
  sentTo: string;
  resendAvailableAt: string;
  channel: TransactionSigningOtpChannel;
}

export interface ITransactionSigningTcsMethod {
  documents: ITcsDocument[];
}

export interface ITcsDocument {
  id: string;
  url: string;
  label: string;
  required: boolean;
}

export interface ITcsDocumentExtended extends ITcsDocument {
  checkboxLabel: string;
  name: string;
}

export enum KeyTypeTransactionSigning {
  DEVICE = 'DEVICE',
  PASSWORD = 'PASSWORD',
  BIOMETRIC = 'BIOMETRIC',
}

export interface ITransactionSigningAuthorizeRequestPayload {
  pinAuth?: ITransactionSigningAppAuth;
  otpAuth?: ITransactionSigningOtpAuth;
  deviceAuth?: ITransactionSigningAppAuth;
  passwordAuth?: ITransactionSigningPasswordAuth;
  tcsAuth?: ITransactionSigningTcsAuth;
  approveItAuth?: ITransactionSigningApproveitAuth;
}

export interface ITransactionSigningAppAuth {
  hmac: string;
  activationId: string;
  keyType: string;
}

export interface ITransactionSigningOtpAuth {
  otp?: string;
  resend: boolean;
}

export interface ITransactionSigningPasswordAuth {
  password: string;
}

export interface ITransactionSigningTcsAuth {
  itemsChecked: ITcsItem[];
}

export interface ITransactionSigningApproveitAuth {
  requestStatus: boolean;
}

export interface ITransactionSigningAuthorizeRequest {
  transactionAuthId: string;
  authRequest: ITransactionSigningAuthorizeRequestPayload;
  onSucceed: (data?: ITransactionSigningAuthorizationHeaders) => void;
  onData?: (data?: ITransactionSigningNextStepResponse) => void;
  onFailure?: (error?: any) => void;
}

export interface ITransactionSigningAuthorizationHeaders {
  signingToken: string;
  transactionAuthId: string;
}

export interface ITcsItem {
  id: string;
  confirmed: string;
}

export interface ITransactionSigning {
  transactionAuthId?: string;
  nextStep?: ITransactionSigningNextStepResponse;
  authenticators: ITransactionSigningAuthenticators;
}

export interface ITransactionSigningAuthenticators {
  xAuthTXId?: string;
  xAuthTXToken?: string;
}

export enum CountryCode {
  NA = 'NA',
  ZA = 'ZA',
}

export enum CurrencyPrefix {
  NA = 'N$',
  ZA = 'R',
}

export enum PhonePrefixCountry {
  NA = '+264',
  ZA = '+27',
}

export interface IDiscoverAvoCategory {
  iconUrl: string;
  name: string;
  navigationUri: string;
  vertical?: string;
  pwaNavigationUri?: string;
}

export enum NextStepOnboarding {
  START_INFO = 'START_INFO',
  OTP = 'OTP',
  PERSONAL_DATA = 'PERSONAL_DATA',
  SET_LOGIN = 'SET_LOGIN',
  EXTERNAL_AUTH = 'EXTERNAL_AUTH',
  ADDRESS = 'ADDRESS',
  SECURITY_QUESTIONS = 'SECURITY_QUESTIONS',
  SUCCESS = 'SUCCESS',
  HOME = 'HOME',
  FIRSTNAME_LASTNAME_AUTH = 'FIRSTNAME_LASTNAME_AUTH',
  BUSINESS_TRADING_NAME = 'BUSINESS_TRADING_NAME',
  ACCOUNT_EXISTS = 'ACCOUNT_EXISTS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  BUSINESS_ADDRESS = 'BUSINESS_ADDRESS',
  BUSINESS_CATEGORY = 'BUSINESS_CATEGORY',
  BUSINESS_PARTNER = 'BUSINESS_PARTNER',
  MULTIDIRECTOR = 'MULTIDIRECTOR',
  STEPUP_SUCCESS = 'STEPUP_SUCCESS',
  STEPUPEND = 'STEPUPEND',
  APPROVEIT = 'APPROVEIT',
  APPROVEIT_RESENT = 'APPROVEIT_RESENT',
  IDPASSPORT_NUMBER = 'IDPASSPORT_NUMBER',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  BUSINESS_LEGAL_ENTITY = 'BUSINESS_LEGAL_ENTITY',
  BASIC_DATA = 'BASIC_DATA',
  BUSINESS_BASIC_DATA = 'BUSINESS_BASIC_DATA',
  BANKPHONE_DIFFERENT = 'BANKPHONE_DIFFERENT',
  OUTSTANDING_TCS = 'OUTSTANDING_TCS',
  TCS = 'TCS',
  WAIT_LIGHT_FICA = 'WAIT_LIGHT_FICA',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SELECT_OTP = 'SELECT_OTP',
  SELECT_SOCIAL = 'SELECT_SOCIAL',
  SELECT_NEDBANK = 'SELECT_NEDBANK',
  LINK_ACCOUNT = 'LINK_ACCOUNT',
  MONEYAPP_SIGNUP = 'MONEYAPP_SIGNUP',
  LOGIN = 'LOGIN',
  BANKOTP = 'BANKOTP',
  ID_PHOTO = 'ID_PHOTO',
  MOBIMONEY = 'MOBIMONEY',
  PROGRESS = 'PROGRESS',
  RETURN = 'RETURN',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  END_SCREEN = 'END_SCREEN',
  END = 'END',
}

export enum StepupJourneyType {
  PL_NTP = 'PL_NTP',
  PL_GHOST = 'PL_GHOST',
  HL_PREAPPROVAL = 'HL_PREAPPROVAL',
  HEALTHCARE = 'HEALTHCARE',
}

export interface ISearchInsightsRequest {
  queryId?: string;
  trackingId?: string;
  position?: number;
  event?: SearchInsightsEnum;
}

export enum SearchInsightsEnum {
  CLICK = 'CLICK',
  VIEW = 'VIEW',
  CONVERSION = 'CONVERSION',
}
