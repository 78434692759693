import { Injectable } from '@angular/core';
import { TaskType } from '../models/store.models';
import { v4 } from 'uuid';
import { select, Store } from '@ngrx/store';
import * as VouchersCategoriesSelectors from './vouchers-categories.selectors';
import * as VouchersCategoriesAction from './vouchers-categories.actions';

@Injectable({ providedIn: 'root' })
export class VouchersCategoriesFacade {
  getCategoriesTop$ = this.store.pipe(select(VouchersCategoriesSelectors.getCategoriesTop));
  constructor(private readonly store: Store) {}

  fetchCategoriesByTop() {
    this.store.dispatch(
      VouchersCategoriesAction.fetchCategoriesByTopRequestAction({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }
}
