import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as LayoutActions from './layout.actions';
import { ApplicationEntity as LayoutEntity } from '../models/store.models';
import { BottomNavigationSection, HeaderType, IHeader, SidenavItemId, SidenavType } from '../../model/core.model';
import cloneDeep from 'lodash-es/cloneDeep';

export const LAYOUT_FEATURE_KEY = 'layout';

export interface LayoutState extends EntityState<LayoutEntity> {
  title: string;
  sidenav: SidenavType;
  sidenavExpanded: boolean;
  sidenavLink?: SidenavItemId;
  paddedContentX: boolean;
  paddedContentY: boolean;
  contentBackdrop: boolean;
  contentLightBackdrop: boolean;
  bottomNavigationSection?: BottomNavigationSection;
  header: IHeader;
  currentDevice?: string;
  onBackButtonCallback: () => boolean;
}

export interface LayoutPartialState {
  readonly [LAYOUT_FEATURE_KEY]: LayoutState;
}

export const layoutAdapter: EntityAdapter<LayoutEntity> = createEntityAdapter<LayoutEntity>();

export const initialHeaderState: IHeader = {
  mobile: {
    type: HeaderType.PRIMARY,
    hasLocationWidget: true,
    hasUserWidget: true,
    hasSearchWidget: true,
    hasArrowBack: true,
    hasSupportWidget: false,
    hasGlobalCart: false,
    contentBackButton: {
      visible: false,
      hasText: true,
    },
  },
};

export const layoutInitialState: LayoutState = layoutAdapter.getInitialState({
  title: '',
  sidenav: SidenavType.PRIMARY,
  sidenavExpanded: true,
  paddedContentX: true,
  paddedContentY: true,
  contentBackdrop: false,
  contentLightBackdrop: false,
  header: cloneDeep(initialHeaderState),
  currentDevice: null,
  sidenavLink: null,
  onBackButtonCallback: null,
});

const _layoutReducer = createReducer(
  layoutInitialState,
  on(LayoutActions.saveTitle, (state, { value }) => ({ ...state, title: value })),
  on(LayoutActions.toggleSidenav, (state, { value }) => {
    const sidenavExpanded = value !== null && value !== undefined ? value : !state?.sidenavExpanded;
    return { ...state, sidenavExpanded };
  }),
  on(LayoutActions.paddedContentX, (state, { value }) => ({ ...state, paddedContentX: value })),
  on(LayoutActions.paddedContentY, (state, { value }) => ({ ...state, paddedContentY: value })),
  on(LayoutActions.contentBackdrop, (state, { value }) => ({ ...state, contentBackdrop: value })),
  on(LayoutActions.contentLightBackdrop, (state, { value }) => ({ ...state, contentLightBackdrop: value })),
  on(LayoutActions.bottomNavigationSection, (state, { value }) => ({ ...state, bottomNavigationSection: value })),
  on(LayoutActions.header, (state, { value }) => ({ ...state, header: value })),
  on(LayoutActions.sidenav, (state, { value }) => ({ ...state, sidenav: value })),
  on(LayoutActions.sidenavLink, (state, { value }) => ({ ...state, sidenavLink: value })),
  on(LayoutActions.currentDevice, (state, { device }) => ({ ...state, currentDevice: device })),
  on(LayoutActions.onBackButtonCallback, (state, { callback }) => ({ ...state, onBackButtonCallback: callback })),
);

export function layoutReducer(state: LayoutState | undefined, action: Action) {
  return _layoutReducer(state, action);
}
