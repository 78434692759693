import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from './store/application/application.effects';
import { LocationEffects } from './store/location/location.effects';
import { TRANSLATE_FEATURE_KEY, TranslateEffects, translateReducer } from '@avo/shared/avo-translate';
import { APPLICATION_FEATURE_KEY, applicationReducer } from './store/application/application.reducer';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AssetsEffects } from './store/assets/assets.effects';
import { AppConfigEffects } from './store/app-config/app-config.effects';
import { APP_CONFIG_FEATURE_KEY, appConfigReducer } from './store/app-config/app-config.reducer';
import { metaReducers } from './logout.meta-reducer';
import { TASKS_FEATURE_KEY, tasksReducer } from './store/tasks/tasks.reducer';
import { TasksEffects } from './store/tasks/tasks.effects';
import { TRANSACTIONS_FEATURE_KEY, transactionsReducer } from './store/transactions/transactions.reducer';
import { TransactionsEffects } from './store/transactions/transactions.effects';
import { LAYOUT_FEATURE_KEY, layoutReducer } from './store/layout/layout.reducer';
import { LayoutEffects } from './store/layout/layout.effects';
import { AUTH_FEATURE_KEY, authReducer } from './store/auth/auth.reducer';
import { AuthEffects } from './store/auth/auth.effects';
import {
  VOUCHERS_CATEGORIES_FEATURE_KEY,
  vouchersCategoriesReducer,
} from './store/vouchers-categories/vouchers-categories.reducer';
import { VouchersCategoriesEffects } from './store/vouchers-categories/vouchers-categories.effects';
import { LOCATION_FEATURE_KEY, locationReducer } from './store/location/location.reducer';
import { CartEffects } from './store/cart/cart.effects';
import { CART_FEATURE_KEY, cartReducer } from './store/cart/cart.reducer';
import { GROCERIES_FEATURE_KEY, groceriesReducer } from './store/groceries/groceries.reducer';
import { GroceriesEffects } from './store/groceries/groceries.effects';
import { FEATURE_FLAGS_FEATURE_KEY, featureFlagsReducer } from './store/feature-flags/feature-flags.reducer';
import { FeatureFlagsEffects } from './store/feature-flags/feature-flags.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    // uncomment only for local development!
    // StoreDevtoolsModule.instrument({ maxAge: 100}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(APPLICATION_FEATURE_KEY, applicationReducer),
    StoreModule.forFeature(FEATURE_FLAGS_FEATURE_KEY, featureFlagsReducer),
    StoreModule.forFeature(TASKS_FEATURE_KEY, tasksReducer),
    StoreModule.forFeature(TRANSACTIONS_FEATURE_KEY, transactionsReducer),
    StoreModule.forFeature(LAYOUT_FEATURE_KEY, layoutReducer),
    StoreModule.forFeature(APP_CONFIG_FEATURE_KEY, appConfigReducer),
    StoreModule.forFeature(TRANSLATE_FEATURE_KEY, translateReducer),
    StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
    StoreModule.forFeature(VOUCHERS_CATEGORIES_FEATURE_KEY, vouchersCategoriesReducer),
    StoreModule.forFeature(LOCATION_FEATURE_KEY, locationReducer),
    StoreModule.forFeature(CART_FEATURE_KEY, cartReducer),
    StoreModule.forFeature(GROCERIES_FEATURE_KEY, groceriesReducer),
    EffectsModule.forFeature([
      TranslateEffects,
      ApplicationEffects,
      LocationEffects,
      AssetsEffects,
      AppConfigEffects,
      TasksEffects,
      TransactionsEffects,
      LayoutEffects,
      AuthEffects,
      VouchersCategoriesEffects,
      CartEffects,
      GroceriesEffects,
      FeatureFlagsEffects,
    ]),
  ],
})
export class CreateStoreModule {}
