import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as ApplicationActions from '../application/application.actions';

import * as TasksActions from './tasks.actions';
import { ApplicationEntity as TasksEntity, ITask, ITaskErrorStacktrace } from '../models/store.models';

export const TASKS_FEATURE_KEY = 'tasks';

export interface TasksState extends EntityState<TasksEntity> {
  tasks: ITask[];
  errors: ITaskErrorStacktrace[];
}

export interface TasksPartialState {
  readonly [TASKS_FEATURE_KEY]: TasksState;
}

export const tasksAdapter: EntityAdapter<TasksEntity> = createEntityAdapter<TasksEntity>();

export const tasksInitialState: TasksState = tasksAdapter.getInitialState({
  tasks: [],
  errors: [],
});

const _tasksReducer = createReducer(
  tasksInitialState,

  on(TasksActions.addTaskAction, (state, { data }) => {
    return { ...state, tasks: [...state.tasks, data] };
  }),

  on(TasksActions.removeTaskAction, (state, { data }) => {
    const filteredTasks = state.tasks?.filter(t => t.taskId !== data.taskId);
    return { ...state, tasks: [...filteredTasks] };
  }),

  on(TasksActions.finishTaskAction, (state, { taskId }) => {
    const filteredTasks = state.tasks?.filter(t => t.taskId !== taskId);
    return { ...state, tasks: [...filteredTasks] };
  }),

  on(TasksActions.repeatTaskAction, (state, { data }) => {
    const filteredErrors = state.errors?.filter(t => t.originalTask?.taskId !== data.taskId);
    return { ...state, errors: [...filteredErrors] };
  }),

  on(ApplicationActions.httpErrorAction, (state, { error, data }) => {
    const originalTask: ITask = state.tasks.find(t => t.taskId == data?.taskId) as ITask;
    const errorTask: ITaskErrorStacktrace = {
      error,
      originalTask,
      type: data?.type,
    };
    return { ...state, errors: [...state.errors, errorTask] };
  }),

  on(ApplicationActions.clearAllErrorsAction, state => {
    return { ...state, errors: [] };
  }),
);

export function tasksReducer(state: TasksState | undefined, action: Action) {
  return _tasksReducer(state, action);
}
