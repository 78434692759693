export const coreErrors = {
  translate: {
    errorCode: 'ERR-T1',
    errorMessage: 'An error occurred while the application was started',
    errorDescription: 'fetching translate',
  },
  appConfig: {
    errorCode: 'ERR-A1',
    errorMessage: 'An error occurred while the application was started',
    errorDescription: 'fetching appConfig',
  },
  initApp: {
    errorCode: 'ERR-I1',
    errorMessage: 'An error occurred while the application was started',
    errorDescription: 'fetching configuration.json',
  },
  subscriptions: {
    errorCode: 'ERR-SU',
    errorMessage: 'An error occurred while the application was started. Please try to refresh this page',
    errorDescription: 'fetching subscriptions',
  },
  companies: {
    errorCode: 'ERR-CO',
    errorMessage: 'An error occurred while the application was started. Please try to refresh this page',
    errorDescription: 'fetching companies',
  },
};

export interface ExceptionDto{
  type?:	string;
  title?:	string;
  detail?:	string;
  defaultTitle?:	string;
  status?:	string;
  classificationId?:	string;
  service?:	string;
  icon?:	string;
  remedialAction?:	string;
  supportsRetry?:	boolean;
  active?:	boolean
  createdDate?:	string;
  lastModifiedOn?:	string;
  createdBy?:	string;
  lastModifiedBy?:	string;
  exceptionAttributes?: ExceptionAttributeDto[]
  }

  export interface ExceptionAttributeDto{
    attribute?:	string;
    value?:	string;
    createdDate?:	string;
    createdBy?:	string;
    lastModifiedOn?:	string;
    lastModifiedBy?:	string;
    }