import { inject, Injectable, NgZone } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 * The Idle service triggers a given callback when a user does not interact with the page in a specific time.
 */
@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private onIdleCallback: () => void;
  private timeoutId: number;
  private eventsSubscription: Subscription;

  private readonly IDLE_THRESHOLD = 60 * 30; // seconds
  private readonly RESET_TIMER_EVENTS = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  private readonly ngZone = inject(NgZone);

  start(onIdle: () => void): void {
    if (this.eventsSubscription && !this.eventsSubscription.closed) return;
    this.onIdleCallback = onIdle;
    this.resetTimer();
    this.initEventListeners();
  }

  stop(): void {
    this.clearTimer();
    this.eventsSubscription?.unsubscribe();
  }

  private initEventListeners(): void {
    const events$ = this.RESET_TIMER_EVENTS.map(event => fromEvent(window, event));

    // do not call angular change detection cycle on every dom event
    this.ngZone.runOutsideAngular(() => {
      this.eventsSubscription = merge(...events$)
        .pipe(throttleTime(1000))
        .subscribe(() => this.resetTimer());
    });
  }

  private resetTimer(): void {
    this.clearTimer();

    this.timeoutId = window.setTimeout(() => {
      this.stop();
      this.onIdleCallback();
    }, this.IDLE_THRESHOLD * 1000);
  }

  private clearTimer(): void {
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }
}
