import { inject, Injectable } from '@angular/core';
import { AVO_SESSION_PREFIX } from '../model/tokens';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKeys } from '../store/models/store.models';
import { CommonUtility } from '../utils/common-utility';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly browserStorageService = inject(BrowserStorageService);
  private readonly sessionPrefix = inject(AVO_SESSION_PREFIX);

  get sessionId(): string {
    let sessionId = this.browserStorageService.get(StorageKeys.SESSION_ID, true);
    if (!sessionId) {
      sessionId = this.generateSessionId();
      this.browserStorageService.set(StorageKeys.SESSION_ID, sessionId, true);
    }

    return sessionId;
  }

  resetSessionId(): void {
    this.browserStorageService.set(StorageKeys.SESSION_ID, this.generateSessionId(), true);
  }

  private generateSessionId(): string {
    return `${this.sessionPrefix}${CommonUtility.generateRandomString()}`;
  }
}
