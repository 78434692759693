import { Action } from '@ngrx/store';
import * as actions from './activity-history.actions';
import * as EVENT from './activity-history.events';
import { IActivityHistoryOrderDetail } from '../../models/activity-history.model';
import { IRmaCancelReason, IRmaDetail, IRmaRequest } from '../../models/rma.model';
import cloneDeep from 'lodash-es/cloneDeep';

export const ACTIVITY_HISTORY_FEATURE_KEY = 'activityHistory';

export interface IActivityHistoryState {
  ongoingActivityHistory?: IActivityHistoryOrderDetail[];
  orderDetail?: IActivityHistoryOrderDetail;
  rmaList?: IRmaDetail[];
  rma?: IRmaRequest;
  rmaCancelReasons?: IRmaCancelReason[];
  rmaDetail?: IRmaDetail;
}

export interface IActivityHistoryPartialState {
  readonly [ACTIVITY_HISTORY_FEATURE_KEY]: IActivityHistoryState;
}

const ACTION_HANDLERS = {
  [EVENT.MY_PROFILE_ACTIVITY_HISTORY_ONGOING_RESPONSE]: (
    state: IActivityHistoryState,
    action: actions.MyProfileActivityHistoryOngoingResponseAction,
  ): IActivityHistoryState => {
    const combinedData = action.page > 1 ? [...(state?.ongoingActivityHistory || []), ...action.data] : action.data;
    return Object.assign({}, state, {
      ongoingActivityHistory: combinedData,
    });
  },

  [EVENT.MY_PROFILE_RMA_LIST_RECEIVED]: (
    state: IActivityHistoryState,
    action: actions.MyProfileFetchRmaListResponse,
  ): IActivityHistoryState => {
    const rmaList = action.next > 0 ? [...(state?.rmaList || []), ...action.data] : action.data;
    return Object.assign({}, state, { rmaList });
  },

  [EVENT.MY_PROFILE_ACTIVITY_HISTORY_DETAIL_RESPONSE]: (
    state: IActivityHistoryState,
    action: actions.MyProfileActivityHistoryDetailResponseAction,
  ): IActivityHistoryState => {
    const orderDetail = cloneDeep(action.data);
    const items = orderDetail.fulfillments[0]?.items;
    items?.forEach(item => {
      item.vouchersProvisioned = item?.vouchers?.some(voucher => voucher?.provisioned === true) ?? false;
    });
    return Object.assign({}, state, { orderDetail });
  },

  [EVENT.MY_PROFILE_RMA_DATA_SET]: (
    state: IActivityHistoryState,
    action: actions.MyProfileRmaDataSet,
  ): IActivityHistoryState => {
    return Object.assign({}, state, { rma: action.data });
  },

  [EVENT.MY_PROFILE_RMA_CANCEL_REASONS_RECEIVED]: (
    state: IActivityHistoryState,
    action: actions.MyProfileRmaCancelReasonsResponse,
  ): IActivityHistoryState => {
    return Object.assign({}, state, { rmaCancelReasons: action.data });
  },

  [EVENT.MY_PROFILE_RMA_RECEIVED]: (state: IActivityHistoryState): IActivityHistoryState => {
    return Object.assign({}, state, {
      rma: null,
      rmaCancelReasons: null,
    });
  },

  [EVENT.MY_PROFILE_FETCH_RMA_DETAIL_RECEIVED]: (
    state: IActivityHistoryState,
    action: actions.MyProfileFetchRmaDetailResponse,
  ): IActivityHistoryState => {
    return Object.assign({}, state, { rmaDetail: action.data });
  },
};

export function activityHistoryReducer(state: IActivityHistoryState = {}, action: Action): IActivityHistoryState {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
