import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { EntityState } from '@ngrx/entity';
import { initialState, State } from './store/application/application.reducer';
import pick from 'lodash-es/pick';
import { logoutAction } from './store/auth/auth.actions';

function preserveApplicationData(state: State): State {
  const picked = pick(state, [
    'maintenance',
    'hasPageVisited',
    'searchOptions',
    'returnUrl',
    'funeralReturnUrl',
    'countryCode',
    'discoverAvoCategories',
    'primersRedirectFinished',
  ]);
  return { ...initialState, ...picked };
}

export function logout(reducer: ActionReducer<EntityState<any>>): ActionReducer<EntityState<any>> {
  return (state, action) => {
    if (action !== null && action.type === logoutAction.type) {
      const resetStore: EntityState<any> = undefined;
      const newStore = Object.assign({}, resetStore, {
        appConfig: state['appConfig'],
        appConfig2: state['appConfig2'],
        translate: state['translate'],
        translate2: state['translate2'],
        ecommerce: state['ecommerce'],
        layout: state['layout'],
        application: preserveApplicationData(state['application']),
      });
      return reducer(newStore, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [logout];
