import { LocationStrategy } from '@angular/common';
import { Directive, EventEmitter, HostBinding, HostListener, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationFacade } from '../store/application/application.facade';
import { UiToastService } from '@avo/shared/ui/toast';
import { TranslatePipe } from '@avo/shared/avo-translate';
import { UrlRoutes } from '../routes/routes';

@Directive({
  selector: '[customRouterLink]',
})
export class CustomRouterLinkDirective implements OnInit {
  @Input() customRouterLink: string;
  @Input() requiredUserLocationRoute: boolean;
  @Output() exitModal?: EventEmitter<{ screenId: string; extUrl: string }> = new EventEmitter();
  @Output() addAddress: EventEmitter<any> = new EventEmitter();

  @HostBinding('attr.href') href: string | null = null;
  @HostBinding('attr.target') @Input() target: string;
  finalUrl: string;
  isExternalUrl: boolean;
  exitUrlParams: URLSearchParams;
  internalUrls = [
    'https://test.avo.africa',
    'https://stage.avo.africa',
    'https://preview.avo.africa',
    'https://avo.africa',
    'https://www.test.avo.africa',
    'https://www.stage.avo.africa',
    'https://www.preview.avo.africa',
    'https://www.avo.africa',
    'https://stage-v3.avo.africa',
    'https://preview-v3.avo.africa',
    'https://www.stage-v3.avo.africa',
    'https://www.preview-v3.avo.africa',
  ];
  applicationFacade: ApplicationFacade;
  userLocationAvailable: boolean;
  LOCATION_DEPENDANT_PARAMETERS = ['vertical=GROCERIES', 'vertical=LIQUOR', 'liquor', 'groceries', 'takeaways'];
  locationDependantRoute: boolean;
  private readonly uiToastService: UiToastService;
  private readonly translatePipe: TranslatePipe;

  constructor(private router: Router, private locationStrategy: LocationStrategy, injector: Injector) {
    this.uiToastService = injector.get(UiToastService);
    this.translatePipe = injector.get(TranslatePipe);
    this.applicationFacade = injector.get(ApplicationFacade);
    this.applicationFacade.hasLocationAvailable$.subscribe((value: boolean | undefined) => {
      this.userLocationAvailable = value;
    });
  }

  ngOnInit(): void {
    this.checkInternalUrl();
    this.checkExternalUrl();
    this.updateTargetUrlAndHref();
    if (this.requiredUserLocationRoute) {
      this.locationDependantRoute = this.isUserLocationDependantRoute(this.finalUrl);
    }
  }

  isUserLocationDependantRoute(url: string): boolean {
    if (!url) {
      return false;
    }
    return this.LOCATION_DEPENDANT_PARAMETERS.some(el => {
      return url.includes(el);
    });
  }

  checkInternalUrl(): void {
    this.finalUrl = this.customRouterLink;
    this.internalUrls.forEach(internalUrl => {
      if (this.customRouterLink?.includes(internalUrl)) {
        this.finalUrl = this.customRouterLink.replace(internalUrl, '');
      }
    });
  }

  checkExternalUrl(): void {
    if (this.finalUrl?.includes('http') || this.finalUrl?.includes('www')) {
      this.isExternalUrl = true;
    } else {
      this.isExternalUrl = false;
    }
  }

  showLocationPrompt = (redirectUrl: string) => {
    this.uiToastService.open({
      data: {
        text: this.translatePipe.transform('customer.ecommerce.location_required.headline'),
        subtext: this.translatePipe.transform('customer.ecommerce.location_required.text'),
      },
    });
    this.router.navigate([UrlRoutes.profile.manageProfile.addresses], {
      replaceUrl: true,
      queryParams: {
        action: 'ADD',
        redirectUrl: redirectUrl,
      },
    });
  };

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.shiftKey', '$event.altKey', '$event.metaKey'])
  onClick(button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean): boolean {
    if (this.locationDependantRoute === true && this.userLocationAvailable === false) {
      // the user has clicked on either the liquor or groceries side nav buttons
      if (this.finalUrl === '/ecommerce/liquor') {
        this.addAddress.emit('liquor');
      } else if (this.finalUrl === '/ecommerce/groceries') {
        this.addAddress.emit('groceries');
      } else if (this.finalUrl === '/ecommerce/takeaways') {
        this.addAddress.emit('takeaways');
      } else {
        // user has clicked on an element (most likely a promo card) that has a link which contains a location dependent vertical
        this.showLocationPrompt(this.finalUrl);
      }
      return false;
    } else {
      if (button !== 0 || ctrlKey || shiftKey || altKey || metaKey) {
        return true;
      }

      if (typeof this.target === 'string' && this.target != '_self') {
        return true;
      }

      if (this.isExternalUrl) {
        if (this.exitUrlParams) {
          const screenId = this.exitUrlParams.get('type');
          const extUrl = this.exitUrlParams.get('url');
          this.exitModal.emit({ screenId, extUrl });
          return false;
        }
        this.target = '_blank';
        return true;
      } else {
        this.router.navigateByUrl(this.finalUrl);
        return false;
      }
    }
  }

  private updateTargetUrlAndHref(): void {
    if (!this.finalUrl) return;
    if (this.isExternalUrl) {
      if (this.finalUrl.includes('exit')) {
        this.exitUrlParams = new URLSearchParams(this.finalUrl.split('?')[1]);
        return;
      }
      this.href = this.finalUrl;
    } else {
      this.href = this.locationStrategy.prepareExternalUrl(this.finalUrl);
    }
  }
}
