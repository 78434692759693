import { createAction, props } from '@ngrx/store';
import { ISearchCategory } from '../../model/shared.model';
import { ITask } from '../models/store.models';

export const fetchCategoriesByTopRequestAction = createAction(
  '[Vouchers/API] fetch categories by top request action',
  props<{ taskMetadata: ITask }>(),
);
export const fetchCategoriesByTopResponseAction = createAction(
  '[Vouchers/API] fetch categories by top response action',
  props<{ data: ISearchCategory[]; taskMetadata: ITask }>(),
);
