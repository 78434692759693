import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  FilterSettlements,
  IAddCardResponse,
  Ibalance,
  ICustomerStoreOfValue,
  IEditPaymentMethodRequest,
  IFilterPayoutTransactionRequest,
  IFilterSettlementRequest,
  IGenerateRemittanceRequest,
  IPayByCardRequest,
  IPayByCardResponse,
  IPayByCreditRequest,
  IPayByCreditResponse,
  IPayByWalletRequest,
  IPaymentInfo,
  IPaymentMethodTransaction,
  IPaymentRemittanceExport,
  ISellerStatement,
  ISellerStatementExport,
  ISellerStatementExportRequest,
  ISellerStatementRequest,
  ISettlementExport,
  ITaskDetail,
  ITaxInvoice,
  ITaxInvoiceRequest,
  ITopUpAndPayRequest,
  ITopUpAndPayResponse,
  ITransactionDetail,
  ITransactionExport,
  ITransactionHistoryReportFilter,
  IWallet,
  IWalletBankInfo,
  IWalletPartyAccount,
  IWalletPaymentLinkResponse,
  IWalletPayResponse,
  IWalletSubscriptionAvailable,
  SellerPayoutTransactions,
} from '../../models/wallet.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './wallet.endpoints';

@Injectable()
export class WalletService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiV3Url;

  public fetchWallet(useMockApi = false): Observable<IWallet[]> {
    return this.api.get<IWallet[]>(endpoints.wallets(this.getApiUrl(useMockApi)));
  }

  public fetchPaymentCarousel(useMockApi = false): Observable<ICustomerStoreOfValue> {
    return this.api.get<ICustomerStoreOfValue>(endpoints.fetchPaymentCarousel(this.getApiUrl(useMockApi)));
  }

  public fetchAddCard(useMockApi = false): Observable<IAddCardResponse> {
    return this.api.post<IAddCardResponse>(endpoints.fetchAddCard(this.getApiUrl(useMockApi)));
  }

  public fetchPaymentMethodActive(useMockApi = false): Observable<ICustomerStoreOfValue> {
    return this.api.get<ICustomerStoreOfValue>(endpoints.fetchActivePaymentMethods(this.getApiUrl(useMockApi)));
  }

  public deletePaymentMethod(paymentMethodId: string, useMockApi = false): Observable<any> {
    return this.api.remove(endpoints.deletePaymentMethod(paymentMethodId, this.getApiUrl(useMockApi)));
  }

  public editPaymentMethod(
    paymentMethodId: string,
    request: IEditPaymentMethodRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.patch(endpoints.editPaymentMethod(paymentMethodId, this.getApiUrl(useMockApi)), request);
  }

  public editPaymentMethodName(
    paymentMethodId: string,
    request: IEditPaymentMethodRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.patch(endpoints.editPaymentMethodName(paymentMethodId, this.getApiUrl(useMockApi)), request);
  }

  public changeDefaultPaymentMethod(
    paymentMethodId: string,
    request: IEditPaymentMethodRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.patch(endpoints.changeDefaultPaymentMethod(paymentMethodId, this.getApiUrl(useMockApi)), request);
  }

  public fetchPaymentMethodBalance(paymentMethodId: string, useMockApi = false): Observable<Ibalance> {
    return this.api.get<Ibalance>(endpoints.fetchPaymentMethodBalance(paymentMethodId, this.getApiUrl(useMockApi)));
  }

  public fetchPaymentMethodTransactionsFiltered(
    page: number,
    pageSize: number,
    filter: ITransactionHistoryReportFilter,
    useMockApi = false,
  ): Observable<ITransactionDetail[]> {
    return this.api.post<ITransactionHistoryReportFilter>(
      endpoints.fetchPaymentMethodTransactionsFiltered(this.getApiUrl(useMockApi)),
      filter,
      {
        params: { next: page, pageSize },
      },
    );
  }

  public fetchPaymentMethodTransactions(limit: number, useMockApi = false): Observable<IPaymentMethodTransaction[]> {
    return this.api.get<IPaymentMethodTransaction[]>(
      endpoints.fetchPaymentMethodTransactions(limit, this.getApiUrl(useMockApi)),
    );
  }

  public processAddCard(paymentFlowId: string, useMockApi = false): Observable<IAddCardResponse> {
    return this.api.get<IAddCardResponse>(endpoints.processAddCard(paymentFlowId, this.getApiUrl(useMockApi)));
  }
  public fetchPartyAccounts(useMockApi = false): Observable<IWalletPartyAccount[]> {
    return this.api.get<IWalletPartyAccount[]>(endpoints.partyAccounts(this.getApiUrl(useMockApi)));
  }

  public fetchPaymentLink(paymentLink: string): Observable<any> {
    return this.api.get<IWalletPaymentLinkResponse>(
      endpoints.fetchPaymentLink(paymentLink, this.appConfig.backendConfig.apiUrl),
    );
  }

  public filterSettlements(
    filter: IFilterSettlementRequest,
    pageSize: number,
    next: number,
  ): Observable<FilterSettlements> {
    return this.api.post(endpoints.settlementFilter(this.getApiUrl(false), { pageSize, next }), filter);
  }

  public generatePaymentRemittance(
    storeId: string,
    request: IGenerateRemittanceRequest,
  ): Observable<IPaymentRemittanceExport> {
    return this.api.post(endpoints.generateRemittance(storeId, this.getApiUrl(false)), request);
  }

  public fetchTaxInvoices(
    filter: ITaxInvoiceRequest,
    storeId: string,
    pageSize: number,
    page: number,
  ): Observable<ITaxInvoice> {
    return this.api.get(endpoints.taxInvoices(this.getApiUrl(false), storeId, { pageSize, page }), filter);
  }

  public fetchTaxInvoice(taxInvoiceId: number, storeId: string): Observable<ITaxInvoice> {
    return this.api.get(endpoints.taxInvoiceById(taxInvoiceId, storeId, this.getApiUrl(false)));
  }

  public downloadTaxInvoice(taxInvoiceId: string, storeId: string): Observable<string> {
    return this.api.get(endpoints.taxInvoiceDownload(taxInvoiceId, storeId, this.getApiUrl(false)), {}, {}, 'blob');
  }

  public filterSettlementsExport(filter: IFilterSettlementRequest, storeId: string): Observable<ISettlementExport> {
    return this.api.get(endpoints.settlementFilterExport(this.getApiUrl(false), storeId), filter);
  }

  public filterTransactionsExport(
    filter: IFilterPayoutTransactionRequest,
    storeId: string,
  ): Observable<ITransactionExport> {
    return this.api.get(endpoints.transactionsFilterExport(this.getApiUrl(false), storeId, filter));
  }

  public sellerStatement = (
    request: ISellerStatementRequest,
    storeId: string,
    pageSize: number,
    page: number,
  ): Observable<ISellerStatement> =>
    this.api.get<ISellerStatement>(
      endpoints.sellerStatement(this.getApiUrl(false), storeId, {
        pageSize,
        page,
      }),
      request,
    );

  public generateSellerStatement(
    filter: ISellerStatementExportRequest,
    storeId: string,
  ): Observable<ISellerStatementExport> {
    return this.api.post(endpoints.generateSellerStatement(this.getApiUrl(false), storeId), filter);
  }

  public downloadSellerStatement(taxInvoiceId: string, storeId: string): Observable<string> {
    return this.api.get(endpoints.statementDownload(taxInvoiceId, storeId, this.getApiUrl(false)), {}, {}, 'blob');
  }

  public fetchTaskFile(taskId: string): Observable<string> {
    return this.api.get(endpoints.getTaskFile(taskId, this.getApiUrl(false)), {}, {}, 'blob');
  }

  public fetchTaskDetail(taskId: string): Observable<ITaskDetail> {
    return this.api.get(endpoints.getTaskDetail(taskId, this.getApiUrl(false)));
  }

  public filterPayoutTransactions(
    storeId: string,
    filter: IFilterPayoutTransactionRequest,
    pageSize: number,
    next: number,
  ): Observable<SellerPayoutTransactions> {
    return this.api.get(endpoints.filterPayoutTransactions(this.getApiUrl(false), storeId, { pageSize, next }), filter);
  }

  public nedbankBanks(useMockApi = false): Observable<IWalletBankInfo[]> {
    return this.api.get<IWalletBankInfo[]>(endpoints.fetchNedbankBanks(this.getApiUrl(useMockApi)));
  }

  public fetchSubscriptionAvailableMerchantSubscriptions(): Observable<IWalletSubscriptionAvailable[]> {
    return this.api.get<IWalletSubscriptionAvailable[]>(
      endpoints.subscriptionAvailableMerchant(this.appConfig.backendConfig.apiUrl),
    );
  }

  public payByCard(flowId: string, request: IPayByCardRequest): Observable<IPayByCardResponse> {
    return this.api.post<IPayByCardRequest>(
      endpoints.payByCard(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payByPointsOnly(flowId: string): Observable<void> {
    return this.api.post<void>(endpoints.payByPointsOnly(flowId, this.appConfig.backendConfig.apiV3Url));
  }

  public topUpAndPay(flowId: string, request: ITopUpAndPayRequest): Observable<ITopUpAndPayResponse> {
    return this.api.post<ITopUpAndPayRequest>(
      endpoints.topUpAndPay(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payByWallet(flowId: string, request: IPayByWalletRequest): Observable<IWalletPayResponse> {
    return this.api.post<IPayByWalletRequest>(
      endpoints.payByWallet(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payForFree(flowId: string): Observable<void> {
    return this.api.post<void>(endpoints.payForFree(flowId, this.appConfig.backendConfig.apiV3Url));
  }

  public payByEft(flowId: string): Observable<void> {
    return this.api.post<void>(endpoints.payByEft(flowId, this.appConfig.backendConfig.apiV3Url));
  }

  public payByAmex(flowId: string, request: IPayByCardRequest): Observable<IPayByCardResponse> {
    return this.api.post<IPayByCardRequest>(
      endpoints.payByAmex(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payByCredit(flowId: string, request: IPayByCreditRequest): Observable<IPayByCreditResponse> {
    return this.api.post<IPayByCreditRequest>(
      endpoints.payByCredit(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }
}
