import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as LayoutActions from './layout.actions';
import * as LayoutSelectors from './layout.selectors';
import { BottomNavigationSection, IHeader, SidenavItemId, SidenavType } from '../../model/core.model';

@Injectable({ providedIn: 'root' })
export class LayoutFacade {
  getTitle$ = this.store.pipe(select(LayoutSelectors.getTitle));
  getSidenav$ = this.store.pipe(select(LayoutSelectors.getSidenav));
  getSidenavLink$ = this.store.pipe(select(LayoutSelectors.getSidenavLink));
  isSidenavExpanded$ = this.store.pipe(select(LayoutSelectors.isSidenavExpanded));
  isPaddedContentX$ = this.store.pipe(select(LayoutSelectors.isPaddedContentX));
  isPaddedContentY$ = this.store.pipe(select(LayoutSelectors.isPaddedContentY));
  isContentBackdrop$ = this.store.pipe(select(LayoutSelectors.isContentBackdrop));
  isContentLightBackdrop$ = this.store.pipe(select(LayoutSelectors.isContentLightBackdrop));
  getBottomNavigationSection$ = this.store.pipe(select(LayoutSelectors.getBottomNavigationSection));
  getHeader$ = this.store.pipe(select(LayoutSelectors.getHeader));
  getCurrentDevice$ = this.store.pipe(select(LayoutSelectors.getCurrentDevice));
  getOnBackButtonCallback$ = this.store.pipe(select(LayoutSelectors.getOnBackButtonCallback));

  constructor(private readonly store: Store) {}

  setTitle(value: string, translate = true) {
    this.store.dispatch(LayoutActions.setTitle({ value, translate }));
  }

  setSidenav(value: SidenavType) {
    this.store.dispatch(LayoutActions.sidenav({ value }));
  }

  toggleSidenav(value?: boolean) {
    this.store.dispatch(LayoutActions.toggleSidenav({ value }));
  }

  setPaddedContentX(value: boolean) {
    this.store.dispatch(LayoutActions.paddedContentX({ value }));
  }

  setPaddedContentY(value: boolean) {
    this.store.dispatch(LayoutActions.paddedContentY({ value }));
  }

  setContentBackdrop(value: boolean) {
    this.store.dispatch(LayoutActions.contentBackdrop({ value }));
  }

  setContentLightBackdrop(value: boolean) {
    this.store.dispatch(LayoutActions.contentLightBackdrop({ value }));
  }

  setBottomNavigationSection(value?: BottomNavigationSection) {
    this.store.dispatch(LayoutActions.bottomNavigationSection({ value }));
  }

  setHeader(value: IHeader) {
    this.store.dispatch(LayoutActions.header({ value }));
  }

  setSidenavLink(value: SidenavItemId) {
    this.store.dispatch(LayoutActions.sidenavLink({ value }));
  }

  hideInitLoader() {
    this.store.dispatch(LayoutActions.hideInitLoader());
  }

  /**
   * Custom header back button behaviour callback.
   *
   * @param callback Return `true` when callback ran successfully, otherwise return `false` to use default behaviour.
   */
  setOnBackButtonCallback(callback: () => boolean) {
    this.store.dispatch(LayoutActions.onBackButtonCallback({ callback }));
  }
}
