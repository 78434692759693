import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ErrorUtility, ITaskAction, IOrchestrationError, ExceptionDto } from '@wakanda/wakanda-core';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import { Subscription } from 'rxjs';

@Pipe({ name: 'getFailureDialogError' })
export class GetFailureDialogError implements PipeTransform, OnDestroy {
  walletId: string;
  //
  subscriptions = new Subscription();

  private getTranslation = (key: string) => {
    let translation;
    this.subscriptions.add(
      this.translateFacade.getTranslateByKey$(key).subscribe(translatedString => (translation = translatedString)),
    );
    return translation;
  };

  constructor(private translateFacade: TranslateFacade) {}

  transform = (task: ITaskAction): string => {
    const orchestrationError = task?.error?.error as IOrchestrationError;
    const exception : ExceptionDto =  task?.error?.error?.exception;
    const error = task?.error?.error?.error;
    const defaultErrorMessage = this.getTranslation('error.general_body');
    const messageCode = orchestrationError?.error?.messageCode;
    const errorMessage = exception?.detail  ?? error?.message ?? defaultErrorMessage;
    if(exception){
      return errorMessage;
    }
    if (messageCode) {
      return this.getTranslation(messageCode);
    }
    return errorMessage;
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
