import { createAction, props } from '@ngrx/store';
import {
  IApplicationMaintenanceResponse,
  IDiscoverAvoCategory,
  IErrorResponse,
  ISearchInsightsRequest,
  ITask,
} from '../models/store.models';
import { ISearchOptions } from '../../model/core.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IAvoInfoDTO, ILocationDTO } from '../../model/shared.model';
import { IUiExitScreenDto } from '@avo/shared/ui/modal';

export const setCartComponentInitialized = createAction(
  '[Application] Set cart component initialized',
  props<{ cartComponentInitialized: boolean }>(),
);

export const setSearchVertical = createAction(
  '[Application] Set current search vertical',
  props<{ searchVertical: string }>(),
);

export const setSearchTerm = createAction('[Application] Set current search term', props<{ searchTerm: string }>());

export const setSearchInputInFocus = createAction(
  '[Application] Set search input in focus',
  props<{ searchInputInFocus: boolean }>(),
);

export const setSearchVerticalInFocus = createAction(
  '[Application] Set search vertical in focus',
  props<{ searchVerticalInFocus: boolean }>(),
);

export const setCurrentLocation = createAction(
  '[Application] Set application current location',
  props<{ data: ILocationDTO }>(),
);

export const setAuthorizationStatus = createAction(
  '[Application] Set authorization status',
  props<{ isAuthorized: boolean }>(),
);

export const setIsTravelDialogOpen = createAction(
  '[Application] Set Travel Dialog status',
  props<{ isTravelDialogOpen: boolean }>(),
);

export const setAvoInfo = createAction('[Application] Set avoInfo', props<{ data: IAvoInfoDTO }>());

export const checkMaintenanceRequest = createAction('[Application] Check maintenance request');

export const checkMaintenanceResponse = createAction(
  '[Application] Check maintenance response',
  props<{ data: IApplicationMaintenanceResponse }>(),
);

export const checkMaintenanceError = createAction('[Application] Check maintenance error');

export const httpErrorAction = createAction(
  '[Application/Error] Http error',
  props<{ error: IErrorResponse & HttpErrorResponse; forceLog?: boolean; data?: ITask }>(),
);

export const setReturnUrl = createAction('[Application] Set returnUrl', props<{ value: string }>());

export const setfuneralReturnUrl = createAction('[Application] Set funeralReturnUrl', props<{ value: string }>());

export const setTermAgreementFailureUrl = createAction(
  '[Application] Set term agreement failure url',
  props<{ url: string }>(),
);

export const setOrderId = createAction('[Application] Set order id', props<{ orderId: string }>());

export const setPrimersRedirectFinished = createAction(
  '[Application] Set setPrimersRedirectFinished',
  props<{ value: boolean }>(),
);

export const setHasLocationAvailable = createAction(
  '[Application] Set has Location available',
  props<{ data: boolean }>(),
);

export const resetLogoutTimer = createAction('[Application] Reset logout timer', props<{ start: boolean }>());

export const clearAllErrorsAction = createAction('[Application/Error] Clear all errors');

export const searchOptions = createAction(
  '[Application] Set search options',
  props<{ searchOptions: ISearchOptions }>(),
);

export const setHasPageVisited = createAction('[Application] Set has page visited', props<{ value: boolean }>());

export const fetchCurrentCountryRequestAction = createAction(
  '[Application] fetch current country request action',
  props<{ taskMetadata?: ITask }>(),
);

export const fetchCurrentCountryResponseAction = createAction(
  '[Application] fetch current country response action',
  props<{ data: string; taskMetadata?: ITask }>(),
);

export const setCountryCodeAction = createAction(
  '[Application] set country code action',
  props<{ countryCode: string }>(),
);

// fetch discover avo categories
export const fetchDiscoverAvoCategoriesRequest = createAction(
  '[Application] discover avo categories request',
  props<{ taskMetadata?: ITask }>(),
);

export const fetchDiscoverAvoCategoriesResponse = createAction(
  '[Application] discover avo categories response',
  props<{ data?: IDiscoverAvoCategory[]; taskMetadata?: ITask }>(),
);

// fetch exit screen
export const fetchExitScreenRequest = createAction(
  '[Application/API] fetch exit screen request',
  props<{
    screenId: string;
    onSucceed: (data: IUiExitScreenDto) => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchExitScreenResponse = createAction(
  '[Application/API] fetch exit screen response',
  props<{ taskMetadata: ITask }>(),
);

export const sendSearchCategoryInsightsRequestAction = createAction(
  '[Application/API] send search category insights request action',
  props<{ request: ISearchInsightsRequest; taskMetadata: ITask }>(),
);
export const sendSearchCategoryInsightsResponseAction = createAction(
  '[Application/API] send search insights category response action',
  props<{ taskMetadata: ITask }>(),
);
