import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as ApplicationActions from './application.actions';
import { ApplicationEntity, IApplicationMaintenanceResponse, IDiscoverAvoCategory } from '../models/store.models';
import { ISearchOptions } from '../../model/core.model';
import { IAvoInfoDTO, ILocationDTO } from '../../model/shared.model';

export const APPLICATION_FEATURE_KEY = 'application';

export interface State extends EntityState<ApplicationEntity> {
  maintenance?: IApplicationMaintenanceResponse;
  currentLocation?: ILocationDTO;
  isAuthorized: boolean;
  avoInfo?: IAvoInfoDTO;
  returnUrl?: string;
  funeralReturnUrl?: string;
  hasLocationAvailable?: boolean;
  searchOptions?: ISearchOptions;
  hasPageVisited?: boolean;
  countryCode?: string;
  discoverAvoCategories?: IDiscoverAvoCategory[];
  primersRedirectFinished?: boolean;
  isTravelDialogOpen: boolean;
  searchTerm?: string;
  searchInputInFocus?: boolean;
  searchVerticalInFocus?: boolean;
  searchVertical?: string;
  cartComponentInitialized?: boolean;
  termAgreementFailureUrl?: string;
  orderId?: string;
}

export interface ApplicationPartialState {
  readonly [APPLICATION_FEATURE_KEY]: State;
}

export const applicationAdapter: EntityAdapter<ApplicationEntity> = createEntityAdapter<ApplicationEntity>();

export const initialState: State = applicationAdapter.getInitialState({
  currentLocation: null,
  isAuthorized: false,
  avoInfo: null,
  searchOptions: null,
  hasPageVisited: false,
  isTravelDialogOpen: false,
  searchTerm: '',
  searchInputInFocus: false,
  searchVerticalInFocus: false,
  searchVertical: '',
  cartComponentInitialized: false,
});

const _applicationReducer = createReducer(
  initialState,
  on(ApplicationActions.setCartComponentInitialized, (state, { cartComponentInitialized }) => ({
    ...state,
    cartComponentInitialized: cartComponentInitialized,
  })),
  on(ApplicationActions.setSearchVertical, (state, { searchVertical }) => ({
    ...state,
    searchVertical: searchVertical,
  })),
  on(ApplicationActions.setSearchTerm, (state, { searchTerm }) => ({ ...state, searchTerm: searchTerm })),
  on(ApplicationActions.setSearchInputInFocus, (state, { searchInputInFocus }) => ({
    ...state,
    searchInputInFocus: searchInputInFocus,
  })),
  on(ApplicationActions.setSearchVerticalInFocus, (state, { searchVerticalInFocus }) => ({
    ...state,
    searchVerticalInFocus: searchVerticalInFocus,
  })),
  on(ApplicationActions.setCurrentLocation, (state, { data }) => ({ ...state, currentLocation: data })),
  on(ApplicationActions.setHasLocationAvailable, (state, { data }) => ({ ...state, hasLocationAvailable: data })),
  on(ApplicationActions.setAvoInfo, (state, { data }) => ({ ...state, avoInfo: data })),
  on(ApplicationActions.setAuthorizationStatus, (state, { isAuthorized }) => ({ ...state, isAuthorized })),
  on(ApplicationActions.checkMaintenanceResponse, (state, { data }) => ({ ...state, maintenance: data })),
  on(ApplicationActions.checkMaintenanceError, state => ({ ...state, maintenance: undefined })),

  on(ApplicationActions.setReturnUrl, (state, { value }) => ({ ...state, returnUrl: value })),
  on(ApplicationActions.setTermAgreementFailureUrl, (state, { url }) => ({ ...state, termAgreementFailureUrl: url })),
  on(ApplicationActions.setOrderId, (state, { orderId }) => ({ ...state, orderId: orderId })),
  on(ApplicationActions.setfuneralReturnUrl, (state, { value }) => ({ ...state, funeralReturnUrl: value })),
  on(ApplicationActions.setPrimersRedirectFinished, (state, { value }) => ({
    ...state,
    primersRedirectFinished: value,
  })),

  on(ApplicationActions.searchOptions, (state, { searchOptions }) => ({ ...state, searchOptions })),

  on(ApplicationActions.setHasPageVisited, (state, { value }) => ({ ...state, hasPageVisited: value })),

  on(ApplicationActions.fetchCurrentCountryResponseAction, (state, { data }) => ({
    ...state,
    countryCode: data,
  })),
  on(ApplicationActions.setCountryCodeAction, (state, { countryCode }) => ({
    ...state,
    countryCode,
  })),
  on(ApplicationActions.fetchDiscoverAvoCategoriesResponse, (state, { data }) => ({
    ...state,
    discoverAvoCategories: data,
  })),
  on(ApplicationActions.fetchDiscoverAvoCategoriesResponse, (state, { data }) => ({
    ...state,
    discoverAvoCategories: data,
  })),
  on(ApplicationActions.setIsTravelDialogOpen, (state, { isTravelDialogOpen }) => ({ ...state, isTravelDialogOpen })),
);

export function applicationReducer(state: State | undefined, action: Action) {
  return _applicationReducer(state, action);
}
