import { IPosition, LocationV3AddressType } from './location.model';

export enum BusinessSize {
  MICRO = 'MICRO',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface LegalEntity {
  tradingName: string;
  businessRegistrationNumber?: string;
  ownershipType: StoreAccountOwnership;
  vatNumber?: string;
  email?: string;
  phoneNumber?: string;
  secondaryPhoneNumber?: string;
  businessType?: BusinessType;
  industry?: string;
  businessSize?: BusinessSize;
  postalAddress?: ILegalEntityAddress;
  registeredBusinessAddress?: ILegalEntityAddress;
  tradingAddress?: ILegalEntityAddress;
}

export interface ILegalEntityAddress {
  houseNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  postalCode: string;
  country?: string;
  countryCode?: string;
}

export enum StoreAccountOwnership {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  STATE_OWNED = 'STATE_OWNED',
  CLOSED_CORPORATION = 'CLOSED_CORPORATION',
  LIMITED_COMPANY = 'LIMITED_COMPANY',
  OTHER = 'OTHER',
  NPO = 'NPO',
  PARTNERSHIP = 'PARTNERSHIP',
  PUBLIC = 'PUBLIC',
}

export enum BusinessType {
  WHOLESALER = 'WHOLESALER',
  WHOLESALER_DISTRIBUTOR = 'WHOLESALER_DISTRIBUTOR',
  WHOLESALE_MERCHANTS = 'WHOLESALE_MERCHANTS',
  WHOLESALE_SELLERS = 'WHOLESALE_SELLERS',
  WHOLESALE_SUPPLIER = 'WHOLESALE_SUPPLIER',
  WHOLESALE_TRADER = 'WHOLESALE_TRADER',
  DISTRIBUTOR_PARTNER_CHANNEL = 'DISTRIBUTOR_PARTNER_CHANNEL',
  IMPORTER = 'IMPORTER',
  WHOLESALER_RETAILER = 'WHOLESALER_RETAILER',
  RETAIL_MERCHANT = 'RETAIL_MERCHANT',
  ECOMMERCE_SHOP = 'ECOMMERCE_SHOP',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  HOTEL_RESTAURANT = 'HOTEL_RESTAURANT',
  NON_PROFIT = 'NON_PROFIT',
  NURSING_HOME = 'NURSING_HOME',
  REAL_ESTATE = 'REAL_ESTATE',
  SCHOOLS = 'SCHOOLS',
  TRAVEL_SERVICE = 'TRAVEL_SERVICE',
  AGRICULTURE = 'AGRICULTURE',
  STONES = 'STONES',
  IT = 'IT',
  EQUIPMENT_RENTAL = 'EQUIPMENT_RENTAL',
  BAKERY = 'BAKERY',
  ARCHITECT = 'ARCHITECT',
  PRODUCERS = 'PRODUCERS',
  FABRICATORS = 'FABRICATORS',
  OTHER = 'OTHER',
  BUYING_GROUP = 'BUYING_GROUP',
}

export interface IBuyer {
  id: string;
  state: BuyerStateEnum;
  countryCode: string;
  legalEntity: LegalEntity;
  name: string;
  locations: IBuyerLocation[];
  closedLoopLinked?: boolean;
  quoteOnBehalfAvailable?: boolean;
  buyingGroupLinked?: boolean;
  buyingGroupManager?: boolean;
  groupBuyingLimits?: IGroupBuyingLimit[];
}

export interface IGroupBuyingLimit {
  buyingGroupId: string;
  onceLimit: number;
  weeklyLimit: number;
  monthlyLimit: number;
}

export interface IBuyerQuery {
  query?: string;
  name?: string;
  states?: BuyerStateEnum[];
  buyerIds?: string[];
}

export enum BuyerStateEnum {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DISABLED_BY_MERCHANT = 'DISABLED_BY_MERCHANT',
  DISABLED_BY_AVO = 'DISABLED_BY_AVO',
  DEACTIVATED = 'DEACTIVATED',
  PENDING_DELETION = 'PENDING_DELETION',
  DELETED = 'DELETED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
}

export enum BuyerLocationAddressTypeEnum {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  BUSINESSPARK = 'TOWNHOUSECOMPLEX',
  ESTATE = 'ESTATE',
}

export interface IBuyerLocation {
  id: string;
  name: string;
  address: IBuyerLocationAddress;
  latitude: number;
  longitude: number;
}

export interface IBuyerLocationRequest {
  name: string;
  address: IBuyerLocationAddress;
  latitude: number;
  longitude: number;
}

export interface IBuyerLocationAddress {
  addressValid?: boolean;
  streetName?: string;
  streetNumber?: string;
  suburb?: string;
  stateOrProvince?: string;
  complex?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  addressType?: BuyerLocationAddressTypeEnum;
  houseNumber?: string;
  building?: string;
  unitNumber?: string;
  estate?: string;
  addressString?: string;
  valid?: boolean;
  contactPerson?: string;
}

export interface ICreateBuyerRequest {
  countryCode?: string;
  legalEntity?: LegalEntity;
  name?: string;
}

export interface IBuyerLocationForm {
  location: IPosition;
  postalCode?: string;
  addressString?: string;
  contactPerson?: string;
  streetName: string;
  streetNumber?: string;
  city?: string;
  complex?: string;
  country: string;
  stateOrProvince: string;
  suburb?: string;
  name?: string;
  geocodingData?: any;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  addressType: LocationV3AddressType;
  instructions?: string;
}
