import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DefaultProjectorFn } from '@ngrx/store/src/selector';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import { IMyMoneyWalletState, IWalletSettlementAccount, MY_MONEY_WALLET_FEATURE_KEY } from '../../models/wallet.model';

const getState = createFeatureSelector<IMyMoneyWalletState>(MY_MONEY_WALLET_FEATURE_KEY);

export const getPaymentCarousel = createSelector(getState, (state: IMyMoneyWalletState) => state?.paymentCarousel);
export const getWallets = createSelector(getState, (state: IMyMoneyWalletState) => state.wallets);

export const getWallet = createSelector(getState, (state: IMyMoneyWalletState) => state.wallet);

export const getMainWallet = createSelector(getState, (state: IMyMoneyWalletState) =>
  find(get(state, 'wallets'), w => w.type === 'MAIN'),
);

export const getExternalAccounts = createSelector(getState, (state: IMyMoneyWalletState) => state.externalAccounts);

export const getNedbankBanks = createSelector(getState, (state: IMyMoneyWalletState) => state.nedbankBanks);

export const getDebitAccount = createSelector(getState, state =>
  state?.bankAccount?.find(bankAccount => bankAccount.isDebit),
);

export const getBankAccountsFiltered = (
  isDebit?: boolean,
): MemoizedSelector<object, IWalletSettlementAccount[], DefaultProjectorFn<IWalletSettlementAccount[]>> =>
  createSelector(getState, (state: IMyMoneyWalletState) => {
    if (isDebit) {
      return state?.bankAccount?.filter(bankAccount => bankAccount.isDebit);
    }
    return state?.bankAccount;
  });

export const getLoyaltyPoints = createSelector(getState, (state: IMyMoneyWalletState) => state.loyaltyPoints);

export const getActiveBuyer = createSelector(getState, (state: IMyMoneyWalletState) => state.activeBuyer);

export const getDashboardWidget = createSelector(getState, (state: IMyMoneyWalletState) => state.dashboardWidget);

export const getSellerPayoutTransactions = createSelector(
  getState,
  (state: IMyMoneyWalletState) => state.sellerPayoutTransactions,
);

export const getSellerPayoutTransactionItem = createSelector(getState, (state: IMyMoneyWalletState) =>
  state.sellerPayoutTransactions?.items?.length > 0 ? state.sellerPayoutTransactions?.items[0] : null,
);

export const getTaxInvoices = createSelector(getState, (state: IMyMoneyWalletState) => state.taxInvoices);

export const getPaymentMethod = createSelector(getState, (state: IMyMoneyWalletState) => state.paymentMethod);

export const getPartyAccounts = createSelector(getState, (state: IMyMoneyWalletState) => state.partyAccounts);

export const getCardTopUp = createSelector(getState, (state: IMyMoneyWalletState) => state.cardTopup);
export const getActivePaymentMethods = createSelector(getState, state => state?.activePaymentMethods);

export const getSellerStatements = createSelector(getState, state => state?.sellerStatements);
