import {
  AvoTranslateModule,
  TRANSLATE_FEATURE_KEY,
  TranslateEffects,
  translateReducer,
} from '@avo/shared/avo-translate';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const STORIES_META_IMPORTS = [
  CommonModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  AvoTranslateModule,
  StoreModule.forRoot(),
  EffectsModule.forRoot([]),
  StoreModule.forFeature(TRANSLATE_FEATURE_KEY, translateReducer),
  EffectsModule.forFeature([TranslateEffects]),
];
