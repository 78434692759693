import { inject, Injectable } from '@angular/core';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKeys } from '../store/models/store.models';

@Injectable({
  providedIn: 'root',
})
export class CmpidService {
  private readonly browserStorageService = inject(BrowserStorageService);

  get cmpId(): string {
    return this.browserStorageService.get(StorageKeys.CMPID, true);
  }

  resetCmpId(cmpid): void {
    this.browserStorageService.set(StorageKeys.CMPID, cmpid, true);
  }

  clearCmpId(): void {
    this.browserStorageService.remove(StorageKeys.CMPID, true);
  }
}
