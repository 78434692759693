import { inject, Injectable } from '@angular/core';
import { AppConfig, CommonUtility } from '@wakanda/wakanda-core';
import { SessionService } from '@avo/shared/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private readonly appConfig = inject(AppConfig);
  private readonly sessionService = inject(SessionService);

  init(): Promise<any> {
    return fetch('configuration.json')
      .then(response => response.json())
      .then(configuration => {
        const sessionId = this.sessionService.sessionId;
        this.appConfig.appType = 'P';
        this.appConfig.backendConfig = {
          apiaryUrl: null,
          authUrl: null,
          onboardingUrl: null,
          sessionId,
          transactionSigningUrl: null,
          apiUrl: configuration.apiUrl,
          translateUrl: CommonUtility.getTranslateUrl(configuration.apiUrl),
        };
        // This is requirement from business
        console.log('Session ID: ', sessionId);

        return Promise.resolve(configuration);
      });
  }
}
