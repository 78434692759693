export interface LocationEntity {
  id: string | number;
  name: string;
}

export interface ILocationV3 {
  id?: string;
  name?: string;
  instructions?: string;
  location?: IPosition;
  address: ILocationV3Address;
  billingAddress?: boolean;
  valid?: boolean;
}

export interface IPosition {
  latitude: number;
  longitude: number;
  locationId?: string;
}

export interface ILocationV3Address {
  addressString: string;
  contactPerson: string;
  streetName: string;
  streetNumber?: string;
  suburb: string;
  city?: string;
  postalCode?: string;
  complex?: string;
  stateOrProvince: string;
  country: string;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  type: LocationV3Type;
  addressType: LocationV3AddressType;
  instructions?: string;
}

export enum LocationV3Type {
  BILLTO = 'BILLTO',
  SHIPTO = 'SHIPTO',
  PRIMARY = 'PRIMARY',
  OTHER = 'OTHER',
  REGISTRED = 'REGISTRED',
  TRADING = 'TRADING',
  BUSINESS = 'BUSINESS',
}

export enum LocationV3AddressType {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  BUSINESSPARK = 'TOWNHOUSECOMPLEX',
  ESTATE = 'ESTATE',
}

export interface INewLocationV3 {
  name: string;
  instructions?: string;
  location?: IPosition;
  address: INewLocationV3Address;
  billingAddress?: boolean;
  geocodingData?: string;
}

export interface INewLocationV3Address {
  addressString?: string;
  contactPerson?: string;
  streetName: string;
  streetNumber?: string;
  suburb?: string;
  city?: string;
  postalCode?: string;
  complex?: string;
  stateOrProvince: string;
  country: string;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  addressType: LocationV3AddressType;
  instructions?: string;
}

export interface ISetCurrentLocation {
  currentLocationId: string;
}

export interface IAddressForm {
  locationId?: string;
  streetName?: string;
  streetNumber?: number;
  suburb?: string;
  stateOrProvince?: string;
  complex?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  locality?: string;
  addressString: string;
  contactPerson?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  houseNo?: string;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  addressType?: LocationV3AddressType;
}

export interface IEditAddressData {
  address: ILocationV3;
  editMode: boolean;
  validateOnChange: boolean;
  navUrl?: string;
  refreshPage?: boolean;
  currentVertical?: string;
}

export enum AddressManagementAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
}

export interface IAddressManagementParams {
  action: AddressManagementAction;
  addressId?: string;
  redirectUrl?: string;
  setCurrentLocation?: boolean;
}

export interface IAddressManagementEditDialogResult {
  action: AddressManagementAction;
  address?: INewLocationV3;
  locationId?: string;
}
