import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmountFormatDirective } from './directives/amount-format.directive';
import { FocusDirective } from './directives/focus.directive';
import { PhoneFormatDirective } from './directives/phone-format.directive';
import { SaIdFormatDirective } from './directives/said-format.directive';
import { AmountTransformPipe } from './pipe/amount-transform.pipe';
import { AvailableBalancePipe } from './pipe/available-balance.pipe';
import { AvoPointsToZARPipe } from './pipe/avo-points.pipe';
import { DateAgoPipe } from './pipe/date-ago.pipe';
import { DurationPipe } from './pipe/duration.pipe';
import { GetDataByIdPipe } from './pipe/get-data-by-id.pipe';
import { GetKilobytePipe } from './pipe/get-kilobyte-value.pipe';
import { GetSafeHTMLPipe } from './pipe/get-safe-html.pipe';
import { GetSafeUrlPipe } from './pipe/get-safe-url.pipe';
import { GroupDataByDatePipe } from './pipe/group-data-by-date.pipe';
import { GroupedTransactionsByDatePipe } from './pipe/grouped-transactions-by-date.pipe';
import { HandleFormErrorPipe } from './pipe/handle-form-error.pipe';
import { HashStringPipe } from './pipe/hash-string.pipe';
import { HomeWalletWidgetPipe } from './pipe/home-wallet-widget.pipe';
import { LeadingZeroesFormatPipe } from './pipe/leading-zeroes.pipe';
import { MaskEmailPipe } from './pipe/mask-email.pipe';
import { FormatDatePipe } from './pipe/format-date.pipe';
import { NotificationDateFormatPipe } from './pipe/notification-date-format.pipe';
import { OrderByPipe } from './pipe/order-by.pipe';
import { PhoneTransformPipe } from './pipe/phone-transform.pipe';
import { ResizeImageUrlPipe } from './pipe/resize-image-url.pipe';
import { SaIdTransformPipe } from './pipe/said-transform.pipe';
import { SecureImagePipe } from './pipe/secure-image.pipe';
import { SortByDatetimePipe } from './pipe/sort-by-datetime.pipe';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { TimesPipe } from './pipe/times.pipe';
import { WalletDateFormatPipe } from './pipe/wallet-date-format.pipe';
import { AlertService } from './services/alert/alert.service';
import { ApiService } from './services/api/api.service';
import { AppConfigService } from './services/app-config/app-config.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { AuthorizationService } from './services/authorization/authorization.service';
import { GuestAuthorizationService } from './services/authorization/guest-authorization.service';
import { EmailVerificationService } from './services/email-verification/email-verification.service';
import { AppInitService } from './services/init/app-init.service';
import { BrowserStorageService } from './services/browser-storage/browser-storage.service';
import { LocationService } from './services/location/location.service';
import { HttpErrorConsoleLogger } from './services/logger/http-error-console.logger';
import { NotificationService } from './services/notification/notification.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { ResponsiveService } from './services/responsive/responsive.service';
import { ScriptService } from './services/script/script.service';
import { ShareService } from './services/share/share.service';
import { WINDOW_PROVIDERS } from './services/window/windows.service';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { BuildNumberService } from './services/build-number/build-number.service';
import { FileSizePipe } from './pipe/file-size.pipe';
import { GetPipe } from './pipe/get.pipe';
import { SortByPipe } from './pipe/sort-by.pipe';
import { StringOptionsPipe } from './pipe/string-options.pipe';
import { TimerService } from './services/timer/timer.service';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { ViewportDetectorDirective } from './directives/viewport-detector.directive';
import { AvoPointsMaxPipe } from './pipe/avo-points-max.pipe';
import { AvoEnoughBalancePipe } from './pipe/enough-balance.pipe';
import { RoundNumberPipe } from './pipe/round-number.pipe';
import { HandleClickOutsideDirective } from './directives/handle-click-outside.directive';
import { ProductCartQuantityPipe } from './pipe/product-cart-quantity.pipe';
import { InViewDirective } from './directives/in-view.directive';
import { IconSrcPipe } from './pipe/icon-src.pipe';
import { HiddenForDirective } from './directives/hidden-for.directive';
import { CustomRouterLinkDirective } from './directives/custom-router-link.directive';
import { AvoCurrencyPipe } from './pipe/avo-currency.pipe';

// force update 2

@NgModule({
  declarations: [
    PhoneFormatDirective,
    SaIdTransformPipe,
    PhoneTransformPipe,
    MaskEmailPipe,
    AmountTransformPipe,
    SaIdFormatDirective,
    AmountFormatDirective,
    FocusDirective,
    GroupedTransactionsByDatePipe,
    AvailableBalancePipe,
    WalletDateFormatPipe,
    DateAgoPipe,
    TimesPipe,
    GetDataByIdPipe,
    GroupDataByDatePipe,
    GetKilobytePipe,
    HandleFormErrorPipe,
    GetSafeHTMLPipe,
    GetSafeUrlPipe,
    SortByDatetimePipe,
    NotificationDateFormatPipe,
    OrderByPipe,
    LeadingZeroesFormatPipe,
    SecureImagePipe,
    TimeAgoPipe,
    HashStringPipe,
    FormatDatePipe,
    AvoPointsToZARPipe,
    ResizeImageUrlPipe,
    DurationPipe,
    HomeWalletWidgetPipe,
    PreventDoubleClickDirective,
    // FilterPipe,
    FileSizePipe,
    GetPipe,
    // FindPipe,
    SortByPipe,
    StringOptionsPipe,
    DragAndDropDirective,
    ViewportDetectorDirective,
    InViewDirective,
    AvoPointsMaxPipe,
    AvoEnoughBalancePipe,
    RoundNumberPipe,
    ProductCartQuantityPipe,
    HandleClickOutsideDirective,
    IconSrcPipe,
    HiddenForDirective,
    CustomRouterLinkDirective,
    AvoCurrencyPipe,
  ],
  imports: [CommonModule],
  exports: [
    AmountTransformPipe,
    SaIdTransformPipe,
    PhoneTransformPipe,
    MaskEmailPipe,
    OrderByPipe,
    PhoneFormatDirective,
    SaIdFormatDirective,
    GroupedTransactionsByDatePipe,
    AmountFormatDirective,
    FocusDirective,
    AvailableBalancePipe,
    WalletDateFormatPipe,
    DateAgoPipe,
    TimesPipe,
    GetDataByIdPipe,
    GroupDataByDatePipe,
    GetKilobytePipe,
    HandleFormErrorPipe,
    GetSafeHTMLPipe,
    GetSafeUrlPipe,
    SortByDatetimePipe,
    NotificationDateFormatPipe,
    LeadingZeroesFormatPipe,
    SecureImagePipe,
    TimeAgoPipe,
    HashStringPipe,
    FormatDatePipe,
    AvoPointsToZARPipe,
    ResizeImageUrlPipe,
    DurationPipe,
    HomeWalletWidgetPipe,
    PreventDoubleClickDirective,
    // FilterPipe,
    FileSizePipe,
    GetPipe,
    // FindPipe,
    SortByPipe,
    StringOptionsPipe,
    DragAndDropDirective,
    ViewportDetectorDirective,
    InViewDirective,
    AvoPointsMaxPipe,
    AvoEnoughBalancePipe,
    RoundNumberPipe,
    ProductCartQuantityPipe,
    HandleClickOutsideDirective,
    IconSrcPipe,
    HiddenForDirective,
    CustomRouterLinkDirective,
    AvoCurrencyPipe,
  ],
  providers: [
    LocationService,
    ApiService,
    AppInitService,
    AmountTransformPipe,
    SaIdTransformPipe,
    PhoneTransformPipe,
    MaskEmailPipe,
    ScriptService,
    GroupedTransactionsByDatePipe,
    AvailableBalancePipe,
    WalletDateFormatPipe,
    AuthorizationService,
    GuestAuthorizationService,
    DateAgoPipe,
    TimesPipe,
    GetDataByIdPipe,
    GetKilobytePipe,
    BrowserStorageService,
    NotificationService,
    NotificationsService,
    WINDOW_PROVIDERS,
    HandleFormErrorPipe,
    GetSafeHTMLPipe,
    GetSafeUrlPipe,
    ShareService,
    SortByDatetimePipe,
    AuthenticationService,
    NotificationDateFormatPipe,
    ResponsiveService,
    HttpErrorConsoleLogger,
    SecureImagePipe,
    TimeAgoPipe,
    HashStringPipe,
    AppConfigService,
    FormatDatePipe,
    AvoPointsToZARPipe,
    AlertService,
    BuildNumberService,
    // FilterPipe,
    GetPipe,
    // FindPipe,
    SortByPipe,
    StringOptionsPipe,
    TimerService,
    OrderByPipe,
    AvoPointsMaxPipe,
    AvoEnoughBalancePipe,
    RoundNumberPipe,
    ProductCartQuantityPipe,
    EmailVerificationService,
    CurrencyPipe,
    AvoCurrencyPipe,
  ],
})
export class WakandaCoreModule {}
