import { createAction, props } from '@ngrx/store';
import {
  BusinessDetailsRequest,
  IAddItemRequest,
  IAddItemResponse,
  IApplyPromoCodesRequest,
  ICart,
  ICartGlobal,
  IFetchCartSummary,
  IFetchLiveStatusResponse,
  IInsuranceData,
  IPayResponse,
  ITermAgreementSuccessFailureUrls,
  IUpdateDeliveryAddressRequest,
  IUpdateDeliveryInstructionsRequest,
  IUpdateDeliveryRequest,
  IUpdateItemQuantityRequest,
} from './cart.models';
import { ITask } from '../models/store.models';

// fetch cart
export const fetchCartRequest = createAction(
  '[CART/API] fetch cart request',
  props<{
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchCartResponse = createAction(
  '[CART/API] fetch cart response',
  props<{
    data: ICartGlobal[];
    taskMetadata: ITask;
  }>(),
);

// fetch cart detail
export const fetchCartDetailRequest = createAction(
  '[CART/API] fetch cart detail request',
  props<{
    cartId: string;
    onSuccess?: (response: ICart) => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchCartDetailResponse = createAction(
  '[CART/API] fetch cart detail response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

// fetch cart summary
export const fetchCartSummaryRequest = createAction(
  '[CART/API] fetch cart summary request',
  props<{
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchCartSummaryResponse = createAction(
  '[CART/API] fetch cart summary response',
  props<{
    data: IFetchCartSummary;
    taskMetadata: ITask;
  }>(),
);

// fetch cart live status
export const fetchCartLiveStatusRequest = createAction(
  '[CART/API] fetch cart live status request',
  props<{
    cartId: string;
    onSuccess?: (response: IFetchLiveStatusResponse) => void;
    onError?: (error?: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchCartLiveStatusResponse = createAction(
  '[CART/API] fetch cart live status response',
  props<{
    data: IFetchLiveStatusResponse;
    taskMetadata: ITask;
  }>(),
);

// add item
export const addItemRequest = createAction(
  '[CART/API] add item request',
  props<{
    request: IAddItemRequest;
    onSuccess: (response: IAddItemResponse) => void;
    onError: () => void;
    taskMetadata: ITask;
  }>(),
);

export const addItemResponse = createAction(
  '[CART/API] add item response',
  props<{
    taskMetadata: ITask;
  }>(),
);

// update delivery
export const updateDeliveryRequest = createAction(
  '[CART/API] update delivery request',
  props<{
    cartId: string;
    fulfilmentId: string;
    request: IUpdateDeliveryRequest;
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const updateDeliveryResponse = createAction(
  '[CART/API] update delivery response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

// update delivery address
export const updateDeliveryAddressRequest = createAction(
  '[CART/API] update delivery address request',
  props<{
    cartId: string;
    request: IUpdateDeliveryAddressRequest;
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const updateDeliveryAddressResponse = createAction(
  '[CART/API] update delivery address response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

// update delivery instructions
export const updateDeliveryInstructionsRequest = createAction(
  '[CART/API] update delivery instructions request',
  props<{
    cartId: string;
    request: IUpdateDeliveryInstructionsRequest;
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const updateDeliveryInstructionsResponse = createAction(
  '[CART/API] update delivery instructions response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

export const updateBusinessDetailsRequest = createAction(
  '[CART/API] update business details request',
  props<{
    cartId: string;
    request: BusinessDetailsRequest;
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const updateBusinessDetailsResponse = createAction(
  '[CART/API] update business details response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

// update item quantity
export const updateItemQuantityRequest = createAction(
  '[CART/API] update item quantity request',
  props<{
    cartId: string;
    cartItemId: string;
    request: IUpdateItemQuantityRequest;
    onSuccess?: () => void;
    onError?: (error?: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const updateItemQuantityResponse = createAction(
  '[CART/API] update item quantity response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

// pay
export const payRequest = createAction(
  '[CART/API] pay request',
  props<{
    cartId: string;
    onSuccess: (response: IPayResponse) => void;
    onError: () => void;
    taskMetadata: ITask;
  }>(),
);

export const payResponse = createAction(
  '[CART/API] pay response',
  props<{
    data: IPayResponse;
    taskMetadata: ITask;
  }>(),
);

// delete cart
export const deleteCartRequest = createAction(
  '[CART/API] delete cart request',
  props<{
    cartId: string;
    onSuccess: () => void;
    onError: (error?: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const deleteCartResponse = createAction(
  '[CART/API] delete cart response',
  props<{
    cartId: string;
    taskMetadata: ITask;
  }>(),
);

// delete fulfilment
export const deleteFulfilmentRequest = createAction(
  '[CART/API] delete fulfilment request',
  props<{
    cartId: string;
    fulfilmentId: string;
    onSuccess: () => void;
    onError: (error?: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const deleteFulfilmentResponse = createAction(
  '[CART/API] delete fulfilment response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

// apply promocodes
export const applyPromoCodesRequest = createAction(
  '[CART/API] apply promo codes request',
  props<{
    cartId: string;
    promoCodes: IApplyPromoCodesRequest;
    onSuccess?: () => void;
    onError?: (error) => void;
    taskMetadata: ITask;
  }>(),
);

export const applyPromoCodesResponse = createAction(
  '[CART/API] apply promo codes response',
  props<{
    data: ICart;
    taskMetadata: ITask;
  }>(),
);

//

export const fetchTermAgreementCompletionUrlRequest = createAction(
  '[CART/API] fetch term agreement completion url request',
  props<{
    flowId: string;
    request: ITermAgreementSuccessFailureUrls;
    onSuccess?: (response: any) => void;
    taskMetadata?: ITask;
  }>(),
);

export const fetchTermAgreementCompletionUrlResponse = createAction(
  '[CART/API] fetch term agreement completion url response',
  props<{ data: any; taskMetadata?: ITask }>(),
);

export const updateInsuranceDataRequest = createAction(
  '[CART/API] update insurance data request',
  props<{
    cartId: string;
    request: IInsuranceData;
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const updateInsuranceDataResponse = createAction(
  '[CART/API] update insurance data response',
  props<{
    taskMetadata: ITask;
  }>(),
);
