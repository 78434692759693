import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, of, Subscription, tap, timer, withLatestFrom } from 'rxjs';
import { ApplicationService } from '../../services/application.service';
import * as ApplicationActions from './application.actions';
import { ITaskAction, StorageKeys, TaskType } from '../models/store.models';
import { Router } from '@angular/router';
import { LoggerContext } from '../../model/core.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggerService } from '../../services/logger.service';
import { UrlRoutes } from '../../routes/routes';
import { select, Store } from '@ngrx/store';
import * as ApplicationSelectors from './application.selectors';
import { AppConfigService } from '../../services/app-config.service';
import { BrowserStorageService } from '../../services/browser-storage.service';
import { TasksFacade } from '../tasks/tasks.facade';
import { httpErrorAction } from './application.actions';
import { SearchInsightsService } from '../../services/search-insights.service';

@Injectable()
export class ApplicationEffects {
  errorHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActions.httpErrorAction),
        tap(action => {
          if (action.error['error']) {
            const httpError: HttpErrorResponse = action.error as HttpErrorResponse;

            // 428 http status codes are handled by authorization dialog
            // only TaskType.FAILED should be logged as error, other types are intended
            if (httpError.status === 428 || action.data.type !== TaskType.FAILED) return;

            const error = this.createErrorObject(httpError);

            // ignore some http status codes (no internet connection, blocked url or server connection issues)
            if (!action.forceLog && [0, 504, 503, 502].includes(httpError.status)) {
              return;
            }

            const rootContext: LoggerContext = {
              // TraceId: httpError.headers.get('traceid'),
            };
            this.logger.logError(error, rootContext, action.error);
          }
        }),
      ),
    { dispatch: false },
  );

  resetLogoutTimer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActions.resetLogoutTimer),
        withLatestFrom(this.store.pipe(select(ApplicationSelectors.isAuthorized))),
        tap(([action, isAuthorized]) => {
          if (this.logoutSubscription) this.logoutSubscription.unsubscribe();
          if (isAuthorized && action.start) {
            this.logoutSubscription = timer(this.logoutInMinutes * 60 * 1000).subscribe(() => {
              this.router.navigate([UrlRoutes.auth.login.logout], {
                queryParams: { keepAlive: true },
              });
            });
          }
        }),
      ),
    { dispatch: false },
  );

  checkMaintenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.checkMaintenanceRequest),
      mergeMap(() =>
        this.appService.checkMaintenance().pipe(
          map(data => ApplicationActions.checkMaintenanceResponse({ data })),
          catchError(() => of(ApplicationActions.checkMaintenanceError())),
        ),
      ),
    ),
  );

  fetchCountryCode = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.fetchCurrentCountryRequestAction),
      mergeMap(({ taskMetadata }) =>
        this.appConfigService.fetchCountryCode().pipe(
          map(data => {
            let countryCode = this.storageService.get(StorageKeys.COUNTRY_CODE);
            if (data?.countryCode) {
              countryCode = data.countryCode;
            } else {
              countryCode = countryCode ?? 'ZA';
            }
            this.storageService.set(StorageKeys.COUNTRY_CODE, countryCode);
            return ApplicationActions.fetchCurrentCountryResponseAction({
              data: countryCode,
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            return of(
              ApplicationActions.httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  setCountryCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationActions.setCountryCodeAction),
        tap(data => {
          this.storageService.set(StorageKeys.COUNTRY_CODE, data.countryCode);
        }),
      ),
    { dispatch: false },
  );

  fetchDiscoverAvoCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.fetchDiscoverAvoCategoriesRequest),
      mergeMap(({ taskMetadata }) =>
        this.appConfigService.fetchDiscoverAvoCategories().pipe(
          map(data => {
            return ApplicationActions.fetchDiscoverAvoCategoriesResponse({
              data,
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            return of(
              ApplicationActions.httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchExitScreen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.fetchExitScreenRequest),
      mergeMap(({ screenId, onSucceed, taskMetadata }) =>
        this.appConfigService.fetchExitScreen(screenId).pipe(
          map(data => {
            onSucceed(data);
            return ApplicationActions.fetchExitScreenResponse({ taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error =>
            of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            ),
          ),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  sendSearchCategoryInsight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.sendSearchCategoryInsightsRequestAction),
      mergeMap(({ request, taskMetadata }) =>
        this.searchInsightsService.sendSearchCategoryInsights(request).pipe(
          map(() =>
            ApplicationActions.sendSearchCategoryInsightsResponseAction({
              taskMetadata: { type: TaskType.SUCCESS, taskId: taskMetadata?.taskId },
            }),
          ),
          catchError(error => {
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  private logoutSubscription = new Subscription();
  private readonly DEFAULT_TITLE = 'Avo';
  private readonly logoutInMinutes = 20;

  constructor(
    private readonly actions$: Actions<ITaskAction>,
    private readonly store: Store,
    private appService: ApplicationService,
    private tasksFacade: TasksFacade,
    private appConfigService: AppConfigService,
    private logger: LoggerService,
    private router: Router,
    private storageService: BrowserStorageService,
    private searchInsightsService: SearchInsightsService,
  ) {}

  private createErrorObject = (httpError: HttpErrorResponse): Error => {
    const status = httpError.status;
    const url = this.normalizeUrl(httpError.url);

    return new Error(`HTTP [${status}] ${url}`);
  };

  /**
   * Remove origin URL part to make it shorter.
   *
   * @param url Failed API endpoint url.
   */
  private normalizeUrl = (url: string): string => {
    const origin = new URL(url).origin;
    return url.replace(origin, '');
  };
}
