import { ISearchCategory } from '../../model/shared.model';
import * as vouchersCategoriesActions from './vouchers-categories.actions';
import { createReducer, on } from '@ngrx/store';

export const VOUCHERS_CATEGORIES_FEATURE_KEY = 'VouchersCategories';

export interface VouchersCategoriesState {
  categoriesTop?: ISearchCategory[];
}

export interface VouchersCategoriesPartialState {
  readonly [VOUCHERS_CATEGORIES_FEATURE_KEY]: VouchersCategoriesState;
}

export const vouchersCategoriesReducer = createReducer(
  { categoriesTop: [] },
  on(vouchersCategoriesActions.fetchCategoriesByTopResponseAction, (vouchersState, { data }) => ({
    categoriesTop: data,
  })),
);
