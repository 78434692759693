import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as CartActions from './cart.actions';
import { CartEntity, IFetchCartSummary } from './cart.models';
import { ICart, ICartGlobal } from './cart.models';
import cloneDeep from 'lodash-es/cloneDeep';

export const CART_FEATURE_KEY = 'cart';

export interface CartState extends EntityState<CartEntity> {
  cart?: ICartGlobal[];
  cartDetail?: ICart;
  cartSummary?: IFetchCartSummary;
}

export interface CartPartialState {
  readonly [CART_FEATURE_KEY]: CartState;
}

export const cartAdapter: EntityAdapter<CartEntity> = createEntityAdapter<CartEntity>();

export const cartInitialState: CartState = cartAdapter.getInitialState({});

const _cartReducer = createReducer(
  cartInitialState,

  on(CartActions.fetchCartResponse, (state, { data }) => ({
    ...state,
    cart: data,
  })),

  on(
    CartActions.fetchCartDetailResponse,
    CartActions.updateDeliveryResponse,
    CartActions.updateDeliveryInstructionsResponse,
    CartActions.updateBusinessDetailsResponse,
    CartActions.deleteFulfilmentResponse,
    CartActions.updateDeliveryAddressResponse,
    CartActions.updateItemQuantityResponse,
    (state, { data }) => ({
      ...state,
      cartDetail: data,
    }),
  ),

  on(CartActions.fetchCartSummaryResponse, (state, { data }) => ({
    ...state,
    cartSummary: data,
  })),

  on(CartActions.fetchCartLiveStatusResponse, (state: CartState, action) => {
    const cart = cloneDeep<ICart>(state.cartDetail);

    // iterate new fulfilments
    action.data.fulfilments.forEach(updatedFulfilment => {
      // get current fulfilment
      const currentFulfilment = cart?.fulfilments.find(f => f.fulfilmentId === updatedFulfilment.fulfilmentId);

      currentFulfilment.driverStatus = updatedFulfilment.driverStatus;

      // iterate new stores
      currentFulfilment.stores = updatedFulfilment.stores.map(updatedStore => {
        // get current store
        const currentStore = currentFulfilment.stores.find(s => s.storeId === updatedStore.storeId);

        currentStore.storeStatus = updatedStore.storeStatus;
        currentStore.items = updatedStore.items.map(updatedItem => {
          const currentItem = currentStore.items.find(v => v.itemId === updatedItem.itemId);

          return {
            ...currentItem,
            ...updatedItem,
          };
        });
        return {
          ...currentStore,
          ...updatedStore,
          items: currentStore.items,
        };
      });
    });

    cart.enablePayment = action.data.enablePayment;
    cart.enableCheckout = action.data.enableCheckout;
    cart.roadblocks = action.data.roadblocks;

    return {
      ...state,
      cartDetail: cart,
    };
  }),

  on(CartActions.deleteCartResponse, (state, { cartId }) => ({
    ...state,
    cart: state.cart.filter(cart => cart.cartId !== cartId),
  })),

  on(CartActions.applyPromoCodesResponse, (state, { data }) => ({
    ...state,
    cartDetail: data,
  })),
);

export function cartReducer(state: CartState | undefined, action: Action) {
  return _cartReducer(state, action);
}
