import { Store, select } from '@ngrx/store';
import { fetchFeatureFlagsRequestAction } from './feature-flags.actions';
import { TaskType } from '../models/store.models';
import { v4 } from 'uuid';
import * as FeatureFlagsSelectors from './feature-flags.selectors';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsFacade {
  featureFlags$ = this.store.pipe(select(FeatureFlagsSelectors.featureFlags));

  constructor(private readonly store: Store) {}

  fetchFeatureFlags(onSuccess?: () => void) {
    this.store.dispatch(
      fetchFeatureFlagsRequestAction({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSuccess,
      }),
    );
  }
}
