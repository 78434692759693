import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-tab',
  template: `
    <div
      class="ui-tab"
      [ngClass]="{
        'margined': margined,
        'disabled': disabled,
        'borderless': borders === 'none',
        'compact': !!compact,
        'full': !!fullHeight,
        'left': !!leftAlign,
        'right': !!rightAlign,
        'slide-in-up-modal': !!slideInUpModal,
        'no-bottom-padding': !!noBottomPadding,
        'no-top-padding': !!noTopPadding
      }"
    >
      <div class="tab-header">
        <ng-container *ngFor="let tab of tabs">
          <span
            class="tab-header-item row"
            [ngClass]="{ 'tab-selected': activeTab === tab, 'small': small, 'light': light }"
            (click)="onTab.emit(tab)"
          >
            <h3 *ngIf="!light">
              {{ tab }}
            </h3>
            <b *ngIf="light" class="small light">
              {{ tab }}
            </b>
            <ng-container *ngIf="requiredTabs?.length > 0 && requiredTabs?.includes(tab)">
              <h3 class="required">*</h3>
            </ng-container>
          </span>
        </ng-container>
      </div>
      <div class="tab-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class TabComponent {
  @Input() disabled: boolean;
  @Input() compact: boolean;
  @Input() borders: string;
  @Input() fullHeight: boolean;
  @Input() leftAlign: boolean;
  @Input() rightAlign: boolean;
  @Input() slideInUpModal: boolean;
  @Input() noBottomPadding: boolean;
  @Input() noTopPadding: boolean;
  @Input() margined: boolean;
  @Input() activeTab: string;
  @Input() tabs: string[];
  @Input() requiredTabs: string[];
  @Input() small = false;
  @Input() light = false;
  @Output() onTab: EventEmitter<string> = new EventEmitter();
}
