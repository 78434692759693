import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { ShoppingCoreService } from '../../services/shopping/shopping-core.service';
import * as action from './shopping-buy.actions';
import * as EVENT from './shopping-buy.events';
import { v4 as uuidv4 } from 'uuid';
import { failed, HttpErrorAction, started, succeed } from '../application/task.actions';
import { IShoppingCart } from '../../models/shopping.model';
import { ApplicationFacade } from '../application/application.facade';

@Injectable()
export class ShoppingBuyEffects {
  fetchShoppingBuyCartContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyContentRequestAction>(EVENT.BUY_CART_CONTENT_REQUEST),
      switchMap(({ taskId, cartType, onSucceed }) =>
        this.service.fetchShoppingCartContent(cartType).pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new action.ShoppingBuyContentResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  createOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyCreateOrderRequestAction>(EVENT.BUY_CART_CREATE_ORDER_REQUEST),
      switchMap(({ taskId, cartType, onSucceeded, onError }) =>
        this.service.createOrder(cartType).pipe(
          map(response => {
            if (onSucceeded) {
              onSucceeded(response);
            }
            return succeed(new action.ShoppingBuyCreateOrderResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new action.ShoppingBuyCreateOrderErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyPayByWalletRequestAction>(EVENT.BUY_CART_PAY_BY_WALLET_REQUEST),
      switchMap(({ taskId, payload, orderNumber, onSucceeded, onError }) =>
        this.service.payByWallet(payload, orderNumber).pipe(
          map(response => {
            if (onSucceeded) {
              onSucceeded(response);
            }
            return succeed(new action.ShoppingBuyPayByWalletResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyPayByCardRequestAction>(EVENT.BUY_CART_PAY_BY_CARD_REQUEST),
      switchMap(({ taskId, payload, orderNumber, onSucceeded, onError }) =>
        this.service.payByCard(payload, orderNumber).pipe(
          map(response => {
            if (onSucceeded) {
              onSucceeded(response);
            }
            return succeed(new action.ShoppingBuyPayByCardResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchOrderInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyOrderInfoRequestAction>(EVENT.BUY_CART_ORDER_INFO_REQUEST),
      switchMap(({ taskId, orderNumber, onSucceeded, onError }) =>
        this.service.fetchOrderInfo(orderNumber).pipe(
          map(response => {
            if (onSucceeded) {
              onSucceeded(response);
            }
            return succeed(new action.ShoppingBuyOrderInfoResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchOrderDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyOrderDetailRequestAction>(EVENT.BUY_CART_ORDER_DETAIL_REQUEST),
      switchMap(({ taskId, orderNumber, onSucceeded, onError }) =>
        this.service.fetchOrderDetail(orderNumber).pipe(
          map(response => {
            if (onSucceeded) {
              onSucceeded(response);
            }
            return succeed(new action.ShoppingBuyOrderDetailResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPaymentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyPaymentInfoRequestAction>(EVENT.BUY_CART_PAYMENT_INFO_REQUEST),
      switchMap(({ taskId, paymentId, onSucceeded, onError }) =>
        this.service.fetchPaymentInfo(paymentId).pipe(
          map(response => {
            if (onSucceeded) {
              onSucceeded(response);
            }
            return succeed(new action.ShoppingBuyPaymentInfoResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchSavedCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyPaymentSavedCardsRequestAction>(EVENT.FETCH_PAYMENT_SAVED_CARDS_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchSavedCards().pipe(
          map(response => {
            return succeed(new action.ShoppingBuyPaymentSavedCardsResponseAction(taskId, response));
          }),
          catchError(error => {
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  updateShoppingBuyCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyUpdateCartItemRequestAction>(EVENT.BUY_CART_UPDATE_CART_ITEM_REQUEST),
      switchMap(({ taskId, cartItem, cartType, onSucceeded, onError }) =>
        this.service.updateCountOfProductShoppingCartItem(cartItem, cartType).pipe(
          map(response => {
            if (typeof onSucceeded === 'function') onSucceeded(response);
            return succeed(new action.ShoppingBuyUpdateCartItemResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error), cartItem));
          }),
        ),
      ),
    ),
  );

  deleteShoppingBuyCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyDeleteCartItemRequestAction>(EVENT.BUY_CART_DELETE_CART_ITEM_REQUEST),
      switchMap(({ taskId, cartItem, cartType, onSucceeded, onError }) =>
        this.service.deleteCartItem(cartItem, cartType).pipe(
          map(response => {
            if (typeof onSucceeded === 'function') onSucceeded(response);
            return succeed(new action.ShoppingBuyDeleteCartItemResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error), cartItem));
          }),
        ),
      ),
    ),
  );

  saveShoppingBuyCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuySaveToLaterCartItemRequestAction>(EVENT.BUY_CART_SAVE_CART_ITEM_REQUEST),
      switchMap(({ taskId, cartItem }) =>
        this.service.saveToLaterCartItem(cartItem).pipe(
          map(response => succeed(new action.ShoppingBuySaveToLaterCartItemResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), cartItem))),
        ),
      ),
    ),
  );

  updateSavedShoppingBuyCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuySaveToLaterCartItemRequestAction>(EVENT.BUY_CART_UPDATE_SAVED_CART_ITEM_REQUEST),
      switchMap(({ taskId, cartItem }) =>
        this.service.updateSavedCartItem(cartItem, cartItem.sellerId).pipe(
          map(response => succeed(new action.ShoppingBuySaveToLaterCartItemResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), cartItem))),
        ),
      ),
    ),
  );

  deleteSavedShoppingBuyCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyDeleteSavedCartItemRequestAction>(EVENT.BUY_CART_DELETE_SAVED_CART_ITEM_REQUEST),
      switchMap(({ taskId, cartItemId, sellerId }) =>
        this.service.deleteSavedCartItem(cartItemId, sellerId).pipe(
          map(response => succeed(new action.ShoppingBuyDeleteSavedCartItemResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), cartItemId))),
        ),
      ),
    ),
  );

  fetchPromoCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyGetPromoRequestAction>(EVENT.BUY_CART_GET_PROMO_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchPromoCodes().pipe(
          map(res => succeed(new action.ShoppingBuyGetPromoResponseAction(taskId, res))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  applyPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyApplyPromoRequestAction>(EVENT.BUY_CART_APPLY_PROMO_REQUEST),
      switchMap(({ taskId, promoCode, cartType, onSuccess, onError }) =>
        this.service.applyPromoCode(promoCode, cartType).pipe(
          map((cart: IShoppingCart) => {
            onSuccess();
            return succeed(new action.ShoppingBuyApplyPromoResponseAction(taskId, cart));
          }),
          catchError(error => {
            onError(error);
            return of(failed(new HttpErrorAction(taskId, error), promoCode));
          }),
        ),
      ),
    ),
  );

  removePromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyRemovePromoRequestAction>(EVENT.BUY_CART_REMOVE_PROMO_REQUEST),
      switchMap(({ taskId, promoCode, cartType, onSucceeded }) =>
        this.service.removePromoCode(promoCode, cartType).pipe(
          map(cart => {
            onSucceeded();
            return succeed(new action.ShoppingBuyRemovePromoResponseAction(taskId, cart));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), promoCode))),
        ),
      ),
    ),
  );

  fetchPickupLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyGetPickupLocationsRequestAction>(EVENT.BUY_CART_GET_PICKUP_LOCATION_REQUEST),
      switchMap(({ taskId, cartType, fulfillmentId, deliveryMethod }) =>
        this.service.fetchPickupLocations(cartType, fulfillmentId, deliveryMethod).pipe(
          map(res => succeed(new action.ShoppingBuyGetPickupLocationsResponseAction(taskId, res))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  updateDeliveryMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingUpdateDeliveryOptionRequestAction>(EVENT.BUY_CART_UPDATE_DELIVERY_OPTIONS_REQUEST),
      switchMap(({ taskId, cartType, fulfillmentId, delivery, onSucceed }) =>
        this.service.updateDeliveryMethod(delivery, cartType, fulfillmentId).pipe(
          map(cart => {
            onSucceed(cart);
            return succeed(new action.ShoppingUpdateDeliveryOptionResponseAction(taskId, cart));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  updateDeliveryAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyDeliveryInfoRequestAction>(EVENT.BUY_CART_DELIVERY_INFO_REQUEST),
      switchMap(({ taskId, deliverInfo, cartType, redirect }) =>
        this.service.updateDeliveryAddresses(deliverInfo, cartType).pipe(
          map(res => {
            redirect();
            return succeed(new action.ShoppingBuyDeliveryInfoResponseAction(taskId, res));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { deliverInfo, redirect }))),
        ),
      ),
    ),
  );

  fetchDeliveryOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyFetchDeliveryOptionsRequestAction>(EVENT.BUY_CART_DELIVERY_OPTIONS_REQUEST),
      switchMap(({ taskId, cartType, location }) =>
        this.service.fetchDeliveryOptions(cartType, location).pipe(
          map(response => succeed(new action.ShoppingBuyFetchDeliveryOptionsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  fetchReserveInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyReserveInfoRequestAction>(EVENT.BUY_CART_RESERVE_INFO_REQUEST),
      switchMap(({ taskId, reserveInfo, cartType, redirect, onSucceeded, onError }) =>
        this.service.fetchReserveInfo(reserveInfo, cartType).pipe(
          map(res => {
            redirect();
            if (typeof onSucceeded === 'function') onSucceeded();
            return succeed(new action.ShoppingBuyReserveInfoResponseAction(taskId, res));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error), { reserveInfo, redirect }));
          }),
        ),
      ),
    ),
  );

  fetchSavedItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingMyCartSavedItemsRequestAction>(EVENT.BUY_CART_SAVED_ITEMS_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchSaveItems().pipe(
          map(response => succeed(new action.ShoppingMyCartSavedItemsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  moveCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyMoveCartItemRequestAction>(EVENT.BUY_CART_MOVE_CART_ITEM_REQUEST),
      switchMap(({ taskId, cartItem, cartType }) =>
        this.service.buyNow(cartItem, cartType).pipe(
          map(response => succeed(new action.ShoppingBuyMoveCartItemResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), cartItem))),
        ),
      ),
    ),
  );

  fetchReturnPolicyUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyFetchReturnPolicyInfoRequestAction>(EVENT.BUY_CART_RETURN_POLICY_INFO_REQUEST),
      switchMap(({ taskId, onSucceeded }) =>
        this.service.fetchReturnPolicyUrlInfo().pipe(
          map(response => {
            onSucceeded(response);
            return succeed(new action.ShoppingBuyFetchReturnPolicyInfoResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { onSucceeded }))),
        ),
      ),
    ),
  );

  fetchTermsAndConditionsUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyFetchTermsAndConditionsInfoRequestAction>(
        EVENT.BUY_CART_TERMS_AND_CONDITIONS_INFO_REQUEST,
      ),
      switchMap(({ taskId, onSucceeded }) =>
        this.service.fetchTermsAndConditionsUrlInfo().pipe(
          map(response => {
            onSucceeded(response);
            return succeed(new action.ShoppingBuyFetchTermsAndConditionsInfoResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { onSucceeded }))),
        ),
      ),
    ),
  );

  fetchPaymentReservationUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyFetchPaymentReservationInfoRequestAction>(
        EVENT.BUY_CART_PAYMENT_RESERVATION_INFO_REQUEST,
      ),
      switchMap(({ taskId, onSucceeded }) =>
        this.service.fetchPaymentReservationUrlInfo().pipe(
          map(response => {
            onSucceeded(response);
            return succeed(new action.ShoppingBuyFetchPaymentReservationInfoResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { onSucceeded }))),
        ),
      ),
    ),
  );

  updateCartGift$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyUpdateCartGiftRequestAction>(EVENT.BUY_CART_UPDATE_CART_GIFT_REQUEST),
      switchMap(({ taskId, updateCartGiftOption, cartType, onSucceeded }) =>
        this.service.updateCartGift(updateCartGiftOption, cartType).pipe(
          switchMap(() => {
            if (onSucceeded) {
              onSucceeded();
            }
            return [
              started(
                new action.ShoppingBuyContentRequestAction(`shopping-fetching-cart-content-${uuidv4()}`, cartType),
                'Update cart',
                true,
              ),
              succeed(new action.ShoppingBuyUpdateCartGiftResponseAction(taskId)),
            ];
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  tvLicenceCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingTvLicenceCheckRequest>(EVENT.TV_LICENCE_CHECK_REQUEST),
      switchMap(({ taskId, request, onSucceed }) =>
        this.service.tvLicenceCheck(request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new action.ShoppingTvLicenceCheckSucceeded(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  uploadAsset = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingUploadAssetRequest>(EVENT.UPLOAD_ASSET_REQUEST),
      switchMap(({ taskId, file, onSucceed }) =>
        this.service.postAsset(file).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new action.ShoppingUploadAssetSucceeded(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  addTvLicencePhotoId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingAddTvLicencePhotoIdRequest>(EVENT.ADD_TV_LICENCE_PHOTO_ID_REQUEST),
      switchMap(({ taskId, request }) =>
        this.service.addTvLicencePhotoId(request).pipe(
          map(response => succeed(new action.ShoppingAddTvLicencePhotoIdSucceeded(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );
  setTermInMonths$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuySetTermInMonthsRequest>(EVENT.BUY_CART_SET_TERM_IN_MONTHS_REQUEST),
      switchMap(({ taskId, cartType, request }) =>
        this.service.setTermInMonths(cartType, request).pipe(
          map(response => succeed(new action.ShoppingBuySetTermInMonthsResponse(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  clearCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyClearCartRequest>(EVENT.BUY_CART_CLEAR_REQUEST),
      switchMap(({ taskId, cartType }) =>
        this.service.clearCart(cartType).pipe(
          map(response => succeed(new action.ShoppingBuyClearCartResponse(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  // cart v3

  fetchShoppingBuyerCarts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerCartsRequestAction>(EVENT.BUYER_CARTS_REQUEST),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.service.fetchCarts().pipe(
          switchMap(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            if (response?.length > 0) {
              return [
                started(
                  new action.ShoppingBuyerCartDetailRequestAction(taskId, response[0].cartId),
                  'Fetching cart detail',
                ),
                succeed(new action.ShoppingBuyerCartsResponseAction(taskId, response)),
              ];
            }
            return [succeed(new action.ShoppingBuyerCartsResponseAction(taskId, response))];
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchShoppingBuyerCartDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerCartDetailRequestAction>(EVENT.BUYER_CART_DETAIL_REQUEST),
      switchMap(({ taskId, cartId, onSucceed, onError }) =>
        this.service.fetchCartDetail(cartId).pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new action.ShoppingBuyerCartDetailResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  addShoppingBuyerCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerAddCartItemRequestAction>(EVENT.BUYER_CART_ADD_CART_ITEM_REQUEST),
      switchMap(({ taskId, request, onSucceeded, onError }) =>
        this.service.addProductToCart(request).pipe(
          map(response => {
            if (typeof onSucceeded === 'function') onSucceeded(response);
            return succeed(new action.ShoppingBuyerAddCartItemResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error), request));
          }),
        ),
      ),
    ),
  );

  payCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerPayCartRequestAction>(EVENT.BUYER_PAY_CART_REQUEST),
      switchMap(({ taskId, cartId, onSucceed, onError }) =>
        this.service.payCart(cartId).pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new action.ShoppingBuyerPayCartResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  updateShoppingBuyerCartItemQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerCartItemQuantityRequestAction>(EVENT.BUYER_CART_ITEM_QUANTITY_REQUEST),
      switchMap(({ taskId, cartId, cartItemId, request, onSucceed, onError }) =>
        this.service.updateItemQuantity(cartId, cartItemId, request).pipe(
          switchMap(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return [
              started(
                new action.ShoppingBuyerCartSummaryRequestAction(`shopping-cart-summary-${uuidv4()}`),
                'Fetching cart summary',
                true,
              ),
              succeed(new action.ShoppingBuyerCartItemQuantityResponseAction(taskId, response)),
            ];
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  updateShoppingBuyerCartDeliveryAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerCartDeliveryAddressRequestAction>(EVENT.BUYER_CART_DELIVERY_ADDRESS_REQUEST),
      switchMap(({ taskId, cartId, request, onSucceed, onError }) =>
        this.service.updateDeliveryAddress(cartId, request).pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new action.ShoppingBuyerCartDeliveryAddressResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  updateShoppingBuyerCartDeliveryOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerCartDeliveryOptionRequestAction>(EVENT.BUYER_CART_DELIVERY_OPTION_REQUEST),
      switchMap(({ taskId, cartId, fulfilmentId, request, onSucceed, onError }) =>
        this.service.updateDeliveryOption(cartId, fulfilmentId, request).pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new action.ShoppingBuyerCartDeliveryOptionResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  fetchShoppingBuyerCartSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType<action.ShoppingBuyerCartSummaryRequestAction>(EVENT.BUYER_CART_SUMMARY_REQUEST),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.service.fetchCartSummary().pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new action.ShoppingBuyerCartSummaryResponseAction(taskId, response));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: ShoppingCoreService) {}
}
