import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as GroceriesActions from './groceries.actions';
import * as GroceriesSelectors from './groceries.selectors';
import { v4 } from 'uuid';
import {
  IGroceriesAddCartCouponRequest,
  IGroceriesCartItemRequest,
  IGroceriesCartResponse,
  IGroceriesCartTimeslotResponse,
  IGroceriesPlaceOrderRequest,
  IGroceriesPlaceOrderResponse,
  IGroceriesRemoveCartItemRequest,
  IGroceriesRemoveCartPromoCodeRequest,
} from './groceries.models';
import { TaskType } from '../models/store.models';

@Injectable({ providedIn: 'root' })
export class GroceriesFacade {
  getCart$ = this.store.select(GroceriesSelectors.getCart);
  getCartCount$ = this.store.select(GroceriesSelectors.getCartCount);

  constructor(private readonly store: Store) {}

  fetchCart(onSuccess?: () => void, onError?: () => void): void {
    this.store.dispatch(
      GroceriesActions.fetchGroceriesCartRequest({
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  clearCart(onSuccess: () => void, onError: () => void): void {
    this.store.dispatch(
      GroceriesActions.clearCartRequest({
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  addCartItem(
    request: IGroceriesCartItemRequest,
    onSuccess: (response: IGroceriesCartResponse) => void,
    onError: (error: any) => void,
    hasFullscreenLoading = false,
  ): void {
    this.store.dispatch(
      GroceriesActions.addCartItemRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: {
          type: hasFullscreenLoading ? TaskType.STARTED_FULLSCREEN : TaskType.STARTED,
          taskId: v4(),
        },
      }),
    );
  }

  updateCartItem(
    request: IGroceriesCartItemRequest,
    onSuccess: (response: IGroceriesCartResponse) => void,
    onError: (error: any) => void,
  ): void {
    this.store.dispatch(
      GroceriesActions.updateCartItemRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  removeCartItem(
    request: IGroceriesRemoveCartItemRequest,
    onSuccess: (response: IGroceriesCartResponse) => void,
    onError: (error: any) => void,
  ): void {
    this.store.dispatch(
      GroceriesActions.removeCartItemRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED_FULLSCREEN, taskId: v4() },
      }),
    );
  }

  addCartPromoCode(
    request: IGroceriesAddCartCouponRequest,
    onSuccess?: (response: IGroceriesCartResponse) => void,
    onError?: (error: any) => void,
  ): void {
    this.store.dispatch(
      GroceriesActions.addCartPromoCodeRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED_FULLSCREEN, taskId: v4() },
      }),
    );
  }

  removeCartPromoCode(
    request: IGroceriesRemoveCartPromoCodeRequest,
    onSuccess?: (response: IGroceriesCartResponse) => void,
    onError?: (error: any) => void,
  ): void {
    this.store.dispatch(
      GroceriesActions.removeCartPromoCodeRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED_FULLSCREEN, taskId: v4() },
      }),
    );
  }

  fetchCartTimeslots(onSuccess?: (response: IGroceriesCartTimeslotResponse) => void, onError?: () => void): void {
    this.store.dispatch(
      GroceriesActions.fetchCartTimeslotsRequest({
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED_FULLSCREEN, taskId: v4() },
      }),
    );
  }

  placeCartOrder(
    request: IGroceriesPlaceOrderRequest,
    onSuccess?: (response: IGroceriesPlaceOrderResponse) => void,
    onError?: (error: any) => void,
  ): void {
    this.store.dispatch(
      GroceriesActions.placeCartOrderRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED_FULLSCREEN, taskId: v4() },
      }),
    );
  }
}
