import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOCATION_FEATURE_KEY, LocationState } from './location.reducer';
export const getLocationState = createFeatureSelector<LocationState>(LOCATION_FEATURE_KEY);

export const getLocations = createSelector(getLocationState, ({ locations }) => locations);

export const getLiveLocation = createSelector(getLocationState, ({ currentLocation }) => currentLocation);

export const isGoogleMapsAPILoaded = createSelector(
  getLocationState,
  ({ isGoogleMapsApiLoaded }) => isGoogleMapsApiLoaded,
);
