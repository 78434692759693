import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UrlRoutes } from '../routes/routes';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.postAction === 'CUSTOMER_DATA_REQUIRED') {
          let editType = null;
          switch (error.error?.error?.code) {
            case 'ADD_EMAIL': {
              editType = 'editEmail';
              break;
            }
            case 'ADD_PHONE': {
              editType = 'editPhone';
              break;
            }
            default:
              break;
          }
          this.navigateToWithParams(UrlRoutes.profile.manageProfile.landing, {
            editType,
            returnUrl: this.router.url,
          });
        } else if (error?.error?.postAction === 'LOGOUT') {
          this.navigate(UrlRoutes.auth.login.logout);
        }
        return throwError(() => error);
      }),
    );
  }

  private navigateToWithParams = (url: string, params: Params, replaceUrl?: boolean): void => {
    this.router.navigate(url ? [url] : [], { replaceUrl, queryParams: params });
  };

  private navigate = (url: string): void => {
    this.router.navigate([url]);
  };
}
///
