import { Component, HostBinding, Input } from '@angular/core';
import { Assets } from '../../assets';
import { UiSpinnerSize } from '../spinner';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'ui-card-link, [ui-card-link]',
  templateUrl: './ui-card-link.component.html',
})
export class UiCardLinkComponent {
  readonly Assets = Assets;
  readonly UiSpinnerSize = UiSpinnerSize;

  /** First line */
  @Input() text: string | undefined;

  /** Optional second line */
  @Input() subtext: string | undefined;

  /** Green text next between text and icon */
  @Input() price: string | undefined;

  /** Left side icon */
  @Input() icon: SafeUrl | string | undefined;

  /** Right side icon */
  @Input() iconRight: SafeUrl | string | undefined;

  /** Flag if there is an icon visible on the right side */
  @Input() hasIconRight = true;

  /** Flag if width is unlimited */
  @Input() fluid = false;

  /** Flag if spinner is visible */
  @Input() hasSpinner = false;

  /** Display 3 lines - default is 1 */
  @Input() clamp3Lines = false;

  /** Flag if card is disabled */
  @Input() isDisabled = false;

  @HostBinding('class')
  get classes(): string[] {
    const output = [
      'bg-primary-white',
      'rounded-lg',
      'border-secondary-concrete',
      'border',
      'p-2',
      'flex',
      'align-center',
      'justify-center',
      'w-full',
    ];
    if (!this.fluid) {
      output.push('md:max-w-form-control');
    }

    if (this.hasSpinner || this.isDisabled) {
      output.push('pointer-events-none');
    }

    if (!this.isDisabled) {
      output.push('cursor-pointer');
    }

    return output;
  }
}
