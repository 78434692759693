import { Pipe, PipeTransform } from '@angular/core';
import { IPhonePipeParam } from '../models/common.model';
import { PhoneUtil } from '../utils/phone.util';
import { PhonePrefixCountry } from '@avo/shared/core';

@Pipe({ name: 'phoneTransform', pure: false })
export class PhoneTransformPipe implements PipeTransform {
  prefix: string;
  prefix2: string;
  namibiaPrefix1: string;
  namibiaPrefix2: string;
  maxLength: number;
  THOUSANDS_SEPARATOR: string;

  constructor() {
    this.prefix = PhonePrefixCountry.ZA + ' ';
    this.prefix2 = PhonePrefixCountry.ZA;
    this.namibiaPrefix1 = PhonePrefixCountry.NA + ' ';
    this.namibiaPrefix2 = PhonePrefixCountry.NA;
    this.maxLength = 11;
    this.THOUSANDS_SEPARATOR = ' ';
  }

  transform(value: string, param?: IPhonePipeParam): string {
    if (param) {
      this.prefix = param.formInputTransform ? '' : param.prefix || this.prefix;
      this.maxLength = param.maxLength || this.maxLength;
    }
    value = value || '';
    if (value === '') {
      return this.prefix + '';
    }

    if (value.startsWith(this.namibiaPrefix1) || value.startsWith(this.namibiaPrefix2)) {
      this.prefix = this.namibiaPrefix1;
      this.prefix2 = this.namibiaPrefix2;
    }
    value = value.replace(this.prefix, '');
    value = value.replace(this.prefix2, '');
    if (value.startsWith('0') && value.length > 9) {
      value = value.substr(1);
    }
    if (value.includes('+')) {
      value = '';
    }
    if (!!param && param.formInputTransform) {
      return value.replace(/\s/g, '');
    }
    const formattedValue = PhoneUtil.phoneSplit(value, this.maxLength);
    return this.prefix + formattedValue;
  }

  parse(value: string, prefix: string): string {
    this.prefix = prefix;
    value = value || '';
    value = value.replace(this.prefix, '');

    value = value.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');
    return value;
  }
}
