<div class="flex flex-row items-center justify-center w-full" [class.opacity-50]="isDisabled">
  <ui-icon [src]="icon" class="rounded-lg" *ngIf="icon"></ui-icon>

  <div class="text-primary-purple w-full">
    <div class="flex flex-col ml-2">
      <p class="font-medium text-h4">{{ text }}</p>
      <p class="mt-1 text-base" [ngClass]="clamp3Lines ? 'line-clamp-3' : 'line-clamp-1'" *ngIf="subtext">
        {{ subtext }}
      </p>
    </div>
  </div>

  <p class="font-medium text-h3 text-primary-good-green ml-8 mt-2" *ngIf="price !== null && price !== undefined">
    {{ price }}
  </p>

  <ui-spinner
    [size]="UiSpinnerSize.SMALL"
    class="!inline ml-auto"
    *ngIf="hasSpinner; else noSpinnerTemplate"
  ></ui-spinner>

  <ng-template #noSpinnerTemplate>
    <ui-icon
      [src]="iconRight || Assets.icon.system.chevron.forward.dark"
      class="rounded-lg ml-auto"
      *ngIf="hasIconRight"
    ></ui-icon>
  </ng-template>
</div>
