import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPLICATION_FEATURE_KEY, State } from './application.reducer';

export const getApplicationState = createFeatureSelector<State>(APPLICATION_FEATURE_KEY);

export const getMaintenance = createSelector(getApplicationState, state =>
  state.maintenance?.maintenancePageON ? state.maintenance : null,
);
export const getCurrentLocation = createSelector(getApplicationState, state => state.currentLocation);
export const getAvoInfo = createSelector(getApplicationState, state => state.avoInfo);
export const isAuthorized = createSelector(getApplicationState, state =>
  state && state.isAuthorized !== undefined ? state.isAuthorized : false,
);
export const isLimitedUser = createSelector(getApplicationState, state => !state.avoInfo?.linkedToBank);
export const getReturnUrl = createSelector(getApplicationState, state => state.returnUrl);
export const getTermAgreementFailureUrl = createSelector(getApplicationState, state => state.termAgreementFailureUrl);
export const getOrderId = createSelector(getApplicationState, state => state.orderId);
export const getfuneralReturnUrl = createSelector(getApplicationState, state => state.funeralReturnUrl);
export const hasLocationAvailable = createSelector(getApplicationState, state => state.hasLocationAvailable);
export const getSearchOptions = createSelector(getApplicationState, state => state.searchOptions);
export const getCountryCode = createSelector(getApplicationState, state => state.countryCode);
export const getDiscoverAvoCategories = createSelector(getApplicationState, state => state.discoverAvoCategories);
export const getPrimersRedirectFinished = createSelector(getApplicationState, state => state.primersRedirectFinished);
export const searchTerm = createSelector(getApplicationState, state => state.searchTerm);
export const searchInputInFocus = createSelector(getApplicationState, state => state.searchInputInFocus);
export const searchVerticalInFocus = createSelector(getApplicationState, state => state.searchVerticalInFocus);
export const searchVertical = createSelector(getApplicationState, state => state.searchVertical);
export const hasPageVisited = createSelector(getApplicationState, state => state.hasPageVisited);
export const cartComponentInitialized = createSelector(getApplicationState, state => state.cartComponentInitialized);

export const isTravelDialogOpen = createSelector(getApplicationState, state => state.isTravelDialogOpen);
