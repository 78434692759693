import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { DeviceIdService } from '../services/deviceid.service';

@Injectable()
export class DeviceIdInterceptor implements HttpInterceptor {
  private readonly deviceService = inject(DeviceIdService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (!request.url.includes('loc-saas') && !request.url.includes('configuration.json')) {
      request = request.clone({
        headers: request.headers.set('X-Avo-DeviceId', this.deviceService.deviceId),
      });
    }

    return next.handle(request);
  }
}
