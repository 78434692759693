import { Action } from '@ngrx/store';
import {
  IBuyer,
  IDashboardWalletWidget,
  IFilterPayoutTransactionRequest,
  IFilterSettlementRequest,
  IGenerateRemittanceRequest,
  ILoyaltyPoint,
  IPayByCardResponse,
  IPayByCreditRequest,
  IPayByCreditResponse,
  IPaymentRemittanceExport,
  IRequestDetailResponse,
  IResponseError,
  ISellerStatement,
  ISellerStatementExport,
  ISellerStatementExportRequest,
  ISellerStatementRequest,
  ISettlementExport,
  IShoppingAvailablePaymentMethod,
  ITaskDetail,
  ITaxInvoice,
  ITaxInvoiceRequest,
  ITopUpAndPayResponse,
  IWalletBankInfo,
  IWalletChangeSettlementSettingsRequest,
  IWalletSettlementAccount,
  IWalletSettlementAccountRequest,
  IWalletSettlementAccountTypeRequest,
  IWalletSettlementSettings,
  IWalletTopUpAddCardRequest,
  SellerPayoutTransactions,
  WalletDashboardWidgetType,
} from '../../..';
import {
  ICustomerStoreOfValue,
  IPayByCardRequest,
  IPayByWalletRequest,
  IQrCodeDecoded,
  ITopUpAndPayRequest,
  IWallet,
  IWalletAddCardResponse,
  IWalletBank,
  IWalletCheckPayRequest,
  IWalletCheckPayResponse,
  IWalletEditCardRequest,
  IWalletExternalAccount,
  IWalletPartyAccount,
  IWalletPaymentLinkResponse,
  IWalletPaymentRequest,
  IWalletPayRecentRecipient,
  IWalletPayResponse,
  IWalletQrCodePaymentRequest,
  IWalletRecentSearch,
  IWalletRefund,
  IWalletRefundResponse,
  IWalletRequest,
  IWalletRequestResponse,
  IWalletStatementFilter,
  IWalletSubscription,
  IWalletSubscriptionAvailable,
  IWalletSubscriptionRequest,
  IWalletTopUpAccountRequest,
  IWalletTopUpAccountResponse,
  IWalletTopUpCardResponse,
  IWalletTopUpPayRequest,
  IWalletTopUpStatusResponse,
  IWalletTransaction,
  IWalletTransactionDetail,
  IWalletWithdrawRequest,
  IWalletWithdrawResponse,
  IAddCardResponse,
  Ibalance,
  ITransactionHistoryReportFilter,
  IPaymentMethodTransaction,
  ITransactionDetail,
  IEditPaymentMethodRequest,
} from '../../models/wallet.model';
import * as EVENT from './wallet.events';

// MyMoneyWallet
export class MyMoneyWalletsRequestAction implements Action {
  readonly type = EVENT.WALLETS_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed?: (data: IWallet[]) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class MyMoneyWalletsResponseAction implements Action {
  readonly type = EVENT.WALLETS_RECEIVED;
  constructor(public taskId: string, public data: IWallet[]) {}
}

// Transactions
export class MyMoneyTransactionsRequestAction implements Action {
  readonly type = EVENT.TRANSACTIONS_REQUEST;
  constructor(public taskId: string, public walletId: string, public onSucceed?: () => void) {}
}

export class MyMoneyTransactionsResponseAction implements Action {
  readonly type = EVENT.TRANSACTIONS_RECEIVED;
  constructor(public taskId: string, public data: IWalletTransaction[]) {}
}

// Transaction detail

export class MyMoneyTransactionDetailRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_DETAIL_REQUEST;
  constructor(public taskId: string, public walletId: string, public transactionId: string) {}
}

export class MyMoneyTransactionDetailResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_DETAIL_RECEIVED;
  constructor(public taskId: string, public data: IWalletTransactionDetail) {}
}

// Transaction search
export class MyMoneyTransactionSeaarchRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_SEARCH_REQUEST;
  constructor(public taskId: string, public walletId: string, public query: string, public onSucceed?: () => void) {}
}

export class MyMoneyTransactionSearchResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_SEARCH_RECEIVED;
  constructor(public taskId: string, public data: IWalletTransaction[]) {}
}

// Transaction RECENT
export class MyMoneyTransactionRecentRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_RECENT_REQUEST;
  constructor(public taskId: string, public walletId: string) {}
}

export class MyMoneyTransactionRecentResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_RECENT_RECEIVED;
  constructor(public taskId: string, public data: IWalletRecentSearch[]) {}
}

// Transaction filter
export class MyMoneyTransactionFilterRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_FILTER_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public filter: IWalletStatementFilter,
    public onSucceed?: () => void,
  ) {}
}

export class MyMoneyTransactionFilterResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_FILTER_RECEIVED;
  constructor(public taskId: string, public data: IWalletTransaction[]) {}
}

// Transaction filter
export class MyMoneyTransactionDownloadStatementRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_DOWNLOAD_STATEMENT_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public filter: IWalletStatementFilter,
    public onSucceed: (data: any) => void,
  ) {}
}

export class MyMoneyTransactionDownloadStatementResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_DOWNLOAD_STATEMENT_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

// MyMoneyPay
export class MyMoneyPayRequestAction implements Action {
  readonly type = EVENT.PAY_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public payRequest: IWalletPaymentRequest,
    public onSucceed: (response) => void,
    public onWalletError: (error) => void,
  ) {}
}

export class MyMoneyPayResponseAction implements Action {
  readonly type = EVENT.PAY_SUCCEEDED;
  constructor(public taskId: string, public data: IWalletPayResponse) {}
}

// MyMoneyPayRecentRecipients
export class MyMoneyPayRecentRecipientsRequestAction implements Action {
  readonly type = EVENT.PAY_RECENT_RECIPIENTS_REQUEST;
  constructor(public taskId: string, public walletId: string) {}
}

export class MyMoneyPayRecentRecipientsResponseAction implements Action {
  readonly type = EVENT.PAY_RECENT_RECIPIENTS_RECEIVED;
  constructor(public taskId: string, public data: IWalletPayRecentRecipient[]) {}
}

// MyMoneyPayByQR
export class MyMoneyPayByQrCodeRequestAction implements Action {
  readonly type = EVENT.PAY_BY_QR_CODE_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public payRequest: IWalletQrCodePaymentRequest,
    public onSucceed: (response) => void,
    public onWalletError: (error) => void,
  ) {}
}

export class MyMoneyPayByQrCodeResponseAction implements Action {
  readonly type = EVENT.PAY_BY_QR_CODE_SUCCEEDED;
  constructor(public taskId: string, public data: IWalletPayResponse) {}
}

// MyMoneyRefund
export class MyMoneyRefundRequestAction implements Action {
  readonly type = EVENT.REFUND_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public transactionId: string,
    public refundAmount: IWalletRefund,
  ) {}
}
export class MyMoneyRefundResponseAction implements Action {
  readonly type = EVENT.REFUND_SUCCEEDED;
  constructor(public taskId: string, public refundData: IWalletRefundResponse) {}
}

// MyMoneyWithdraw
export class MyMoneyWithdrawRequestAction implements Action {
  readonly type = EVENT.WITHDRAW_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public withdrawRequest: IWalletWithdrawRequest,
    public onSucceed: () => void,
    public onError?: (error: any) => void,
  ) {}
}

export class MyMoneySelectMainWalletAction implements Action {
  readonly type = EVENT.WALLET_SELECTED_MAIN;
}

export class MyMoneyWithdrawResponseAction implements Action {
  readonly type = EVENT.WITHDRAW_SUCCEEDED;
  constructor(public taskId: string, public data: IWalletWithdrawResponse) {}
}

export class MyMoneyWalletErrorAction implements Action {
  readonly type = EVENT.WALLET_FAILED;
  constructor(public taskId: string, public error: IResponseError, public hidden?: boolean) {}
}

export class MyMoneyAcceptSwitch implements Action {
  readonly type = EVENT.ACCEPT_SWITCH_PAGE;
  constructor(public taskId: string, public componentName: string) {}
}

export class MyMoneyDecodeQrCodeRequestAction implements Action {
  readonly type = EVENT.DECODE_QR_CODE_REQUEST;
  constructor(public taskId: string, public data: string, public onSucceed: () => void) {}
}

export class MyMoneyDecodeQrCodeResponseAction implements Action {
  readonly type = EVENT.DECODE_QR_CODE_RECEIVED;
  constructor(public taskId: string, public data: IQrCodeDecoded) {}
}

export class MyMoneyClearQrCodePaymentDataAction implements Action {
  readonly type = EVENT.CLEAR_QR_CODE_PAYMENT_DATA;
  constructor(public taskId: string) {}
}

export class MyMoneyCreatePaymentLinkRequestAction implements Action {
  readonly type = EVENT.CREATE_PAYMENT_LINK_REQUEST;
  constructor(public taskId: string, public walletId: string, public form: any, public onSucceed: () => void) {}
}

export class MyMoneyCreatePaymentLinkResponseAction implements Action {
  readonly type = EVENT.CREATE_PAYMENT_LINK_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export class MyMoneyAddCardRequestAction implements Action {
  readonly type = EVENT.ADD_CARD_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public addCardRequest: IWalletTopUpAddCardRequest,
    public onSucceed: (data: IWalletAddCardResponse) => void,
  ) {}
}

export class MyMoneyAddCardResponseAction implements Action {
  readonly type = EVENT.ADD_CARD_RECEIVED;
  constructor(public taskId: string, public data: IWalletAddCardResponse) {}
}

export class MyMoneyPayTopUpRequestAction implements Action {
  readonly type = EVENT.WALLET_TOPUP_PAY_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public cardId: string,
    public pay: IWalletTopUpPayRequest,
    public onSucceed: (data: IWalletAddCardResponse) => void,
  ) {}
}

export class MyMoneyPayTopResponseAction implements Action {
  readonly type = EVENT.WALLET_TOPUP_PAY_RECEIVED;
  constructor(public taskId: string, public data: IWalletAddCardResponse) {}
}

export class MyMoneyEditCardRequestAction implements Action {
  readonly type = EVENT.EDIT_CARD_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public cardId: string,
    public request: IWalletEditCardRequest,
    public onSucceed: (data: any) => void,
  ) {}
}

export class MyMoneyEditCardResponseAction implements Action {
  readonly type = EVENT.EDIT_CARD_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export class MyMoneyDeleteCardRequestAction implements Action {
  readonly type = EVENT.DELETE_CARD_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public cardId: string,
    public destination: string,
    public onSucceed: () => void,
  ) {}
}

export class MyMoneyDeleteCardResponseAction implements Action {
  readonly type = EVENT.DELETE_CARD_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneyCreatePaymentRequestAction implements Action {
  readonly type = EVENT.CREATE_PAYMENT_REQUEST;
  constructor(
    public taskId: string,
    public walletId: string,
    public request: IWalletRequest,
    public onSucceed: () => void,
    public onError: (error: any) => void,
  ) {}
}

export class MyMoneyCreatePaymentResponseAction implements Action {
  readonly type = EVENT.CREATE_PAYMENT_RECEIVED;
  constructor(public taskId: string, public data: IWalletRequestResponse) {}
}

export class MyMoneyExternalAccountsRequestAction implements Action {
  readonly type = EVENT.WALLET_EXTERNAL_ACCOUNTS_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public accountType: string,
    public onSucceed?: (data: IWalletExternalAccount[]) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class MyMoneyExternalAccountsResponseAction implements Action {
  readonly type = EVENT.WALLET_EXTERNAL_ACCOUNTS_RECEIVED;
  constructor(public taskId: string, public data: IWalletExternalAccount[]) {}
}

export class MyMoneyCardWithDestinationRequestAction implements Action {
  readonly type = EVENT.WALLET_CARD_WITH_DESTINATION_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public destination: string,
    public onSucceed?: (data: IWalletTopUpCardResponse[]) => void,
  ) {}
}

export class MyMoneyCardWithDestinationResponseAction implements Action {
  readonly type = EVENT.WALLET_CARD_WITH_DESTINATION_RECEIVED;
  constructor(public taskId: string, public data: IWalletTopUpCardResponse[]) {}
}

export class MyMoneyBanksRequestAction implements Action {
  readonly type = EVENT.WALLET_BANKS_REQUESTED;
  constructor(public taskId: string) {}
}

export class MyMoneyBanksResponseAction implements Action {
  readonly type = EVENT.WALLET_BANKS_RECEIVED;
  constructor(public taskId: string, public data: IWalletBank[]) {}
}

export class MyMoneyNedbankBanksRequestAction implements Action {
  readonly type = EVENT.WALLET_NEDBANK_BANKS_REQUESTED;
  constructor(public taskId: string) {}
}

export class MyMoneyNedbankBanksResponseAction implements Action {
  readonly type = EVENT.WALLET_NEDBANK_BANKS_RECEIVED;
  constructor(public taskId: string, public data: IWalletBankInfo[]) {}
}

export class FilterSettlementsExportRequestAction implements Action {
  readonly type = EVENT.FILTER_SETTLEMENTS_EXPORT_REQUESTED;
  constructor(
    public taskId: string,
    public filter: IFilterSettlementRequest,
    public storeId: string,
    public onSucceed?: (data: ISettlementExport) => void,
  ) {}
}

export class FilterSettlementsExportResponseAction implements Action {
  readonly type = EVENT.FILTER_SETTLEMENTS_EXPORT_RECEIVED;
  constructor(public taskId: string, public response: ISettlementExport) {}
}

export class FilterTransactionsExportRequestAction implements Action {
  readonly type = EVENT.FILTER_TRANSACTIONS_EXPORT_REQUESTED;
  constructor(
    public taskId: string,
    public filter: IFilterPayoutTransactionRequest,
    public storeId: string,
    public onSucceed?: (data: ISettlementExport) => void,
  ) {}
}

export class FilterTransactionsExportResponseAction implements Action {
  readonly type = EVENT.FILTER_TRANSACTIONS_EXPORT_RECEIVED;
  constructor(public taskId: string, public response: ISettlementExport) {}
}

export class FetchTaskDetailRequestAction implements Action {
  readonly type = EVENT.FETCH_TASK_DETAIL_REQUESTED;
  constructor(
    public taskId: string,
    public fetchTaskId: string,
    public onSucceed?: (taskDetail: ITaskDetail) => void,
  ) {}
}

export class FetchTaskDetailResponseAction implements Action {
  readonly type = EVENT.FETCH_TASK_DETAIL_RECEIVED;
  constructor(public taskId: string, public response: ITaskDetail) {}
}

export class FetchTaskFileRequestAction implements Action {
  readonly type = EVENT.FETCH_TASK_FILE_REQUESTED;
  constructor(public taskId: string, public fetchTaskId: string, public onSucceed?: (file: any) => void) {}
}

export class FetchTaskFileResponseAction implements Action {
  readonly type = EVENT.FETCH_TASK_FILE_RECEIVED;
  constructor(public taskId: string, public response: any) {}
}

export class FilterPayoutTransactionsRequestAction implements Action {
  readonly type = EVENT.FILTER_SETTLEMENT_TRANSACTION_REQUESTED;
  constructor(
    public taskId: string,
    public storeId: string,
    public filter: IFilterPayoutTransactionRequest,
    public pageSize: number,
    public next: number,
    public onSucceed?: (data: SellerPayoutTransactions) => void,
    public onError?: (error?: any) => void,
  ) {}
}

export class FilterPayoutTransactionsResponseAction implements Action {
  readonly type = EVENT.FILTER_SETTLEMENT_TRANSACTION_RECEIVED;
  constructor(public taskId: string, public response: SellerPayoutTransactions) {}
}

// Payment remittance
export class GeneratePaymentRemittanceRequestAction implements Action {
  readonly type = EVENT.GENERATE_PAYMENT_REMITTANCE_REQUESTED;
  constructor(
    public taskId: string,
    public filter: IGenerateRemittanceRequest,
    public storeId: string,
    public onSucceed?: (data: IPaymentRemittanceExport) => void,
    public onError?: (error?: any) => void,
  ) {}
}

export class GeneratePaymentRemittanceResponseAction implements Action {
  readonly type = EVENT.GENERATE_PAYMENT_REMITTANCE_RECEIVED;
  constructor(public taskId: string, public response: IPaymentRemittanceExport) {}
}

// Tax invoices
export class FetchTaxInvoicesRequestAction implements Action {
  readonly type = EVENT.FETCH_TAX_INVOICES_REQUESTED;
  constructor(
    public taskId: string,
    public filter: ITaxInvoiceRequest,
    public storeId: string,
    public pageSize: number,
    public page: number,
    public onSucceed?: (data: ITaxInvoice) => void,
    public onError?: (error?: any) => void,
  ) {}
}

export class FetchTaxInvoicesResponseAction implements Action {
  readonly type = EVENT.FETCH_TAX_INVOICES_RECEIVED;
  constructor(public taskId: string, public response: ITaxInvoice) {}
}

export class DownloadTaxInvoiceRequestAction implements Action {
  readonly type = EVENT.DOWNLOAD_TAX_INVOICE_REQUESTED;

  constructor(
    public taskId: string,
    public taxInvoiceId: string,
    public storeId: string,
    public onSucceed?: (file: any) => void,
  ) {}
}

export class DownloadTaxInvoiceResponseAction implements Action {
  readonly type = EVENT.DOWNLOAD_TAX_INVOICE_RECEIVED;
  constructor(public taskId: string, public response: any) {}
}

// Seller statements
// FetchSellerStatement
export class FetchSellerStatementRequest implements Action {
  readonly type = EVENT.STATEMENTS_FETCH_SELLER_STATEMENT_REQUEST;
  constructor(
    public taskId: string,
    public request: ISellerStatementRequest,
    public storeId: string,
    public pageSize: number,
    public page: number,
    public onError?: (error?: any) => void,
  ) {}
}
export class FetchSellerStatementReceived implements Action {
  readonly type = EVENT.STATEMENTS_FETCH_SELLER_STATEMENT_RECEIVED;

  constructor(public taskId: string, public response: ISellerStatement) {}
}

// DownloadSellerStatement
export class DownloadSellerStatementRequestAction implements Action {
  readonly type = EVENT.STATEMENTS_DOWNLOAD_SELLER_STATEMENT_REQUEST;

  constructor(
    public taskId: string,
    public statementNumber: string,
    public storeId: string,
    public onSucceed?: (file: any) => void,
  ) {}
}

export class DownloadSellerStatementResponseAction implements Action {
  readonly type = EVENT.STATEMENTS_DOWNLOAD_SELLER_STATEMENT_RECEIVED;
  constructor(public taskId: string, public response: any) {}
}

// GenerateSellerStatement
export class GenerateSellerStatementRequest implements Action {
  readonly type = EVENT.STATEMENTS_GENERATE_SELLER_STATEMENT_REQUEST;
  constructor(
    public taskId: string,
    public request: ISellerStatementExportRequest,
    public storeId: string,
    public onSucceed?: (data: ISellerStatementExport) => void,
  ) {}
}
export class GenerateSellerStatementReceived implements Action {
  readonly type = EVENT.STATEMENTS_GENERATE_SELLER_STATEMENT_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneySaveDefaultExternalAccountAction implements Action {
  readonly type = EVENT.WALLET_SAVE_EXTERNAL_ACCOUNT;
  constructor(public taskId: string, public account: IWalletExternalAccount, public onSucceed?: () => void) {}
}

export class MyMoneyRemoveExternalAccountRequestAction implements Action {
  readonly type = EVENT.WALLET_REMOVE_EXTERNAL_ACCOUNTS_REQUESTED;
  constructor(public taskId: string, public walletId: string, public accountId: string, public onSucceed: () => void) {}
}

export class MyMoneyRemoveExternalAccountResponseAction implements Action {
  readonly type = EVENT.WALLET_REMOVE_EXTERNAL_ACCOUNTS_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneyFetchPaymentLinkRequestAction implements Action {
  readonly type = EVENT.WALLET_PAYMENT_LINK_REQUESTED;
  constructor(
    public taskId: string,
    public link: string,
    public onSucceed?: (response: IWalletPaymentLinkResponse) => void,
    public onError?: () => void,
  ) {}
}

export class MyMoneyFetchPaymentLinkResponseAction implements Action {
  readonly type = EVENT.WALLET_PAYMENT_LINK_RECEIVED;
  constructor(public taskId: string, public response: IWalletPaymentLinkResponse) {}
}

export class MyMoneyAcceptPaymentLinkRequestAction implements Action {
  readonly type = EVENT.WALLET_PAYMENT_ACCEPT_LINK_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public paymentLinkId: string,
    public onSucceed: () => void,
    public onError: (error: any) => void,
  ) {}
}

export class MyMoneyAcceptPaymentLinkResponseAction implements Action {
  readonly type = EVENT.WALLET_PAYMENT_ACCEPT_LINK_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneySetActiveBuyerAction implements Action {
  readonly type = EVENT.MERCHANT_ACTIVE_BUYER_SET;

  constructor(public taskId: string, public buyer: IBuyer) {}
}

export class MyMoneyCopyLinkSucceedAction implements Action {
  readonly type = EVENT.COPY_LINK;
}

export class MyMoneyReminderRequestAction implements Action {
  readonly type = EVENT.REMINDER_REQUESTED;
  constructor(
    public taskId: string,
    public walletId,
    public paymentRequestId: string,
    public message: string,
    public onSucceed?: () => void,
  ) {}
}

export class MyMoneyReminderResponseAction implements Action {
  readonly type = EVENT.REMINDER_RECEIVED;
  constructor(public taskId: string, public data: IRequestDetailResponse[]) {}
}

export class MyMoneyLoyaltyPointsRequestAction implements Action {
  readonly type = EVENT.LOYALTY_POINTS_REQUESTED;
  constructor(public taskId: string) {}
}

export class MyMoneyLoyaltyPointsResponseAction implements Action {
  readonly type = EVENT.LOYALTY_POINTS_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyPoint) {}
}

export class MyMoneyPartyAccountsRequestAction implements Action {
  readonly type = EVENT.PARTY_ACCOUNTS_REQUEST;
  constructor(public taskId: string, public onSucceed: (data: IWalletPartyAccount[]) => void) {}
}
export class MyMoneyPartyAccountsResponseAction implements Action {
  readonly type = EVENT.PARTY_ACCOUNTS_SUCCEEDED;
  constructor(public taskId: string, public data: IWalletPartyAccount[]) {}
}

export class MyMoneyTopupPayCheckRequestAction implements Action {
  readonly type = EVENT.TOPUP_PAYCHECK_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public payload: IWalletCheckPayRequest,
    public onSucceed: (data: IWalletCheckPayResponse) => void,
  ) {}
}
export class MyMoneyTopupPayCheckResponseAction implements Action {
  readonly type = EVENT.TOPUP_PAYCHECK_RECEIVED;
  constructor(public taskId: string, public data: IWalletCheckPayResponse) {}
}

export class MyMoneyTopupAccountRequestAction implements Action {
  readonly type = EVENT.TOPUP_ACCOUNT_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public payload: IWalletTopUpAccountRequest,
    public onSucceed: (data: IWalletTopUpAccountResponse) => void,
  ) {}
}
export class MyMoneyTopupAccountResponseAction implements Action {
  readonly type = EVENT.TOPUP_ACCOUNT_RECEIVED;
  constructor(public taskId: string, public data: IWalletTopUpAccountResponse) {}
}

export class MyMoneyTopupAccountStatusRequestAction implements Action {
  readonly type = EVENT.TOPUP_ACCOUNT_STATUS_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public topupId: string,
    public onSucceed: (data: IWalletTopUpStatusResponse) => void,
  ) {}
}
export class MyMoneyTopupAccountStatusResponseAction implements Action {
  readonly type = EVENT.TOPUP_ACCOUNT_STATUS_RECEIVED;
  constructor(public taskId: string, public data: IWalletTopUpStatusResponse) {}
}

export class MyMoneyTopupCardStatusRequestAction implements Action {
  readonly type = EVENT.TOPUP_CARD_STATUS_REQUESTED;
  constructor(
    public taskId: string,
    public walletId: string,
    public cardId: string,
    public topupId: string,
    public onSucceed: (data: IWalletTopUpStatusResponse) => void,
  ) {}
}
export class MyMoneyTopupCardStatusResponseAction implements Action {
  readonly type = EVENT.TOPUP_CARD_STATUS_RECEIVED;
  constructor(public taskId: string, public data: IWalletTopUpStatusResponse) {}
}

export class MyMoneyFetchMerchantSubscriptionsRequestAction implements Action {
  readonly type = EVENT.SUBSCRIPTIONS_MERCHANT_LIST_REQUESTED;
  constructor(
    public taskId: string,
    public onSucceed?: (data: IWalletSubscription[]) => void,
    public onError?: (error: any) => void,
  ) {}
}
export class MyMoneyFetchMerchantSubscriptionsResponseAction implements Action {
  readonly type = EVENT.SUBSCRIPTIONS_MERCHANT_LIST_RECEIVED;
  constructor(public taskId: string, public data: IWalletSubscription[]) {}
}

export class MyMoneyFetchAvailableMerchantSubscriptionsRequestAction implements Action {
  readonly type = EVENT.SUBSCRIPTIONS_AVAILABLE_MERCHANT_LIST_REQUESTED;
  constructor(public taskId: string, public onSucceed?: (data: IWalletSubscriptionAvailable[]) => void) {}
}
export class MyMoneyFetchAvailableMerchantSubscriptionsResponseAction implements Action {
  readonly type = EVENT.SUBSCRIPTIONS_AVAILABLE_MERCHANT_LIST_RECEIVED;
  constructor(public taskId: string, public data: IWalletSubscriptionAvailable[]) {}
}

export class MyMoneyAddMerchantSubscriptionsRequestAction implements Action {
  readonly type = EVENT.SUBSCRIPTIONS_MERCHANT_ADD_REQUESTED;
  constructor(
    public taskId: string,
    public request: IWalletSubscriptionRequest,
    public onSucceed?: (data: IWalletSubscription) => void,
  ) {}
}
export class MyMoneyAddMerchantSubscriptionsResponseAction implements Action {
  readonly type = EVENT.SUBSCRIPTIONS_MERCHANT_ADD_RECEIVED;
  constructor(public taskId: string, public data: IWalletSubscription) {}
}

export class MyMoneyFetchDashboardWidgetRequest implements Action {
  readonly type = EVENT.DASHBOARD_WIDGET_REQUESTED;
  constructor(
    public taskId: string,
    public widgetType: WalletDashboardWidgetType,
    public onError?: (error: any) => void,
  ) {}
}
export class MyMoneyFetchDashboardWidgetResponse implements Action {
  readonly type = EVENT.DASHBOARD_WIDGET_RECEIVED;
  constructor(public taskId: string, public response: IDashboardWalletWidget) {}
}

export class MyMoneyClearPayInfoAction implements Action {
  readonly type = EVENT.PAY_INFO_CLEAR;
  constructor(public taskId: string) {}
}
export class MyMoneyPayByCardRequestAction implements Action {
  readonly type = EVENT.PAY_BY_CARD_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IPayByCardRequest,
    public onSucceed?: (data: IPayByCardResponse) => void,
  ) {}
}

export class MyMoneyPayByCardResponseAction implements Action {
  readonly type = EVENT.PAY_BY_CARD_RECEIVED;
  constructor(public taskId: string, public data: IPayByCardResponse) {}
}

export class MyMoneyPayByPointsOnlyRequestAction implements Action {
  readonly type = EVENT.PAY_BY_POINTS_ONLY_REQUESTED;
  constructor(public taskId: string, public flowId: string, public onSucceed: () => void) {}
}

export class MyMoneyPayByPointsOnlyResponseAction implements Action {
  readonly type = EVENT.PAY_BY_POINTS_ONLY_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneyPayByWalletRequestAction implements Action {
  readonly type = EVENT.PAY_BY_WALLET_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IPayByWalletRequest,
    public onSucceed?: (data: IWalletPayResponse) => void,
  ) {}
}

export class MyMoneyPayByWalletResponseAction implements Action {
  readonly type = EVENT.PAY_BY_WALLET_RECEIVED;
  constructor(public taskId: string, public data: IWalletPayResponse) {}
}

export class MyMoneyPayForFreeRequestAction implements Action {
  readonly type = EVENT.PAY_FOR_FREE_REQUESTED;
  constructor(public taskId: string, public flowId: string, public onSucceed: () => void) {}
}

export class MyMoneyPayForFreeResponseAction implements Action {
  readonly type = EVENT.PAY_FOR_FREE_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneyTopUpAndPayRequestAction implements Action {
  readonly type = EVENT.TOPUP_AND_PAY_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: ITopUpAndPayRequest,
    public onSucceed?: (data: ITopUpAndPayResponse) => void,
  ) {}
}

export class MyMoneyTopUpAndPayResponseAction implements Action {
  readonly type = EVENT.TOPUP_AND_PAY_RECEIVED;
  constructor(public taskId: string, public data: ITopUpAndPayResponse) {}
}

export class MyMoneySetPaymentMethodAction implements Action {
  readonly type = EVENT.SET_PAYMENT_METHOD;

  constructor(public taskId: string, public data: IShoppingAvailablePaymentMethod) {}
}

export class MyMoneyPayByEftRequestAction implements Action {
  readonly type = EVENT.PAY_BY_EFT_REQUESTED;
  constructor(public taskId: string, public flowId: string, public onSucceed: () => void) {}
}

export class MyMoneyPayByEftResponseAction implements Action {
  readonly type = EVENT.PAY_BY_EFT_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyMoneyPayByAmexRequestAction implements Action {
  readonly type = EVENT.PAY_BY_AMEX_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IPayByCardRequest,
    public onSucceed: (data: IPayByCardResponse) => void,
  ) {}
}

export class MyMoneyPayByAmexResponseAction implements Action {
  readonly type = EVENT.PAY_BY_AMEX_RECEIVED;
  constructor(public taskId: string) {}
}

// MyMoneyPayByCredit
export class MyMoneyPayByCreditRequestAction implements Action {
  readonly type = EVENT.PAY_BY_CREDIT_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IPayByCreditRequest,
    public onSucceed: (data: IPayByCreditResponse) => void,
  ) {}
}

export class MyMoneyPayByCreditResponseAction implements Action {
  readonly type = EVENT.PAY_BY_CREDIT_RECEIVED;
  constructor(public taskId: string) {}
}

export class FetchPaymentCarouselRequestAction implements Action {
  readonly type = EVENT.FETCH_CAROUSEL_REQUESTED;
  constructor(public taskId: string) {}
}
export class FetchPaymentCarouselResponseAction implements Action {
  readonly type = EVENT.FETCH_CAROUSEL_RECEIVED;
  constructor(public taskId: string, public data: ICustomerStoreOfValue) {}
}

export class FetchAddPaymentCardRequestAction implements Action {
  readonly type = EVENT.ADD_PAYMENT_CARD_REQUESTED;
  constructor(public taskId: string, public onSucceed: (data: IAddCardResponse) => void) {}
}

export class FetchAddPaymentCardResponseAction implements Action {
  readonly type = EVENT.ADD_PAYMENT_CARD_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export class editPaymentMethodRequestAction implements Action {
  readonly type = EVENT.EDIT_PAYMENT_METHOD_REQUESTED;
  constructor(
    public taskId: string,
    public paymentMethodId: string,
    public request: IEditPaymentMethodRequest,
    public onSucceed: (data: any) => void,
  ) {}
}

export class editPaymentMethodResponseAction implements Action {
  readonly type = EVENT.EDIT_PAYMENT_METHOD_RECEIVED;
  constructor(public taskId: string) {}
}

export class editPaymentMethodNameRequestAction implements Action {
  readonly type = EVENT.EDIT_PAYMENT_METHOD_NAME_REQUESTED;
  constructor(
    public taskId: string,
    public paymentMethodId: string,
    public request: IEditPaymentMethodRequest,
    public onSucceed: (data: any) => void,
  ) {}
}

export class editPaymentMethodNameResponseAction implements Action {
  readonly type = EVENT.EDIT_PAYMENT_METHOD_NAME_RECEIVED;
  constructor(public taskId: string) {}
}

export class editPaymentMethodDefaultRequestAction implements Action {
  readonly type = EVENT.EDIT_PAYMENT_METHOD_DEFAULT_REQUESTED;
  constructor(
    public taskId: string,
    public paymentMethodId: string,
    public request: IEditPaymentMethodRequest,
    public onSucceed: (data: any) => void,
  ) {}
}
export class editPaymentMethodDefaultResponseAction implements Action {
  readonly type = EVENT.EDIT_PAYMENT_METHOD_DEFAULT_RECEIVED;
  constructor(public taskId: string) {}
}

export class FetchPaymentMethodBalanceRequestAction implements Action {
  readonly type = EVENT.PAYMENT_METHOD_BALANCE_REQUESTED;
  constructor(public taskId: string, public paymentMethodId: string, public onSucceed: (data: Ibalance) => void) {}
}

export class FetchPaymentMethodBalanceResponseAction implements Action {
  readonly type = EVENT.PAYMENT_METHOD_BALANCE_RECEIVED;
  constructor(public taskId: string) {}
}

export class FetchPaymentMethodActiveRequestAction implements Action {
  readonly type = EVENT.FETCH_ACTIVE_PAYMENT_METHODS_REQUEST;
  constructor(public taskId: string) {}
}

export class FetchPaymentMethodActiveResponseAction implements Action {
  readonly type = EVENT.FETCH_ACTIVE_PAYMENT_METHODS_RECEIVED;
  constructor(public taskId: string, public data: ICustomerStoreOfValue) {}
}

export class DeletePaymentMethodRequestAction implements Action {
  readonly type = EVENT.DELETE_PAYMENT_METHOD_REQUEST;
  constructor(public taskId: string, public paymentMethodId: string, public onSucceed: () => void) {}
}
export class DeletePaymentMethodResponseAction implements Action {
  readonly type = EVENT.DELETE_PAYMENT_METHOD_RECEIVED;
  constructor(public taskId: string) {}
}

export class ProcessAddCardRequestAction implements Action {
  readonly type = EVENT.PROCESS_ADD_CARD_REQUESTED;
  constructor(
    public paymentFlowId: string,
    public taskId: string,
    public onSucceed: (data: IAddCardResponse) => void,
  ) {}
}

export class ProcessAddCardResponseAction implements Action {
  readonly type = EVENT.PROCESS_ADD_CARD_RECEIVED;
  constructor(public taskId: string, public data: IAddCardResponse) {}
}

export class FetchTransactionsRequestAction implements Action {
  readonly type = EVENT.FETCH_TRANSACTIONS_REQUESTED;
  constructor(public limit: number, public taskId: string) {}
}

export class FetchTransactionsResponseAction implements Action {
  readonly type = EVENT.FETCH_TRANSACTIONS_RECEIVED;
  constructor(public taskId: string, public data: IPaymentMethodTransaction[]) {}
}

export class FetchTransactionsFilteredRequestAction implements Action {
  readonly type = EVENT.FETCH_TRANSACTIONS_FILTERED_REQUESTED;

  constructor(
    public page: number,
    public pageSize: number,
    public filter: ITransactionHistoryReportFilter,
    public onSucceed: (data: ITransactionDetail[]) => void,
    public taskId: string,
  ) {}
}

export class FetchTransactionsFilteredResponseAction implements Action {
  readonly type = EVENT.FETCH_TRANSACTIONS_FILTERED_RECEIVED;
  constructor(public taskId: string) {}
}
export type MyMoneyWalletActions =
  | MyMoneyAcceptPaymentLinkResponseAction
  | MyMoneyAcceptPaymentLinkRequestAction
  | MyMoneyLoyaltyPointsRequestAction
  | MyMoneyLoyaltyPointsResponseAction
  | MyMoneyReminderRequestAction
  | MyMoneyReminderResponseAction
  | MyMoneyDeleteCardRequestAction
  | MyMoneyCardWithDestinationResponseAction
  | MyMoneyCardWithDestinationRequestAction
  | MyMoneyCopyLinkSucceedAction
  | MyMoneyCreatePaymentResponseAction
  | MyMoneyCreatePaymentRequestAction
  | MyMoneyCreatePaymentLinkResponseAction
  | MyMoneyCreatePaymentLinkRequestAction
  | MyMoneyTransactionRecentRequestAction
  | MyMoneyTransactionRecentResponseAction
  | MyMoneySelectMainWalletAction
  | MyMoneyTransactionFilterRequestAction
  | MyMoneyTransactionFilterResponseAction
  | MyMoneyTransactionSeaarchRequestAction
  | MyMoneyTransactionSearchResponseAction
  | MyMoneyWalletsRequestAction
  | MyMoneyWalletsResponseAction
  | MyMoneyPayRequestAction
  | MyMoneyPayResponseAction
  | MyMoneyPayByQrCodeRequestAction
  | MyMoneyPayByQrCodeResponseAction
  | MyMoneyRefundRequestAction
  | MyMoneyRefundResponseAction
  | MyMoneyWithdrawRequestAction
  | MyMoneyWithdrawResponseAction
  | MyMoneyTransactionsRequestAction
  | MyMoneyTransactionsResponseAction
  | MyMoneyTransactionDetailRequestAction
  | MyMoneyTransactionDetailResponseAction
  | MyMoneyAcceptSwitch
  | MyMoneyWalletErrorAction
  | MyMoneyDecodeQrCodeRequestAction
  | MyMoneyDecodeQrCodeResponseAction
  | MyMoneyPayRecentRecipientsRequestAction
  | MyMoneyPayRecentRecipientsResponseAction
  | MyMoneyEditCardRequestAction
  | MyMoneyEditCardResponseAction
  | MyMoneyPayTopUpRequestAction
  | MyMoneyPayTopResponseAction
  | MyMoneyAddCardRequestAction
  | MyMoneyAddCardResponseAction
  | MyMoneyExternalAccountsRequestAction
  | MyMoneyExternalAccountsResponseAction
  | MyMoneyBanksRequestAction
  | MyMoneyBanksResponseAction
  | MyMoneySaveDefaultExternalAccountAction
  | MyMoneyClearQrCodePaymentDataAction
  | MyMoneyRemoveExternalAccountRequestAction
  | MyMoneyRemoveExternalAccountResponseAction
  | MyMoneyFetchPaymentLinkRequestAction
  | MyMoneyFetchPaymentLinkResponseAction
  | MyMoneyTopupPayCheckRequestAction
  | MyMoneyTopupPayCheckResponseAction
  | MyMoneyTopupAccountRequestAction
  | MyMoneyTopupAccountResponseAction
  | MyMoneyTopupAccountStatusRequestAction
  | MyMoneyTopupAccountStatusResponseAction
  | MyMoneyTopupCardStatusRequestAction
  | MyMoneyPartyAccountsRequestAction
  | MyMoneyPartyAccountsResponseAction
  | MyMoneyTopupCardStatusResponseAction
  | MyMoneyFetchMerchantSubscriptionsRequestAction
  | MyMoneyFetchMerchantSubscriptionsResponseAction
  | MyMoneyFetchAvailableMerchantSubscriptionsRequestAction
  | MyMoneyFetchAvailableMerchantSubscriptionsResponseAction
  | MyMoneyAddMerchantSubscriptionsRequestAction
  | MyMoneyAddMerchantSubscriptionsResponseAction
  | MyMoneyFetchDashboardWidgetRequest
  | MyMoneyFetchDashboardWidgetResponse
  | MyMoneyPayByCardRequestAction
  | MyMoneyPayByCardResponseAction
  | MyMoneyPayByPointsOnlyRequestAction
  | MyMoneyPayByPointsOnlyResponseAction
  | MyMoneyPayByWalletRequestAction
  | MyMoneyPayByWalletResponseAction
  | MyMoneyPayForFreeRequestAction
  | MyMoneyPayForFreeResponseAction
  | MyMoneyTopUpAndPayRequestAction
  | MyMoneyTopUpAndPayResponseAction
  | MyMoneySetPaymentMethodAction
  | MyMoneyNedbankBanksRequestAction
  | MyMoneyNedbankBanksResponseAction
  | FilterPayoutTransactionsRequestAction
  | FilterPayoutTransactionsResponseAction
  | FetchTaskDetailRequestAction
  | FetchTaskDetailResponseAction
  | MyMoneyPayByEftRequestAction
  | MyMoneyPayByAmexRequestAction
  | MyMoneyPayByAmexResponseAction
  | MyMoneyPayByEftResponseAction
  | MyMoneyClearPayInfoAction
  | FetchTaxInvoicesRequestAction
  | FetchTaxInvoicesResponseAction
  | FetchSellerStatementRequest
  | FetchSellerStatementReceived
  | GenerateSellerStatementRequest
  | GenerateSellerStatementReceived
  | DownloadSellerStatementRequestAction
  | DownloadSellerStatementResponseAction
  | MyMoneyPayByCreditRequestAction
  | MyMoneyPayByCreditResponseAction
  | GeneratePaymentRemittanceRequestAction
  | GeneratePaymentRemittanceResponseAction
  | MyMoneySetActiveBuyerAction
  | FetchPaymentCarouselRequestAction
  | FetchPaymentCarouselResponseAction
  | FetchAddPaymentCardResponseAction
  | FetchAddPaymentCardRequestAction
  | FetchPaymentMethodBalanceRequestAction
  | FetchPaymentMethodBalanceResponseAction
  | ProcessAddCardRequestAction
  | ProcessAddCardResponseAction
  | FetchTransactionsResponseAction
  | FetchTransactionsRequestAction
  | FetchTransactionsFilteredResponseAction
  | FetchTransactionsFilteredRequestAction
  | FetchPaymentMethodActiveRequestAction
  | FetchPaymentMethodActiveResponseAction
  | DeletePaymentMethodRequestAction
  | DeletePaymentMethodResponseAction
  | editPaymentMethodDefaultRequestAction
  | editPaymentMethodDefaultResponseAction
  | editPaymentMethodNameRequestAction
  | editPaymentMethodNameResponseAction
  | editPaymentMethodRequestAction
  | editPaymentMethodResponseAction;

export const baseActions = {
  MyMoneyAcceptPaymentLinkResponseAction,
  MyMoneyAcceptPaymentLinkRequestAction,
  MyMoneyLoyaltyPointsRequestAction,
  MyMoneyLoyaltyPointsResponseAction,
  MyMoneyReminderResponseAction,
  MyMoneyReminderRequestAction,
  MyMoneyDeleteCardRequestAction,
  MyMoneyCardWithDestinationRequestAction,
  MyMoneyCardWithDestinationResponseAction,
  MyMoneyCopyLinkSucceedAction,
  MyMoneyCreatePaymentResponseAction,
  MyMoneyCreatePaymentRequestAction,
  MyMoneyCreatePaymentLinkResponseAction,
  MyMoneyCreatePaymentLinkRequestAction,
  MyMoneyTransactionRecentRequestAction,
  MyMoneyTransactionRecentResponseAction,
  MyMoneySelectMainWalletAction,
  MyMoneyTransactionFilterRequestAction,
  MyMoneyTransactionFilterResponseAction,
  MyMoneyTransactionDownloadStatementRequestAction,
  MyMoneyTransactionDownloadStatementResponseAction,
  MyMoneyTransactionSeaarchRequestAction,
  MyMoneyTransactionSearchResponseAction,
  MyMoneyWalletsRequestAction,
  MyMoneyWalletsResponseAction,
  MyMoneyPayRequestAction,
  MyMoneyPayResponseAction,
  MyMoneyPayByQrCodeRequestAction,
  MyMoneyPayByQrCodeResponseAction,
  MyMoneyRefundRequestAction,
  MyMoneyRefundResponseAction,
  MyMoneyWithdrawRequestAction,
  MyMoneyWithdrawResponseAction,
  MyMoneyTransactionsRequestAction,
  MyMoneyTransactionsResponseAction,
  MyMoneyTransactionDetailRequestAction,
  MyMoneyTransactionDetailResponseAction,
  MyMoneyWalletErrorAction,
  MyMoneyAcceptSwitch,
  MyMoneyDecodeQrCodeRequestAction,
  MyMoneyDecodeQrCodeResponseAction,
  MyMoneyExternalAccountsRequestAction,
  MyMoneyExternalAccountsResponseAction,
  MyMoneyBanksRequestAction,
  MyMoneyBanksResponseAction,
  MyMoneyEditCardRequestAction,
  MyMoneyEditCardResponseAction,
  MyMoneyPayTopUpRequestAction,
  MyMoneyPayTopResponseAction,
  MyMoneyClearQrCodePaymentDataAction,
  MyMoneyAddCardResponseAction,
  MyMoneyAddCardRequestAction,
  MyMoneySaveDefaultExternalAccountAction,
  MyMoneyPayRecentRecipientsResponseAction,
  MyMoneyPayRecentRecipientsRequestAction,
  MyMoneyRemoveExternalAccountRequestAction,
  MyMoneyRemoveExternalAccountResponseAction,
  MyMoneyFetchPaymentLinkRequestAction,
  MyMoneyFetchPaymentLinkResponseAction,
  MyMoneyTopupPayCheckRequestAction,
  MyMoneyTopupPayCheckResponseAction,
  MyMoneyTopupAccountRequestAction,
  MyMoneyTopupAccountResponseAction,
  MyMoneyTopupAccountStatusRequestAction,
  MyMoneyTopupAccountStatusResponseAction,
  MyMoneyTopupCardStatusRequestAction,
  MyMoneyPartyAccountsRequestAction,
  MyMoneyPartyAccountsResponseAction,
  MyMoneyTopupCardStatusResponseAction,
  MyMoneyFetchMerchantSubscriptionsRequestAction,
  MyMoneyFetchMerchantSubscriptionsResponseAction,
  MyMoneyFetchAvailableMerchantSubscriptionsRequestAction,
  MyMoneyFetchAvailableMerchantSubscriptionsResponseAction,
  MyMoneyAddMerchantSubscriptionsRequestAction,
  MyMoneyAddMerchantSubscriptionsResponseAction,
  MyMoneyFetchDashboardWidgetRequest,
  MyMoneyFetchDashboardWidgetResponse,
  MyMoneyPayByCardRequestAction,
  MyMoneyPayByCardResponseAction,
  MyMoneyPayByPointsOnlyRequestAction,
  MyMoneyPayByPointsOnlyResponseAction,
  MyMoneyPayByWalletRequestAction,
  MyMoneyPayByWalletResponseAction,
  MyMoneyPayForFreeRequestAction,
  MyMoneyPayForFreeResponseAction,
  MyMoneyTopUpAndPayRequestAction,
  MyMoneyTopUpAndPayResponseAction,
  MyMoneySetPaymentMethodAction,
  MyMoneyNedbankBanksRequestAction,
  MyMoneyNedbankBanksResponseAction,
  FilterPayoutTransactionsRequestAction,
  FilterPayoutTransactionsResponseAction,
  FilterSettlementsExportRequestAction,
  FilterSettlementsExportResponseAction,
  FetchTaskFileRequestAction,
  FetchTaskFileResponseAction,
  FetchTaskDetailRequestAction,
  FetchTaskDetailResponseAction,
  FilterTransactionsExportRequestAction,
  FilterTransactionsExportResponseAction,
  MyMoneyPayByEftRequestAction,
  MyMoneyPayByEftResponseAction,
  MyMoneyPayByAmexRequestAction,
  MyMoneyPayByAmexResponseAction,
  MyMoneyClearPayInfoAction,
  FetchTaxInvoicesRequestAction,
  FetchTaxInvoicesResponseAction,
  DownloadTaxInvoiceRequestAction,
  DownloadTaxInvoiceResponseAction,
  FetchSellerStatementRequest,
  FetchSellerStatementReceived,
  GenerateSellerStatementRequest,
  GenerateSellerStatementReceived,
  DownloadSellerStatementRequestAction,
  DownloadSellerStatementResponseAction,
  MyMoneyPayByCreditRequestAction,
  MyMoneyPayByCreditResponseAction,
  GeneratePaymentRemittanceRequestAction,
  GeneratePaymentRemittanceResponseAction,
  MyMoneySetActiveBuyerAction,
  FetchPaymentCarouselRequestAction,
  FetchPaymentCarouselResponseAction,
  FetchAddPaymentCardResponseAction,
  FetchAddPaymentCardRequestAction,
  FetchPaymentMethodBalanceRequestAction,
  FetchPaymentMethodBalanceResponseAction,
  ProcessAddCardRequestAction,
  ProcessAddCardResponseAction,
  FetchTransactionsResponseAction,
  FetchTransactionsRequestAction,
  FetchTransactionsFilteredResponseAction,
  FetchTransactionsFilteredRequestAction,
  FetchPaymentMethodActiveResponseAction,
  FetchPaymentMethodActiveRequestAction,
  DeletePaymentMethodRequestAction,
  DeletePaymentMethodResponseAction,
  editPaymentMethodDefaultRequestAction,
  editPaymentMethodDefaultResponseAction,
  editPaymentMethodNameRequestAction,
  editPaymentMethodNameResponseAction,
  editPaymentMethodRequestAction,
  editPaymentMethodResponseAction,
};
