import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TranslateActions from './translate.actions';
import * as TranslateSelectors from './translate.selectors';

@Injectable({
  providedIn: 'root',
})
export class TranslateFacade {
  getTranslates$ = this.store.pipe(select(TranslateSelectors.getTranslates));
  getTranslateByKey$ = (translateKey: string, defaultTranslate?: string) =>
    this.store.pipe(select(TranslateSelectors.getTranslateByKey(translateKey, defaultTranslate)));

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  fetchTranslations(name: string, onSuccess: () => void) {
    this.store.dispatch(TranslateActions.fetchTranslationsRequestAction({ name, onSuccess }));
  }
}
