import { createAction, props } from '@ngrx/store';
import { ITask } from '../models/store.models';
import { FeatureFlag } from '../../model/feature-flags.model';

export const fetchFeatureFlagsRequestAction = createAction(
  '[FEATURE_FLAGS/API] fetch feature flags request action',
  props<{ taskMetadata: ITask; onSuccess?: () => void }>(),
);

export const fetchFeatureFlagsResponseAction = createAction(
  '[FEATURE_FLAGS/API] fetch feature flags response action',
  props<{ taskMetadata: ITask; response: FeatureFlag[] }>(),
);
