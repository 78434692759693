import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationFacade, BrowserStorageService, StorageKeys } from '@avo/shared/core';
import {
  AppConfig,
  AuthenticationService,
  AuthorizationService,
  BuildNumberService,
  CommonUtility,
} from '@wakanda/wakanda-core';

import isString from 'lodash-es/isString';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor, OnDestroy {
  private subscriptions = new Subscription();
  private currentLocationId: string;

  constructor(
    private authorizeService: AuthorizationService,
    private authenticationService: AuthenticationService,
    private appConfig: AppConfig,
    private buildNumberService: BuildNumberService,
    private appFacade: ApplicationFacade,
    private storageService: BrowserStorageService,
  ) {
    this.subscriptions.add(this.appFacade.getCurrentLocation$.subscribe(data => (this.currentLocationId = data?.id)));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticators = this.authenticationService.getAuthenticators();
    const token = this.authorizeService.getToken();
    const forbiddenIdempodentyUrls = ['/wallet/v1/referencedata/banks'];

    if (CommonUtility.getEnvironment() === 'preview') {
      request = request.clone({
        headers: request.headers.set('X-Canary', 'always'),
      });
    }

    let deviceId = this.authorizeService.getDeviceId();
    if (!deviceId) {
      deviceId = uuidv4();
      this.authorizeService.setDeviceId(deviceId);
    }

    if (this.appConfig.backendConfig && !request.url.includes('loc-saas')) {
      request = request.clone({
        headers: request.headers
          .set('X-Avo-SessionId', this.appConfig.backendConfig.sessionId)
          .set('X-Avo-ApiVersion', this.appConfig.apiVersion)
          .set('X-Avo-DeviceId', deviceId),
      });
      if (this.buildNumberService.buildNumber) {
        request = request.clone({ headers: request.headers.set('X-AppBuildID', this.buildNumberService.buildNumber) });
      }
    }

    const countryCode = this.storageService.get(StorageKeys.COUNTRY_CODE);

    if (!request.url.includes('/configuration.json') && countryCode) {
      request = request.clone({
        headers: request.headers.set('X-AVO-CC', countryCode),
      });
    }

    if (forbiddenIdempodentyUrls.some(url => !request.url.includes(url))) {
      if (request.url.includes('/onboarding/externalAuth') && request.body && request.body.code) {
        request = request.clone({ headers: request.headers.set('x-idempotency-key', request.body.code) });
      } else {
        request = request.clone({ headers: request.headers.set('x-idempotency-key', uuidv4()) });
      }
    }

    if (!!token && isString(token)) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    if (!!authenticators && !!authenticators.xAuthTXId && !!authenticators.xAuthTXToken) {
      request = request.clone({
        headers: request.headers
          .set('X-AUTH-TX-ID', authenticators.xAuthTXId)
          .set('X-AUTH-TX-TOKEN', authenticators.xAuthTXToken),
      });
      return next.handle(request).pipe(finalize(() => this.authenticationService.clearAuthenticators()));
    }

    if (this.currentLocationId !== undefined) {
      request = request.clone({
        headers: request.headers.set('X-Avo-LocationId', this.currentLocationId),
      });
    }

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
