import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as GroceriesActions from './groceries.actions';
import { GroceriesEntity, IGroceriesCartResponse } from './groceries.models';

export const GROCERIES_FEATURE_KEY = 'groceries2';

export interface GroceriesState extends EntityState<GroceriesEntity> {
  cart?: IGroceriesCartResponse;
}

export interface GroceriesPartialState {
  readonly [GROCERIES_FEATURE_KEY]: GroceriesState;
}

export const groceriesAdapter: EntityAdapter<GroceriesEntity> = createEntityAdapter<GroceriesEntity>();

export const groceriesInitialState: GroceriesState = groceriesAdapter.getInitialState({});

const _groceriesReducer = createReducer(
  groceriesInitialState,

  on(
    GroceriesActions.fetchGroceriesCartResponse,
    GroceriesActions.addCartItemResponse,
    GroceriesActions.updateCartItemResponse,
    GroceriesActions.removeCartItemResponse,
    GroceriesActions.addCartPromoCodeResponse,
    GroceriesActions.removeCartPromoCodeResponse,
    (state, { data }) => ({
      ...state,
      cart: data,
    }),
  ),

  on(GroceriesActions.clearCartResponse, state => ({
    ...state,
    cart: undefined,
  })),
);

export function groceriesReducer(state: GroceriesState | undefined, action: Action) {
  return _groceriesReducer(state, action);
}
