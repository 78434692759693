import { IAmount } from '../../model/core.model';
import { ILocationV3Address, IPosition } from '../location/location.models';

export interface GroceriesEntity {
  id: string | number;
  name: string;
}

export interface IGroceriesCartResponse {
  cartAddresses: IShoppingCartAddresses;
  cartType: GroceriesCartType;
  fees: IGroceriesCartFee[];
  fulfillments: IGroceriesCartFullfillment[];
  promos: IGroceriesAppliedCartCoupon[];
  totalDiscount: IGroceriesCartTaxPrice;
  totalFees: IGroceriesCartTaxPrice;
  totalItemsPrice: IGroceriesCartTaxPrice;
  totalPrice: IGroceriesCartTaxPrice;
  totalShipmentPrice: IGroceriesCartTaxPrice;
  totalPoints?: number;
}

export enum GroceriesCartType {
  ONECART = 'ONECART',
}

export interface IGroceriesAppliedCartCoupon {
  code: string;
  discountTarget: GroceriesCouponDiscountTarget;
  id: string;
  itemsDiscount: IGroceriesCartTaxPrice;
  message: string;
  shippingDiscount: IGroceriesCartTaxPrice;
  title: string;
}

export enum GroceriesCouponDiscountTarget {
  SHIPPING = 'SHIPPING',
  CART = 'CART',
}

export interface IGroceriesCartFee {
  amount: IGroceriesCartTaxPrice;
  type: string;
}

export interface IGroceriesCartFullfillment {
  deliveryMethod: IGroceriesCartDeliveryMethod;
  id: string;
  malls: IGroceriesMall[];
  itemsPrice: IGroceriesCartTaxPrice;
  price: IGroceriesCartTaxPrice;
  sellerId: string;
  sellerName: string;
  shipmentPrice: IGroceriesCartTaxPrice;
}

export interface IGroceriesCartDeliveryMethod {
  cartDeliveryMethodType: GroceriesCartDeliveryMethodTypes;
}

export enum GroceriesCartDeliveryMethodTypes {
  TO_DOOR = 'TO_DOOR',
}

export interface IGroceriesCartTaxPrice {
  tax: IAmount;
  withTax: IAmount;
  withoutTax: IAmount;
}

export interface IGroceriesMall {
  mallName: string;
  stores: IGroceriesStore[];
  distanceFee: IGroceriesCartTaxPrice;
  itemsPrice: IGroceriesCartTaxPrice;
}

export interface IGroceriesStore {
  storeName: string;
  storeLogoUrl: string;
  items: IGroceriesCartFullfillmentItem[];
}

export interface IGroceriesCartFullfillmentItem {
  name: string;
  pricePerUnit: IGroceriesCartTaxPrice;
  quantity: number;
  sellerId: string;
  storeName: string;
  sku: string;
  adobeSku: string;
  thumbnail: IGroceriesCartImage;
  totalPrice: IGroceriesCartTaxPrice;
}

export interface IGroceriesCartImage {
  altText: string;
  recommended: IGroceriesCartImageVariant;
  variants: IGroceriesCartImageVariant[];
}

export interface IGroceriesCartImageVariant {
  height: number;
  url: string;
  width: number;
}

export interface IShoppingCartAddresses {
  billingAddress?: IShoppingCartAdress;
  deliveryAddress?: IShoppingCartAdress;
}

export interface IShoppingCartAdress {
  locationId: string;
  address: ILocationV3Address;
  location: IPosition;
  name: string;
  surname: string;
}

export interface IGroceriesCartItemRequest {
  productSku: string;
  quantity: number;
  queryId?: string;
  trackingId?: string;
}

export interface IGroceriesRemoveCartPromoCodeRequest {
  couponCode: string;
}

export interface IGroceriesRemoveCartItemRequest {
  productSku: string;
}

export interface IGroceriesAddCartCouponRequest {
  couponCode: string;
}

export interface IGroceriesCartTimeslotResponse {
  timeslots: IGroceriesCartTimeslotWithDate[];
}

export interface IGroceriesCartTimeslotWithDate {
  date: string;
  order: number;
  slots: IGroceriesCartTimeslot[];
  formattedDate: string;
}

export interface IGroceriesCartTimeslot {
  id: string;
  order: number;
  deliveryFrom: string;
  deliveryTo: string;
}

export interface IGroceriesTimeOption {
  name: string;
  id: string;
}

export interface IGroceriesPlaceOrderRequest {
  timeslotId: string;
}

export interface IGroceriesPlaceOrderResponse {
  deliveryFee: IGroceriesCartTaxPrice;
  deliveryFeeWithoutDistanceFee: IGroceriesCartTaxPrice;
  distanceFee: IGroceriesCartTaxPrice;
  expectedDelivery: string;
  feesTotal: IGroceriesCartTaxPrice;
  itemsTotal: IGroceriesCartTaxPrice;
  orderId: string;
  paymentId: string;
  personalShopperFee: IGroceriesCartTaxPrice;
  shippingTotal: IGroceriesCartTaxPrice;
  total: IGroceriesCartTaxPrice;
  totalDiscount: IGroceriesCartTaxPrice;
  points: number;
}
