export enum AssetUploadStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export interface IAssetStatusResponse {
  uploadId: string;
  status: AssetUploadStatus;
  documentId: string;
  uploadedFile: IAssetUploadedFile;
  error: string;
}

export interface IAssetUploadedFile {
  id: string;
  url: string;
}

export interface IAssetDMZUploadInfo {
  dmzFileId: string;
}

export enum AssetsBucketsEnum {
  UPLOADS = 'uploads',
  CHAT = 'chat',
  TVLICENSE = 'tvlicense',
  ECOMMERCE = 'ecommerce',
}
