import { Injectable, NgZone } from '@angular/core';
import { CommonUtility } from '../../utils/common-utility';
import { TasksFacade } from '../tasks/tasks.facade';
import { TaskType } from '../models/store.models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationFacade } from '../application/application.facade';
import { httpErrorAction } from '../application/application.actions';
import get from 'lodash-es/get';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { GoogleMapsPredictionService } from '../../services/google-maps-prediction.service';
import { ProfileLocationService } from '../../services/location.service';
import { getAddressByPlace, getAddressForm } from '../../services/map-utils';
import * as locationActions from './location.actions';

@Injectable({
  providedIn: 'root',
})
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private tasksFacade: TasksFacade,
    private appFacade: ApplicationFacade,
    private locationService: ProfileLocationService,
    private googleMapsService: GoogleMapsPredictionService,
    private ngZone: NgZone,
  ) {}

  loadGoogleMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.loadGoogleMapsAPIRequestAction),
      mergeMap(({ onSuccess, taskMetadata }) =>
        this.googleMapsService.mapsApi$.pipe(
          map(() => {
            if (onSuccess) onSuccess();
            return locationActions.loadGoogleMapsAPIResponseAction({ taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error =>
            of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED } })),
          ),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.fetchLocationsRequestAction),
      mergeMap(({ taskMetadata, onSuccess, onError }) =>
        this.locationService.fetchLocations().pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return locationActions.fetchLocationsResponseAction({ data, taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            if (onError) onError();
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  deleteLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.deleteLocationRequestAction),
      mergeMap(({ locationId, taskMetadata, onSucceed, onError }) =>
        this.locationService.deleteLocation(locationId).pipe(
          map(() => {
            if (onSucceed) onSucceed();
            return locationActions.deleteLocationResponseAction({ taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            if (onError) onError();
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  addLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.addLocationRequestAction),
      mergeMap(({ payload, taskMetadata, onSucceed, onError }) =>
        this.locationService.addLocation(payload).pipe(
          map(data => {
            if (onSucceed) onSucceed(data);
            return locationActions.addLocationResponseAction({ data, taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            if (onError) onError();
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  editLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.editLocationRequestAction),
      mergeMap(({ locationId, payload, taskMetadata, onSucceed, onError }) =>
        this.locationService.editLocation(locationId, payload).pipe(
          map(data => {
            if (onSucceed) onSucceed(data);
            return locationActions.editLocationResponseAction({ data, taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            if (onError) onError();
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  setCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.setCurrentLocationRequestAction),
      mergeMap(({ payload, onSuccess, onError, taskMetadata }) =>
        this.locationService.setCurrentLocation(payload).pipe(
          map(data => {
            this.appFacade.setCurrentLocation({
              id: data.id,
              name: data.name,
              position: data.location,
            });
            if (onSuccess) onSuccess();
            return locationActions.setCurrentLocationResponseAction({ data, taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            if (errorCode === 'current_location_not_set') {
              this.appFacade.setCurrentLocation(null);
              return of(
                locationActions.setCurrentLocationResponseAction({
                  data: null,
                  taskMetadata: { type: TaskType.SUCCESS },
                }),
              );
            }
            if (onError) onError();
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.fetchCurrentLocationRequestAction),
      mergeMap(({ taskMetadata, onSuccess, onError }) =>
        this.locationService.fetchCurrentLocation().pipe(
          map(data => {
            this.appFacade.setCurrentLocation({
              id: data.id,
              name: data.name,
              position: data.location,
            });
            if (onSuccess) onSuccess();
            return locationActions.fetchCurrentLocationResponseAction({
              data,
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            if (onError) onError();
            if (errorCode === 'current_location_not_set') {
              this.appFacade.setCurrentLocation(null);
              return of(
                locationActions.fetchCurrentLocationResponseAction({
                  data: null,
                  taskMetadata: { type: TaskType.SUCCESS },
                }),
              );
            }
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
  fetchNearestCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.fetchNearestCurrentLocationRequestAction),
      mergeMap(({ payload, taskMetadata }) =>
        this.locationService.fetchNearestLocation(payload).pipe(
          map(data => {
            this.appFacade.setCurrentLocation({
              id: data.id,
              name: data.name,
              position: data.location,
            });
            return locationActions.fetchNearestCurrentLocationResponseAction({
              data,
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            if (errorCode === 'current_location_not_set') {
              this.appFacade.setCurrentLocation(null);
              return of(
                locationActions.fetchNearestCurrentLocationResponseAction({
                  data: null,
                  taskMetadata: { type: TaskType.SUCCESS },
                }),
              );
            }
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchGooglePredictions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.fetchGooglePredictionsRequestAction),
      mergeMap(({ payload, taskMetadata, onSucceed, onError }) =>
        this.googleMapsService.getPlaceByQuery(payload).pipe(
          CommonUtility.enterZone(this.ngZone),
          map(data => {
            const result = getAddressByPlace(data[0]);
            if (onSucceed) onSucceed(payload, result);
            return locationActions.fetchGooglePredictionsResponseAction({ taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            if (onError) onError();
            console.log('fetchGooglePredictions error', error);
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  findPlaceByAddressString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.fetchPlaceByAddressStringRequestAction),
      mergeMap(({ payload, onSucceed, onError, taskMetadata }) =>
        this.googleMapsService.getPlaceByAddress(payload).pipe(
          CommonUtility.enterZone(this.ngZone),
          map(response => {
            if (onSucceed) onSucceed(getAddressForm(response), get(response, [0, 0]));
            return locationActions.fetchPlaceByAddressStringResponseAction({
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            console.log('findPlaceByAddressString error', error);
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  findPlaceByCoordinatesString$ = createEffect(() =>
    this.actions$.pipe(
      ofType(locationActions.fetchPlaceByCoordinatesRequestAction),
      mergeMap(({ latLng, onSuccess, taskMetadata }) =>
        this.googleMapsService.getPlaceByCoordinates(latLng).pipe(
          map(response => {
            if (onSuccess) onSuccess(getAddressForm(response));
            return locationActions.fetchPlaceByCoordinatesResponseAction({
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error =>
            of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } })),
          ),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
}
