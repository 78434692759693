import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import * as action from './activity-history.actions';
import { IActivityHistoryPartialState } from './activity-history.reducer';
import * as selector from './activity-history.selectors';
import { IActivityHistoryOrderDetail } from '../../models/activity-history.model';
import { started } from '../application/task.actions';
import { IRmaDetail, IRmaRequest, RmaType } from '../../models/rma.model';

@Injectable()
export class ActivityHistoryFacade {
  getOngoingOrders$ = this.store.pipe(select(selector.getOngoingOrders));

  getOrderDetail$ = this.store.pipe(select(selector.getAOrderDetail));
  getRma$ = this.store.pipe(select(selector.getRma));
  getRmaList$ = this.store.pipe(select(selector.getRmaList));
  getRmaCancelReasons$ = this.store.pipe(select(selector.getRmaCancelReasons));
  getRmaDetail$ = this.store.pipe(select(selector.getRmaDetail));

  constructor(private store: Store<IActivityHistoryPartialState>) {}

  fetchActivityHistoryOngoingOrders = (
    onSucceed?: (orderHistory: IActivityHistoryOrderDetail[]) => void,
    silented?: boolean,
    page?: number,
    size?: number,
  ): void => {
    const taskId = `fetch-ongoing-orders-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryOngoingRequestAction(taskId, onSucceed, page, size),
        'Fetching orders',
        silented,
      ),
    );
  };

  fetchActivityHistoryOrderDetail = (
    orderId: string,
    onSucceed?: (response: IActivityHistoryOrderDetail) => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetch-order-detail-${orderId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryDetailRequestAction(taskId, orderId, onSucceed),
        'Fetching data',
        silently,
      ),
    );
  };

  fetchRmaCancelReasons = (orderId: string, fulfilmentId: string): void => {
    const taskId = `fetch-rma-cancel-reasons-${orderId}-${fulfilmentId}-${uuidv4()}`;
    this.store.dispatch(
      started(new action.MyProfileRmaCancelReasonsRequest(taskId, orderId, fulfilmentId), 'Fetching reasons'),
    );
  };

  rma = (orderId: string, fulfilmentId: string, type: RmaType, request: IRmaRequest, onSucceeded: () => void): void => {
    const taskId = `rma-${orderId}-${fulfilmentId}-${type}-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileRmaRequest(taskId, orderId, fulfilmentId, type, request, onSucceeded),
        'Please wait a moment',
      ),
    );
  };

  setRma = (data: IRmaRequest): void => {
    const taskId = `set-rma-${JSON.stringify(data)}`;
    this.store.dispatch(new action.MyProfileRmaDataSet(taskId, data));
  };

  fetchRmaDetail = (orderId: string, fulfilmentId: string, rmaId: string): void => {
    const taskId = `fetch-rma-detail-${orderId}-${fulfilmentId}-${rmaId}`;
    this.store.dispatch(
      started(new action.MyProfileFetchRmaDetailRequest(taskId, orderId, fulfilmentId, rmaId), 'Fetching data'),
    );
  };

  fetchRmaList = (activeOnly: boolean, next: number, size: number, onSucceeded: (data: IRmaDetail[]) => void): void => {
    const taskId = `fetch-rma-list-${activeOnly}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileFetchRmaListRequest(taskId, activeOnly, next, size, onSucceeded),
        'Please wait a moment',
      ),
    );
  };
}
