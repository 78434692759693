import { createAction, props } from '@ngrx/store';
import { BottomNavigationSection, IHeader, SidenavItemId, SidenavType } from '../../model/core.model';

export const setTitle = createAction(
  '[Application] Set application title',
  props<{ value?: string; translate: boolean }>(),
);

export const saveTitle = createAction('[Application] Save finalized title', props<{ value?: string }>());

export const sidenav = createAction('[Application] Sidenav', props<{ value: SidenavType }>());
export const toggleSidenav = createAction('[Application] Toggle sidenav', props<{ value?: boolean }>());
export const sidenavLink = createAction('[Application] Sidenav link', props<{ value: SidenavItemId }>());

export const paddedContentX = createAction('[Application] Padded content horizontal', props<{ value: boolean }>());
export const paddedContentY = createAction('[Application] Padded content vertical', props<{ value: boolean }>());

export const contentBackdrop = createAction('[Application] Content backdrop', props<{ value: boolean }>());
export const contentLightBackdrop = createAction('[Application] Content light backdrop', props<{ value: boolean }>());

export const bottomNavigationSection = createAction(
  '[Application] Bottom navigation section',
  props<{ value?: BottomNavigationSection }>(),
);

export const header = createAction('[Application] Header', props<{ value: IHeader }>());

export const currentDevice = createAction('[Application] Current device', props<{ device: string }>());

export const hideInitLoader = createAction('[Application] Hide init loader');

export const onBackButtonCallback = createAction(
  '[Application] On back button callback',
  props<{ callback: () => boolean }>(),
);
