import { createAction, props } from '@ngrx/store';
import {
  IAuthAccessToken,
  IAuthAvoClientInfo,
  IAuthDevice,
  IAuthFetchTermsResponse,
  IAuthNextStep,
  IAuthRefreshTokenStartRequest,
} from './auth.models';
import { ITask } from '../models/store.models';

export const initAction = createAction(
  '[Auth] Init',
  props<{
    onSuccess: () => void;
    onError: () => void;
  }>(),
);

export const saveTokenAction = createAction(
  '[Auth] Save token',
  props<{
    data: IAuthAccessToken;
    saveToStorage: boolean;
    onSuccess?: (data?: IAuthNextStep) => void;
    onError?: () => void;
  }>(),
);

// refreshToken

export const refreshTokenRequest = createAction(
  '[Auth/API] Refresh token request',
  props<{
    payload: IAuthRefreshTokenStartRequest;
    taskMetadata?: ITask;
    onSuccess?: (data?: IAuthNextStep) => void;
    onError?: () => void;
  }>(),
);
export const refreshTokenResponse = createAction(
  '[Auth/API] Refresh token response',
  props<{ data: IAuthNextStep; taskMetadata?: ITask }>(),
);

// logout

export const logoutAction = createAction(
  '[Auth] Logout',
  props<{
    keepAlive?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
  }>(),
);

// avoInfo

export const avoInfoRequest = createAction(
  '[Auth/API] Avo info request',
  props<{
    taskMetadata?: ITask;
    onSuccess?: (data: IAuthAvoClientInfo) => void;
  }>(),
);
export const avoInfoResponse = createAction(
  '[Auth/API] Avo info response',
  props<{ data: IAuthAvoClientInfo; taskMetadata?: ITask }>(),
);

// currentDevice

export const currentDeviceRequest = createAction(
  '[Auth/API] Current device request',
  props<{
    taskMetadata?: ITask;
    onSuccess?: (data: IAuthDevice) => void;
    onError?: () => void;
  }>(),
);
export const currentDeviceResponse = createAction(
  '[Auth/API] Current device response',
  props<{ data: IAuthDevice; taskMetadata?: ITask }>(),
);

// fetchTerms

export const fetchTermsRequest = createAction(
  '[Login/API] Fetch terms request',
  props<{
    taskMetadata?: ITask;
    onSuccess?: (response: IAuthFetchTermsResponse) => void;
    onError?: () => void;
  }>(),
);
export const fetchTermsResponse = createAction('[Login/API] Fetch terms response', props<{ taskMetadata?: ITask }>());
