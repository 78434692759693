import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../services/logger.service';
import { ApplicationFacade } from '../store/application/application.facade';

@Injectable()
export class LoggerInterceptor implements HttpInterceptor {
  private readonly loggerService = inject(LoggerService);
  private readonly appFacade = inject(ApplicationFacade);
  isAuthorized = false;
  hasLocation = false;

  constructor() {
    this.appFacade.isAuthorized$.subscribe(auth => (this.isAuthorized = auth));
    this.appFacade.hasLocationAvailable$.subscribe(location => (this.hasLocation = location));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP' || !this.loggerService.isLogsInitialized) return next.handle(request);

    if (!request.url.includes('loc-saas') && !request.url.includes('configuration.json')) {
      this.loggerService.logInfo(`PWA Calling ${request.url}`, {
        authorized: this.isAuthorized,
        hasLocation: this.hasLocation,
      });
    }

    return next.handle(request);
  }
}
