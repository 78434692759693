export interface IAdobeRecommendedProduct {
  product_points: string;
  product_rating: string;
  product_sellerId: string;
  product_sellerName: string;
  product_id: string;
  product_name: string;
  product_percentage_discount: string;
  product_price: string;
  product_thumbnail: string;
  product_url: string;
  product_value: string;
  product_slug: string;
}

export interface IAdobeMboxParamsUC0708 {
  'entity.id': string;
  'entity.productId': string;
  'entity.inventory': string;
  'entity.categoryId': string;
  'entity.name': string;
  'entity.message': string;
  'entity.thumbnailUrl': string;
  'entity.pageUrl': string;
  'entity.sku': string;
  'entity.brand'?: string;
  'entity.vertical': AdobeSearchVertical | AdobeCartVertical;
  'entity.displayPrice': number;
  'entity.price': number;
  'entity.event.detailsOnly'?: boolean;
  'entity.countryCode': string;
  // currently in testing phase
  'entity.points'?: number;
  'entity.rating'?: number;
  'entity.enabled'?: AdobeMboxEnabledFlagEnum;
  'entity.enabledInB2c'?: AdobeMboxEnabledFlagEnum;
  'entity.enabledInB2B'?: AdobeMboxEnabledFlagEnum;
}

export enum AdobeMboxEnabledFlagEnum {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

// redeclared from mfe-commerce
export enum AdobeSearchVertical {
  ALL = 'ALL',
  ESHOP = 'ESHOP',
  TAKEAWAYS = 'TAKEAWAYS',
  GROCERIES = 'GROCERIES',
  VOUCHERS = 'VOUCHERS',
  VAS = 'VAS',
  LIQUOR = 'LIQUOR',
  PREPAIDS = 'PREPAIDS',
  BANKING = 'BANKING',
  SERVICES = 'SERVICES',
  TRAVEL = 'TRAVEL',
  HEALTH = 'HEALTH',
}

// redeclared from mfe-commerce
export enum AdobeCartVertical {
  SHOPPING = 'SHOPPING',
  TAKEAWAYS = 'TAKEAWAYS',
  LIQUOR = 'LIQUOR',
  GROCERIES = 'GROCERIES',
  VOUCHERS = 'VOUCHERS',
  VAS = 'VAS',
  ESHOP = 'ESHOP',
}

export enum AdobeCountryCode {
  NA = 'NA',
  ZA = 'ZA',
}

export interface IAdobeMboxParamsOrderConfirmation {
  productPurchasedId: string;
  orderTotal: number;
  orderId: string;
}

export enum AdobeTargetOfferType {
  UC07,
  UC08,
}
