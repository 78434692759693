import { Action } from '@ngrx/store';
import {
  ActivityHistoryType,
  IActivityHistoryOrderDetail,
  IActivityHistoryCancelReasonsResponse,
  IActivityHistoryCancelRequest,
  IActivityHistoryRatingRequest,
  ICustomerOrderMerchantDetailRequest,
  IJobCardMerchantResponse,
} from '../../models/activity-history.model';
import { IRmaCancelReason, IRmaDetail, IRmaRequest, RmaType } from '../../models/rma.model';
import * as EVENT from './activity-history.events';

// MyProfileActivityHistoryOngoing
export class MyProfileActivityHistoryOngoingRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_ONGOING_REQUEST;

  constructor(
    public taskId,
    public onSucceed?: (orderHistory: IActivityHistoryOrderDetail[]) => void,
    public page?: number,
    public size?: number,
  ) {}
}

export class MyProfileActivityHistoryOngoingResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_ONGOING_RESPONSE;

  constructor(public taskId, public data: IActivityHistoryOrderDetail[], public page?: number) {}
}

// MyProfileActivityHistoryDetail
export class MyProfileActivityHistoryDetailRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_DETAIL_REQUEST;

  constructor(
    public taskId,
    public orderId: string,
    public onSucceed?: (response: IActivityHistoryOrderDetail) => void,
  ) {}
}

export class MyProfileActivityHistoryDetailResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_DETAIL_RESPONSE;

  constructor(public taskId, public data: IActivityHistoryOrderDetail) {}
}

// MyProfileRmaData
export class MyProfileRmaDataSet implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_DATA_SET;

  constructor(public taskId, public data: IRmaRequest) {}
}

// MyProfileRmaCancelReasons
export class MyProfileRmaCancelReasonsRequest implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_CANCEL_REASONS_REQUEST;

  constructor(public taskId, public orderId: string, public fulfilmentId: string) {}
}

export class MyProfileRmaCancelReasonsResponse implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_CANCEL_REASONS_RECEIVED;

  constructor(public taskId, public data: IRmaCancelReason[]) {}
}

// MyProfileRma
export class MyProfileRmaRequest implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_REQUEST;

  constructor(
    public taskId,
    public orderId: string,
    public fulfilmentId: string,
    public rmaType: RmaType,
    public request: IRmaRequest,
    public onSucceeded: () => void,
  ) {}
}

export class MyProfileRmaResponse implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_RECEIVED;

  constructor(public taskId) {}
}

// MyProfileFetchRmaDetail
export class MyProfileFetchRmaDetailRequest implements Action {
  readonly type = EVENT.MY_PROFILE_FETCH_RMA_DETAIL_REQUEST;

  constructor(public taskId, public orderId: string, public fulfilmentId: string, public rmaId: string) {}
}

export class MyProfileFetchRmaDetailResponse implements Action {
  readonly type = EVENT.MY_PROFILE_FETCH_RMA_DETAIL_RECEIVED;

  constructor(public taskId, public data: IRmaDetail) {}
}

// MyProfileFetchRmaList
export class MyProfileFetchRmaListRequest implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_LIST_REQUEST;

  constructor(
    public taskId,
    public activeOnly: boolean,
    public next?: number,
    public size?: number,
    public onSucceed?: (orderHistory: IRmaDetail[]) => void,
  ) {}
}

export class MyProfileFetchRmaListResponse implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_LIST_RECEIVED;

  constructor(public taskId, public data: IRmaDetail[], public next: number) {}
}
