import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IGroceriesAddCartCouponRequest,
  IGroceriesCartItemRequest,
  IGroceriesCartResponse,
  IGroceriesCartTimeslotResponse,
  IGroceriesPlaceOrderRequest,
  IGroceriesPlaceOrderResponse,
  IGroceriesRemoveCartItemRequest,
  IGroceriesRemoveCartPromoCodeRequest,
} from './groceries.models';
import { AppConfig } from '../../init/app.config';

@Injectable({
  providedIn: 'root',
})
export class GroceriesService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  fetchCart = () => this.httpClient.get<IGroceriesCartResponse>(`${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART`);

  clearCart = () => this.httpClient.delete<void>(`${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART`);

  cartItem = (request: IGroceriesCartItemRequest) =>
    this.httpClient.post<IGroceriesCartResponse>(`${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/item`, request);

  updateCartItem = (request: IGroceriesCartItemRequest) =>
    this.httpClient.put<IGroceriesCartResponse>(`${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/item`, request);

  removeCartItem = (request: IGroceriesRemoveCartItemRequest) =>
    this.httpClient.post<IGroceriesCartResponse>(
      `${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/item/delete`,
      request,
    );

  addCartPromoCode = (request: IGroceriesAddCartCouponRequest) =>
    this.httpClient.post<IGroceriesCartResponse>(
      `${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/coupon`,
      request,
    );

  removeCartPromoCode = (request: IGroceriesRemoveCartPromoCodeRequest) =>
    this.httpClient.post<IGroceriesCartResponse>(
      `${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/coupon/delete`,
      request,
    );

  fetchCartTimeslots = () =>
    this.httpClient.get<IGroceriesCartTimeslotResponse>(
      `${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/timeslots`,
    );

  placeCartOrder = (request: IGroceriesPlaceOrderRequest) =>
    this.httpClient.post<IGroceriesPlaceOrderResponse>(
      `${this.appConfig.apiV3Url}/groceries/v1/cart/ONECART/order`,
      request,
    );
}
