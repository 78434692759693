import { Injectable } from '@angular/core';
import { MfeConfigModule } from '../mfe/mfe-config';

@Injectable({ providedIn: 'root' })
export class AppConfig {
  name?: string;
  mfe?: MfeConfigModule[];
  apiUrl: string;
  apiV2Url?: string;
  apiV3Url: string;
  apiVersion?: string;
  websocketUrl?: string;
  translations?: string[];
  prepaidElectricitySku?: string;
  primadsUrl?: string;
}
