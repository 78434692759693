export const image = {
  wallet: {
    amex: './assets/images/wallet/Amex.svg',
    btnAdd: './assets/images/wallet/BtnAdd.svg',
    mastercard: './assets/images/wallet/Mastercard.svg',
    visa: './assets/images/wallet/Visa.svg',
    payItYourWay1: './assets/images/wallet/PayItYourWay.svg',
    payItYourWay2: './assets/images/wallet/PayItYourWay2.svg',
    payItYourWay3: './assets/images/wallet/PayItYourWay3.svg',
    paySuccess: './assets/images/wallet/PaySuccess.svg',
    rewardsActive: './assets/images/wallet/RewardsActive.svg',
    greenBacksRewardsActive: './assets/images/wallet/GreenBacksRewardsActive.svg',
    transactionEmpty: './assets/images/wallet/TransactionEmpty.svg',
    mobiMoney: './assets/images/wallet/MobiMoney.svg',
  },
  logo: {
    header: './assets/images/logo/Header.svg',
    avo: './assets/images/logo/avo.svg',
    google: './assets/images/logo/Google.svg',
    facebook: './assets/images/logo/Facebook.svg',
    apple: './assets/images/logo/Apple.svg',
    nedbank: './assets/images/logo/Nedbank.svg',
    namibia: './assets/images/logo/namibia.svg',
    southAfrica: './assets/images/logo/south-africa.png',
    googlePlay: './assets/images/logo/google_play.svg',
    appleStore: './assets/images/logo/apple_store.svg',
    avoPoints: './assets/images/logo/AvoPoints.svg',
  },
  errors: {
    noInternetDesktop: './assets/images/errors/Desktop - No internet 1.svg',
    noInternetMobile: './assets/images/errors/Mobile - No internet 1.svg',
    noInternet: './assets/images/errors/No internet.svg',
    technicalProblemDesktop: './assets/images/errors/Desktop-technical problem.svg',
    technicalProblemMobile: './assets/images/errors/Mobile-technical problem.svg',
    notFound: './assets/images/errors/notFound.svg',
    unexpectedError: './assets/images/errors/Desktop - Error.svg',
    outageDesktop: './assets/images/errors/Desktop - Outage Image.svg',
    outageMobile: './assets/images/errors/Mobile - Outage Image.svg',
    negative: './assets/images/errors/Negative.svg',
    unauthorized: './assets/images/errors/Unauthorized.svg',
    simSwap: './assets/images/errors/Sim-swap-error.svg',
    lastContactError: './assets/images/errors/Last_contact_error.svg',
    exceptionsBadWeather: './assets/images/errors/Exceptions-Bad-Weather.svg',
  },
  banking: {
    newToProduct: './assets/images/banking/ntp.svg',
    hangTight: './assets/images/banking/HangTight.svg',
    expired: './assets/images/banking/Expired.svg',
  },
  homeLoans: {
    property: {
      homeScreen: './assets/images/property/landing screen.svg',
      propertySearch: './assets/images/property/property search.svg',
      propertySearching: './assets/images/property/property-searching.jpg',
      report: './assets/images/property/property report.svg',
    },
    affordAbilityCheck: {
      homeScreen: './assets/images/property/check affordability landing.svg',
      succesfull: './assets/images/property/check affordability successfull.svg',
      unsuccesfull: './assets/images/property/check affordability unsuccessfull.svg',
      done: './assets/images/property/property report.svg',
    },
  },
  profile: {
    navigation: {
      info: './assets/images/profile/info.svg',
      orders: './assets/images/profile/orders.svg',
      mybookings: './assets/images/profile/mybookings.svg',
      addresses: './assets/images/profile/addresses.svg',
      preferences: './assets/images/profile/preferences.svg',
      subscriptions: './assets/images/profile/subscriptions.svg',
      security: './assets/images/profile/security.svg',
      communications: './assets/images/profile/communications.svg',
      legal: './assets/images/profile/legal.svg',
      faq: './assets/images/profile/faq.svg',
      email: './assets/images/profile/email.svg',
    },
    location: {
      address: './assets/images/profile/location/address.svg',
      addressMissing: './assets/images/profile/location/address_missing.svg',
      addressCart: './assets/images/profile/location/cart_warning.svg',
      addressNeed: './assets/images/profile/location/we_need_address.svg',
      addressPrompt: './assets/images/profile/location/whereareyou.svg',
      locationPrompt: './assets/images/profile/location/location_prompt.svg',
      groceries: './assets/images/profile/location/groceries.svg',
      addAddress: './assets/images/profile/location/add_address.svg',
      liquor: './assets/images/profile/location/liquor.svg',
      takeaways: './assets/images/profile/location/takeaway.svg',
    },
    subscriptions: {
      subscriptions: './assets/images/profile/subscriptions/subscriptions.svg',
    },
    stepProgressBar: {
      dot1: './assets/images/profile/step-progress-bar/dot_1.svg',
      dot2: './assets/images/profile/step-progress-bar/dot_2.svg',
      dot3: './assets/images/profile/step-progress-bar/dot_3.svg',
      dot4: './assets/images/profile/step-progress-bar/dot_4.svg',
      dot5: './assets/images/profile/step-progress-bar/dot_5.svg',
    },
  },
  other: {
    fromNedbank: './assets/images/other/fromNedbank.svg',
    secureGreen: './assets/images/other/secureGreen.svg',
    secure: './assets/images/other/secure.svg',
    buyCart: './assets/images/other/buy_cart.svg',
    avo: './assets/images/other/avo.svg',
    avoGreen: './assets/images/other/avoGreen.svg',
    avoShop: './assets/images/other/avoShop.svg',
    profileCarousel: './assets/images/other/profile_carousel.png',
    basket: './assets/images/other/basket.png',
    payflex: './assets/images/other/payflex.svg',
    success: './assets/images/other/success.svg',
  },
  funeral: {
    success: './assets/images/funeral/success.svg',
    successFeedback: './assets/images/funeral/feedback.svg',
    inccorect: './assets/images/funeral/incorrect.svg',
    main: './assets/images/funeral/main.svg',
    login: './assets/images/funeral/login.svg',
  },
  login: {
    apple: './assets/images/login/apple.svg',
    facebook: './assets/images/login/facebook.svg',
    nedbank: './assets/images/login/nedbank.svg',
    terms: './assets/images/login/terms.svg',
  },
  loyaltyAndRewards: {
    loader: './assets/images/loyalty-and-rewards/loader.svg',
  },
  cart: {
    empty: './assets/images/cart/EmptyCart.svg',
    emptyWithShadow: './assets/images/cart/CartIsEmptyWithShadow.svg',
  },
  payments: {
    creditCard: './assets/images/payments/creditCard.svg',
    moreMethods: './assets/images/payments/moreMethods.svg',
    success: './assets/images/payments/success.svg',
    convert: './assets/images/payments/convert.svg',
    wallet: './assets/images/payments/wallet.svg',
    payflex: './assets/images/payments/payflex.svg',
    mobicred: './assets/images/payments/mobicred.svg',
    personalLoan: './assets/images/payments/personal-loan.svg',
    expired: './assets/images/payments/expired.svg',
    gb: './assets/images/payments/gb.svg',
    cardProviders: {
      amex: './assets/images/payments/card-providers/amex.svg',
      masterCard: './assets/images/payments/card-providers/masterCard.svg',
      visa: './assets/images/payments/card-providers/visa.svg',
    },
    long: {
      mobicred: './assets/images/payments/long/mobicred.svg',
      payflex: './assets/images/payments/long/payflex.svg',
      nedbank: './assets/images/payments/long/nedbank.svg',
    },
  },
  travel: {
    plane: './assets/images/travel/Plane.svg',
    poweredbytourvest: './assets/images/travel/poweredbytourvest.png',
  },
};
