export const MY_PROFILE_ACTIVITY_HISTORY_ONGOING_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/ONGOING/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_ONGOING_RESPONSE = 'MY-PROFILE/ACTIVITY_HISTORY/ONGOING/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_DETAIL_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/DETAIL/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_DETAIL_RESPONSE = 'MY-PROFILE/ACTIVITY_HISTORY/DETAIL/RECEIVED';

export const MY_PROFILE_RMA_DATA_SET = 'MY-PROFILE/RMA-DATA/SET';

export const MY_PROFILE_RMA_CANCEL_REASONS_REQUEST = 'MY-PROFILE/RMA/CANCEL-REASONS/REQUEST';
export const MY_PROFILE_RMA_CANCEL_REASONS_RECEIVED = 'MY-PROFILE/RMA/CANCEL-REASONS/RECEIVED';

export const MY_PROFILE_RMA_REQUEST = 'MY-PROFILE/RMA/REQUEST';
export const MY_PROFILE_RMA_RECEIVED = 'MY-PROFILE/RMA/RECEIVED';

export const MY_PROFILE_RMA_LIST_REQUEST = 'MY-PROFILE/RMA-LIST/REQUEST';
export const MY_PROFILE_RMA_LIST_RECEIVED = 'MY-PROFILE/RMA-LIST/RECEIVED';

export const MY_PROFILE_FETCH_RMA_DETAIL_REQUEST = 'MY-PROFILE/FETCH-RMA-DETAIL/REQUEST';
export const MY_PROFILE_FETCH_RMA_DETAIL_RECEIVED = 'MY-PROFILE/FETCH-RMA-DETAIL/RECEIVED';
