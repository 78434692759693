import { Injectable } from '@angular/core';

export class IAppConfig {
  sessionId: string;
  apiUrl: string;
  apiV3Url?: string;
  authUrl?: string;
  onboardingUrl?: string;
  onboardingV2Url?: string;
  transactionSigningUrl?: string;
  apiaryUrl?: string;
  websocketUrl?: string;
  translateUrl?: string;
  cognitoConfig?: any;
}

@Injectable({ providedIn: 'root' })
export class AppConfig {
  isDevelopmentUrl?: boolean;
  timeoutRequests?: string[] = [];
  backendConfig: IAppConfig;
  appType: string;
  avoBusinessBaseUrl: string;
  readonly apiVersion = '35';

  getMockApiUrl = (): string =>
    this.backendConfig && this.backendConfig.apiaryUrl ? this.backendConfig.apiaryUrl : this.backendConfig.apiUrl;
}
