import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { IAdobeSearchData } from './algolia-search.model';
import { AdobeCartVertical, AdobeCountryCode, AdobeSearchVertical } from './target.model';

export enum PROPERTY {
  DIGITAL_DATA = 'digitalData',
  SATELLITE = '_satellite',
  ADOBE = 'adobe',
}

export enum SECTION {
  HOMEPAGE = 'Homepage',
  GLOBAL_CART = 'Global cart',
  ESHOP = 'EShop',
  LIQUOR = 'Liquor',
  TAKEAWAYS = 'Takeaways',
  GROCERIES = 'Groceries',
  BANKING = 'Banking',
  PREPAIDS_AND_VOUCHERS = 'VoucherPrepaids',
  LOYALTY_AND_REWARDS = 'LoyaltyRewards',
  HEALTH = 'Health',
  SERVICES = 'Services',
  CUSTOMER_PROFILE = 'Customer Profile',
  MY_ASSISTANT = 'My Assistant',
  SIGN_IN_AND_REGISTRATION = 'SignIn & Registration',
  PAYMENTS = 'Payments',
  REWARDS = 'Rewards',
  SYSTEM_RESPONSES = 'System Responses',
  UNSUBSCRIBED = 'unsubscribed',
  PAST_BUYS = 'PastBuys',
  // UNCONFIRMED SECTION NAMES:
  WALLET = 'Wallet',
  // Merchant sections
  AVO_SELLER = 'AvoSeller',
}

export enum SUBSECTION {
  // --- PREPAIDS_AND_VOUCHERS ---
  PREPAIDS = 'Prepaids',
  VOUCHERS = 'Vouchers',
}

export enum ENTRY_POINT_ACTION {
  DISCOVER = 'Discover',
  BOTTOM_NAV = 'Bottom Nav',
  SEARCH = 'Search',
  DIRECT = 'Direct',
  QUICK_ACTIONS = 'Quick Actions',
}

export enum EVENT {
  COMMON_PAGE_LOAD = 'commonpageload',
  LINK_CLICK = 'linkclick',
  ERROR = 'errortracking',
  FORM_INITIATED = 'forminitiated',
  SIGN_IN_START = 'signinstart',
  SIGN_IN_SUCCESS = 'signinsuccess',
  SIGN_UP_SUCCESS = 'signupsuccess',
  PRODUCT_VIEW = 'productView',
  SHOPPING_CART_ADD = 'scadd',
  SHOPPING_CART_REMOVE = 'scremove',
  SHOPPING_CART_PRODUCT_INCREASE = 'productincrease',
  SHOPPING_CART_PRODUCT_DECREASE = 'productdecrease',
  PAYMENT_SUCCESS_3 = 'paymentsuccess3',
  CART_CHECKOUT_CLICK = 'checkoutbutton',
  CART_APPLY_PROMO_CODE = 'applypromocode',
  ORDER_CONFIRMATION = 'orderconfirmation',
  CUSTOMER_PROFILE = 'customerprofile',
  PROFILE_UPDATE_SUCCESS = 'profileupdatesuccess',
  ADDRESS_UPDATE_SUCCESS = 'addressupdatesuccess',
  CUSTOMER_PERSONAL_DETAILS = 'customerpersonaldetailsevent',
  PUSH_NOTIFICATION_CLICKED = 'pushnotificationclicked',
  BUY_NOW = 'buyproductnow',

  // avo2 groceries
  CART_DELIVERY_ADDRESS = 'cartdeliveryaddress',
}

export enum TARGET_FUNCTION {
  TRIGGER_VIEW = 'triggerView',
  GET_OFFER = 'getOffer',
  APPLY_OFFER = 'applyOffer',
}

export enum ERROR_CODE {
  CARD_PAYMENT_ERROR,
}

interface IAdobePageInfo {
  pageName: string;
  previousPage?: string;
  siteSection?: SECTION;
  subSiteSection?: SUBSECTION;
  entryPointAction?: ENTRY_POINT_ACTION;
  entryPointValue?: string;
}

interface IAdobeUser {
  id?: string;
  loginState: 'authenticated' | 'non-authenticated';
  sessionId?: string;
  walletCreated?: boolean;
  loginCreated?: boolean;
  linkedToBank?: boolean;
}

interface IAdobeTechDetail {
  countryCode?: AdobeCountryCode;
  userPlatform: string;
  serviceVersion: string;
}

export interface IAdobeActivePage {
  pageInfo: IAdobePageInfo;
  user: IAdobeUser;
  techDetail: IAdobeTechDetail;
}

export interface IAdobeEntryPoint {
  subChannel?: string;
  action?: ENTRY_POINT_ACTION;
  value?: string;
}

export interface IAdobeData {
  activePage?: IAdobeActivePage;
  entryPoint?: IAdobeEntryPoint;
  searchData?: IAdobeSearchData;
}

export interface IEventOptions {
  viewName: string;
  siteSection?: SECTION;
  subSiteSection?: SUBSECTION;
  entryPointAction?: ENTRY_POINT_ACTION;
  entryPointValue?: string;
  additionalObject?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface IAdobeEventLoadData {
  page: IAdobeActivePage;
}

export interface IAdobeEventClickData {
  page: IAdobeActivePage;
  link: IAdobeEventClickLink;
}

interface IAdobeEventClickLink {
  linkName: string;
  assetAction: string;
  options?: any;
}

export interface IAdobeEventErrorData {
  page: IAdobeActivePage;
  link?: IAdobeEventErrorLink;
  errorInfo: IAdobeEventErrorInfo;
}

export interface IAdobeEventErrorInfo {
  errorCause?: string;
  errorCode?: string;
  errorDetail?: string;
  requestUrl?: string;
  errorType?: IAdobeEventErrorType;
}
interface IAdobeEventErrorLink {
  details: IAdobeEventErrorDetails;
}

export enum IAdobeEventErrorType {
  TECHNICAL_API_ERROR = 'TECHNICAL_API_ERROR',
  BUSINESS_API_ERROR = 'BUSINESS_API_ERROR',
  ERROR = 'ERROR',
}

export interface IAdobeEventErrorDetails {
  // use the two attributes for any error received from BE - handled by adobe's interceptor
  request?: HttpRequest<unknown>;
  error?: HttpErrorResponse;
  // use this attribute and build the object manually otherwise
  // (for example: payment failure - does not come from our BE but bank systems)
  customError?: IAdobeCustomError;
}

export interface IAdobeCustomError {
  errorMessage: string;
  errorCode: ERROR_CODE;
  errorReason?: string;
  // any other custom error data that may differ from case to case
  additionalData?: any;
}

export interface IAdobeEventFormData {
  formName?: string;
  componentEvent: unknown;
}

export interface IAdobeCartFulfillment {
  stores: IAdobeCartStore[];
}

export interface IAdobeCartStore {
  label: string;
  totalAmount: number;
  items: IAdobeCartItem[];
}

export interface IAdobeCartItem {
  sku: string;
  adobeSku?: string;
  title: string;
  storeName?: string;
  brand?: string;
  price: number;
  quantity: number;
  fromGenAIRecommender?: boolean;
  addons?: any;
}

export interface IAdobeCartTransformedItem {
  productInfo: IAdobeCartTransformedItemProductInfo;
}

interface IAdobeCartTransformedItemProductInfo {
  productId: string;
  productSku: string;
  productName: string;
  storeName?: string;
  amount: number;
  totalAmount?: number;
  brand?: string;
  quantity?: number;
  vertical: AdobeSearchVertical | AdobeCartVertical;
  fromGenAIRecommender?: boolean;
}

export interface IAdobeShoppingCartData {
  page?: IAdobeActivePage;
  promocode?: string;
  cart?: IAdobeCart;
  apiResponse?: IAdobeApiResponse;
}

interface IAdobeCart {
  item: IAdobeCartTransformedItem[];
  cartTotalValue?: number;
}

interface IAdobeApiResponse {
  apiResponse: string;
}

export interface IAdobeOrderDetails {
  vertical: AdobeSearchVertical | AdobeCartVertical;
  orderConfirmation: IAdobeOrderConfirmation;
}

interface IAdobeOrderConfirmation {
  orderPlaced: number;
  orderRevenue: number;
  orderDeliveryCharges: any;
  orderTotalPayableAmount: any;
  orderReceiptItems: any;
  purchaseId: string;
  paymentMethod: any;
  avoPointsEarned: number;
  avoPointsSpent: number;
  item: IAdobeCartTransformedItem;
  items: IAdobeCartTransformedItem;
  entrySubChannel: any;
}

export interface IAdobeDigitalData {
  page?: IAdobeActivePage;
  link?: IAdobeLink;
  component?: IAdobeComponent;
  cmpid?: string;
  [key: string]: any;
}

interface IAdobeLink {
  linkName?: string;
  assetAction?: string;
  [key: string]: any; // used rarely
}

interface IAdobeComponent {
  componentName: string;
  componentEvent: string;
  componentStartSource: string;
}

export interface IAdobeCustomerPersonalDetailsData {
  event: IAdobeCustomerPersonalDetailsEvent;
}

interface IAdobeCustomerPersonalDetailsEvent {
  eventName: string;
}

export interface IAdobePushNotification {
  token: string;
  deeplinkUrl: string;
  notificationId: string;
  userAgent: string;
}

export enum AdobeEnvironmentEnum {
  TEST = 'test',
  STAGE = 'stage',
  PREVIEW = 'preview',
  PROD = 'prod',
}
