import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { ActivityHistoryService } from '../../services/activity-history/activity-history.service';
import * as actions from './activity-history.actions';
import * as EVENT from './activity-history.events';
import { ApplicationFacade } from '../application/application.facade';
import { IActivityHistoryOrderDetail } from '../../models/activity-history.model';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';

@Injectable()
export class ActivityHistoryEffects {
  constructor(
    private actions$: Actions,
    private appFacade: ApplicationFacade,
    private activityHistoryService: ActivityHistoryService,
  ) {}

  ongoingActivityHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyProfileActivityHistoryOngoingRequestAction>(EVENT.MY_PROFILE_ACTIVITY_HISTORY_ONGOING_REQUEST),
      switchMap(({ taskId, onSucceed, page, size }) =>
        this.activityHistoryService.fetchActivityHistoryOngoing(page, size).pipe(
          map((data: IActivityHistoryOrderDetail[]) => {
            if (onSucceed) {
              onSucceed(data);
            }
            return succeed(new actions.MyProfileActivityHistoryOngoingResponseAction(taskId, data, page));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  detailActivityHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyProfileActivityHistoryDetailRequestAction>(EVENT.MY_PROFILE_ACTIVITY_HISTORY_DETAIL_REQUEST),
      switchMap(({ taskId, orderId, onSucceed }) =>
        this.activityHistoryService.fetchActivityHistoryDetail({ orderId }).pipe(
          map((data: IActivityHistoryOrderDetail) => {
            if (onSucceed) {
              onSucceed(data);
            }
            return succeed(new actions.MyProfileActivityHistoryDetailResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  rma$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyProfileRmaRequest>(EVENT.MY_PROFILE_RMA_REQUEST),
      switchMap(({ taskId, orderId, fulfilmentId, rmaType, request, onSucceeded }) =>
        this.activityHistoryService.rma(orderId, fulfilmentId, rmaType, request).pipe(
          map(() => {
            onSucceeded();
            return succeed(new actions.MyProfileRmaResponse(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRmaCancelReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyProfileRmaCancelReasonsRequest>(EVENT.MY_PROFILE_RMA_CANCEL_REASONS_REQUEST),
      switchMap(({ taskId, orderId, fulfilmentId }) =>
        this.activityHistoryService.fetchRmaCancelReasons(orderId, fulfilmentId).pipe(
          map(response => succeed(new actions.MyProfileRmaCancelReasonsResponse(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRmaDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyProfileFetchRmaDetailRequest>(EVENT.MY_PROFILE_FETCH_RMA_DETAIL_REQUEST),
      switchMap(({ taskId, orderId, fulfilmentId, rmaId }) =>
        this.activityHistoryService.fetchRmaDetail(orderId, fulfilmentId, rmaId).pipe(
          map(data => new actions.MyProfileFetchRmaDetailResponse(taskId, data)),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRmaList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyProfileFetchRmaListRequest>(EVENT.MY_PROFILE_RMA_LIST_REQUEST),
      switchMap(({ taskId, activeOnly, next, size, onSucceed }) =>
        this.activityHistoryService.fetchRmaList(activeOnly, size, next).pipe(
          map(data => {
            if (onSucceed) onSucceed(data);
            return succeed(new actions.MyProfileFetchRmaListResponse(taskId, data, next));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
