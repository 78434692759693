import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { AppConfig } from '../init/app.config';
import { BrowserStorageService } from '../services/browser-storage.service';
import { StorageKeys } from '../store/models/store.models';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from '@avo/environment/customer/environment';
import { getMessaging, getToken } from 'firebase/messaging';
import { AdobeAnalyticsService, IAdobePushNotification } from '@avo/shared/adobe-analytics';
import { DeviceIdService } from './deviceid.service';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  private readonly fireMessaging: AngularFireMessaging;
  private readonly subscriptions = new Subscription();
  private readonly adobeAnalyticsService: AdobeAnalyticsService;
  private readonly deviceService = inject(DeviceIdService);

  constructor(
    injector: Injector,
    private appConfig: AppConfig,
    private httpClient: HttpClient,
    private webStorage: BrowserStorageService,
  ) {
    this.fireMessaging = injector.get(AngularFireMessaging);
    this.adobeAnalyticsService = injector.get(AdobeAnalyticsService);

    if ('serviceWorker' in navigator) {
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notifications');
      } else if (Notification.permission !== 'denied') {
        const handlePermission = (permission: NotificationPermission): void => {
          if (permission === 'granted') {
            this.webStorage.set(StorageKeys.PUSH_PERMISSIONS, 'accepted');
          }
        };

        if (this.checkNotificationPromise()) {
          Notification.requestPermission().then(handlePermission);
        } else {
          Notification.requestPermission(handlePermission);
        }
      }

      this.subscriptions.add(
        this.fireMessaging.messages.subscribe(message => {
          console.log('+++++NEW MESSAGE ARRIVAL FROM PUSH SERVICE: ', message);
          if (!message) return;
          const data = {
            _id: message.data?.['_id'],
            deepLinkUri: message.data?.['deepLinkUri'],
          };
          this.showNotification(
            message.notification.title,
            message.notification.body,
            message.data?.['icon'] || '/assets/app-icons/customer-icon-512x512.png',
            data,
            message.data['deepLinkUri'],
          );
        }),
      );
    }
  }

  showNotification(title, body, icon, data, link): void {
    if ('Notification' in window) {
      const notification = new Notification(title, { body, icon, data });
      const pushToken = this.webStorage.get(StorageKeys.PUSH_TOKEN, false, null);
      notification.addEventListener('click', () => {
        const pnid = data.id || data['_id'] || data._id;
        const adobePushNotification: IAdobePushNotification = {
          token: pushToken,
          deeplinkUrl: data.deepLinkUri,
          notificationId: pnid,
          userAgent: this.getUserAgent(),
        };
        this.adobeAnalyticsService.pushNotificationClicked(adobePushNotification);
        window.focus();
        this.httpClient
          .post(`${this.appConfig.apiUrl}/notifications/v1/push/clicked`, {
            userAgent: this.getUserAgent(),
            notificationId: pnid,
            deeplinkUrl: data.deepLinkUri,
            clickUrl: link,
            token: pushToken,
          })
          .subscribe();
        // Needed for full URL in message.data['deepLinkUri'] to be accessible
        window.open(link, '_self');
      });
    }
  }

  checkBrowserNotificationPermission(): void {
    if (navigator.userAgent.indexOf('OPR') != -1) {
      this.registerFailedDevice();
    } else if (navigator.userAgent.indexOf('Edg') > -1) {
      this.requestToken();
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      this.requestToken();
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      this.registerFailedDevice();
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      this.requestToken();
    } else {
      console.log('Unknown Browser');
      this.registerFailedDevice();
    }
  }

  requestToken(): void {
    if ('serviceWorker' in navigator) {
      this.subscriptions.add(
        this.fireMessaging.requestToken.subscribe({
          next: token => {
            if (!token) {
              this.registerFailedDevice();
            } else {
              this.webStorage.set(StorageKeys.PUSH_TOKEN, token);
              this.registerToken(token);
            }
          },
          error: error => console.log('++++NOTIFICATIONS PERMISSION DENIED: ', error),
          complete: () => console.log('++++NOTIFICATIONS PERMISSION COMPLETED'),
        }),
      );
    }
  }

  backgroundMessageReceived(message: any): void {
    console.log('+++++NEW MESSAGE ARRIVED FROM SERVICEWORKER: ', message);
  }

  registerNotifications = (): void => {
    const token = this.webStorage.get(StorageKeys.PUSH_TOKEN, false, null);
    if (token) {
      this.registerToken(token);
    } else {
      this.requestToken();
    }
  };

  requestPushPermission() {
    const messaging = getMessaging();
    navigator.serviceWorker.register('./firebase-messaging-sw.js').then(() => {
      getToken(messaging, { vapidKey: environment.firebase.vapidKey })
        .then(currentToken => {
          if (currentToken) {
            console.log('Firebase Token Received...');
            console.log(currentToken);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch(err => {
          console.log('A user pushed block on permissions!!!!');
          console.log('An error occurred while retrieving token. ', err);
        });
    });
  }

  registerToken = (token: string): void => {
    console.log('+++++REGISTERING WITH TOKEN: ', token);
    const deviceData = {
      applicationVersion: '1.0.30',
      deviceId: this.deviceService.deviceId,
      deviceName: 'PWA-Client',
      deviceType: this.getUserAgent(),
      language: 'en',
      os: 'PWA',
      pushToken: token,
    };
    this.subscriptions.add(
      this.httpClient.post(`${this.appConfig.apiUrl}/notifications/v1/push/register`, deviceData).subscribe(() => {
        console.log('+++++NOTIFICATIONS REGISTERED: ', deviceData);
      }),
    );
    this.webStorage.set(StorageKeys.PUSH_PERMISSIONS, 'accepted');
  };

  registerFailedDevice() {
    const deviceData = {
      applicationVersion: '1.0.30',
      deviceId: this.deviceService.deviceId,
      deviceName: this.getUserAgent(),
      deviceType: 'PWA',
      language: 'en',
      os: 'PWA',
    };
    this.webStorage.remove(StorageKeys.PUSH_TOKEN);
    this.subscriptions.add(
      this.httpClient.post(`${this.appConfig.apiUrl}/notifications/v1/push/blocked`, deviceData).subscribe(() => {
        console.log('+++++REGISTER FAILED DEVICE: ', deviceData);
      }),
    );
    this.webStorage.set(StorageKeys.PUSH_PERMISSIONS, 'blocked');
  }

  unregisterFromNotifications = (): void => {
    const deviceId = this.deviceService.deviceId;
    const token = this.webStorage.get(StorageKeys.PUSH_TOKEN, false, null);
    console.log('+++++UNSUBSCRIBING TOKEN ', token);
    if (!token) return;

    this.subscriptions.add(
      this.fireMessaging.deleteToken(token).subscribe(result => {
        this.webStorage.remove(StorageKeys.PUSH_TOKEN);
        console.log('+++++OLD TOKEN WAS REMOVED: ', result);
      }),
    );

    const authenticationToken = this.webStorage.get(StorageKeys.TOKEN, null);
    if (authenticationToken) {
      this.httpClient
        .post(`${this.appConfig.apiUrl}/notifications/v1/push/unregister/${deviceId}`, {})
        .pipe(take(1))
        .subscribe(() => {
          console.log('+++++UNREGISTERED DEVICE: ', deviceId);
        });
    }

    this.checkBrowserNotificationPermission();
  };

  getUserAgent = (): string => {
    return navigator.userAgent || navigator.vendor || (window as any).opera;
  };

  /**
   * Check if Notification.requestPermission() returns promise.
   * Only Safari uses callback approach.
   */
  private checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }
}
