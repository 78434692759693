export const customerWallet = (baseUrl: string): string => `${baseUrl}/customer-wallet/v1`;
export const merchantWallet = (baseUrl: string): string => `${baseUrl}/customer-wallet/v1/merchant`;
export const wallets = (baseUrl: string): string => `${baseUrl}/customer-wallet/v1/wallets`;
export const bankingCoreServices = (baseUrl: string): string => `${baseUrl}/banking-core-services/v1/wallet`;
export const payments = (baseUrl: string): string => `${baseUrl}/payments/v1`;
export const paymentsDefault = (baseUrl: string): string => `${baseUrl}/payments`;
export const nedbank = (baseUrl: string): string => `${baseUrl}/users/v1/nedbank`;
export const ecommerce = (baseUrl: string): string => `${baseUrl}/ecommerce/v1`;

export const fetchPaymentMethodTransactionsFiltered = (baseUrl: string): string =>
  `${customerWallet(baseUrl)}/payment-method/transaction-filtered`;

export const fetchPaymentMethodTransactions = (limit: number, baseUrl: string): string =>
  `${customerWallet(baseUrl)}/payment-method/transaction?limit=${limit}`;

export const settlementFilter = (baseUrl: string, params: any): string =>
  `${payments(baseUrl)}/seller/settlement/?${new URLSearchParams(params).toString()}`;

export const settlementFilterExport = (baseUrl: string, storeId: string): string =>
  `${payments(baseUrl)}/seller/settlement/${storeId}/export`;

export const transactionsFilterExport = (baseUrl: string, storeId: string, params: any): string =>
  `${payments(baseUrl)}/seller/settlement/${storeId}/transaction/export?${new URLSearchParams(params).toString()}`;

export const taxInvoices = (baseUrl: string, storeId: string, params: any): string =>
  `${payments(baseUrl)}/seller/tax-invoices/${storeId}?${new URLSearchParams(params).toString()}`;

export const taxInvoiceById = (taxInvoiceId: number, storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/tax-invoices/${storeId}/${taxInvoiceId}`;

export const generateRemittance = (storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/remittance/${storeId}/generate`;

export const taxInvoiceDownload = (taxInvoiceId: string, storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/tax-invoices/${storeId}/${taxInvoiceId}/download`;

export const sellerStatement = (baseUrl: string, storeId: string, params?: any): string => {
  return `${payments(baseUrl)}/seller/statement/${storeId}${
    params ? '?' + new URLSearchParams(params).toString() : ''
  }`;
};

export const generateSellerStatement = (baseUrl: string, storeId: string): string => {
  return `${payments(baseUrl)}/seller/statement/${storeId}/generate`;
};

export const sellerStatementDownload = (statementNumber: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/statement/${statementNumber}/download`;

export const statementDownload = (statementNumber: string, storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/statement/${storeId}/${statementNumber}/download`;

export const getTaskFile = (taskId: string, baseUrl: string): string =>
  `${paymentsDefault(baseUrl)}/user-tasks/files/${taskId}`;

export const getTaskDetail = (taskId: string, baseUrl: string): string =>
  `${paymentsDefault(baseUrl)}/user-tasks/${taskId}`;

export const filterPayoutTransactions = (baseUrl: string, storeId: string, params: any): string =>
  `${payments(baseUrl)}/seller/settlement/${storeId}/transaction/filter?${new URLSearchParams(params).toString()}`;

export const settlementAccount = (accountId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/settlement/account/${accountId}`;

export const fetchPaymentLink = (paymentLink: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/payment-link/${paymentLink}`;

export const partyAccounts = (baseUrl: string): string => `${bankingCoreServices(baseUrl)}/partyAccounts`;

export const subscriptionAvailable = (baseUrl: string): string => `${baseUrl}/wallet/v1/availableSubscription`;
export const subscriptionAvailableMerchant = (baseUrl: string): string =>
  `${baseUrl}/wallet/v1/merchant/availableSubscription`;

export const fetchPayInfo = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}`;
export const payByCard = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-card`;
export const payByPointsOnly = (flowId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/${flowId}/pay-points-only`;
export const payByWallet = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-wallet`;
export const payForFree = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-free`;
export const payByEft = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-eft`;
export const payByAmex = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-amex`;
export const payByCredit = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-credit`;
export const topUpAndPay = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/topup-and-pay`;

export const fetchWalletSubscription = (baseUrl: string): string => `${baseUrl}/wallet/v1/subscription`;

export const fetchPaymentCarousel = (baseUrl: string): string => `${merchantWallet(baseUrl)}/payment-carousel`;
export const fetchAddCard = (baseUrl: string): string => `${merchantWallet(baseUrl)}/add-payment-card-request`;
export const fetchActivePaymentMethods = (baseUrl: string): string =>
  `${merchantWallet(baseUrl)}/payment-method/active`;
export const deletePaymentMethod = (paymentMethodId: string, baseUrl: string): string =>
  `${merchantWallet(baseUrl)}/payment-method/${paymentMethodId}`;
export const editPaymentMethod = (paymentMethodId: string, baseUrl: string): string =>
  `${merchantWallet(baseUrl)}/payment-method/${paymentMethodId}`;
export const changeDefaultPaymentMethod = (paymentMethodId: string, baseUrl: string): string =>
  `${merchantWallet(baseUrl)}/payment-method/${paymentMethodId}/default`;
export const editPaymentMethodName = (paymentMethodId: string, baseUrl: string): string =>
  `${merchantWallet(baseUrl)}/payment-method/${paymentMethodId}/name`;
export const fetchPaymentMethodBalance = (paymentMethodId: string, baseUrl: string): string =>
  `${customerWallet(baseUrl)}/payment-method/${paymentMethodId}/balance`;

export const processAddCard = (paymentFlowId: string, baseUrl: string): string =>
  `${merchantWallet(baseUrl)}/add-payment-card-request/${paymentFlowId}`;

export const fetchNedbankBanks = (baseUrl: string): string => `${payments(baseUrl)}/settlement/banks`;
