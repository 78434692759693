import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VOUCHERS_CATEGORIES_FEATURE_KEY, VouchersCategoriesState } from './vouchers-categories.reducer';

export const getVouchersCategoriesState = createFeatureSelector<VouchersCategoriesState>(
  VOUCHERS_CATEGORIES_FEATURE_KEY,
);

export const getCategoriesTop = createSelector(
  getVouchersCategoriesState,
  (state: VouchersCategoriesState) => state?.categoriesTop,
);
