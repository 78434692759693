import { TranslateFacade } from '@avo/shared/avo-translate';
import isEmpty from 'lodash-es/isEmpty';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AppConfigFacade, ApplicationFacade, AuthFacade, BrowserStorageService, IdleService, StorageKeys } from '../..';
import { AppInitService } from '../services/app-init.service';
import { LoggerService } from '../services/logger.service';
import { SessionService } from '../services/session.service';
import { DeviceIdService } from '../services/deviceid.service';
import { CmpidService } from '../services/cmpid.service';
import { FeatureFlagsFacade } from '../store/feature-flags/feature-flags.facade';

export function appConfigFactory(
  translateFacade: TranslateFacade,
  appFacade: ApplicationFacade,
  appConfigFacade: AppConfigFacade,
  appInitService: AppInitService,
  storageService: BrowserStorageService,
  loggerService: LoggerService,
  authFacade: AuthFacade,
  sessionService: SessionService,
  idleService: IdleService,
  deviceIdService: DeviceIdService,
  cmpidService: CmpidService,
  featureFlagsFacade: FeatureFlagsFacade,
): () => Promise<any> {
  const setSessionId = (): void => {
    // This is requirement from business
    console.log('Session ID: ', sessionService.sessionId);
  };

  //Resolve cmpid from url structure variations
  const setCmpId = (): void => {
    let _cmpId = 'organic-web';
    let cmpidSource = '';

    //get incoming url, decoded
    const incomingURL = decodeUrl(window.location.href);

    //does the url contain trackerId?
    let incomingCmpid = containsCmpidParams(incomingURL.decodedUrl, ['trackerid']);
    //yes, extract and set
    if (incomingCmpid) {
      _cmpId = extractTrackerId(incomingURL.decodedUrl);
      cmpidSource = 'from trackerId value in url';
      cmpidService.resetCmpId(_cmpId);
      logCmpIdInfo(cmpidSource, incomingURL);
      return;
    }

    //no, does url have cmpid
    incomingCmpid = containsCmpidParams(incomingURL.decodedUrl, ['cmpid']);
    //yes, extract and set
    if (incomingCmpid) {
      const urlParams = new URLSearchParams(new URL(incomingURL.decodedUrl).search);
      _cmpId = urlParams.get('cmpid');
      cmpidSource = 'from cmpid value in url';
      cmpidService.resetCmpId(_cmpId);
      logCmpIdInfo(cmpidSource, incomingURL);
      return;
    }

    //no, is there a value for cmpid in cookie?
    if (getCookie('cmpid')) {
      cmpidSource = 'from cookie';
      _cmpId = getCookie('cmpid');
      cmpidService.resetCmpId(_cmpId);
      logCmpIdInfo(cmpidSource, incomingURL);
      return;
    }

    //no, is there a value for cmpid in session storage?
    //no, set as organic
    if (!cmpidService.cmpId) {
      cmpidSource = 'not able to resolve cmpid, set as organic';
      cmpidService.resetCmpId(_cmpId);
      logCmpIdInfo(cmpidSource, incomingURL);
      return;
    }

    //use the cmpid from browser storage
    cmpidSource = 'from browser storage';
    logCmpIdInfo(cmpidSource, incomingURL);
  };

  //handle both single-encoded and double-encoded URLs
  const decodeUrl = (url: string): { status: string; decodedUrl: string } => {
    const singleDecoded = decodeURIComponent(url);

    if (url !== singleDecoded && singleDecoded === decodeURIComponent(singleDecoded)) {
      return { status: 'single encoded', decodedUrl: singleDecoded };
    }

    const doubleDecoded = decodeURIComponent(singleDecoded);

    if (singleDecoded !== doubleDecoded && doubleDecoded === decodeURIComponent(doubleDecoded)) {
      return { status: 'double encoded', decodedUrl: doubleDecoded };
    }

    if (url === singleDecoded) {
      return { status: 'not encoded', decodedUrl: url };
    }

    // If none of the above, the URL is either not encoded or encoded in a way that's not detectable
    return { status: 'unknown encoding', decodedUrl: url };
  };

  const extractTrackerId = (url): string | null => {
    const trackerIdMatch = url.match(/trackerid=([^&]*)/);
    return trackerIdMatch ? trackerIdMatch[1] : null;
  };

  const containsCmpidParams = (url: string, targetParams: string[]): boolean => {
    return targetParams.some(param => url.includes(param));
  };

  const logCmpIdInfo = (cmpidSource: string, incomingURL: { decodedUrl: string; status: string }) => {
    //business requirement
    console.log('Campaign ID:', cmpidService.cmpId);
    loggerService.logInfo('Campaign Info', {
      Campaign: {
        rawUrl: window.location.href,
        decodedUrl: incomingURL.decodedUrl,
        urlEncodingStatus: incomingURL.status,
        cmpidSource: cmpidSource,
        cmpid: cmpidService.cmpId,
      },
    });
  };

  const setCountryId = (): void => {
    const countryCode = storageService.get(StorageKeys.COUNTRY_CODE);
    if (countryCode) {
      appFacade.setCountryCode(countryCode);
    } else {
      appFacade.fetchCountryCode();
    }
  };

  const setDeviceId = (): string => {
    return deviceIdService.deviceId;
  };

  const onIdle = (): void => {
    sessionService.resetSessionId();
    cmpidService.clearCmpId();
    idleService.start(onIdle);
  };

  return (): Promise<any> => {
    return new Promise<void>((resolve: any): any => {
      setDeviceId();
      setSessionId();

      idleService.start(onIdle);

      loggerService.fetchConfigAndStartDatadog(() => {
        appInitService
          .fetchConfig()
          .pipe(
            take(1),
            map(config => {
              appFacade.checkMaintenance();

              const initCallback = (): void => {
                setCountryId();
                appConfigFacade.fetchAppConfig();
                featureFlagsFacade.fetchFeatureFlags();
                config.translations?.forEach(translation => {
                  translateFacade.fetchTranslations(translation, () => resolve());
                });
                setCmpId();
              };

              authFacade.init(initCallback, initCallback);
            }),
            catchError(error => {
              console.error(`App init error: `, error);
              return of(false);
            }),
          )
          .subscribe();
      });
    });
  };
}

export function isJwtTokenValid(token: any): boolean {
  if (!token) return false;

  const tokenData = token['access_token'] || token['accessToken'];
  if (tokenData && !isEmpty(token)) {
    const expiration = new Date(token.expiresAt).getTime();
    const currentTime = new Date().getTime();
    if (currentTime > expiration) {
      console.log('accessToken expired');
      return false;
    }
    return true;
  }
  return false;
}

function getCookie(name: string): string {
  const cookies = {};

  document.cookie.split(';').forEach(cookie => {
    const [key, value] = cookie.split('=');
    cookies[key.trim()] = value;
  });

  return cookies[name] || null;
}
