import { Injectable, inject } from '@angular/core';
import { CartFacade } from '../store/cart/cart.facade';
import {
  AdobeAlgoliaSearchService,
  AdobeAnalyticsService,
  AdobeCartVertical,
  AdobeSearchVertical,
  IAdobeCartItem,
} from '@avo/shared/adobe-analytics';
import { IUiCardProductData, SearchVertical, UiCardBigButtonType } from '@avo/shared/ui/model';
import { CartVertical, IAddItemRequest } from '../store/cart/cart.models';
import { TranslatePipe } from '@avo/shared/avo-translate';
import { UrlRoutes } from '../routes/routes';
import { CommonUtility } from './common-utility';
import { Params, Router } from '@angular/router';
import { IGroceriesCartItemRequest } from '../store/groceries/groceries.models';
import { GroceriesFacade } from '../store/groceries/groceries.facade';
import { UiToastService, UiToastType } from '@avo/shared/ui/toast';

@Injectable({
  providedIn: 'root',
})
export class CartUtils {
  cartFacade = inject(CartFacade);
  adobeAlgoliaSearchService = inject(AdobeAlgoliaSearchService);
  adobeAnalyticsService = inject(AdobeAnalyticsService);
  uiToastService = inject(UiToastService);
  translatePipe = inject(TranslatePipe);
  groceriesFacade = inject(GroceriesFacade);

  router = inject(Router);

  addToCart(
    request: { product: IUiCardProductData; vertical: SearchVertical },
    sku: string,
    additionalRequestData: any,
    onSuccess: () => void,
    onError: () => void,
    additionalQueryParams?: any,
  ): void {
    const algoliaData = this.adobeAlgoliaSearchService.getSearchDataForSku(request.product.sku);
    switch (request.product.bigButtonType) {
      case UiCardBigButtonType.ADD_TO_CART:
      case UiCardBigButtonType.BUY_NOW: {
        if (request.vertical !== SearchVertical.GROCERIES) {
          // avo 3
          const productData: IAddItemRequest = {
            ...algoliaData,
            buyNow: request.product.bigButtonType === UiCardBigButtonType.BUY_NOW,
            sku: request.product.sku,
            vertical: request.vertical as unknown as CartVertical,
            quantity: 1,
            trackingId: request?.product?.trackingId,
            queryId: request?.product?.queryId,
            fromGenAIRecommender: additionalRequestData.fromGenAIRecommender,
          };

          const analyticsDataItems: IAdobeCartItem[] = [
            {
              sku: productData.sku,
              adobeSku: request.product?.adobeSku,
              title: request.product.title,
              storeName: productData.description || request.product?.description,
              price: request.product.price,
              quantity: productData.quantity,
              fromGenAIRecommender: additionalRequestData.fromGenAIRecommender,
            },
          ];

          this.cartFacade.addItem(
            productData,
            response => {
              this.cartFacade.fetchCartDetail(response.cartId, async cartFacadeResponse => {
                if (cartFacadeResponse) {
                  await this.findSkuAndAddToAnalytics(cartFacadeResponse, sku, analyticsDataItems);
                }

                this.adobeAnalyticsService.shoppingCartAdd(
                  analyticsDataItems,
                  this.mapVerticalToAdobeVertical(request.vertical),
                  'Success',
                );
              });

              this.uiToastService.open({
                data: {
                  type: UiToastType.SUCCESS,
                  text: this.translatePipe.transform('customer.ecommerce.add_to_cart.success_message'),
                },
              });
              if (request.product.bigButtonType === UiCardBigButtonType.BUY_NOW) {
                if (request.product.termAgreement) {
                  this.navigateTo(`/ecommerce/cart/${response.cartId}/checkout?isTermAgreement=true`);
                } else {
                  this.navigateTo(UrlRoutes.ecommerce.cart.detail, response.cartId);
                }
              } else {
                onSuccess();
              }
            },
            () => {
              this.adobeAnalyticsService.shoppingCartAdd(
                analyticsDataItems,
                this.mapVerticalToAdobeVertical(request.vertical),
                'error',
              );
              onError();
            },
          );
          break;
        } else {
          this.groceriesAddToCart(
            request.product.bigButtonType === UiCardBigButtonType.BUY_NOW,
            sku,
            additionalRequestData,
            request.vertical,
            onSuccess,
            onError,
          );
        }
        break;
      }

      case UiCardBigButtonType.DETAIL: {
        this.navigateToWithParams(
          CommonUtility.format(UrlRoutes.ecommerce.productDetail, request.product.slug, request.product.sku),
          additionalQueryParams ?? {},
        );
        break;
      }
    }
  }

  async findSkuAndAddToAnalytics(response: any, sku: string, analyticsDataItems: IAdobeCartItem[]): Promise<void> {
    for (const fulfilment of response.fulfilments) {
      for (const store of fulfilment.stores) {
        for (const item of store.items) {
          if (item.sku === sku && item.addons.length > 0) {
            for (const addon of item.addons) {
              const adobeCartItem: IAdobeCartItem = {
                sku: addon.sku,
                title: addon.title,
                quantity: addon.quantity,
                price: addon.price.amount,
                adobeSku: addon.adobeSku,
              };
              analyticsDataItems.push(adobeCartItem);
            }
          }
        }
      }
    }
  }

  groceriesAddToCart(
    buyNow = false,
    sku: string,
    additionalRequestData: any,
    vertical: SearchVertical,
    onSuccess?: () => void,
    onError?: (error) => void,
  ) {
    this.adobeAnalyticsService.eventClick(buyNow ? 'Buy now' : 'Add to cart');
    if (buyNow) this.adobeAnalyticsService.buyNow();
    const request: IGroceriesCartItemRequest = {
      trackingId: additionalRequestData.trackingId,
      queryId: additionalRequestData.queryId || '',
      productSku: sku,
      quantity: 1,
    };

    this.groceriesFacade.addCartItem(
      request,
      data => {
        this.onSucceedOneCartAdd(this.adobeCartAddData(additionalRequestData), buyNow, vertical);
        if (onSuccess) onSuccess();
      },
      error => {
        this.onAddToCartErrorInner(error, vertical, buyNow);
        if (onError) onError(error);
      },
    );
  }

  private onSucceedOneCartAdd = (additionalRequestData: any, buyNow?: boolean, vertical?: SearchVertical): void => {
    const adobeVertical = this.mapVerticalToAdobeVertical(vertical);
    this.adobeAnalyticsService.shoppingCartAdd(this.adobeCartAddData(additionalRequestData), adobeVertical, 'Success');

    if (buyNow) {
      this.navigateTo(UrlRoutes.ecommerce.groceries.cart.checkout);
    } else {
      this.uiToastService.open({
        data: {
          text: this.translatePipe.transform('customer.ecommerce.add_to_cart.success_message'),
        },
      });
    }
  };

  private onAddToCartErrorInner = (error, vertical: SearchVertical, adobeCartAddData: any): void => {
    const adobeVertical = this.mapVerticalToAdobeVertical(vertical);
    this.adobeAnalyticsService.shoppingCartAdd(adobeCartAddData, adobeVertical, error);
  };

  private mapVerticalToAdobeVertical(vertical: SearchVertical): AdobeSearchVertical | AdobeCartVertical {
    switch (vertical) {
      case SearchVertical.ALL:
        return AdobeSearchVertical.ALL;
      case SearchVertical.ESHOP:
        return AdobeCartVertical.ESHOP;
      case SearchVertical.TAKEAWAYS:
        return AdobeSearchVertical.TAKEAWAYS;
      case SearchVertical.GROCERIES:
        return AdobeSearchVertical.GROCERIES;
      case SearchVertical.VOUCHERS:
        return AdobeSearchVertical.VOUCHERS;
      case SearchVertical.VAS:
        return AdobeSearchVertical.VAS;
      case SearchVertical.LIQUOR:
        return AdobeSearchVertical.LIQUOR;
      case SearchVertical.PREPAIDS:
        // this is intentional to maintain vertical alignment throughout the whole journey
        return AdobeSearchVertical.VOUCHERS;
      case SearchVertical.BANKING:
        return AdobeSearchVertical.BANKING;
      case SearchVertical.SERVICES:
        return AdobeSearchVertical.SERVICES;
      case SearchVertical.TRAVEL:
        return AdobeSearchVertical.TRAVEL;
      case SearchVertical.HEALTH:
        return AdobeSearchVertical.HEALTH;
      default:
        return AdobeSearchVertical.ALL;
    }
  }

  private adobeCartAddData(additionalRequestData: any): IAdobeCartItem[] {
    return [
      {
        ...additionalRequestData,
        sku: additionalRequestData.sku,
        adobeSku: additionalRequestData.skuForAdobe || additionalRequestData.adobeSku,
        title: additionalRequestData.title ?? additionalRequestData.name,
        // prioritize sellerRealName if available
        storeName:
          additionalRequestData.seller?.sellerRealName ||
          additionalRequestData.sellerName ||
          additionalRequestData.seller?.name,
        // prioritize name if available
        brand:
          additionalRequestData.seller?.name ||
          additionalRequestData.sellerName ||
          additionalRequestData.seller?.sellerRealName,
        price:
          additionalRequestData.pricing?.price?.amount || additionalRequestData.amount || additionalRequestData.price,
        quantity: 1,
      },
    ];
  }

  navigateToWithParams = (url: string, params: Params, replaceUrl?: boolean, state?: any): void => {
    this.router.navigate(url ? [url] : [], { replaceUrl, queryParams: params, state: state });
  };

  navigateTo = (url: string, arg?: string): void => {
    const routerLink = CommonUtility.format(url, arg);
    this.router.navigateByUrl(encodeURI(routerLink));
  };
}
