export enum UiSpinnerType {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export enum UiSpinnerSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}
