import { ITask } from '../models/store.models';
import { createAction, props } from '@ngrx/store';
import { IAddressForm, ILocationV3, INewLocationV3, IPosition, ISetCurrentLocation } from './location.models';

//
export const fetchLocationsRequestAction = createAction(
  '[LOCATION/API] fetch profile locations request action',
  props<{ taskMetadata?: ITask; onSuccess?: (data: ILocationV3[]) => void; onError?: () => void }>(),
);
export const fetchLocationsResponseAction = createAction(
  '[LOCATION/API] fetch profile locations response action',
  props<{ data: ILocationV3[]; taskMetadata?: ITask }>(),
);

//
export const deleteLocationRequestAction = createAction(
  '[LOCATION/API] delete profile location request action',
  props<{ locationId: string; taskMetadata?: ITask; onSucceed?: () => void; onError?: () => void }>(),
);
export const deleteLocationResponseAction = createAction(
  '[LOCATION/API] delete profile location response action',
  props<{ taskMetadata?: ITask }>(),
);

//
export const addLocationRequestAction = createAction(
  '[LOCATION/API] add Profile location request action',
  props<{
    payload: INewLocationV3;
    taskMetadata?: ITask;
    onSucceed?: (data: ILocationV3) => void;
    onError?: () => void;
  }>(),
);
export const addLocationResponseAction = createAction(
  '[LOCATION/API] add profile location response action',
  props<{ data: ILocationV3; taskMetadata?: ITask }>(),
);

//
export const editLocationRequestAction = createAction(
  '[LOCATION/API] edit profile location request action',
  props<{
    locationId: string;
    payload: INewLocationV3;
    taskMetadata?: ITask;
    onSucceed?: (data: ILocationV3) => void;
    onError?: () => void;
  }>(),
);
export const editLocationResponseAction = createAction(
  '[LOCATION/API] edit profile location response action',
  props<{ data: ILocationV3; taskMetadata?: ITask }>(),
);

//
export const setCurrentLocationRequestAction = createAction(
  '[LOCATION/API] set profile current location request action',
  props<{ payload: ISetCurrentLocation; onSuccess?: () => void; onError?: () => void; taskMetadata?: ITask }>(),
);
export const setCurrentLocationResponseAction = createAction(
  '[LOCATION/API] set profile current location response action',
  props<{ data: ILocationV3; taskMetadata?: ITask }>(),
);

//
export const fetchCurrentLocationRequestAction = createAction(
  '[LOCATION/API] fetch profile current location request action',
  props<{ taskMetadata?: ITask; onSuccess?: () => void; onError?: () => void }>(),
);
export const fetchCurrentLocationResponseAction = createAction(
  '[LOCATION/API] fetch profile current location response action',
  props<{ data: ILocationV3; taskMetadata?: ITask }>(),
);

//
export const fetchNearestCurrentLocationRequestAction = createAction(
  '[LOCATION/API] fetch profile nearest current location request action',
  props<{ payload: IPosition; taskMetadata?: ITask }>(),
);
export const fetchNearestCurrentLocationResponseAction = createAction(
  '[LOCATION/API] fetch profile nearest current location response action',
  props<{ data: ILocationV3; taskMetadata?: ITask }>(),
);

export const fetchGooglePredictionsRequestAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile google query prediction request action',
  props<{
    payload: string;
    onSucceed: (query: string, data: ILocationV3[]) => void;
    onError?: () => void;
    taskMetadata?: ITask;
  }>(),
);

export const fetchGooglePredictionsResponseAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile google query prediction response action',
  props<{ taskMetadata?: ITask }>(),
);

export const fetchPlaceByAddressStringRequestAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile place by address string request action',
  props<{
    payload: string;
    onSucceed: (data: IAddressForm, json?: string) => void;
    onError?: () => void;
    taskMetadata?: ITask;
  }>(),
);

export const fetchPlaceByAddressStringResponseAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile place by address string response action',
  props<{ taskMetadata?: ITask }>(),
);

//
export const fetchPlaceByCoordinatesRequestAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile place by coordinates request action',
  props<{ latLng: google.maps.LatLng; onSuccess?: (data: IAddressForm) => void; taskMetadata?: ITask }>(),
);

export const fetchPlaceByCoordinatesResponseAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile place by coordinates response action',
  props<{ taskMetadata?: ITask }>(),
);

//
export const loadGoogleMapsAPIRequestAction = createAction(
  '[LOCATION/GOOGLE API] fetch profile place by coordinates response action',
  props<{ onSuccess?: () => void; taskMetadata?: ITask }>(),
);

export const loadGoogleMapsAPIResponseAction = createAction(
  '[LOCATION/GOOGLE API] load profile google maps resposne action',
  props<{ taskMetadata?: ITask }>(),
);
