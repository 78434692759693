import { inject, Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  finalize,
  fromEvent,
  map,
  mergeMap,
  of,
  Subscription,
  switchMap,
  tap,
  throttleTime,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AuthService } from '../../services/auth.service';
import * as authActions from './auth.actions';
import { AuthFacade } from './auth.facade';
import { ITaskAction, StorageKeys, TaskType } from '../models/store.models';
import { BrowserStorageService } from '../../services/browser-storage.service';
import { TasksFacade } from '../tasks/tasks.facade';
import { ApplicationFacade } from '../application/application.facade';
import { httpErrorAction } from '../application/application.actions';
import { isJwtTokenValid } from '../../factories/appConfig.factory';
import dayjs from 'dayjs';
import { UrlRoutes } from '../../routes/routes';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects implements OnDestroy {
  refreshTokenSubscription: Subscription;

  /** Default time in seconds when refresh token is used in advance. */
  private readonly RT_REFRESH_BEFORE = 60;

  constructor(
    private readonly actions$: Actions<ITaskAction>,
    private storageService: BrowserStorageService,
    private authService: AuthService,
    private tasksFacade: TasksFacade,
    private appFacade: ApplicationFacade,
    private authFacade: AuthFacade,
  ) {}

  private readonly router = inject(Router);

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.initAction),
        tap(({ onSuccess, onError }) => {
          const token = this.storageService.get(StorageKeys.TOKEN);
          if (token) {
            if (isJwtTokenValid(token)) {
              this.authFacade.saveToken(token, false, () => {
                this.authFacade.fetchAvoInfo();
                this.authFacade.fetchCurrentDevice();
                onSuccess();
              });
              return;
            }

            this.storageService.remove(StorageKeys.TOKEN);

            const refreshToken = this.storageService.get(StorageKeys.REFRESH_TOKEN);
            if (refreshToken) {
              this.authFacade.refreshToken({ refreshToken }, onSuccess, onError);
              return;
            }
          }

          this.appFacade.setAuthorizationStatus(false);
          onSuccess();
        }),
      ),
    { dispatch: false },
  );

  saveToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.saveTokenAction),
        tap(({ data, saveToStorage, onSuccess }) => {
          const refreshToken = data?.refresh_token || data?.refreshToken;
          if (refreshToken) {
            this.removeRefreshTokenTimer();
            const refreshIn =
              dayjs(data.expiresAt).diff(undefined, 'milliseconds') - this.RT_REFRESH_BEFORE * 1000 || 0;
            this.refreshTokenSubscription = timer(refreshIn).subscribe(() => {
              this.authFacade.refreshToken({ refreshToken });
            });
          }

          if (saveToStorage) {
            this.storageService.set(StorageKeys.TOKEN, data);
            if (refreshToken) {
              this.storageService.set(StorageKeys.REFRESH_TOKEN, refreshToken);
            }
            this.authFacade.fetchAvoInfo();
            this.authFacade.fetchCurrentDevice();
          }

          this.appFacade.setAuthorizationStatus(true);
          if (onSuccess) onSuccess();
        }),
      ),
    { dispatch: false },
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.refreshTokenRequest),
      mergeMap(({ payload, taskMetadata, onSuccess, onError }) =>
        this.authService.refreshToken(payload).pipe(
          switchMap(data => {
            return [
              authActions.saveTokenAction({
                data: data?.token,
                saveToStorage: true,
                onSuccess: onSuccess ? () => onSuccess(data) : undefined,
              }),
              authActions.refreshTokenResponse({
                data,
                taskMetadata: { type: TaskType.SUCCESS, taskId: taskMetadata.taskId },
              }),
            ];
          }),
          catchError(error => {
            return [
              authActions.logoutAction({ keepAlive: false, onSuccess: onError }),
              httpErrorAction({ error, data: { taskId: taskMetadata.taskId, type: TaskType.FAILED_SILENT } }),
            ];
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logoutAction),
        tap(({ keepAlive, onSuccess }) => {
          this.removeRefreshTokenTimer();
          if (!keepAlive) {
            this.storageService.remove(StorageKeys.REFRESH_TOKEN);
          }
          this.storageService.remove(StorageKeys.TOKEN);
          this.storageService.remove(StorageKeys.PUSH_PERMISSIONS);
          this.appFacade.setAuthorizationStatus(false);
          this.appFacade.setAvoInfo(null);
          if (onSuccess) onSuccess();
        }),
      ),
    { dispatch: false },
  );

  avoInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.avoInfoRequest),
      mergeMap(({ taskMetadata, onSuccess }) =>
        this.authService.avoInfo().pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            this.appFacade.setAvoInfo({
              displayName: data.displayName,
              id: data.id,
              linkedToBank: data.linkedToBank,
              loginCreated: data.loginCreated,
              walletCreated: data.walletCreated,
              email: data.email,
              name: data.name,
              phone: data.phone,
              epn: data.epn,
              photo: data.photo,
              surname: data.surname,
              nationality: data.nationality,
              saId: data.saId,
              addressLine1: data.addressLine1,
              country: data.country,
            });

            return authActions.avoInfoResponse({
              data,
              taskMetadata: { type: TaskType.SUCCESS, taskId: taskMetadata.taskId },
            });
          }),
          catchError(error =>
            of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata.taskId, type: TaskType.FAILED_SILENT },
              }),
            ),
          ),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );

  currentDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.currentDeviceRequest),
      mergeMap(({ taskMetadata, onSuccess }) =>
        this.authService.currentDevice().pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return authActions.currentDeviceResponse({
              data,
              taskMetadata: { type: TaskType.SUCCESS, taskId: taskMetadata.taskId },
            });
          }),
          catchError(error =>
            of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata.taskId, type: TaskType.FAILED_SILENT },
              }),
            ),
          ),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );

  fetchTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.fetchTermsRequest),
      mergeMap(({ taskMetadata, onSuccess, onError }) =>
        this.authService.fetchTerms().pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return authActions.fetchTermsResponse({ taskMetadata: { type: TaskType.SUCCESS } });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({ error, forceLog: true, data: { taskId: taskMetadata.taskId, type: TaskType.FAILED } }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );

  checkTermsOnActiveTab$ = createEffect(
    () =>
      fromEvent(document, 'visibilitychange').pipe(
        filter(() => document.visibilityState === 'visible'),
        throttleTime(60 * 1000 * 15), //every 15 minutes
        withLatestFrom(this.authFacade.isLoggedIn$),
        filter(([, isLoggedIn]) => isLoggedIn),
        tap(() => {
          this.authFacade.fetchTerms(response => {
            if (response.termsToAccept || response.termsUpdated) {
              this.appFacade.setReturnUrl(this.router.url);
              this.router.navigate([UrlRoutes.auth.login.terms], {
                queryParams: { termsUpdated: response.termsUpdated },
              });
            }
          });
        }),
      ),
    { dispatch: false },
  );

  private removeRefreshTokenTimer(): void {
    if (this.refreshTokenSubscription) {
      this.refreshTokenSubscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.removeRefreshTokenTimer();
  }
}
