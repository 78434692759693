import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

/**
 * Use for DSM icons only.
 *
 * @example
 * src = Assets object value
 *
 * @example
 * src = ORCH path (e.g. Category/Arrow/Forward/Yellow)
 * isOrch = true
 */
@Component({
  selector: 'ui-icon',
  templateUrl: './ui-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiIconComponent {
  /** Icon file path */
  @Input() src: SafeUrl | string | undefined;

  /** Applied for both width and height */
  @Input() size = '2.5rem';

  /** Alternative text in case of file error */
  @Input() alt: string | undefined;

  /** Flag if icon is provided from orchestrator. In that case add path prefix. */
  @Input() isOrch = false;

  @HostBinding('class') classes = 'inline-block select-none';
}
