import { inject, Injectable, isDevMode, OnDestroy } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs, LogsEvent } from '@datadog/browser-logs';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from '@avo/environment/customer/environment';
import { BuildNumberService } from './build-number.service';
import { IDatadogRemoteConfig, LoggerContext } from '../model/core.model';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKeys } from '../store/models/store.models';
import { ApplicationFacade } from '../store/application/application.facade';
import { SessionService } from './session.service';
import { DeviceIdService } from './deviceid.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements OnDestroy {
  datadogConfig: IDatadogRemoteConfig;

  private rumInitialized = false;
  private logsInitialized = false;
  private subscriptions = new Subscription();

  private readonly httpClient = inject(HttpClient);
  private readonly buildNumberService = inject(BuildNumberService);
  private readonly applicationFacade = inject(ApplicationFacade);
  private readonly storageService = inject(BrowserStorageService);
  private readonly sessionService = inject(SessionService);
  private readonly deviceIdService = inject(DeviceIdService);

  get isLogsInitialized() {
    return this.logsInitialized;
  }

  /**
   * Logs any javascript object which is instance of ErrorConstructor.
   * Additional context can be provided as multi-level key-value objects.
   *
   * @param error ErrorConstructor instance
   * @param rootContext Multi-level key-value object
   * @param additionalContext Multi-level key-value object
   */
  logError = (error: Error, rootContext: LoggerContext, additionalContext: LoggerContext): void => {
    if (!this.rumInitialized && !this.logsInitialized) {
      console.log('logError: rum and logs are not initialized');
      console.error(error, rootContext, additionalContext);
      return;
    }

    if (this.rumInitialized) {
      datadogRum.addError(error, {
        browserUrl: window.location.pathname + window.location.search,
        ...rootContext,
        ...additionalContext,
      });
    }

    if (this.logsInitialized) {
      datadogLogs.logger.error(error.message, {
        browserUrl: window.location.pathname + window.location.search,
        additional: additionalContext,
        ...rootContext,
      });
    }
  };

  /**
   * Logs info message.
   * Additional context can be provided as multi-level key-value objects.
   *
   * @param message Info message
   * @param rootContext Multi-level key-value object
   * @param additionalContext Multi-level key-value object
   */
  logInfo = (message: string, rootContext: LoggerContext = null, additionalContext: LoggerContext = null): void => {
    if (!this.logsInitialized) {
      console.log('logInfo: logs are not initialized');
      console.log(message, rootContext, additionalContext);
      return;
    }

    datadogLogs.logger.info(message, {
      browserUrl: window.location.pathname + window.location.search,
      additional: additionalContext,
      ...(rootContext ?? {}),
    });
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Gets datadog config from config file.
   * Datadog is not initialized if config file is missing.
   */
  fetchConfigAndStartDatadog = (onInit: () => void): void => {
    if (isDevMode() || !environment?.datadog) {
      onInit();
      return;
    }

    this.httpClient
      .get<IDatadogRemoteConfig>(environment.datadog.remoteConfigUrl)
      .pipe(
        take(1),
        map(data => {
          this.datadogConfig = data;
          this.buildNumberService.fetchBuildNumber('shell').then(buildNumber => this.initDatadog(buildNumber, onInit));
        }),
        catchError(error => {
          onInit();
          throw error;
        }),
      )
      .subscribe();
  };

  /**
   * Connects logs to currently logged user.
   */
  private watchUser = (): void => {
    this.subscriptions.add(
      this.applicationFacade.getAvoInfo$.subscribe(data => {
        if (data) {
          datadogRum.setUser({
            id: data.id,
            xavodeviceid: this.deviceIdService.deviceId,
            xavosessionid: this.sessionService.sessionId,
            notificationstatus: this.storageService.get(StorageKeys.PUSH_PERMISSIONS),
          });
        } else {
          datadogRum.removeUser();
        }
      }),
    );
  };

  /**
   * Initialize datadog libs and add default global context.
   *
   * - datadogRum collects whole visitor's journey (resources, requests, route changes, ...)
   * - datadogLogs is for easier monitoring in datadog application.
   *
   * Information from both can be connected via X-Avo-SessionId header, which is
   * filterable and searchable facet in datadog application.
   *
   * @param version App build number
   * @param onInit Init callback
   */
  private initDatadog = (version: string, onInit: () => void): void => {
    if (this.datadogConfig.rumEnabled) {
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        site: environment.datadog.site,
        service: environment.datadog.service,
        allowedTracingUrls: [/https:\/\/.*\.avo\.africa/],
        useSecureSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input',
        env: environment.environment,
        version: version ?? undefined,
        sessionSampleRate: this.datadogConfig.rumSampleRate,
        sessionReplaySampleRate: this.datadogConfig.replaySampleRate,
        trackUserInteractions: this.datadogConfig.trackInteractions,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        beforeSend: (event: any) => {
          // ignore some http status codes (no internet connection, blocked url or server connection issues)
          return ![0, 504, 503, 502].includes(event.error?.resource?.status_code);
        },
      });

      datadogRum.addRumGlobalContext('X-Avo-DeviceId', this.deviceIdService.deviceId);
      datadogRum.addRumGlobalContext('X-Avo-SessionId', this.sessionService.sessionId);
      datadogRum.addRumGlobalContext('X-Avo-PushPermissions', this.storageService.get(StorageKeys.PUSH_PERMISSIONS));
      this.rumInitialized = true;
      this.watchUser();

      if (this.datadogConfig.sessionReplayEnabled) {
        datadogRum.startSessionReplayRecording();
      }
    }

    /* Logs init */

    if (this.datadogConfig.logsEnabled) {
      datadogLogs.init({
        clientToken: environment.datadog.clientToken,
        site: environment.datadog.site,
        service: environment.datadog.service,
        useSecureSessionCookie: true,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error'],
        env: environment.environment,
        version: version ?? undefined,
        sessionSampleRate: this.datadogConfig.logsSampleRate,
        beforeSend: this.getErrorTypes,
      });

      datadogLogs.logger.addContext('X-Avo-DeviceId', this.deviceIdService.deviceId);
      datadogLogs.logger.addContext('X-Avo-SessionId', this.sessionService.sessionId);
      datadogLogs.logger.addContext('X-Avo-PushPermissions', this.storageService.get(StorageKeys.PUSH_PERMISSIONS));

      this.logsInitialized = true;
    }

    onInit();
  };

  private getErrorTypes(event: LogsEvent) {
    //if the log comes with an error_type already, don't override it
    if (event['error_type']) {
      return ![0, 504, 503, 502].includes(event.http?.status_code);
    }

    let statusCode: number;
    if (event.http?.status_code) {
      statusCode = event.http?.status_code;
    } else if (event['additional']?.['status']) {
      statusCode = event['additional']?.['status'];
    }

    if (event.status === 'error') {
      if (event.error?.origin === 'console') {
        event['error_type'] = ErrorTypes.CONSOLE_ERROR;
      }
      if (event.error?.origin === 'network') {
        event['error_type'] = ErrorTypes.NETWORK_ERROR;
      }
      if (statusCode >= 500) {
        event['error_type'] = ErrorTypes.SERVER_SIDE_ERROR;
      }
      if (statusCode >= 400 && statusCode !== 404) {
        event['error_type'] = ErrorTypes.BAD_REQUEST_ERROR;
      }
      if (statusCode === 404) {
        event['error_type'] = ErrorTypes.RESOURCE_NOT_FOUND;
      }
    }

    return ![0, 504, 503, 502].includes(event.http?.status_code);
  }
}

enum ErrorTypes {
  CONSOLE_ERROR = 'console_error',
  NETWORK_ERROR = 'network_error',
  BAD_REQUEST_ERROR = 'bad_request_error',
  SERVER_SIDE_ERROR = 'server_side_error',
  RESOURCE_NOT_FOUND = 'resource_not_found',
}
