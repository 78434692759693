import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationFacade } from '../store/application/application.facade';
import { AuthFacade } from '../store/auth/auth.facade';
import { take } from 'rxjs/operators';

@Injectable()
export class LogoutActivityInterceptor implements HttpInterceptor {
  private readonly appFacade = inject(ApplicationFacade);
  private readonly authFacade = inject(AuthFacade);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // todo(lukas.jurygacek): these static URLs in interceptors can be replaced with usage of HttpContext in HttpRequest
    const ignoredUrls = [
      'refreshtoken/start',
      'banking-core-services/v1/profile/avoInfo',
      'login/devices/current',
      'login/auth/terms',
    ];

    if (ignoredUrls.every(url => !request.url.includes(url))) {
      // todo(lukas.jurygacek): inactivity timer should be rewritten into logging activities like mouse or keyboard events (use idle frame of browser)
      this.authFacade.isTrustedDevice$
        .pipe(take(1))
        .subscribe(isTrusted => this.appFacade.resetLogoutTimer(!isTrusted));
    }

    return next.handle(request);
  }
}
