import { ActivityHistoryType } from '../../models/activity-history.model';
import { RmaType } from '../../models/rma.model';

const ecommerce = (baseUrl: string): string => `${baseUrl}/ecommerce/v2`;

export const activityHistoryOngoing = (baseUrl: string): string => `${ecommerce(baseUrl)}/order-history/search`;

export const activityHistoryDetail = (baseUrl: string, orderId: string): string =>
  `${ecommerce(baseUrl)}/order-history/order/${orderId}`;

export const rmaCancelReasons = (baseUrl: string, orderId: string, fulfilmentId: string): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/rma/cancel-reasons`;

export const rma = (baseUrl: string, orderId: string, fulfilmentId: string, type: RmaType): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/rma/${type.toLowerCase()}`;

export const fetchRmaDetail = (baseUrl: string, orderId: string, fulfilmentId: string, rmaId: string): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/rma/${rmaId}`;

export const fetchRmaList = (baseUrl: string, params: any): string =>
  `${ecommerce(baseUrl)}/order/rma?${new URLSearchParams(params).toString()}`;
