<div
  class="flex items-center gap-3 text-primary-purple"
  [class.justify-between]="subtext"
  [class.justify-center]="!subtext"
>
  <div class="flex flex-col">
    <p class="font-medium text-base">{{ text }}</p>
    <p class="mt-2 text-h6" *ngIf="subtext">{{ subtext }}</p>
  </div>

  <ui-icon [src]="icon" *ngIf="icon"></ui-icon>
</div>
