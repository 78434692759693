import { createAction, props } from '@ngrx/store';
import {
  IGroceriesAddCartCouponRequest,
  IGroceriesCartItemRequest,
  IGroceriesCartResponse,
  IGroceriesCartTimeslotResponse,
  IGroceriesPlaceOrderRequest,
  IGroceriesPlaceOrderResponse,
  IGroceriesRemoveCartItemRequest,
  IGroceriesRemoveCartPromoCodeRequest,
} from './groceries.models';
import { ITask } from '../models/store.models';

// fetch cart

export const fetchGroceriesCartRequest = createAction(
  '[GROCERIES/API] fetch cart request',
  props<{
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchGroceriesCartResponse = createAction(
  '[GROCERIES/API] fetch cart response',
  props<{
    data: IGroceriesCartResponse;
    taskMetadata: ITask;
  }>(),
);

// clear cart
export const clearCartRequest = createAction(
  '[GROCERIES/API] clear cart request',
  props<{
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const clearCartResponse = createAction(
  '[GROCERIES/API] clear cart response',
  props<{
    taskMetadata: ITask;
  }>(),
);

// add cart item
export const addCartItemRequest = createAction(
  '[GROCERIES/API] add cart item request',
  props<{
    request: IGroceriesCartItemRequest;
    onSuccess: (response: IGroceriesCartResponse) => void;
    onError: (error: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const addCartItemResponse = createAction(
  '[GROCERIES/API] add cart item response',
  props<{
    data: IGroceriesCartResponse;
    taskMetadata: ITask;
  }>(),
);

// add cart item
export const updateCartItemRequest = createAction(
  '[GROCERIES/API] update cart item request',
  props<{
    request: IGroceriesCartItemRequest;
    onSuccess: (response: IGroceriesCartResponse) => void;
    onError: (error: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const updateCartItemResponse = createAction(
  '[GROCERIES/API] update cart item response',
  props<{
    data: IGroceriesCartResponse;
    taskMetadata: ITask;
  }>(),
);

// remove cart item
export const removeCartItemRequest = createAction(
  '[GROCERIES/API] remove cart item request',
  props<{
    request: IGroceriesRemoveCartItemRequest;
    onSuccess: (response: IGroceriesCartResponse) => void;
    onError: (error: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const removeCartItemResponse = createAction(
  '[GROCERIES/API] remove cart item response',
  props<{
    data: IGroceriesCartResponse;
    taskMetadata: ITask;
  }>(),
);

// add cart promo code
export const addCartPromoCodeRequest = createAction(
  '[GROCERIES/API] add cart promo code request',
  props<{
    request: IGroceriesAddCartCouponRequest;
    onSuccess?: (response: IGroceriesCartResponse) => void;
    onError?: (error: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const addCartPromoCodeResponse = createAction(
  '[GROCERIES/API] add cart promo code response',
  props<{
    data: IGroceriesCartResponse;
    taskMetadata: ITask;
  }>(),
);

// remove cart promo code
export const removeCartPromoCodeRequest = createAction(
  '[GROCERIES/API] remove cart promo code request',
  props<{
    request: IGroceriesRemoveCartPromoCodeRequest;
    onSuccess?: (response: IGroceriesCartResponse) => void;
    onError?: (error: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const removeCartPromoCodeResponse = createAction(
  '[GROCERIES/API] remove cart promo code response',
  props<{
    data: IGroceriesCartResponse;
    taskMetadata: ITask;
  }>(),
);

// fetch cart timeslots

export const fetchCartTimeslotsRequest = createAction(
  '[GROCERIES/API] fetch cart timeslots request',
  props<{
    onSuccess?: (response: IGroceriesCartTimeslotResponse) => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const fetchCartTimeslotsResponse = createAction(
  '[GROCERIES/API] fetch cart timeslots response',
  props<{
    taskMetadata: ITask;
  }>(),
);

// place cart order
export const placeCartOrderRequest = createAction(
  '[GROCERIES/API] place cart order request',
  props<{
    request: IGroceriesPlaceOrderRequest;
    onSuccess?: (response: IGroceriesPlaceOrderResponse) => void;
    onError?: (error: any) => void;
    taskMetadata: ITask;
  }>(),
);

export const placeCartOrderResponse = createAction(
  '[GROCERIES/API] place cart order response',
  props<{
    taskMetadata: ITask;
  }>(),
);
