/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { bindCallback, map, Observable, shareReplay, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@avo/environment/customer/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsPredictionService {
  private autocompleteService: google.maps.places.AutocompleteService;
  private geocoder: google.maps.Geocoder;

  constructor(private httpClient: HttpClient) {}

  mapsApi$ = this.httpClient
    .jsonp(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.googleMapApiKey}`, 'callback')
    .pipe(
      map(() => {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();
        return true;
      }),
      shareReplay(1),
    );

  getPlaceByQuery(query: string): Observable<any> {
    const request: google.maps.places.AutocompletionRequest = {
      input: query,
      componentRestrictions: { country: ['ZA', 'NA'] },
    };

    return this.callMapsApi((callback: any) => this.autocompleteService.getPlacePredictions(request, callback));
  }

  getPlaceByAddress(address: string): Observable<any> {
    const request: google.maps.GeocoderRequest = {
      address,
    };

    return this.callMapsApi((callback: any) => this.geocoder.geocode(request, callback));
  }

  getPlaceByCoordinates(coordinates: google.maps.LatLng): Observable<any> {
    const request: google.maps.GeocoderRequest = {
      location: coordinates,
    };

    return this.callMapsApi((callback: any) => this.geocoder.geocode(request, callback));
  }

  private callMapsApi(callback: any): Observable<unknown> {
    if (!this.geocoder) {
      return this.mapsApi$.pipe(switchMap(() => bindCallback(callback)()));
    } else {
      return bindCallback(callback)();
    }
  }
}
