import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as ApplicationActions from './application.actions';
import * as ApplicationSelectors from './application.selectors';
import { ISearchOptions } from '../../model/core.model';
import { IErrorResponse, ISearchInsightsRequest, TaskType } from '../models/store.models';
import { IAvoInfoDTO, ILocationDTO } from '../../model/shared.model';
import { HttpErrorResponse } from '@angular/common/http';
import { v4 } from 'uuid';
import { IUiExitScreenDto } from '@avo/shared/ui/modal';

@Injectable({ providedIn: 'root' })
export class ApplicationFacade {
  getMaintenance$ = this.store.pipe(select(ApplicationSelectors.getMaintenance));
  isAuthorized$ = this.store.pipe(select(ApplicationSelectors.isAuthorized));
  getCurrentLocation$ = this.store.pipe(select(ApplicationSelectors.getCurrentLocation));
  getAvoInfo$ = this.store.pipe(select(ApplicationSelectors.getAvoInfo));
  getCountryCode$ = this.store.pipe(select(ApplicationSelectors.getCountryCode));
  getDiscoverAvoCategories$ = this.store.pipe(select(ApplicationSelectors.getDiscoverAvoCategories));
  isLimitedUser$ = this.store.pipe(select(ApplicationSelectors.isLimitedUser));
  hasLocationAvailable$ = this.store.pipe(select(ApplicationSelectors.hasLocationAvailable));
  getReturnUrl$ = this.store.pipe(select(ApplicationSelectors.getReturnUrl));
  getTermAgreementFailureUrl$ = this.store.pipe(select(ApplicationSelectors.getTermAgreementFailureUrl));
  getOrderId$ = this.store.pipe(select(ApplicationSelectors.getOrderId));
  getFuneralReturnUrl$ = this.store.pipe(select(ApplicationSelectors.getfuneralReturnUrl));
  hasPageVisited$ = this.store.pipe(select(ApplicationSelectors.hasPageVisited));

  getSearchOptions$ = this.store.pipe(select(ApplicationSelectors.getSearchOptions));
  getPrimersRedirectFinished$ = this.store.pipe(select(ApplicationSelectors.getPrimersRedirectFinished));
  isTravelDialogOpen$ = this.store.pipe(select(ApplicationSelectors.isTravelDialogOpen));
  searchTerm$ = this.store.pipe(select(ApplicationSelectors.searchTerm));
  searchInputInFocus$ = this.store.pipe(select(ApplicationSelectors.searchInputInFocus));
  searchVerticalInFocus$ = this.store.pipe(select(ApplicationSelectors.searchVerticalInFocus));
  searchVertical$ = this.store.pipe(select(ApplicationSelectors.searchVertical));
  cartComponentInitialized$ = this.store.pipe(select(ApplicationSelectors.cartComponentInitialized));
  constructor(private readonly store: Store) {}

  setTermAgreementFailureUrl(url: string) {
    this.store.dispatch(ApplicationActions.setTermAgreementFailureUrl({ url }));
  }

  setOrderId(orderId: string) {
    this.store.dispatch(ApplicationActions.setOrderId({ orderId }));
  }

  checkMaintenance() {
    this.store.dispatch(ApplicationActions.checkMaintenanceRequest());
  }

  clearAllErrors() {
    this.store.dispatch(ApplicationActions.clearAllErrorsAction());
  }

  setCurrentLocation(data: ILocationDTO) {
    this.store.dispatch(ApplicationActions.setCurrentLocation({ data }));
  }

  setAvoInfo(data: IAvoInfoDTO) {
    this.store.dispatch(ApplicationActions.setAvoInfo({ data }));
  }

  setAuthorizationStatus(isAuthorized: boolean) {
    this.store.dispatch(ApplicationActions.setAuthorizationStatus({ isAuthorized }));
  }

  setTravelDialogOpen(isTravelDialogOpen: boolean) {
    this.store.dispatch(ApplicationActions.setIsTravelDialogOpen({ isTravelDialogOpen }));
  }

  setCartComponentInitialized(cartComponentInitialized: boolean) {
    this.store.dispatch(ApplicationActions.setCartComponentInitialized({ cartComponentInitialized }));
  }
  setSearchVertical(searchVertical: string) {
    this.store.dispatch(ApplicationActions.setSearchVertical({ searchVertical }));
  }

  setSearchTerm(searchTerm: string) {
    this.store.dispatch(ApplicationActions.setSearchTerm({ searchTerm }));
  }

  setSearchInputInFocus(searchInputInFocus: boolean) {
    this.store.dispatch(ApplicationActions.setSearchInputInFocus({ searchInputInFocus }));
  }

  setSearchVerticalInFocus(searchVerticalInFocus: boolean) {
    this.store.dispatch(ApplicationActions.setSearchVerticalInFocus({ searchVerticalInFocus }));
  }

  setReturnUrl(value: string) {
    this.store.dispatch(ApplicationActions.setReturnUrl({ value }));
  }

  setfuneralReturnUrl(value: string) {
    this.store.dispatch(ApplicationActions.setfuneralReturnUrl({ value }));
  }

  setHasPageVisited(value: boolean) {
    this.store.dispatch(ApplicationActions.setHasPageVisited({ value }));
  }

  setHasLocationAvailable(data: boolean) {
    this.store.dispatch(ApplicationActions.setHasLocationAvailable({ data }));
  }

  resetLogoutTimer(start: boolean) {
    this.store.dispatch(ApplicationActions.resetLogoutTimer({ start }));
  }

  setSearchOptions(searchOptions: ISearchOptions) {
    this.store.dispatch(ApplicationActions.searchOptions({ searchOptions }));
  }

  addError(error: IErrorResponse & HttpErrorResponse) {
    this.store.dispatch(ApplicationActions.httpErrorAction({ error, data: { type: TaskType.FAILED } }));
  }

  fetchCountryCode() {
    this.store.dispatch(
      ApplicationActions.fetchCurrentCountryRequestAction({ taskMetadata: { type: TaskType.STARTED, taskId: v4() } }),
    );
  }

  setCountryCode(countryCode: string) {
    this.store.dispatch(ApplicationActions.setCountryCodeAction({ countryCode }));
  }

  fetchDiscoverAvoCategories(): void {
    this.store.dispatch(
      ApplicationActions.fetchDiscoverAvoCategoriesRequest({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchExitScreen(screenId: string, onSucceed: (data: IUiExitScreenDto) => void): void {
    this.store.dispatch(
      ApplicationActions.fetchExitScreenRequest({
        screenId,
        onSucceed,
        taskMetadata: { type: TaskType.STARTED_FULLSCREEN, taskId: v4() },
      }),
    );
  }

  setPrimersRedirectFinished(value: boolean) {
    this.store.dispatch(ApplicationActions.setPrimersRedirectFinished({ value }));
  }

  sendSearchCategoryInsights(request: ISearchInsightsRequest) {
    this.store.dispatch(
      ApplicationActions.sendSearchCategoryInsightsRequestAction({
        request,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }
}
