import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ApplicationFacade } from './store/application/application.facade';
import { LocationFacade } from './store/location/location.facade';
import { LinkFormatPipe } from './pipes/link-format.pipe';
import { HiddenForDirective } from './directives/hidden-for.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { environment } from '@avo/environment/customer/environment';
import { FacebookLoginProvider } from './providers/facebook-login.provider';
import { AppleLoginProvider } from './providers/apple-login.provider';
import { DeviceIdInterceptor } from './interceptors/device-id.interceptor';
import { ExternalComponent } from './components/external/external.component';
import { AfterIfDirective } from './directives/after-if.directive';
import { SessionIdInterceptor } from './interceptors/session-id.interceptor';
import { LocationInterceptor } from './interceptors/location.interceptor';
import { TransactionSigningInterceptor } from './interceptors/transaction-signing.interceptor';
import { SecureImagePipe } from './pipes/secure-image.pipe';
import { ApiVersionInterceptor } from './interceptors/api-version.interceptor';
import { LogoutActivityInterceptor } from './interceptors/logout-activity.interceptor';
import { OrderDatePipe } from './pipes/order-date.pipe';
import player from 'lottie-web/build/player/lottie_svg';
import { LottieModule } from 'ngx-lottie';
import { ExpireDatePipe } from './pipes/expire-date.pipe';
import { KilometersFormatPipe } from './pipes/kilometers-format.pipe';
import { ViewportDetectorDirective } from './directives/viewport-detector.directive';
import { LandingInputLabelPipe } from './pipes/landing-input-label.pipe';
import { ResizeImageUrlPipe } from './pipes/resize-image-url.pipe';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { PhoneTransformPipe } from './pipes/phone-transform.pipe';
import { CustomRouterLinkDirective } from './directives/custom-router-link.directive';
import { LimitPipe } from './pipes/limit.pipe';
import { IdempotencyInterceptor } from './interceptors/idempotency.interceptor';
import { CmpidInterceptor } from './interceptors/cmpid.interceptor';
import { CountryCodeInterceptor } from './interceptors/country-code.interceptor';
import { CanaryInterceptor } from './interceptors/canary.interceptor';
import { GetSafeHTMLPipe } from './pipes/get-safe-html.pipe';
import { TasksFacade } from './store/tasks/tasks.facade';
import { TransactionsFacade } from './store/transactions/transactions.facade';
import { LayoutFacade } from './store/layout/layout.facade';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import { AppBuildIdInterceptor } from './interceptors/app-build-id.interceptor';
import { AvoTranslateModule } from '@avo/shared/avo-translate';
import { AdobeErrorInterceptor } from '@avo/shared/adobe-analytics';
import { AvoCurrencyModule } from '@avo/shared/avo-currency';
import { ResponsiveService } from './services/responsive.service';
import { FileSizePipe } from './pipes/file-size.pipe';
import { LoggerInterceptor } from './interceptors/logger.interceptor';

// We need a seperate function as it's required by the AOT compliler.
function playerFactory() {
  return player;
}
// force update 20

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AvoTranslateModule,
    AvoCurrencyModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    ApplicationFacade,
    LocationFacade,
    TasksFacade,
    TransactionsFacade,
    LayoutFacade,
    LinkFormatPipe,
    HiddenForDirective,
    ClickOutsideDirective,
    CustomRouterLinkDirective,
    SocialAuthService,
    { provide: HTTP_INTERCEPTORS, useClass: CmpidInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TransactionSigningInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogoutActivityInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IdempotencyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CountryCodeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CanaryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppBuildIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AdobeErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggerInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, {
              prompt: 'none',
              oneTapEnabled: false,
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId),
          },
          {
            id: AppleLoginProvider.PROVIDER_ID,
            provider: new AppleLoginProvider(environment.appleClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    CurrencyPipe,
    ResizeImageUrlPipe,
    LimitPipe,
    GetSafeHTMLPipe,
    ResponsiveService,
  ],
  exports: [
    LinkFormatPipe,
    HiddenForDirective,
    ClickOutsideDirective,
    ExternalComponent,
    AfterIfDirective,
    SecureImagePipe,
    OrderDatePipe,
    ExpireDatePipe,
    KilometersFormatPipe,
    ViewportDetectorDirective,
    LandingInputLabelPipe,
    ResizeImageUrlPipe,
    PhoneTransformPipe,
    CustomRouterLinkDirective,
    LimitPipe,
    GetSafeHTMLPipe,
    PreventDoubleClickDirective,
    FileSizePipe,
  ],
  declarations: [
    LinkFormatPipe,
    HiddenForDirective,
    ClickOutsideDirective,
    ExternalComponent,
    AfterIfDirective,
    SecureImagePipe,
    OrderDatePipe,
    ExpireDatePipe,
    KilometersFormatPipe,
    ViewportDetectorDirective,
    LandingInputLabelPipe,
    ResizeImageUrlPipe,
    PhoneTransformPipe,
    CustomRouterLinkDirective,
    LimitPipe,
    GetSafeHTMLPipe,
    PreventDoubleClickDirective,
    FileSizePipe,
  ],
})
export class SharedCoreModule {}
