export interface IImage {
  id: string;
  date?: string;
  url: string;
  Title?: string;
}

export interface IFileUpload {
  name: string;
  data: File;
}

export interface IImageFileResponse {
  id: string;
  url: string;
}

export interface IImageExtended {
  id?: string;
  url?: string;
  name?: string;
  size?: number;
  uploaded?: number;
  error?: boolean;
}

export interface IImageDto {
  id: string;
  type: IImageDtoTypeEnum;
  url: string;
}

export enum IImageDtoTypeEnum {
  LARGE,
  THUMBNAIL,
  ICON,
}
