import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../init/app.config';
import { ISearchInsightsRequest } from '../store/models/store.models';

@Injectable({
  providedIn: 'root',
})
export class SearchInsightsService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  sendSearchCategoryInsights = (request: ISearchInsightsRequest) =>
    this.httpClient.post(`${this.appConfig.apiV3Url}/search/insights/category`, request);
}
