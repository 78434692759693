export const onboardingV2 = (baseUrl: string): string => `${baseUrl}/users/v2/onboarding`;

export const start = (baseUrl: string): string => `${baseUrl}/api/public/login/v1/start`;
export const startSocial = (baseUrl: string): string => `${baseUrl}/users/v1/autologon/start/social`;
export const loginMobimoney = (baseUrl: string): string => `${baseUrl}/users/v1/autologon/start/mobipin`;
export const loginNedid = (baseUrl: string): string => `${baseUrl}/users/v1/autologon/start/nedid`;
export const authenticate = (baseUrl: string): string => `${baseUrl}/api/public/login/v1/authenticate`;
export const onboardingStart = (baseUrl: string): string => `${baseUrl}/onboarding/start`;
export const onboardingStepup = (baseUrl: string): string => `${baseUrl}/onboarding/stepUp`;
export const onboardingHealtStepup = (baseUrl: string): string => `${baseUrl}/onboarding/healthStepUp`;
export const onboardingSelectOtp = (baseUrl: string): string => `${onboardingV2(baseUrl)}/selectOtp`;
export const onboardingSelectSocial = (baseUrl: string): string => `${onboardingV2(baseUrl)}/selectSocial`;
export const onboardingSelectNedbank = (baseUrl: string): string => `${onboardingV2(baseUrl)}/selectNedbank`;
export const onboardingSignup = (baseUrl: string): string => `${onboardingV2(baseUrl)}/signup`;
export const onboardingSignupManual = (baseUrl: string): string => `${onboardingV2(baseUrl)}/signup/manual`;
export const onboardingSignupSocial = (baseUrl: string): string => `${onboardingV2(baseUrl)}/signup/social`;
export const onboardingSignupNedbank = (baseUrl: string): string => `${onboardingV2(baseUrl)}/signup/nedbank`;
export const onboardingLogin = (baseUrl: string): string => `${baseUrl}/onboarding/login`;
export const onboardingCheckBank = (baseUrl: string, merchantId?: string): string =>
  `${baseUrl}/onboarding${merchantId ? `/${merchantId}` : ''}/checkBank`;
export const onboardingCheckLogin = (baseUrl: string): string => `${baseUrl}/onboarding/checkLogin`;
export const onboardingAddMerchant = (baseUrl: string): string => `${baseUrl}/onboarding/addMerchant`;
export const onboardingStartInfo = (baseUrl: string): string => `${baseUrl}/onboarding/startInfo`;
export const onboardingStartMoneyApp = (baseUrl: string): string => `${baseUrl}/onboarding/start/moneyapp`;
export const onboardingStartMoneyApp2 = (baseUrl: string): string => `${baseUrl}/autologon/start/moneyapp`;
export const onboardingStartMoneyAppAsync = (baseUrl: string): string => `${baseUrl}/autologon/start/moneyapp`;
export const onboardingBankInfo = (baseUrl: string): string => `${onboardingV2(baseUrl)}/customer/bankInfo`;
export const onboardingAvoInfo = (baseUrl: string): string => `${baseUrl}/banking-core-services/v1/profile/avoInfo`;
export const editPersonalDetailsEmail = (baseUrl: string): string => `${baseUrl}/profile/v1/personal-details/email`;
export const onboardingStartSocial = (baseUrl: string): string => `${baseUrl}/onboarding/start/social`;
export const onboardingStartManual = (baseUrl: string): string => `${baseUrl}/onboarding/start/manual`;
export const onboardingOtp = (baseUrl: string): string => `${baseUrl}/onboarding/otp`;
export const onboardingExternalAuth = (baseUrl: string): string => `${baseUrl}/onboarding/externalAuth`;
export const onboardingPersonalData = (baseUrl: string): string => `${baseUrl}/onboarding/personalData`;
export const createUsernamePassword = (baseUrl: string): string => `${baseUrl}/onboarding/setLogin`;
export const createSocialUsernamePassword = (baseUrl: string): string => `${baseUrl}/onboarding/login`;
export const suggestedUsernames = (baseUrl: string, onboardingId: string): string =>
  `${baseUrl}/onboarding/setLogin/suggest?onboardingId=${onboardingId}`;
export const onboardingBusinessDetails = (baseUrl: string): string => `${baseUrl}/onboarding/businessDetails`;
export const onboardingBusinessAddress = (baseUrl: string): string => `${baseUrl}/onboarding/businessAddress`;
export const onboardingBusinessPartners = (baseUrl: string): string => `${baseUrl}/onboarding/businessPartner`;
export const onboardingBusinessCategoryInfo = (baseUrl: string): string => `${baseUrl}/onboarding/businessCategory`;
export const onboardingSuccess = (baseUrl: string): string => `${baseUrl}/onboarding/success`;
export const onboardingAddress = (baseUrl: string): string => `${baseUrl}/onboarding/address`;
export const onboardingMobiMoney = (baseUrl: string): string => `${baseUrl}/onboarding/mobimoney`;
export const onboardingIdPassport = (baseUrl: string): string => `${baseUrl}/onboarding/idpassportNumber`;
export const onboardingLegalEntity = (baseUrl: string): string => `${baseUrl}/onboarding/legalEntity`;
export const onboardingBusinessLegalEntity = (baseUrl: string): string => `${baseUrl}/onboarding/businessLegalEntity`;
export const onboardingBasicData = (baseUrl: string): string => `${baseUrl}/onboarding/basicData`;
export const onboardingOutstandingTcs = (baseUrl: string): string => `${baseUrl}/onboarding/outstandingtcs`;
export const onboardingTcs = (baseUrl: string): string => `${baseUrl}/onboarding/tcs`;
export const onboardingLinkAccount = (baseUrl: string): string => `${baseUrl}/onboarding/linkAccount`;
export const onboardingStartLinkAccount = (baseUrl: string): string => `${baseUrl}/onboarding/startLinkAccount`;
export const onboardingInitiateLinkAccount = (baseUrl: string): string => `${baseUrl}/onboarding/initiateLinkAccount`;
export const onboardingBusinessBasicData = (baseUrl: string): string => `${baseUrl}/onboarding/businessBasicData`;
export const onboardingCountries = (baseUrl: string): string => `${baseUrl}/ref-data/countries`;
export const onboardingAddressSuggestion = (query: string, baseUrl: string): string =>
  `${baseUrl}/onboarding/address/suggest?suburb=${query}`;
export const onboardingSecurityQuestions = (baseUrl: string): string => `${baseUrl}/onboarding/securityQuestions`;
export const onboardingFirstNameLastNameAuth = (baseUrl: string): string =>
  `${baseUrl}/onboarding/firstNameLastNameAuth`;
export const onboardingBusinessTradingName = (baseUrl: string): string => `${baseUrl}/onboarding/businessTradingName`;
export const onboardingApproveIt = (baseUrl: string): string => `${baseUrl}/onboarding/approveit`;
export const onboardingProgress = (baseUrl: string): string => `${baseUrl}/onboarding/progress`;
export const onboardingSentApproveIt = (baseUrl: string): string => `${baseUrl}/onboarding/sentapproveit`;
export const onboardingBankOtp = (baseUrl: string): string => `${baseUrl}/onboarding/bankotp`;
export const onboardingIdPhoto = (baseUrl: string): string => `${baseUrl}/onboarding/idPhoto`;
export const onboardingBankphoneDifferent = (baseUrl: string): string => `${baseUrl}/onboarding/bankphoneDifferent`;
export const postCheck = (baseUrl: string): string => `${baseUrl}/onboarding/postCheck`;
export const transactionSigningStart = (transactionAuthId: string, baseUrl: string): string =>
  `${baseUrl}/transaction/${transactionAuthId}/start`;
export const transactionSigningAuthorize = (transactionAuthId: string, baseUrl: string): string =>
  `${baseUrl}/transaction/${transactionAuthId}/authorize`;
export const transactionSigningCancel = (transactionAuthId: string, baseUrl: string): string =>
  `${baseUrl}/transaction/${transactionAuthId}`;
export const getReferralDetails = (baseUrl: string, code: string): string => `${baseUrl}/referral/${code}`;
export const transactionTest = (baseUrl: string): string => `${baseUrl}/dashboard/v1/txsign`;
export const guestLogin = (baseUrl: string) => `${baseUrl}/onboarding/socialBuy`;
export const onboardingInfoStepup = (baseUrl: string): string => `${baseUrl}/onboarding/infoStepUp`;

//v3
export const passwordlessStart = (baseUrl: string): string => `${baseUrl}/login/passwordless/start`;
export const loginOtp = (baseUrl: string): string => `${baseUrl}/login/otp`;
export const loginOtpResend = (baseUrl: string): string => `${baseUrl}/login/otp/resend`;
export const loginRefreshToken = (baseUrl: string): string => `${baseUrl}/login/refreshtoken/start`;
export const loginProfile = (baseUrl: string): string => `${baseUrl}/login/profile`;
export const loginGoogle = (baseUrl: string): string => `${baseUrl}/login/google/start`;
export const loginFacebook = (baseUrl: string): string => `${baseUrl}/login/facebook/start`;
export const loginApple = (baseUrl: string): string => `${baseUrl}/login/apple/start`;
