import { Injectable } from '@angular/core';
import { WebsocketsFacade } from '../../redux/websockets/websockets.facade';
import { Observable, Subject } from 'rxjs';
import {
  IWebsocketKeepAliveStatus,
  IWebsocketMessageEvent,
  IWebsocketPing,
  IWebsocketTokenResponse,
} from '../../models/websocket.model';
import { AppConfig } from '../../configs/app.config';
import { ApiService } from '../api/api.service';
import get from 'lodash-es/get';
import { AuthorizationService } from '../authorization/authorization.service';
import { ChatMessageType, ChatSendMessageId, IChatSendMessage } from '../../models/chat.model';
import { TimerService } from '../timer/timer.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private wssEndpoint: string;
  private hasToken: boolean;

  webSocket: WebSocket;
  webSocketKeepAliveStatus: IWebsocketKeepAliveStatus;
  getMessages$ = new Subject<IWebsocketMessageEvent>();
  messages: IWebsocketMessageEvent;
  reconnectTimer: TimerService;
  pingPongSendTimer: TimerService;
  pongCheckTimer: TimerService;

  constructor(
    private api: ApiService,
    private wssFacade: WebsocketsFacade,
    private appConfig: AppConfig,
    private authService: AuthorizationService,
  ) {
    /**
     * will execute connect function on every endpoint change
     */
    this.wssFacade.getEndpointWithToken$.subscribe(wssEpWithToken => {
      if (!wssEpWithToken) return;
      this.hasToken = !!wssEpWithToken;
      this.connect(wssEpWithToken);
    });
  }

  public fetchToken = (): Observable<IWebsocketTokenResponse> => {
    const baseUrl = get(this.appConfig, ['backendConfig', 'apiUrl']);
    return this.api.get<IWebsocketTokenResponse>(`${baseUrl}/chat-v4/v1/ws/token`);
  };

  private connect = (wssEpWithToken: string): void => {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      return;
    }
    this.webSocket = new WebSocket(wssEpWithToken);
    this.webSocket.onopen = this.onOpen;
  };

  private onOpen = (): void => {
    this.webSocket.onmessage = this.onMessage;
    this.webSocket.onclose = this.onClose;
    this.webSocket.onerror = this.onError;
    this.webSocketKeepAliveStatus = {
      createSocketAt: Date.now().toString(),
      durationInMs: 0,
      countReconnect: 0,
      refreshWsInMs: 6000000, // 100mins
      pongCheckInMs: 5000,
      pingPongInMs: 300000, // 5mins
    };
    this.reconnectTimer = new TimerService();
    this.reconnectTimer.start({
      timer: this.webSocketKeepAliveStatus.refreshWsInMs,
      callback: this.refresh,
    });

    this.pingPongSendTimer = new TimerService();
    this.pingPongSendTimer.start({
      timer: this.webSocketKeepAliveStatus.pingPongInMs,
      callback: this.ping,
    });
  };

  private onError = event => {
    console.error('web socket error', event);
    this.refresh();
  };

  private onMessage = (event: MessageEvent): void => {
    if (event && event.data) {
      this.messages = JSON.parse(event.data);
      this.getMessages$.next(Object.assign({}, this.messages));
    }
  };

  private onClose = (): void => {
    if (this.reconnectTimer) {
      this.reconnectTimer.stop();
      this.reconnectTimer = null;
    }

    if (this.pingPongSendTimer) {
      this.pingPongSendTimer.stop();
      this.pingPongSendTimer = null;
    }
    this.hasToken = false;
    this.webSocket = undefined;
  };

  /**
   * will initialize whole process
   * fetch token in first time and run connect in the second
   */
  init = (): void => {
    if (this.hasToken) return;
    const websocketUrl = get(this.appConfig, 'backendConfig.websocketUrl');
    if (!websocketUrl) return;
    this.wssEndpoint = websocketUrl;
  };

  sendMessage = (msg: IChatSendMessage): void => {
    if (!this.webSocket || this.webSocket?.readyState !== WebSocket.OPEN) return;

    if (!msg?.id) return;

    const message = JSON.stringify(msg, null, 4);

    this.webSocket.send(message);
  };

  /**
   * closes the connection by completing the subject
   */

  closeConnection = (): void => {
    this.webSocket?.close();
  };

  ping = (): void => {
    const ping: IWebsocketPing = {
      pingId: uuidv4(),
      pingSentTime: Date.now().toString(),
    };
    this.webSocketKeepAliveStatus.ping = ping;
    const pingMessage: IChatSendMessage = {
      id: ChatSendMessageId.SENDMESSAGE,
      message: {
        content: {
          msgtype: ChatMessageType.ping,
          body: this.webSocketKeepAliveStatus.ping.pingId,
        },
        roomId: uuidv4(),
      },
    };
    this.sendMessage(pingMessage);
    this.pongCheckTimer = new TimerService();
    this.pongCheckTimer.start({ timer: this.webSocketKeepAliveStatus.pongCheckInMs, callback: this.checkPong });
  };

  refresh = (): void => {
    this.closeConnection();
    this.webSocketKeepAliveStatus.countReconnect++;
  };

  checkPong = (): void => {
    this.pongCheckTimer.stop();
    this.pongCheckTimer = null;

    if (
      this.messages?.content &&
      JSON.parse(this.messages.content)?.body === this.webSocketKeepAliveStatus?.ping?.pingId
    ) {
      return;
    }

    this.refresh();
  };
}
