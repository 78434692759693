import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import { WalletService } from '../../services/wallet/wallet.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './wallet.actions';
import * as EVENT from './wallet.events';

@Injectable()
export class MyMoneyWalletEffects {
  fetchMyMoneyWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyWalletsRequestAction>(EVENT.WALLETS_REQUEST),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.service.fetchWallet().pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyWalletsResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error, true)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPaymentCarouselEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchPaymentCarouselRequestAction>(EVENT.FETCH_CAROUSEL_REQUESTED),

      switchMap(({ taskId }) =>
        this.service.fetchPaymentCarousel().pipe(
          map(response => succeed(new actions.FetchPaymentCarouselResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchAddCardEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchAddPaymentCardRequestAction>(EVENT.ADD_PAYMENT_CARD_REQUESTED),
      switchMap(({ onSucceed, taskId }) =>
        this.service.fetchAddCard().pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.FetchAddPaymentCardResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchActivePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchPaymentMethodActiveRequestAction>(EVENT.FETCH_ACTIVE_PAYMENT_METHODS_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchPaymentMethodActive().pipe(
          map(response => {
            return succeed(new actions.FetchPaymentMethodActiveResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  deletePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DeletePaymentMethodRequestAction>(EVENT.DELETE_PAYMENT_METHOD_REQUEST),
      switchMap(({ taskId, paymentMethodId, onSucceed }) =>
        this.service.deletePaymentMethod(paymentMethodId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.DeletePaymentMethodResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  editPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.editPaymentMethodRequestAction>(EVENT.EDIT_PAYMENT_METHOD_NAME_REQUESTED),
      switchMap(({ taskId, paymentMethodId, request, onSucceed }) =>
        this.service.editPaymentMethod(paymentMethodId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.editPaymentMethodResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  editPaymentMethodDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.editPaymentMethodDefaultRequestAction>(EVENT.EDIT_PAYMENT_METHOD_DEFAULT_REQUESTED),
      switchMap(({ taskId, paymentMethodId, request, onSucceed }) =>
        this.service.changeDefaultPaymentMethod(paymentMethodId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.editPaymentMethodDefaultResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  editPaymentMethodName$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.editPaymentMethodNameRequestAction>(EVENT.EDIT_PAYMENT_METHOD_NAME_REQUESTED),
      switchMap(({ taskId, paymentMethodId, request, onSucceed }) =>
        this.service.editPaymentMethodName(paymentMethodId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.editPaymentMethodNameResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchAddPaymentMethodBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchPaymentMethodBalanceRequestAction>(EVENT.PAYMENT_METHOD_BALANCE_REQUESTED),
      switchMap(({ taskId, paymentMethodId, onSucceed }) =>
        this.service.fetchPaymentMethodBalance(paymentMethodId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.FetchPaymentMethodBalanceResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  processAddCardEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ProcessAddCardRequestAction>(EVENT.PROCESS_ADD_CARD_REQUESTED),
      switchMap(({ paymentFlowId, taskId, onSucceed }) =>
        this.service.processAddCard(paymentFlowId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ProcessAddCardResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTransactionsRequestAction>(EVENT.FETCH_TRANSACTIONS_REQUESTED),
      switchMap(({ limit, taskId }) =>
        this.service.fetchPaymentMethodTransactions(limit).pipe(
          map(response => succeed(new actions.FetchTransactionsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTransactionsFiltered$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTransactionsFilteredRequestAction>(EVENT.FETCH_TRANSACTIONS_FILTERED_REQUESTED),
      switchMap(({ page, pageSize, filter, onSucceed, taskId }) =>
        this.service.fetchPaymentMethodTransactionsFiltered(page, pageSize, filter).pipe(
          map(response => {
            onSucceed(response);
            succeed(new actions.FetchTransactionsFilteredResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
  fetchPartyAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPartyAccountsRequestAction>(EVENT.PARTY_ACCOUNTS_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.fetchPartyAccounts().pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPartyAccountsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  nedbankBanks$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyNedbankBanksRequestAction>(EVENT.WALLET_NEDBANK_BANKS_REQUESTED),
      switchMap(({ taskId }) =>
        this.service.nedbankBanks().pipe(
          map(response => succeed(new actions.MyMoneyNedbankBanksResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  filterSettlementsExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FilterSettlementsExportRequestAction>(EVENT.FILTER_SETTLEMENTS_EXPORT_REQUESTED),
      switchMap(({ taskId, filter, storeId, onSucceed }) =>
        this.service.filterSettlementsExport(filter, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FilterSettlementsExportResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  filterTransactionsExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FilterTransactionsExportRequestAction>(EVENT.FILTER_TRANSACTIONS_EXPORT_REQUESTED),
      switchMap(({ taskId, filter, storeId, onSucceed }) =>
        this.service.filterTransactionsExport(filter, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FilterTransactionsExportResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTaskFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTaskFileRequestAction>(EVENT.FETCH_TASK_FILE_REQUESTED),
      switchMap(({ taskId, fetchTaskId, onSucceed }) =>
        this.service.fetchTaskFile(fetchTaskId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FetchTaskFileResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTaskDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTaskDetailRequestAction>(EVENT.FETCH_TASK_DETAIL_REQUESTED),
      switchMap(({ taskId, fetchTaskId, onSucceed }) =>
        this.service.fetchTaskDetail(fetchTaskId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FetchTaskDetailResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  settlementTransactionFilter = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FilterPayoutTransactionsRequestAction>(EVENT.FILTER_SETTLEMENT_TRANSACTION_REQUESTED),
      switchMap(({ taskId, storeId, filter, pageSize, next, onSucceed, onError }) =>
        this.service.filterPayoutTransactions(storeId, filter, pageSize, next).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FilterPayoutTransactionsResponseAction(taskId, response));
          }),
          catchError(error => {
            if (error?.error?.error?.code === 'unknown_error') {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  generatePaymentRemittance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GeneratePaymentRemittanceRequestAction>(EVENT.GENERATE_PAYMENT_REMITTANCE_REQUESTED),
      switchMap(({ taskId, filter, storeId, onSucceed, onError }) =>
        this.service.generatePaymentRemittance(storeId, filter).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.GeneratePaymentRemittanceResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTaxInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTaxInvoicesRequestAction>(EVENT.FETCH_TAX_INVOICES_REQUESTED),
      switchMap(({ taskId, filter, storeId, pageSize, page, onSucceed, onError }) =>
        this.service.fetchTaxInvoices(filter, storeId, pageSize, page).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FetchTaxInvoicesResponseAction(taskId, response));
          }),
          catchError(error => {
            if (error?.error?.error?.code === 'unknown_error') {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  downloadTaxInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DownloadTaxInvoiceRequestAction>(EVENT.DOWNLOAD_TAX_INVOICE_REQUESTED),
      switchMap(({ taskId, taxInvoiceId, storeId, onSucceed }) =>
        this.service.downloadTaxInvoice(taxInvoiceId, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.DownloadTaxInvoiceResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchSellerStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchSellerStatementRequest>(EVENT.STATEMENTS_FETCH_SELLER_STATEMENT_REQUEST),
      mergeMap(({ taskId, request, storeId, page, pageSize, onError }) =>
        this.service.sellerStatement(request, storeId, pageSize, page).pipe(
          map(data => succeed(new actions.FetchSellerStatementReceived(taskId, data))),
          catchError(error => {
            if (error?.error?.error?.code === 'unknown_error') {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  downloadSellerStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DownloadSellerStatementRequestAction>(EVENT.STATEMENTS_DOWNLOAD_SELLER_STATEMENT_REQUEST),
      switchMap(({ taskId, statementNumber, storeId, onSucceed }) =>
        this.service.downloadSellerStatement(statementNumber, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.DownloadSellerStatementResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  generateSellerStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GenerateSellerStatementRequest>(EVENT.STATEMENTS_GENERATE_SELLER_STATEMENT_REQUEST),
      switchMap(({ taskId, request, storeId, onSucceed }) =>
        this.service.generateSellerStatement(request, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.GenerateSellerStatementReceived(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPaymentLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchPaymentLinkRequestAction>(EVENT.WALLET_PAYMENT_LINK_REQUESTED),
      switchMap(({ onSucceed, taskId, link, onError }) =>
        this.service.fetchPaymentLink(link).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyFetchPaymentLinkResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchAvailableMerchantSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchAvailableMerchantSubscriptionsRequestAction>(
        EVENT.SUBSCRIPTIONS_AVAILABLE_MERCHANT_LIST_REQUESTED,
      ),
      switchMap(({ onSucceed, taskId }) =>
        this.service.fetchSubscriptionAvailableMerchantSubscriptions().pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new actions.MyMoneyFetchAvailableMerchantSubscriptionsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByCardRequestAction>(EVENT.PAY_BY_CARD_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByCard(flowId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPayByCardResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByPointsOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByPointsOnlyRequestAction>(EVENT.PAY_BY_POINTS_ONLY_REQUESTED),
      switchMap(({ taskId, flowId, onSucceed }) =>
        this.service.payByPointsOnly(flowId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyPayByPointsOnlyResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByWalletRequestAction>(EVENT.PAY_BY_WALLET_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByWallet(flowId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPayByWalletResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payForFree$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayForFreeRequestAction>(EVENT.PAY_FOR_FREE_REQUESTED),
      switchMap(({ taskId, flowId, onSucceed }) =>
        this.service.payForFree(flowId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyPayForFreeResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByEft$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByEftRequestAction>(EVENT.PAY_BY_EFT_REQUESTED),
      switchMap(({ taskId, flowId, onSucceed }) =>
        this.service.payByEft(flowId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyPayByEftResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByAmex$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByAmexRequestAction>(EVENT.PAY_BY_AMEX_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByAmex(flowId, request).pipe(
          map(data => {
            onSucceed(data);
            return succeed(new actions.MyMoneyPayByAmexResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByCredit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByCreditRequestAction>(EVENT.PAY_BY_CREDIT_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByCredit(flowId, request).pipe(
          map(data => {
            onSucceed(data);
            return succeed(new actions.MyMoneyPayByCreditResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topUpAndPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTopUpAndPayRequestAction>(EVENT.TOPUP_AND_PAY_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.topUpAndPay(flowId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTopUpAndPayResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: WalletService) {}
}
