import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as actions from './shopping-buy.actions';
import { IShoppingCartPartialState } from './shopping-buy.reducer';
import * as selector from './shopping-buy.selectors';
import { v4 as uuidv4 } from 'uuid';

import { started } from '../application/task.actions';
import { IPosition } from '../../models/location.model';
import { IImageFileResponse } from '../../models/image.model';

import {
  IShoppingAddress,
  IShoppingApplyPromoCode,
  IShoppingBuyDeliveryInfoRequest,
  IShoppingBuyerAddItemRequest,
  IShoppingBuyerAddItemResponse,
  IShoppingBuyerCart,
  IShoppingBuyerCartDeliveryAddressRequest,
  IShoppingBuyerCartDeliveryOptionRequest,
  IShoppingBuyerCartDetail,
  IShoppingBuyerCartItemQuantityRequest,
  IShoppingBuyerCartPaymentDetails,
  IShoppingBuyerCartSummary,
  IShoppingCart,
  IShoppingFulfillmentPatch,
  IShoppingOrderDetailResponse,
  IShoppingOrderPaymentResponse,
  IShoppingPayCardRequest,
  IShoppingPaymentInfoResponse,
  IShoppingPayWalletRequest,
  IShoppingPayWalletResponse,
  IShoppingProductRequest,
  IShoppingReserveInfoRequest,
  IShoppingSetTermInMonthsRequest,
  IShoppingTvLicenceCheckRequest,
  IShoppingTvLicenceCheckResponse,
  IShoppingTvLicencePhotoIdRequest,
  IShoppingUpdateCartGiftOptionRequest,
  IUrlInfoResponse,
  ShoppingCartType,
} from '../../models/shopping.model';
import { IPayByCardResponse } from '../../models/wallet.model';

// build trigger
@Injectable({
  providedIn: 'root',
})
export class ShoppingBuyFacade {
  getCartSummary$ = this.store.pipe(select(selector.getCartSummary));
  getCartDetail$ = this.store.pipe(select(selector.getCartDetail));
  getCartPaymentDetails$ = this.store.pipe(select(selector.getCartPaymentDetails));

  getCart$ = this.store.pipe(select(selector.getCart));
  getPromoCodes$ = this.store.pipe(select(selector.getPromoCodes));
  getPickupLocations$ = this.store.pipe(select(selector.getPickupLocations));
  getDeliverInfo$ = this.store.pipe(select(selector.getDeliveryInfo));
  getReserveInfo$ = this.store.pipe(select(selector.getReserveInfo));
  getCartCount$ = this.store.pipe(select(selector.getCartCount));
  getDeliveryOptions$ = this.store.pipe(select(selector.getDeliveryOptions));
  getSavedItems$ = this.store.pipe(select(selector.getSavedItems));
  getPlacedOrder$ = this.store.pipe(select(selector.getPlacedOrder));
  getPayByCardResponse$ = this.store.pipe(select(selector.getPayByCardResponse));
  getPayByWalletResponse$ = this.store.pipe(select(selector.getPayByWalletResponse));
  getOrderInfo$ = this.store.pipe(select(selector.getOrderInfo));
  getPaymentInfo$ = this.store.pipe(select(selector.getPaymentInfo));
  getSavedCards$ = this.store.pipe(select(selector.getSavedCards));

  constructor(private store: Store<IShoppingCartPartialState>) {}

  fetchShoppingCartContent = (cartType: string, onSucceed?: (data: IShoppingCart) => void, silently = true): void => {
    const taskId = `shopping-fetching-cart-content-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyContentRequestAction(taskId, cartType, onSucceed), 'Fetching cart.', silently),
    );
  };

  deleteCartItem = (
    cartItem: IShoppingProductRequest,
    cartType: string,
    onSucceed?: (response: any) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-delete-cart-item-${JSON.stringify(cartItem)}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyDeleteCartItemRequestAction(taskId, cartItem, cartType, onSucceed, onError)),
    );
  };

  updateCartItem = (
    cartItem: IShoppingProductRequest,
    cartType: string,
    onSucceed?: (response: any) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-update-cart-item-${JSON.stringify(cartItem)}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyUpdateCartItemRequestAction(taskId, cartItem, cartType, onSucceed, onError)),
    );
  };

  fetchSavedItems = (): void => {
    const taskId = `shopping-fetch-saved-items-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingMyCartSavedItemsRequestAction(taskId)));
  };

  saveToLaterCartItem = (cartItem: IShoppingProductRequest): void => {
    const taskId = `shopping-save-to-later-cart-item-${JSON.stringify(cartItem)}`;
    this.store.dispatch(started(new actions.ShoppingBuySaveToLaterCartItemRequestAction(taskId, cartItem)));
  };

  updateSavedCartItem = (cartItem: IShoppingProductRequest): void => {
    const taskId = `shopping-update-saved-cart-item-${JSON.stringify(cartItem)}`;
    this.store.dispatch(started(new actions.ShoppingBuyUpdateSavedCartItemRequestAction(taskId, cartItem)));
  };

  deleteSavedCartItem = (cartItemId: string, sellerId: string): void => {
    const taskId = `shopping-delete-saved-cart-item-${cartItemId}`;
    this.store.dispatch(started(new actions.ShoppingBuyDeleteSavedCartItemRequestAction(taskId, cartItemId, sellerId)));
  };

  applyPromoCode = (
    applyPromoCode: IShoppingApplyPromoCode,
    cartType: string,
    onSucceeded: () => void,
    onError: (error) => void,
  ): void => {
    const taskId = `shopping-apply-promo-code-${JSON.stringify(applyPromoCode)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyApplyPromoRequestAction(
          taskId,
          applyPromoCode.promocode,
          cartType,
          onSucceeded,
          onError,
        ),
      ),
    );
  };

  removePromoCode = (promoCode: string, cartType: string, onSucceeded: () => void): void => {
    const taskId = `shopping-remove-promo-code-${JSON.stringify(promoCode)}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyRemovePromoRequestAction(taskId, promoCode, cartType, onSucceeded)),
    );
  };

  fetchPromoCodes = (): void => {
    const taskId = `shopping-fetch-promo-codes`;
    this.store.dispatch(started(new actions.ShoppingBuyGetPromoRequestAction(taskId)));
  };

  fetchPickupLocations = (
    cartType: string,
    fulfillmentId: string,
    deliveryMethod: string,
    silently?: boolean,
  ): void => {
    const taskId = `shopping-fetching-pickup-locations-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyGetPickupLocationsRequestAction(taskId, cartType, fulfillmentId, deliveryMethod),
        'Please wait a moment',
        silently,
      ),
    );
  };

  fetchDeliveryLocations = (): void => {
    const taskId = `shopping-fetching-delivery-locations-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyGetDeliveryLocationsRequestAction(taskId)));
  };

  fetchDeliveryOptions = (cartType: string, location: IPosition): void => {
    const taskId = `shopping-fetching-delivery-options-${cartType}`;
    this.store.dispatch(started(new actions.ShoppingBuyFetchDeliveryOptionsRequestAction(taskId, cartType, location)));
  };

  updateDeliveryMethod = (
    delivery: IShoppingFulfillmentPatch,
    cartType: string,
    fulfillmentId: string,
    onSucceeded: (data: IShoppingCart) => void,
  ): void => {
    const taskId = `shopping-update-delivery-method-${JSON.stringify(delivery)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingUpdateDeliveryOptionRequestAction(taskId, cartType, fulfillmentId, delivery, onSucceeded),
        'Please wait a moment',
      ),
    );
  };

  sendDeliveryInfo = (
    deliveryInfoRequest: IShoppingBuyDeliveryInfoRequest,
    cartType: string,
    redirect: () => void,
  ): void => {
    const taskId = `shopping-send-delivery-info-${JSON.stringify(deliveryInfoRequest)}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyDeliveryInfoRequestAction(taskId, deliveryInfoRequest, cartType, redirect)),
    );
  };

  sendReserveInfo = (
    reserveInfoRequest: IShoppingReserveInfoRequest,
    cartType: string,
    redirect: () => void,
    onSucceeded?: () => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-send-reserve-info-${JSON.stringify(reserveInfoRequest)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyReserveInfoRequestAction(
          taskId,
          reserveInfoRequest,
          cartType,
          redirect,
          onSucceeded,
          onError,
        ),
        'Please wait a moment',
      ),
    );
  };

  moveCartItem = (cartItem: IShoppingProductRequest, cartType: string): void => {
    const taskId = `shopping-move-cart-item-${JSON.stringify(cartItem)}`;
    this.store.dispatch(started(new actions.ShoppingBuyMoveCartItemRequestAction(taskId, cartItem, cartType)));
  };

  cleanShoppingCart = (): void => {
    const taskId = `shopping-clean-cart`;
    this.store.dispatch(new actions.ShoppingBuyCartCleanActionAction(taskId));
  };

  setBillingAddress = (address: IShoppingAddress): void => {
    const taskId = `shopping-set-billing-address-${JSON.stringify(address)}`;
    this.store.dispatch(new actions.ShoppingBuySetBillingAddressAction(taskId, address));
  };

  fetchReturnPolicyUrl = (onSucceeded: (url: IUrlInfoResponse) => void): void => {
    const taskId = `shopping-fetching-return-policy-url-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyFetchReturnPolicyInfoRequestAction(taskId, onSucceeded)));
  };

  fetchTermsAndConditionsUrl = (onSucceeded: (url: IUrlInfoResponse) => void): void => {
    const taskId = `shopping-fetching-terms-and-conditions-url-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyFetchTermsAndConditionsInfoRequestAction(taskId, onSucceeded)));
  };

  fetchPaymentReservationUrl = (onSucceeded: (url: IUrlInfoResponse) => void): void => {
    const taskId = `shopping-fetching-payment-reservation-url-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyFetchPaymentReservationInfoRequestAction(taskId, onSucceeded)));
  };

  updateCartGiftOption = (
    updateCartGiftOption: IShoppingUpdateCartGiftOptionRequest,
    cartType: string,
    silent: boolean,
    onSucceeded?: () => void,
  ): void => {
    const taskId = `shopping-update-cart-gift-${JSON.stringify(updateCartGiftOption)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyUpdateCartGiftRequestAction(taskId, updateCartGiftOption, cartType, onSucceeded),
        'Update cart gift',
        silent,
      ),
    );
  };

  tvLicenceCheck = (
    request: IShoppingTvLicenceCheckRequest,
    onSucceed: (response: IShoppingTvLicenceCheckResponse) => void,
  ): void => {
    const taskId = `shopping-tv-licence-check-${uuidv4()}-${JSON.stringify(request)}`;
    this.store.dispatch(
      started(new actions.ShoppingTvLicenceCheckRequest(taskId, request, onSucceed), 'Checking your TV Licence', true),
    );
  };

  uploadAsset = (file: FormData, onSucceed: (response: IImageFileResponse) => void): void => {
    const taskId = `shopping-upload-asset-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ShoppingUploadAssetRequest(taskId, file, onSucceed), 'Please wait a moment'),
    );
  };

  uploadTvLicencePhotoId = (request: IShoppingTvLicencePhotoIdRequest): void => {
    const taskId = `shopping-upload-tv-licence-photo-id-${uuidv4()}-${JSON.stringify(request)}`;
    this.store.dispatch(
      started(new actions.ShoppingAddTvLicencePhotoIdRequest(taskId, request), 'Please wait a moment', true),
    );
  };

  createOrder = (
    cartType: string,
    onSucceeded?: (data: IShoppingOrderDetailResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-create-order-${JSON.stringify(cartType)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyCreateOrderRequestAction(taskId, cartType, onSucceeded, onError),
        'Please wait a moment',
      ),
    );
  };

  payByWallet = (
    payload: IShoppingPayWalletRequest,
    orderNumber: string,
    onSucceeded?: (data: IShoppingPayWalletResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-pay-by-wallet-${JSON.stringify(payload)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyPayByWalletRequestAction(taskId, payload, orderNumber, onSucceeded, onError),
        'Please wait a moment',
      ),
    );
  };

  payByCard = (
    payload: IShoppingPayCardRequest,
    orderNumber: string,
    onSucceeded?: (data: IPayByCardResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-pay-by-card-${JSON.stringify(payload)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyPayByCardRequestAction(taskId, payload, orderNumber, onSucceeded, onError),
        'Please wait a moment',
      ),
    );
  };

  fetchOrderInfo = (
    orderNumber: string,
    onSucceeded?: (data: IShoppingOrderPaymentResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-order-info-${JSON.stringify(orderNumber)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyOrderInfoRequestAction(taskId, orderNumber, onSucceeded, onError),
        'Please wait a moment',
      ),
    );
  };

  fetchOrderDetail = (
    orderNumber: string,
    onSucceeded?: (data: IShoppingOrderDetailResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-order-detail-${JSON.stringify(orderNumber)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyOrderDetailRequestAction(taskId, orderNumber, onSucceeded, onError),
        'Please wait a moment',
      ),
    );
  };

  setTermInMonths = (cartType: string, request: IShoppingSetTermInMonthsRequest): void => {
    const taskId = `shopping-set-term-in-months-${cartType}-${JSON.stringify(request)}`;
    this.store.dispatch(
      started(new actions.ShoppingBuySetTermInMonthsRequest(taskId, cartType, request), 'Please wait a moment'),
    );
  };

  clearCart = (cartType: ShoppingCartType): void => {
    const taskId = `shopping-clear-cart-${cartType}}`;
    this.store.dispatch(started(new actions.ShoppingBuyClearCartRequest(cartType, taskId), 'Please wait a moment'));
  };

  //v3 cart
  fetchCarts = (onSucceed?: (response: IShoppingBuyerCart[]) => void, onError?: (error: any) => void): void => {
    const taskId = `shopping-fetch-carts-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyerCartsRequestAction(taskId, onSucceed, onError), 'Fetching your cart'),
    );
  };

  fetchCartDetail = (
    cartId: string,
    onSucceed?: (response: IShoppingBuyerCartDetail) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-fetch-cart-detail-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyerCartDetailRequestAction(taskId, cartId, onSucceed, onError)));
  };

  addCartItem = (
    request: IShoppingBuyerAddItemRequest,
    onSucceed?: (response: IShoppingBuyerAddItemResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-add-cart-item-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyerAddCartItemRequestAction(taskId, request, onSucceed, onError)),
    );
  };

  updateCartItemQuantity = (
    cartId: string,
    cartItemId: string,
    request: IShoppingBuyerCartItemQuantityRequest,
    onSucceed?: (response: IShoppingBuyerCartDetail) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-update-cart-item-quantity-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyerCartItemQuantityRequestAction(taskId, cartId, cartItemId, request, onSucceed, onError),
      ),
    );
  };

  updateCartDeliveryAddress = (
    cartId: string,
    request: IShoppingBuyerCartDeliveryAddressRequest,
    onSucceed?: (response: IShoppingBuyerCartDetail) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-update-cart-delivery-address-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ShoppingBuyerCartDeliveryAddressRequestAction(taskId, cartId, request, onSucceed, onError)),
    );
  };

  updateCartDeliveryOption = (
    cartId: string,
    fulfilmentId: string,
    request: IShoppingBuyerCartDeliveryOptionRequest,
    onSucceed?: (response: IShoppingBuyerCartDetail) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-update-cart-delivery-option-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyerCartDeliveryOptionRequestAction(
          taskId,
          cartId,
          fulfilmentId,
          request,
          onSucceed,
          onError,
        ),
      ),
    );
  };

  fetchCartSummary = (
    onSucceed?: (response: IShoppingBuyerCartSummary) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-cart-summary-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyerCartSummaryRequestAction(taskId, onSucceed, onError)));
  };

  payCart = (
    cartId: string,
    onSucceed?: (response: IShoppingBuyerCartPaymentDetails) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-pay-cart-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyerPayCartRequestAction(taskId, cartId, onSucceed, onError)));
  };

  fetchPaymentInfo = (
    paymentId: string,
    onSucceeded?: (data: IShoppingPaymentInfoResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `shopping-payment-info-${JSON.stringify(paymentId)}`;
    this.store.dispatch(
      started(
        new actions.ShoppingBuyPaymentInfoRequestAction(taskId, paymentId, onSucceeded, onError),
        'Please wait a moment',
      ),
    );
  };

  fetchSavedCards = (): void => {
    const taskId = `shopping-buy-payment-saved-cards-${uuidv4()}`;
    this.store.dispatch(started(new actions.ShoppingBuyPaymentSavedCardsRequestAction(taskId), 'Please wait a moment'));
  };
}
