import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@avo/environment/customer/environment';
import { CmpidService } from '../services/cmpid.service';

@Injectable()
export class CmpidInterceptor implements HttpInterceptor {
  private readonly cmpidService = inject(CmpidService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (environment.domainUrl === 'preview-v3.avo.africa' || environment.domainUrl === 'preview.avo.africa') {
      request = request.clone({
        headers: request.headers.set('X-Canary', 'always'),
      });
    }

    if (!request.url.includes('configuration.json')) {
      request = request.clone({
        headers: request.headers.set('cmpid', this.cmpidService.cmpId),
      });
    }

    return next.handle(request);
  }
}
