import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { UiToastInput, UiToastType } from './ui-toast.model';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'ui-toast',
  templateUrl: './ui-toast.component.html',
  styleUrls: ['./ui-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiToastComponent implements UiToastInput {
  /** First line */
  @Input() text: string | undefined;

  /** Optional second line */
  @Input() subtext: string | undefined;

  /** Toast type */
  @Input() type = UiToastType.SUCCESS;

  /** Icon on the right side. */
  @Input() icon: string | undefined;

  constructor(
    @Optional() @Inject(MAT_SNACK_BAR_DATA) private data: UiToastInput,
    @Optional() private matSnackBarRef: MatSnackBarRef<UiToastComponent>,
  ) {
    if (data) Object.assign(this, data);
  }

  @HostBinding('class')
  get setClasses(): string[] {
    const classes = [`is-${this.type.toLowerCase()}`, 'px-4', 'py-2'];
    if (!this.subtext) classes.push('text-center');
    return classes;
  }

  @HostListener('click')
  onClick(): void {
    if (this.matSnackBarRef) this.matSnackBarRef.dismissWithAction();
  }
}
