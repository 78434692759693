import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as ProfileActions from './app-config.actions';

export const APP_CONFIG_FEATURE_KEY = 'appConfig2';

export interface AppConfigEntity {
  id: string | number;
  name: string;
}

export interface AppConfigState extends EntityState<AppConfigEntity> {
  appConfig?: any; // todo add type
}

export interface ProfilePartialState {
  readonly [APP_CONFIG_FEATURE_KEY]: AppConfigState;
}

export const appConfigAdapter: EntityAdapter<AppConfigEntity> = createEntityAdapter<AppConfigEntity>();

export const appConfigInitialState: AppConfigState = appConfigAdapter.getInitialState({});

const _appConfigReducer = createReducer(
  appConfigInitialState,
  on(ProfileActions.fetchAppConfigResponseAction, (state, { data }) => ({
    ...state,
    appConfig: data,
  })),
);

export function appConfigReducer(state: AppConfigState | undefined, action: Action) {
  return _appConfigReducer(state, action);
}
