import find from 'lodash-es/find';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import split from 'lodash-es/split';
import toNumber from 'lodash-es/toNumber';
import { IAddressForm } from '../store/location/location.models';

const getAddrComponent = (place, type: string | string[], long?: boolean): any =>
  get(
    find(place.address_components, p => includes(p.types, type)),
    long ? 'long_name' : 'short_name',
  );

const getStreet = (place): string => getAddrComponent(place, 'route', true);

const getCity = (place): string => getAddrComponent(place, 'locality', true);

const getCountry = (place): string => getAddrComponent(place, 'country');

const getStateOrProvince = (place): string => getAddrComponent(place, 'administrative_area_level_1', true);

const getSuburb = (place): string => getAddrComponent(place, 'sublocality', true);

const getFullAddress = (place): string => place.formatted_address;

const getPostCode = (place): string => {
  const postalCode = getAddrComponent(place, 'postal_code');
  return postalCode ? postalCode.replace(/ /g, '') : null;
};

const getLatitude = (place): number => {
  const latitude = get(place, 'geometry.location');
  return toNumber(latitude.lat());
};

const getLongitude = (place): number => {
  const longitude = get(place, 'geometry.location');
  return toNumber(longitude.lng());
};

const getStreetNumber = (place): number => getAddrComponent(place, 'street_number');

export const getAddressForm = (response: google.maps.GeocoderResult[]): IAddressForm => {
  const place = get(response, [0, 0]);

  if (!place) return { addressString: '' };

  return {
    addressString: getFullAddress(place),
    streetName: getStreet(place),
    stateOrProvince: getStateOrProvince(place),
    postalCode: getPostCode(place),
    city: getCity(place),
    country: getCountry(place),
    streetNumber: getStreetNumber(place),
    latitude: getLatitude(place),
    longitude: getLongitude(place),
    suburb: getSuburb(place),
  };
};

export const getAddressByPlace = (response: google.maps.places.AutocompletePrediction[]): any[] =>
  map(response, (item: any) => ({
    id: item.place_id,
    name: head(split(get(item, 'description'), ',')),
    address: {
      addressString: item.description,
    },
  }));
