import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  addToCart,
  addToFavorites,
  applyPromoCode,
  cart,
  cartDeliveryAddress,
  cartDeliveryOption,
  cartDetail,
  cartItemQuantity,
  carts,
  cartSummary,
  checkTvLicence,
  clearCart,
  createOrder,
  deletePromoCode,
  deliveryInfoAddress,
  deliveryOptions,
  gift,
  orderDetail,
  orderInfo,
  payByCard,
  payByWallet,
  payCart,
  paymentInfo,
  paymentReservation,
  pickupLocations,
  postAsset,
  promoCodes,
  reserveInfo,
  returnPolicy,
  savedCards,
  saveCartItem as saveToLaterCartItem,
  sellerProductList,
  sellerReviewList,
  shoppingCategoryLandingData,
  shoppingCustomerReviewsData,
  shoppingProductDetailData,
  termInMonths,
  termsAndConditions,
  tvLicPhotoId,
  updateCartItem,
  updateDeliveryMethod,
  updateSavedCartItem,
  writeAReview,
} from './shopping.endpoints';
import { AppConfig } from '../../configs/app.config';
import { ApiService } from '../api/api.service';
import {
  IShoppingBuyDeliveryInfoRequest,
  IShoppingBuyerAddItemRequest,
  IShoppingBuyerAddItemResponse,
  IShoppingBuyerCart,
  IShoppingBuyerCartDeliveryAddressRequest,
  IShoppingBuyerCartDeliveryOptionRequest,
  IShoppingBuyerCartDetail,
  IShoppingBuyerCartItemQuantityRequest,
  IShoppingBuyerCartPaymentDetails,
  IShoppingBuyerCartSummary,
  IShoppingBuyPickupLocationResponse,
  IShoppingBuyPromoResponse,
  IShoppingCart,
  IShoppingCatalogCategoryFilterItem,
  IShoppingCategory,
  IShoppingDeliveryInfoResponse,
  IShoppingFulfillmentDeliveryOptions,
  IShoppingFulfillmentPatch,
  IShoppingLandingCategoryFilterData,
  IShoppingOrderDetailResponse,
  IShoppingOrderPaymentResponse,
  IShoppingPayCardRequest,
  IShoppingPaymentInfoResponse,
  IShoppingPaymentSavedCards,
  IShoppingPayWalletRequest,
  IShoppingPayWalletResponse,
  IShoppingProduct,
  IShoppingProductCards,
  IShoppingProductRequest,
  IShoppingReserveInfoRequest,
  IShoppingReserveInfoResponse,
  IShoppingSellerCategory,
  IShoppingSellerReview,
  IShoppingSetTermInMonthsRequest,
  IShoppingTvLicenceCheckRequest,
  IShoppingTvLicenceCheckResponse,
  IShoppingTvLicencePhotoIdRequest,
  IShoppingUpdateCartGiftOptionRequest,
  IUrlInfoResponse,
  ShoppingCartType,
} from '../../models/shopping.model';
import { IPosition } from '../../models/location.model';
import { IImageFileResponse } from '../../models/image.model';
import { IPayByCardResponse } from '../../models/wallet.model';

@Injectable()
export class ShoppingCoreService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiV3Url;

  public fetchCustomerReviewsData(productId: string, useMockApi = false): Observable<any> {
    return this.api.get<any>(shoppingCustomerReviewsData(productId, this.getApiUrl(useMockApi)));
  }

  public writeAReview(sku: string, request: { text: string; rating: number }, useMockApi = false): Observable<any> {
    return this.api.post<{ text: string; rating: number }>(writeAReview(sku, this.getApiUrl(useMockApi)), request);
  }

  public fetchShoppingCategoryLandingData(
    categoryId: string,
    queryParams: IShoppingLandingCategoryFilterData,
    filters: IShoppingCatalogCategoryFilterItem[],
    useMockApi = false,
  ): Observable<IShoppingCategory> {
    return this.api.post<IShoppingCatalogCategoryFilterItem[]>(
      shoppingCategoryLandingData(categoryId, this.getApiUrl(useMockApi), queryParams),
      filters,
    );
  }

  public fetchShoppingSellerProductList(
    sellerId: string,
    mallId: string,
    useMockApi = false,
  ): Observable<IShoppingSellerCategory> {
    return this.api.get<IShoppingSellerCategory>(sellerProductList(sellerId, mallId, this.getApiUrl(useMockApi)));
  }

  public fetchShoppingSellerReviews(sellerId: string, useMockApi = false): Observable<IShoppingSellerReview[]> {
    return this.api.get<IShoppingSellerReview[]>(sellerReviewList(sellerId, this.getApiUrl(useMockApi)));
  }

  public fetchShoppingProductDetail(sku: string, mallId: string, useMockApi = false): Observable<IShoppingProduct> {
    return this.api.get<IShoppingProduct>(shoppingProductDetailData(sku, mallId, this.getApiUrl(useMockApi)));
  }

  public addToFavorites(sku: string, useMockApi = false): Observable<any> {
    return this.api.post(addToFavorites(sku, this.getApiUrl(useMockApi)));
  }

  public buyNow(productToCart: IShoppingProductRequest, cartType: string, useMockApi = false): Observable<any> {
    return this.api.post(cart(cartType, this.getApiUrl(useMockApi)), productToCart);
  }

  public fetchShoppingCartContent(cartType: string, useMockApi = false): Observable<IShoppingCart> {
    return this.api.get<IShoppingCart>(cart(cartType, this.getApiUrl(useMockApi)));
  }

  public deleteCartItem(
    cartItem: IShoppingProductRequest,
    cartType: string,
    useMockApi = false,
  ): Observable<IShoppingCart> {
    return this.api.put<IShoppingProductRequest>(updateCartItem(cartType, this.getApiUrl(useMockApi)), {
      ...cartItem,
      quantity: 0,
    });
  }

  public updateCountOfProductShoppingCartItem(
    cartItem: IShoppingProductRequest,
    cartType: string,
    useMockApi = false,
  ): Observable<IShoppingCart> {
    return this.api.put<IShoppingProductRequest>(updateCartItem(cartType, this.getApiUrl(useMockApi)), cartItem);
  }

  public fetchSaveItems(useMockApi = false): Observable<IShoppingProductCards[]> {
    return this.api.get<IShoppingProductCards[]>(saveToLaterCartItem(this.getApiUrl(useMockApi)));
  }

  public saveToLaterCartItem(
    cartItem: IShoppingProductRequest,
    useMockApi = false,
  ): Observable<IShoppingProductCards[]> {
    return this.api.post<IShoppingProductRequest>(saveToLaterCartItem(this.getApiUrl(useMockApi)), cartItem);
  }

  public updateSavedCartItem(
    cartItem: IShoppingProductRequest,
    sellerId: string,
    useMockApi = false,
  ): Observable<IShoppingProductCards[]> {
    return this.api.put<IShoppingProductRequest>(
      updateSavedCartItem(cartItem.sku, sellerId, this.getApiUrl(useMockApi)),
      cartItem,
    );
  }

  public deleteSavedCartItem(
    cartItemId: string,
    sellerId: string,
    useMockApi = false,
  ): Observable<IShoppingProductCards[]> {
    return this.api.remove(updateSavedCartItem(cartItemId, sellerId, this.getApiUrl(useMockApi)));
  }

  public fetchPromoCodes(useMockApi = false): Observable<IShoppingBuyPromoResponse[]> {
    return this.api.get<IShoppingBuyPromoResponse[]>(promoCodes(this.getApiUrl(useMockApi)));
  }

  public applyPromoCode(promocode: string, cartType: string, useMockApi = false): Observable<any> {
    return this.api.post<any>(applyPromoCode(promocode, cartType, this.getApiUrl(useMockApi)));
  }

  public removePromoCode(promoCode: string, cartType: string, useMockApi = false): Observable<any> {
    return this.api.remove<any>(deletePromoCode(promoCode, cartType, this.getApiUrl(useMockApi)));
  }

  public fetchPickupLocations(
    cartType: string,
    fulfillmentId: string,
    deliveryMethod: string,
    useMockApi = false,
  ): Observable<IShoppingBuyPickupLocationResponse[]> {
    return this.api.get<IShoppingBuyPickupLocationResponse[]>(
      pickupLocations(cartType, fulfillmentId, this.getApiUrl(useMockApi)),
      { deliveryMethod },
    );
  }

  public updateDeliveryAddresses(
    delivery: IShoppingBuyDeliveryInfoRequest,
    cartType: string,
    useMockApi = false,
  ): Observable<IShoppingCart> {
    return this.api.patch<IShoppingBuyDeliveryInfoRequest>(
      deliveryInfoAddress(cartType, this.getApiUrl(useMockApi)),
      delivery,
    );
  }

  public updateDeliveryMethod(
    delivery: IShoppingFulfillmentPatch,
    cartType: string,
    fulfillmentId: string,
    useMockApi = false,
  ): Observable<any> {
    return this.api.patch<any>(updateDeliveryMethod(cartType, fulfillmentId, this.getApiUrl(useMockApi)), delivery);
  }

  public fetchDeliveryOptions(
    cartType: string,
    location: IPosition,
    useMockApi = false,
  ): Observable<IShoppingFulfillmentDeliveryOptions[]> {
    return this.api.post<IPosition>(deliveryOptions(cartType, this.getApiUrl(useMockApi)), location);
  }

  public fetchReserveInfo(
    reserveInfoRequest: IShoppingReserveInfoRequest,
    cartType: string,
    useMockApi = false,
  ): Observable<IShoppingReserveInfoResponse> {
    return this.api.post<IShoppingReserveInfoRequest>(
      reserveInfo(cartType, this.getApiUrl(useMockApi)),
      reserveInfoRequest,
    );
  }

  public createOrder(cartType: string, useMockApi = false): Observable<IShoppingOrderDetailResponse> {
    return this.api.post<any>(createOrder(cartType, this.getApiUrl(useMockApi)), {});
  }

  public payByWallet(
    request: IShoppingPayWalletRequest,
    orderNumber: string,
    useMockApi = false,
  ): Observable<IShoppingPayWalletResponse> {
    return this.api.post<IShoppingPayWalletRequest>(payByWallet(orderNumber, this.getApiUrl(useMockApi)), request);
  }

  public payByCard(
    request: IShoppingPayCardRequest,
    orderNumber: string,
    useMockApi = false,
  ): Observable<IPayByCardResponse> {
    return this.api.post<IShoppingPayCardRequest>(payByCard(orderNumber, this.getApiUrl(useMockApi)), request);
  }

  public fetchOrderInfo(orderNumber: string, useMockApi = false): Observable<IShoppingOrderPaymentResponse> {
    return this.api.get<IShoppingOrderPaymentResponse>(orderInfo(orderNumber, this.getApiUrl(useMockApi)));
  }

  public fetchOrderDetail(orderNumber: string, useMockApi = false): Observable<IShoppingOrderDetailResponse> {
    return this.api.get<IShoppingOrderDetailResponse>(orderDetail(orderNumber, this.getApiUrl(useMockApi)));
  }

  public fetchPaymentInfo(paymentId: string, useMockApi = false): Observable<IShoppingPaymentInfoResponse> {
    return this.api.get<IShoppingPaymentInfoResponse>(paymentInfo(paymentId, this.getApiUrl(useMockApi)));
  }

  public fetchSavedCards(useMockApi = false): Observable<IShoppingPaymentSavedCards> {
    return this.api.get<IShoppingPaymentSavedCards>(savedCards(this.getApiUrl(useMockApi)));
  }

  public fetchReturnPolicyUrlInfo(useMockApi = false): Observable<IUrlInfoResponse> {
    return this.api.get<IUrlInfoResponse>(returnPolicy(this.getApiUrl(useMockApi)));
  }

  public fetchTermsAndConditionsUrlInfo(useMockApi = false): Observable<IUrlInfoResponse> {
    return this.api.get<IUrlInfoResponse>(termsAndConditions(this.getApiUrl(useMockApi)));
  }

  public fetchPaymentReservationUrlInfo(useMockApi = false): Observable<IUrlInfoResponse> {
    return this.api.get<IUrlInfoResponse>(paymentReservation(this.getApiUrl(useMockApi)));
  }

  public updateCartGift(
    updateCartGiftOption: IShoppingUpdateCartGiftOptionRequest,
    cartType: string,
    useMockApi = false,
  ): Observable<IShoppingDeliveryInfoResponse> {
    return this.api.patch<IShoppingUpdateCartGiftOptionRequest>(
      gift(cartType, this.getApiUrl(useMockApi)),
      updateCartGiftOption,
    );
  }

  public tvLicenceCheck(
    request: IShoppingTvLicenceCheckRequest,
    useMockApi = false,
  ): Observable<IShoppingTvLicenceCheckResponse> {
    const { cartType, ...rest } = request;
    return this.api.post<IShoppingTvLicenceCheckRequest>(checkTvLicence(cartType, this.getApiUrl(useMockApi)), rest);
  }

  public postAsset(file: FormData, useMockApi = false): Observable<IImageFileResponse> {
    return this.api.post<FormData>(postAsset(this.getApiUrl(useMockApi)), file);
  }

  public addTvLicencePhotoId(request: IShoppingTvLicencePhotoIdRequest, useMockApi = false): Observable<IShoppingCart> {
    const { cartType, ...rest } = request;
    return this.api.patch<IShoppingTvLicencePhotoIdRequest>(tvLicPhotoId(cartType, this.getApiUrl(useMockApi)), rest);
  }

  setTermInMonths(
    cartType: string,
    request: IShoppingSetTermInMonthsRequest,
    useMockApi = false,
  ): Observable<IShoppingCart> {
    return this.api.patch<IShoppingSetTermInMonthsRequest>(termInMonths(cartType, this.getApiUrl(useMockApi)), request);
  }

  public clearCart(cartType: ShoppingCartType, useMockApi = false): Observable<IShoppingCart> {
    return this.api.remove<IShoppingCart>(clearCart(cartType, this.getApiUrl(useMockApi)));
  }

  //v3 cart

  public fetchCarts(useMockApi = false): Observable<IShoppingBuyerCart[]> {
    return this.api.get<IShoppingBuyerCart[]>(carts(this.getApiUrl(useMockApi)));
  }

  public fetchCartDetail(cartId: string, useMockApi = false): Observable<IShoppingBuyerCartDetail> {
    return this.api.get<IShoppingBuyerCartDetail>(cartDetail(cartId, this.getApiUrl(useMockApi)));
  }

  updateItemQuantity(
    cartId: string,
    cartItemId: string,
    request: IShoppingBuyerCartItemQuantityRequest,
    useMockApi = false,
  ): Observable<IShoppingBuyerCartDetail> {
    return this.api.patch<IShoppingBuyerCartItemQuantityRequest>(
      cartItemQuantity(cartId, cartItemId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  updateDeliveryAddress(
    cartId: string,
    request: IShoppingBuyerCartDeliveryAddressRequest,
    useMockApi = false,
  ): Observable<IShoppingBuyerCartDetail> {
    return this.api.patch<IShoppingBuyerCartDeliveryAddressRequest>(
      cartDeliveryAddress(cartId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  updateDeliveryOption(
    cartId: string,
    fulfilmentId: string,
    request: IShoppingBuyerCartDeliveryOptionRequest,
    useMockApi = false,
  ): Observable<IShoppingBuyerCartDetail> {
    return this.api.patch<IShoppingBuyerCartDeliveryOptionRequest>(
      cartDeliveryOption(cartId, fulfilmentId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  public addProductToCart(
    request: IShoppingBuyerAddItemRequest,
    useMockApi = false,
  ): Observable<IShoppingBuyerAddItemResponse> {
    return this.api.post<IShoppingBuyerAddItemRequest>(addToCart(this.getApiUrl(useMockApi)), request);
  }

  public fetchCartSummary(useMockApi = false): Observable<IShoppingBuyerCartSummary> {
    return this.api.get<IShoppingBuyerCartSummary>(cartSummary(this.getApiUrl(useMockApi)));
  }

  public payCart(cartId: string, useMockApi = false): Observable<IShoppingBuyerCartPaymentDetails> {
    return this.api.post(payCart(cartId, this.getApiUrl(useMockApi)));
  }
}
