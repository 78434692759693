import { Injectable, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidatorUtils {
  alphaValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const alphaRegex = /^[a-zA-Z\s]+$/;
    if (control.value && !alphaRegex.test(control.value)) {
      return { alphaOnly: true };
    }
    return null;
  }

  idNumberValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const idNumberRegex = /^\d{13}$/;
    if (control.value && !idNumberRegex.test(control.value)) {
      return { invalidID: true };
    }
    return null;
  }
  passportValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const passportRegex = /^(0|[a-zA-Z])$/;
    if (control.value && !passportRegex.test(control.value)) {
      return { invalidPassport: true };
    }
    return null;
  }
}
