import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IShoppingBuyState, SHOPPING_BUY_FEATURE_KEY } from './shopping-buy.reducer';

const getState = createFeatureSelector<IShoppingBuyState>(SHOPPING_BUY_FEATURE_KEY);

export const getCartSummary = createSelector(getState, (state: IShoppingBuyState) =>
  state && state.cartSummary ? state.cartSummary : { totalItems: 0 },
);

export const getCartDetail = createSelector(getState, (state: IShoppingBuyState) =>
  state && state.cartDetail ? state.cartDetail : null,
);

export const getCartPaymentDetails = createSelector(getState, (state: IShoppingBuyState) =>
  state && state.cartPaymentDetails ? state.cartPaymentDetails : null,
);

export const getCart = createSelector(getState, (state: IShoppingBuyState) =>
  state && state.cart ? state.cart : null,
);

export const getCartCount = createSelector(getState, (state: IShoppingBuyState) =>
  state?.cart?.fulfillments
    ? state?.cart?.fulfillments?.reduce(
        (acc, fulfilment) => acc + fulfilment?.items?.reduce((accItem, item) => accItem + item?.quantity, 0),
        0,
      )
    : null,
);

export const getPromoCodes = createSelector(getState, (state: IShoppingBuyState) =>
  state && state.promoCodes ? state.promoCodes : null,
);

export const getPickupLocations = createSelector(getState, ({ pickupLocations }) => pickupLocations);

export const getDeliveryInfo = createSelector(getState, ({ deliveryInfo }) => deliveryInfo);

export const getReserveInfo = createSelector(getState, ({ reserveInfo }) => reserveInfo);

export const getReturnPolicyUrl = createSelector(getState, ({ returnPolicyUrl }) => returnPolicyUrl);

export const getTermsAndConditionsUrl = createSelector(getState, ({ termsAndConditionsUrl }) => termsAndConditionsUrl);

export const getPaymentReservationUrl = createSelector(getState, ({ paymentReservationUrl }) => paymentReservationUrl);

export const getDeliveryOptions = createSelector(getState, ({ deliveryOptions }) => deliveryOptions);

export const getSavedItems = createSelector(getState, ({ savedItems }) => savedItems);

export const getPayByCardResponse = createSelector(getState, ({ payByCardResponse }) => payByCardResponse);
export const getPayByWalletResponse = createSelector(getState, ({ payByWalletResponse }) => payByWalletResponse);
export const getPlacedOrder = createSelector(getState, ({ placedOrder }) => placedOrder);
export const getOrderInfo = createSelector(getState, ({ orderInfo }) => orderInfo);
export const getPaymentInfo = createSelector(getState, ({ paymentInfo }) => paymentInfo);
export const getSavedCards = createSelector(getState, ({ savedCards }) => savedCards);
