import { Injectable } from '@angular/core';
import { AppConfig } from '../../init/app.config';
import { HttpClient } from '@angular/common/http';
import { FeatureFlag } from '../../model/feature-flags.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  fetchFeatureFlags = () => this.httpClient.get<FeatureFlag[]>(`${this.appConfig.apiV3Url}/dashboard/feature-flags`);
}
