import { Injectable } from '@angular/core';
import { AppConfig } from '../init/app.config';
import { ISearchCategory } from '../model/shared.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VouchersCategoriesService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  fetchCategoriesByTop = () =>
    this.httpClient.get<ISearchCategory[]>(`${this.appConfig.apiV3Url}/search/category/top?vertical=VOUCHERS`);
}
