import { InjectionToken } from '@angular/core';

export const AVO_SESSION_PREFIX = new InjectionToken<string>('avoSessionPrefix', {
  factory: () => {
    /*
    TODO:
    For standard apps (merchant, pos, etc.) it works 100%.

    If we use it in shell-remote app, it works for shell, but the InjectionToken is not provided in remotes
    even when it is redefined in their header again.

    This behaviour might be caused by MFE package issue or a wrong architecture of our application.

    Come back when we update angular to version 16 and try again. Until that time, use `PC` as a default value.
     */
    // todo: remove backendConfig.sessionId from project and replace with SessionService
    return 'PC';
  },
});
