import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, of, switchMap } from 'rxjs';
import * as groceriesActions from './groceries.actions';
import { GroceriesService } from './groceries.service';
import { TasksFacade } from '../tasks/tasks.facade';
import { TaskType } from '../models/store.models';
import { httpErrorAction } from '../application/application.actions';

@Injectable()
export class GroceriesEffects {
  constructor(
    private readonly actions$: Actions,
    private groceriesService: GroceriesService,
    private tasksFacade: TasksFacade,
  ) {}

  fetchCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.fetchGroceriesCartRequest),
      switchMap(({ onSuccess, onError, taskMetadata }) =>
        this.groceriesService.fetchCart().pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return groceriesActions.fetchGroceriesCartResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  clearCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.clearCartRequest),
      switchMap(({ onSuccess, onError, taskMetadata }) =>
        this.groceriesService.clearCart().pipe(
          map(() => {
            onSuccess();
            return groceriesActions.clearCartResponse({
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  addCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.addCartItemRequest),
      switchMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.groceriesService.cartItem(request).pipe(
          map(data => {
            onSuccess(data);
            return groceriesActions.addCartItemResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.updateCartItemRequest),
      switchMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.groceriesService.updateCartItem(request).pipe(
          map(data => {
            onSuccess(data);
            return groceriesActions.updateCartItemResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  removeCartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.removeCartItemRequest),
      switchMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.groceriesService.removeCartItem(request).pipe(
          map(data => {
            onSuccess(data);
            return groceriesActions.removeCartItemResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  addCartPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.addCartPromoCodeRequest),
      switchMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.groceriesService.addCartPromoCode(request).pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return groceriesActions.addCartPromoCodeResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  removeCartPromoCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.removeCartPromoCodeRequest),
      switchMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.groceriesService.removeCartPromoCode(request).pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return groceriesActions.removeCartPromoCodeResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchCartTimeslots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.fetchCartTimeslotsRequest),
      switchMap(({ onSuccess, onError, taskMetadata }) =>
        this.groceriesService.fetchCartTimeslots().pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return groceriesActions.fetchCartTimeslotsResponse({
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  placeCartOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(groceriesActions.placeCartOrderRequest),
      switchMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.groceriesService.placeCartOrder(request).pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return groceriesActions.placeCartOrderResponse({
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError(error);
            const taskType =
              error?.error?.error?.code === 'groceries_minimum_spend_amount' ? TaskType.FAILED_SILENT : TaskType.FAILED;
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: taskType },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
}
