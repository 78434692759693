import { inject, Injectable } from '@angular/core';
import { BrowserStorageService } from './browser-storage.service';
import { StorageKeys } from '../store/models/store.models';
import { v4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class DeviceIdService {
  private readonly browserStorageService = inject(BrowserStorageService);
  private _deviceId: string;

  get deviceId(): string {
    if (!this._deviceId) {
      this._deviceId = this.browserStorageService.get(StorageKeys.DEVICE_ID, false);
      if (!this._deviceId) {
        this._deviceId = this.generateDeviceId();
        this.browserStorageService.set(StorageKeys.DEVICE_ID, this._deviceId, false);
      }
    }

    return this._deviceId;
  }

  resetDeviceId(): void {
    this._deviceId = this.generateDeviceId();
    this.browserStorageService.set(StorageKeys.DEVICE_ID, this._deviceId, false);
  }

  private generateDeviceId(): string {
    return v4();
  }
}
