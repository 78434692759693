import { PathLocationStrategy } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { MfeCacheNames } from '../mfe/mfe-config';
import { AppConfig } from '../init/app.config';

@Injectable({
  providedIn: 'root',
})
export class CacheUtil {
  private readonly pathLocationStrategy = inject(PathLocationStrategy);
  private readonly appConfig = inject(AppConfig);

  async removeAllCacheFromAllMfes() {
    return Promise.all(
      this.appConfig.mfe.map(mfe => {
        return this.removeAllKeysFromCache(mfe.name);
      }),
    );
  }

  async removeAllKeysFromCache(mfeModuleName: string): Promise<boolean[]> {
    try {
      // get the latest app version hash
      const db = await caches.open('ngsw:/:db:control');
      const latest = await db.match('/latest');
      const latestHash = JSON.parse(await new Response(latest.body).text())['latest'];
      const cacheNames = this.getCacheNames(latestHash, mfeModuleName);

      const cache = await caches.open(cacheNames.cache);
      const metaCache = await caches.open(cacheNames.meta);

      const keys = await cache.keys();
      keys.concat(await metaCache.keys());

      return Promise.all(keys.map(request => cache.delete(request)));
    } catch (e) {
      return Promise.resolve([false]);
    }
  }

  private getCacheNames(hash: string, mfeModuleName: string): MfeCacheNames {
    const baseHref = this.pathLocationStrategy.getBaseHref();
    return {
      meta: `ngsw:${baseHref}:db:${hash}:assets:${mfeModuleName}:meta`,
      cache: `ngsw:${baseHref}:${hash}:assets:${mfeModuleName}:cache`,
    };
  }
}
