import { Location } from '@angular/common';
import { ChangeDetectorRef, Directive, Injector, OnDestroy } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';
import { AdobeAnalyticsService } from '@avo/shared/adobe-analytics';
import { Subscription } from 'rxjs';
import { UrlRoutes } from '../routes/routes';
import { CommonUtility } from './common-utility';
import { ResponsiveType } from '../services/responsive.service';
import { LoggerService } from '../services/logger.service';

@Directive()
export class CommonComponent implements OnDestroy {
  subscriptions = new Subscription();
  adobeAnalyticsService: AdobeAnalyticsService;
  loggerService: LoggerService;
  router: Router;
  locationRouter: Location;
  routes = UrlRoutes;
  cdr: ChangeDetectorRef;
  screenType: ResponsiveType;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.cdr = injector.get(ChangeDetectorRef);
    this.locationRouter = injector.get(Location);
    this.adobeAnalyticsService = injector.get(AdobeAnalyticsService);
    this.loggerService = injector.get(LoggerService);
  }

  navigateTo = (url: string, p1?: string, p2?: string, p3?: string, p4?: string): void => {
    const routerLink = CommonUtility.format(url, p1, p2, p3, p4);
    this.navigateByUrl(encodeURI(routerLink));
  };

  navigateToReplace = (url: string, p1?: string, p2?: string, p3?: string, p4?: string): void => {
    const routerLink = CommonUtility.format(url, p1, p2, p3, p4);
    this.navigateByUrl(encodeURI(routerLink), { replaceUrl: true });
  };

  navigateByUrl = (page: string, extras?: NavigationExtras): void => {
    this.router.navigateByUrl(page, extras);
  };

  navigateToWithParams = (url: string, params: Params, replaceUrl?: boolean, state?: any): void => {
    this.router.navigate(url ? [url] : [], { replaceUrl, queryParams: params, state: state });
  };

  navigateBack = (): void => {
    this.locationRouter.back();
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
