import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, finalize, map, mergeMap, of, switchMap } from 'rxjs';
import { CartService } from './cart.service';
import * as cartActions from './cart.actions';
import { v4 } from 'uuid';
import { TasksFacade } from '../tasks/tasks.facade';
import { TaskType } from '../models/store.models';
import { httpErrorAction } from '../application/application.actions';

@Injectable()
export class CartEffects {
  constructor(private readonly actions$: Actions, private cartService: CartService, private tasksFacade: TasksFacade) {}

  fetchCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchCartRequest),
      mergeMap(({ onSuccess, onError, taskMetadata }) =>
        this.cartService.fetchCart().pipe(
          map(data => {
            onSuccess();
            return cartActions.fetchCartResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchCartDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchCartDetailRequest),
      mergeMap(({ cartId, onSuccess, onError, taskMetadata }) =>
        this.cartService.fetchCartDetail(cartId).pipe(
          map(data => {
            onSuccess(data);
            return cartActions.fetchCartDetailResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchCartSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchCartSummaryRequest),
      mergeMap(({ onSuccess, onError, taskMetadata }) =>
        this.cartService.fetchCartSummary().pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.fetchCartSummaryResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchCartLiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchCartLiveStatusRequest),
      mergeMap(({ cartId, onSuccess, onError, taskMetadata }) =>
        this.cartService.fetchCartLiveStatus(cartId).pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return cartActions.fetchCartLiveStatusResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  addItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.addItemRequest),
      mergeMap(({ request, onSuccess, onError, taskMetadata }) =>
        this.cartService.addItem(request).pipe(
          switchMap(data => {
            onSuccess(data);
            return [
              cartActions.addItemResponse({
                taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
              }),
              cartActions.fetchCartSummaryRequest({
                taskMetadata: { taskId: v4(), type: TaskType.STARTED },
              }),
            ];
          }),
          catchError(error => {
            onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateItemQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateItemQuantityRequest),
      debounceTime(400),
      switchMap(({ cartId, cartItemId, request, onSuccess, onError, taskMetadata }) =>
        this.cartService.updateItemQuantity(cartId, cartItemId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.updateItemQuantityResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateDeliveryRequest),
      mergeMap(({ cartId, fulfilmentId, request, onSuccess, onError, taskMetadata }) =>
        this.cartService.updateDelivery(cartId, fulfilmentId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.updateDeliveryResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateDeliveryAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateDeliveryAddressRequest),
      mergeMap(({ cartId, request, onSuccess, onError, taskMetadata }) =>
        this.cartService.updateDeliveryAddress(cartId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.updateDeliveryAddressResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateDeliveryInstructions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateDeliveryInstructionsRequest),
      mergeMap(({ cartId, request, onSuccess, onError, taskMetadata }) =>
        this.cartService.updateDeliveryInstructions(cartId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.updateDeliveryInstructionsResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateBusinessDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateBusinessDetailsRequest),
      mergeMap(({ cartId, request, onSuccess, onError, taskMetadata }) =>
        this.cartService.updateBusinessDetails(cartId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.updateBusinessDetailsResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  pay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.payRequest),
      mergeMap(({ cartId, onSuccess, onError, taskMetadata }) =>
        this.cartService.pay(cartId).pipe(
          map(data => {
            onSuccess(data);
            return cartActions.payResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError();
            if (error?.error?.postAction === 'CUSTOMER_DATA_REQUIRED') {
              return of(
                httpErrorAction({
                  error,
                  data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT },
                }),
              );
            } else {
              return of(
                httpErrorAction({
                  error,
                  data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
                }),
              );
            }
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  deleteCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.deleteCartRequest),
      mergeMap(({ cartId, onSuccess, onError, taskMetadata }) =>
        this.cartService.deleteCart(cartId).pipe(
          map(() => {
            onSuccess();
            return cartActions.deleteCartResponse({
              cartId,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  deleteFulfilment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.deleteFulfilmentRequest),
      mergeMap(({ cartId, fulfilmentId, onSuccess, onError, taskMetadata }) =>
        this.cartService.deleteFulfilment(cartId, fulfilmentId).pipe(
          map(data => {
            onSuccess();
            return cartActions.deleteFulfilmentResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  applyPromoCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.applyPromoCodesRequest),
      mergeMap(({ cartId, promoCodes, onSuccess, onError, taskMetadata }) =>
        this.cartService.applyPromoCodes(cartId, promoCodes).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.applyPromoCodesResponse({
              data,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  fetchTermAgreementCompletionUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.fetchTermAgreementCompletionUrlRequest),
      mergeMap(({ flowId, request, onSuccess, taskMetadata }) =>
        this.cartService.payByTermAgreement(flowId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess(data);
            return cartActions.fetchTermAgreementCompletionUrlResponse({
              data,
              taskMetadata: { type: TaskType.SUCCESS, taskId: taskMetadata?.taskId },
            });
          }),
          catchError(error => {
            return of(
              httpErrorAction({
                error,
                data: {
                  taskId: taskMetadata?.taskId,
                  type: TaskType.FAILED,
                },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  updateInsuranceData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartActions.updateInsuranceDataRequest),
      mergeMap(({ cartId, request, onSuccess, onError, taskMetadata }) =>
        this.cartService.insuranceData(cartId, request).pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return cartActions.updateInsuranceDataResponse({
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
}
