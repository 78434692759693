export interface FeatureFlag {
  feature: string;
  enabled: boolean;
  value?: any;
}

export enum FeatureFlags {
  RECOMMENDATIONS_SEARCH = 'app.customer.recommendations.search',
  RECOMMENDATIONS_API = 'app.customer.recommendations.api',
}
