import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly document = inject(DOCUMENT);

  setCanonical(url: string): void {
    const existingElement = this.document.querySelector('link[rel=canonical]');
    if (existingElement) {
      existingElement.setAttribute('href', url);
      return;
    }

    const element = this.document.createElement('link');
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    this.document.head.append(element);
  }

  removeCanonical(): void {
    const existingElement = this.document.querySelector('link[rel=canonical]');
    if (existingElement) existingElement.remove();
  }

  setShortDescription(shortDescription: string): void {
    if (this.document.querySelector('meta[name="description"]') !== null) {
      this.document.querySelector('meta[name="description"]').setAttribute('content', shortDescription);
    } else {
      const shortDescriptionMetaTag = this.document.createElement('meta');
      shortDescriptionMetaTag.setAttribute('name', 'description');
      shortDescriptionMetaTag.setAttribute('content', shortDescription);
      this.document.head.appendChild(shortDescriptionMetaTag);
    }
  }

  removeShortDescription(): void {
    const existingElement = this.document.querySelector('meta[name="description"]');
    if (existingElement) existingElement.remove();
  }
}
