import { IAdobeAvoInfoDto, IAdobeUiCardProductDataDto } from './model/dto.model';
import {
  IAdobeActivePage,
  IAdobeCartItem,
  IAdobeCartTransformedItem,
  IAdobeData,
  IAdobeEntryPoint,
  PROPERTY,
  TARGET_FUNCTION,
} from './model/analytics.model';
import {
  AdobeCartVertical,
  AdobeSearchVertical,
  IAdobeMboxParamsUC0708,
  IAdobeRecommendedProduct,
} from './model/target.model';
import { IAdobeSearchData } from './model/algolia-search.model';

/*
  This class contains functionality that is not called outside adobe-analytics lib.
  Most methods are helper methods for
    - adobe-analytics.service.ts
    - adobe-target.service.ts
    - adobe-algolia-search.service.ts
  to keep those classes clean and simplified.

  adobe-analytics does not use ngrx for state management, the state is managed here instead
  (using getters and setters).
*/
export class AdobeCore {
  // -------------------------- State management --------------------------
  protected authUserInfo: IAdobeAvoInfoDto;
  protected countryCode: string;
  protected isLoggedIn: boolean;
  protected cmpId: string;

  protected readonly window = window;

  get adobeData(): IAdobeData {
    return this.window['adobeData'];
  }

  set adobeData(data: IAdobeData) {
    this.window['adobeData'] = data;
  }

  get activePage(): IAdobeActivePage {
    return this.adobeData?.activePage;
  }

  set activePage(activePage: IAdobeActivePage) {
    this.adobeData = { ...this.adobeData, activePage };
  }

  get entryPoint(): IAdobeEntryPoint {
    return this.adobeData?.entryPoint;
  }

  set entryPoint(entryPoint: IAdobeEntryPoint) {
    this.adobeData = { ...this.adobeData, entryPoint };
  }

  get searchData(): IAdobeSearchData {
    return this.adobeData?.searchData || {};
  }

  set searchData(searchData: IAdobeSearchData) {
    this.adobeData = { ...this.adobeData, searchData };
  }

  // -------------------------- Adobe analytics --------------------------
  /*
    The core method that is called by all Adobe Analytics events.
    Uses _satellite global property to send events to Adobe console on the bank side
  */
  protected satelliteTrack = (trackEvent: string, digitalData?: any): void => {
    this.consoleDebug(trackEvent, digitalData);
    if (
      Object.prototype.hasOwnProperty.call(this.window, PROPERTY.SATELLITE) &&
      typeof this.window[PROPERTY.SATELLITE].track === 'function'
    ) {
      if (digitalData) {
        this.window[PROPERTY.DIGITAL_DATA] = {
          link: {},
          component: {},
          ...digitalData,
        };
      }
      this.window[PROPERTY.SATELLITE].track(trackEvent);
    }
  };

  /*
    Transforms cart items to a required form when sending cart related events.
  */
  protected getProductDetailsDataLayer = (
    product: IAdobeCartItem[],
    vertical: AdobeSearchVertical | AdobeCartVertical,
  ): IAdobeCartTransformedItem[] => {
    return product.map(item => ({
      productInfo: {
        productId: item.sku,
        productSku: item.adobeSku || item.sku,
        productName: item.title,
        storeName: item.storeName || 'Unknown Store',
        brand: item.brand || 'Unknown Brand',
        amount: item.price,
        vertical,
        fromGenAIRecommender: item.fromGenAIRecommender,
      },
    }));
  };

  // -------------------------- Adobe target --------------------------
  /*
    The core method that is called by all Adobe Target events.
    Uses adobe global property to send events to Adobe console on the bank side
  */
  protected adobeTarget = (targetFunction: string) => functionArguments => {
    if (typeof this.window[PROPERTY.ADOBE]?.target?.[targetFunction] === 'function') {
      this.window[PROPERTY.ADOBE].target[targetFunction](functionArguments);
    }
  };

  /*
    One-level higher method. Calls adobeTarget() after processing obtained mbox function properties.
  */
  protected targetGetOffer = (mbox: string, params: any, callback?: any) => {
    this.consoleDebug('TARGET getOffer', { mbox, params });
    this.adobeTarget(TARGET_FUNCTION.GET_OFFER)({
      mbox,
      params,
      success: (offer): void => {
        this.consoleDebug('offer response', offer);
        if (callback) callback(offer);
        this.adobeTarget(TARGET_FUNCTION.APPLY_OFFER)({ mbox, offer });
      },
      error: () => null,
    });
  };

  /*
    Two-level higher method. Calls targetGetOffer() after processing obtained mbox function properties.
    This method is more specific, type-safe and is used only by UC07 and UC08.
  */
  protected getOffer(
    mbox: string,
    params: IAdobeMboxParamsUC0708,
    resolve: (value: IAdobeUiCardProductDataDto[] | PromiseLike<IAdobeUiCardProductDataDto[]>) => void,
  ) {
    this.targetGetOffer(mbox, params, (offer: { action: string; content?: string }[]) => {
      const offerContent = offer?.find(item => item.action === 'setContent')?.content;
      let jsonResult = offerContent?.replace(/\n/g, '');
      jsonResult = jsonResult?.replace(/,(\s)*}/g, '}');
      jsonResult = jsonResult?.replace(/,(\s)*]/g, ']');
      try {
        const recommendations = jsonResult ? JSON.parse(jsonResult) || {} : {};
        const { products } = recommendations;
        const productCards = products?.map(this.adobeRecToProductCard);
        resolve(productCards || []);
      } catch (e) {
        console.log('Adobe Target GetOffer parse error', e);
        resolve([]);
      }
    });
  }

  /*
    Transforms a product object received in Adobe Target success callback into an object suitable for UI product
    card component.
  */
  protected adobeRecToProductCard = (recommendationItem: IAdobeRecommendedProduct): IAdobeUiCardProductDataDto => ({
    sku: recommendationItem.product_id,
    slug: recommendationItem.product_slug,
    title: recommendationItem.product_name,
    priceBeforeDiscount:
      Number(recommendationItem.product_price) > Number(recommendationItem.product_value)
        ? Number(recommendationItem.product_price)
        : undefined,
    price: Number(recommendationItem.product_value),
    points: Number(recommendationItem.product_points) > 0 ? Number(recommendationItem.product_points) : undefined,
    rating: Number(recommendationItem.product_rating) > 0 ? Number(recommendationItem.product_rating) : undefined,
    description: recommendationItem.product_sellerName,
    image: `https://stage-cdn.avo.africa/cdn-cgi/image/w=360/${recommendationItem.product_thumbnail}`,
  });

  // ------------------------ Logs and debugging --------------------------
  /*
    Console debugger. Use the following line in the console tab in your browser to see all adobe-related logs:
    localStorage.setItem('adobeDebug', 'true')
  */
  private consoleDebug = (...args: any[]) => {
    if (localStorage?.getItem('adobeDebug') === 'true') console.log('---ADOBE---', ...args);
  };
}
