import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiToastComponent } from './ui-toast.component';
import { UiIconModule } from '../icon';
import { UiToastService } from './ui-toast.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [UiToastComponent],
  providers: [UiToastService],
  exports: [UiToastComponent],
  imports: [CommonModule, UiIconModule, MatSnackBarModule],
})
export class UiToastModule {}
