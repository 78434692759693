import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ITaskAction, HashStringPrefix } from '@wakanda/wakanda-core';
import { FadeInOutLongAnimation } from '../../utils/animations';

@Component({
  selector: 'ui-modal',
  animations: [FadeInOutLongAnimation],
  template: `
    <div
      *ngIf="!!isVisible"
      class="modal"
      [ngClass]="{
        'error': modal === ModalType.ERROR,
        'spinner': !!spinner,
        'slide-in-up': modal === ModalType.SLIDE_IN_UP,
        'fade-in': modal === ModalType.FADE_IN,
        'sidepanel': modal === ModalType.SIDEPANEL,
        'visible-spinner': !!visibleSpinner
      }"
    >
      <div
        class="modal-content"
        [ngClass]="{
          'size-small': size === ModalSize.SMALL,
          'size-medium': size === ModalSize.MEDIUM,
          'modal-content-align-left': aligned === ModalAligned.LEFT,
          'no-top-padding': !!noTopPadding
        }"
        *ngIf="!spinner || !visibleSpinner"
      >
        <div
          *ngIf="!!title"
          [attr.id]="modal || title | hashString : hashType.MESSAGE"
          class="modal-title"
          [ngClass]="{ 'text-align-left': aligned === ModalAligned.LEFT }"
        >
          {{ title }}
        </div>
        <ui-icon *ngIf="!!closeButton" icon="cross" size="1rem" (click)="this.onCloseClick.emit()"></ui-icon>
        <div class="scrollable-content">
          <ng-template [ngTemplateOutlet]="template || defaultTemplate"></ng-template>

          <div *ngIf="!!description" class="modal-description">
            {{ description }}
          </div>
        </div>
      </div>

      <div class="spinner-modal-content" *ngIf="!!spinner || !!visibleSpinner">
        <ng-container *ngIf="!agentChat">
          <div class="loading-spiner-v3" *ngIf="!!spinner && !visibleSpinner"></div>
        </ng-container>
        <ng-container *ngIf="!!agentChat">
          <div class="agent-spinner">
            <div class="circle">
              <div class="inner"></div>
            </div>
            <div class="circle">
              <div class="inner"></div>
            </div>
            <div class="circle">
              <div class="inner"></div>
            </div>
            <div class="circle">
              <div class="inner"></div>
            </div>
            <div class="circle">
              <div class="inner"></div>
            </div>
          </div>
        </ng-container>

        <div class="modal-description-wrapper" *ngIf="tasks?.length > 0">
          <div *ngFor="let item of tasks" [@fadeInOut] class="modal-description">
            {{ item?.text }}
          </div>
        </div>
      </div>
    </div>

    <ng-template #defaultTemplate>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class ModalComponent {
  hashType = HashStringPrefix;
  @Input() isVisible: boolean;

  @Input() tasks: ITaskAction[] | any[];
  @Input() title: string;
  @Input() closeButton: boolean;

  @Input() description: string;
  @Input() template: TemplateRef<any>;
  @Input() modal: ModalType;
  @Input() size: string;
  @Input() aligned: ModalAligned;
  @Input() noTopPadding: boolean;

  @Input() spinner: boolean;
  @Input() visibleSpinner: boolean;
  @Input() agentChat: boolean;

  @Output() onCloseClick = new EventEmitter();

  ModalType = ModalType;
  ModalSize = ModalSize;
  ModalAligned = ModalAligned;
}

export enum ModalType {
  ERROR = 'ERROR',
  SLIDE_IN_UP = 'SLIDE_IN_UP',
  FADE_IN = 'FADE_IN',
  SIDEPANEL = 'SIDEPANEL',
}

export enum ModalAligned {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export enum ModalSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}
