import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeatureFlagsService } from './feature-flags.service';
import { Store } from '@ngrx/store';
import { ITaskAction, TaskType } from '../models/store.models';
import { fetchFeatureFlagsRequestAction, fetchFeatureFlagsResponseAction } from './feature-flags.actions';
import { catchError, finalize, map, mergeMap, of } from 'rxjs';
import { httpErrorAction } from '../application/application.actions';
import { TasksFacade } from '../tasks/tasks.facade';
import { Injectable } from '@angular/core';

@Injectable()
export class FeatureFlagsEffects {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private readonly actions$: Actions<ITaskAction>,
    private readonly store: Store,
    private tasksFacade: TasksFacade,
  ) {}

  fetchFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFeatureFlagsRequestAction),
      mergeMap(({ taskMetadata, onSuccess }) =>
        this.featureFlagsService.fetchFeatureFlags().pipe(
          map(response => {
            if (onSuccess) {
              onSuccess();
            }
            return fetchFeatureFlagsResponseAction({
              taskMetadata: { type: TaskType.SUCCESS, taskId: taskMetadata?.taskId },
              response,
            });
          }),
          catchError(error => {
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
}
