import { Inject, Injectable } from '@angular/core';
import { IAdobeUiCardProductDataDto } from '../model/dto.model';
import {
  AdobeCountryCode,
  AdobeMboxEnabledFlagEnum,
  AdobeTargetOfferType,
  IAdobeMboxParamsOrderConfirmation,
  IAdobeMboxParamsUC0708,
} from '../model/target.model';
import { parseCategories } from '../utilities/adobe.utility';
import { AdobeCore } from '../adobe-core';
import { AdobeEnvironmentEnum } from '../model/analytics.model';
import { ENVIRONMENT_AVO3 } from '../../../../../../environments/tokens';

@Injectable({
  providedIn: 'root',
})
/*
  Adobe Target are events that don't use _satellite global property but adobe.target.getOffer() instead.
  Every target event has these properties:
    - mbox: function name to call on the bank site to get requested data
    - params: object with function parameters
    - callback: handling for success and error results

  Testing of this functionality is only available on test/stage/preview/prod, since target data
  only arrive to bank specified endpoints.
*/
export class AdobeTargetService extends AdobeCore {
  constructor(@Inject(ENVIRONMENT_AVO3) private environment: any) {
    super();
  }

  setCountryCode(value: string): void {
    this.countryCode = value;
  }

  getOfferOrderConfirmation = (data: IAdobeMboxParamsOrderConfirmation) => {
    if (!data) return null;
    const mbox = 'orderConfirmPage';
    this.targetGetOffer(mbox, data);
  };

  /*
    Used to retrieve recommended products for a user when on product detail page.
    Two use-cases are recongnized:
      - "You might like" --> use-case 07 (UC07)
      - "Bought together" --> use-case 08 (UC08)

    Returns data for products if succeeded.
  */
  getOfferUC0708 = (
    data: IAdobeMboxParamsUC0708,
    categories: string[],
    offerType: AdobeTargetOfferType,
  ): Promise<IAdobeUiCardProductDataDto[]> => {
    if (!data) return null;
    return new Promise(resolve => {
      const ucSuffix = offerType === AdobeTargetOfferType.UC07 ? '1' : '2';
      let mbox =
        this.countryCode === AdobeCountryCode.ZA
          ? `target-custom-mbox-av30${ucSuffix}`
          : `target-custom-mbox-nam0${ucSuffix}`;
      const parsedCategories = parseCategories(categories);

      let params: IAdobeMboxParamsUC0708 = {
        ...data,
        'entity.categoryId': parsedCategories,
        'entity.event.detailsOnly': offerType === AdobeTargetOfferType.UC08 ? true : undefined,
        'entity.countryCode': this.countryCode,
      };

      if (this.environment['environment'] === AdobeEnvironmentEnum.TEST) {
        mbox = `target-custom-mbox-av30${ucSuffix}_test`;
        params = {
          ...params,
          'entity.points': 0.39,
          'entity.rating': 0.0,
          'entity.enabled': AdobeMboxEnabledFlagEnum.ENABLED,
          'entity.enabledInB2c': AdobeMboxEnabledFlagEnum.ENABLED,
          'entity.enabledInB2B': AdobeMboxEnabledFlagEnum.DISABLED,
        };
      }
      this.getOffer(mbox, params, resolve);
    });
  };
}
