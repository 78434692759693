import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CartActions from './cart.actions';
import * as CartSelectors from './cart.selectors';
import {
  BusinessDetailsRequest,
  IAddItemRequest,
  IAddItemResponse,
  IFetchLiveStatusResponse,
  IInsuranceData,
  IPayResponse,
  ITermAgreementSuccessFailureUrls,
  IUpdateDeliveryAddressRequest,
  IUpdateDeliveryInstructionsRequest,
  IUpdateDeliveryRequest,
  IUpdateItemQuantityRequest,
} from './cart.models';
import { v4 } from 'uuid';
import { IApplyPromoCodesRequest, ICart } from './cart.models';
import { TaskType } from '../models/store.models';

@Injectable({ providedIn: 'root' })
export class CartFacade {
  getCart$ = this.store.select(CartSelectors.getCart);
  getCartDetail$ = this.store.select(CartSelectors.getCartDetail);
  getCartSummary$ = this.store.select(CartSelectors.getCartSummary);

  constructor(private readonly store: Store) {}

  fetchCart(onSuccess: () => void, onError: () => void): void {
    this.store.dispatch(
      CartActions.fetchCartRequest({
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchCartDetail(cartId: string, onSuccess?: (response: ICart) => void, onError?: () => void): void {
    this.store.dispatch(
      CartActions.fetchCartDetailRequest({
        cartId,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchCartSummary(onSuccess?: () => void, onError?: () => void): void {
    this.store.dispatch(
      CartActions.fetchCartSummaryRequest({
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchCartLiveStatus(
    cartId: string,
    onSuccess?: (response: IFetchLiveStatusResponse) => void,
    onError?: (error?: any) => void,
  ): void {
    this.store.dispatch(
      CartActions.fetchCartLiveStatusRequest({
        cartId,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  addItem(request: IAddItemRequest, onSuccess: (response: IAddItemResponse) => void, onError: () => void): void {
    this.store.dispatch(
      CartActions.addItemRequest({
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  updateItemQuantity(
    cartId: string,
    cartItemId: string,
    request: IUpdateItemQuantityRequest,
    onSuccess?: () => void,
    onError?: (error?: any) => void,
  ): void {
    this.store.dispatch(
      CartActions.updateItemQuantityRequest({
        cartId,
        cartItemId,
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  updateDelivery(
    cartId: string,
    fulfilmentId: string,
    request: IUpdateDeliveryRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      CartActions.updateDeliveryRequest({
        cartId,
        fulfilmentId,
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  updateDeliveryAddress(
    cartId: string,
    request: IUpdateDeliveryAddressRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      CartActions.updateDeliveryAddressRequest({
        cartId,
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  updateDeliveryInstructions(
    cartId: string,
    request: IUpdateDeliveryInstructionsRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      CartActions.updateDeliveryInstructionsRequest({
        cartId,
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  updateBusinessDetails(
    cartId: string,
    request: BusinessDetailsRequest,
    onSuccess?: () => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      CartActions.updateBusinessDetailsRequest({
        cartId,
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  pay(cartId: string, onSuccess: (response: IPayResponse) => void, onError: () => void): void {
    this.store.dispatch(
      CartActions.payRequest({
        cartId,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  deleteCart(cartId: string, onSuccess: () => void, onError: (error?: any) => void): void {
    this.store.dispatch(
      CartActions.deleteCartRequest({
        cartId,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  deleteFulfilment(cartId: string, fulfilmentId: string, onSuccess: () => void, onError: (error?: any) => void): void {
    this.store.dispatch(
      CartActions.deleteFulfilmentRequest({
        cartId,
        fulfilmentId,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  applyPromoCodes(
    cartId: string,
    promoCodes: IApplyPromoCodesRequest,
    onSuccess?: () => void,
    onError?: (error) => void,
  ): void {
    this.store.dispatch(
      CartActions.applyPromoCodesRequest({
        cartId,
        promoCodes,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }

  fetchTermAgreementCompletionUrl(
    flowId: string,
    request: ITermAgreementSuccessFailureUrls,
    onSuccess?: (response: any) => void,
  ): void {
    this.store.dispatch(
      CartActions.fetchTermAgreementCompletionUrlRequest({
        flowId,
        request,
        onSuccess,
      }),
    );
  }

  addInsuranceData(cartId: string, request: IInsuranceData, onSuccess?: () => void, onError?: (error?: any) => void) {
    this.store.dispatch(
      CartActions.updateInsuranceDataRequest({
        cartId,
        request,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
      }),
    );
  }
}
