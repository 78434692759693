export enum UiToastType {
  ERROR = 'ERROR',
  ALERT = 'ALERT',
  SUCCESS = 'SUCCESS',
  AVO = 'AVO',
}

export interface UiToastInput {
  text?: string;
  subtext?: string;
  type?: UiToastType;
  icon?: string;
}
