<div
  class="labeled-input"
  #labelledInput
  [ngClass]="{
    'required': !!required,
    'disabled': !!disabled,
    'dropdown': !!dropdown,
    'multiple': !!multiple,
    'aligned-right': !!alignedRight,
    'aligned-left': !!alignedLeft,
    'aligned-center': !!alignedCenter,
    'full-width': !!fullWidth,
    'area': !!textarea,
    'color': !!colorPicker,
    'error': !!errors && (errors | inputError)?.length > 0
  }"
  [ngStyle]="{ 'min-width': minWidth }"
  (click)="handleClick($event)"
  (handleBlur)="isOpen = false; focused = false"
>
  <!-- labeled-input title -->
  <ng-container
    *ngIf="
      type === 'text' ||
      type === 'amount' ||
      type === 'phone' ||
      type === 'said' ||
      type === 'number' ||
      (!dropdown && !!value) ||
      (!!dropdown && !!value && value.length > 0)
    "
  >
    <p class="visible">
      <span class="label-text">{{ title }}</span>
      <ui-icon
        class="info-tooltip"
        icon="tooltip"
        width=".75rem"
        height=".75rem"
        *ngIf="!!tooltip"
        (click)="onToggleTooltip()"
      ></ui-icon>
    </p>
  </ng-container>

  <ng-container *ngIf="!!prefix">
    <p class="light prefix">{{ prefix }}</p>
  </ng-container>

  <!-- text input -->
  <input
    *ngIf="
      !dropdown &&
      !textarea &&
      type !== 'amount' &&
      type !== 'phone' &&
      type !== 'said' &&
      !multiple &&
      !colorPicker &&
      !numberPicker
    "
    [type]="type && passwordIsVisible === undefined ? type : passwordIsVisible ? 'text' : 'password'"
    [attr.id]="name | hashString : hashType.TEXT"
    [readonly]="readonly"
    [placeholder]="placeholder || ''"
    [name]="name"
    [value]="value || ''"
    [applyFocus]="applyFocus"
    [attr.maxLength]="maxLength || null"
    autocomplete="off"
    appFocus
    [ngClass]="{
      valid: !errors && (isValid === true || status === true || status === 'VALID'),
      invalid: errors && (isValid === false || status === false || status === 'INVALID')
    }"
    (focus)="onFocus()"
    (keyup)="handleChange($event)"
    (blur)="handleBlur($event.target)"
    (paste)="handlePaste($event)"
    [tabindex]="ignoreTabKey"
  />

  <!-- Color picker -->
  <ng-container *ngIf="!dropdown && !textarea && !multiple && !numberPicker && !!colorPicker">
    <div class="row">
      <input
        type="color"
        [attr.id]="name | hashString : hashType.TEXT"
        [readonly]="readonly"
        [placeholder]="placeholder || ''"
        [name]="name"
        [value]="value || ''"
        [applyFocus]="applyFocus"
        [attr.minlength]="maxLength || 3"
        [attr.maxLength]="maxLength || 6"
        autocomplete="off"
        appFocus
        [ngClass]="{
          'read-only': readonly,
          'valid': !errors && (isValid === true || status === true || status === 'VALID'),
          'invalid': errors && (isValid === false || status === false || status === 'INVALID')
        }"
        (change)="handleChange($event)"
        (focus)="onFocus()"
        (blur)="handleBlur($event.target)"
        [tabindex]="ignoreTabKey"
      />
      <span class="color-value">
        <p class="light">{{ value }}</p>
      </span>
    </div>
  </ng-container>

  <!-- Number picker -->
  <ng-container *ngIf="!dropdown && !textarea && !multiple && !!numberPicker">
    <input
      type="number"
      [attr.id]="name | hashString : hashType.TEXT"
      [readonly]="readonly"
      [placeholder]="placeholder || ''"
      [name]="name"
      [value]="value || ''"
      [applyFocus]="applyFocus"
      [attr.minlength]="maxLength || 3"
      [attr.maxLength]="maxLength || 6"
      autocomplete="off"
      appFocus
      [ngClass]="{
        valid: !errors && (isValid === true || status === true || status === 'VALID'),
        invalid: errors && (isValid === false || status === false || status === 'INVALID')
      }"
      (change)="handleChange($event)"
      (focus)="onFocus()"
      (blur)="handleBlur($event.target)"
      [tabindex]="ignoreTabKey"
    />
  </ng-container>

  <!-- Password visibility toggle -->
  <ng-container *ngIf="type === 'password' && showEye">
    <ui-icon
      [@fadeInOut]
      class="toggle-password-visibility"
      [icon]="passwordIsVisible ? 'password-visibility-off' : 'password-visibility-on'"
      size="1.2rem"
      (click)="onTogglePasswordVisible()"
    ></ui-icon>
  </ng-container>

  <!-- required star -->
  <ng-container *ngIf="required">
    <span
      class="required-star"
      *ngIf="(!errors && !!value) || isValid === true || status === true || status === 'VALID'; else reqStartTemplate"
    >
      <ui-icon [@fadeInOut] icon="input-check" size=".8rem"></ui-icon>
    </span>
    <ng-template #reqStartTemplate>
      <span
        [@fadeInOut]
        class="required-star"
        [ngClass]="{
          'required-star-dropdown': dropdown
        }"
        >*</span
      >
    </ng-template>
  </ng-container>

  <!-- unit -->
  <ng-container *ngIf="unit">
    <span
      class="unit"
      *ngIf="(!errors && !!value) || isValid === true || status === true || status === 'VALID'; else unitTemplate"
    >
      <ui-icon [@fadeInOut] icon="input-check" size=".8rem"></ui-icon>
    </span>
    <ng-template #unitTemplate>
      <span [@fadeInOut] class="unit">/{{ unit }}</span>
    </ng-template>
  </ng-container>

  <!-- amount input -->
  <input
    *ngIf="!dropdown && !textarea && type === 'amount' && !multiple && !numberPicker && !colorPicker"
    type="text"
    [attr.id]="name || title | hashString : hashType.TEXT"
    [readonly]="readonly"
    pattern="[0-9]*"
    inputmode="decimal"
    [placeholder]="title"
    [noDecimal]="noDecimal"
    appAmountFormatMask
    autocomplete="off"
    [prefixValue]="currencyCode"
    [name]="name"
    [applyFocus]="applyFocus"
    appFocus
    [value]="value | amountTransform : { prefixValue: currencyCode, isInput: true, noDecimal: noDecimal } || ''"
    [ngClass]="{
      valid: !errors && (isValid === true || status === true || status === 'VALID'),
      invalid: errors && (isValid === false || status === false || status === 'INVALID')
    }"
    (focus)="onFocus()"
    (ngModelChange)="onAmountChange($event)"
    (paste)="handlePaste($event)"
    [tabindex]="ignoreTabKey"
  />

  <!-- phone input -->
  <input
    *ngIf="!dropdown && !textarea && type === 'phone' && !multiple && !numberPicker && !colorPicker"
    type="text"
    [attr.id]="name | hashString : hashType.TEXT"
    [readonly]="readonly"
    pattern="[0-9]*"
    [placeholder]="title"
    appPhoneFormatMask
    [applyFocus]="applyFocus"
    appFocus
    autocomplete="off"
    [prefixValue]="currencyCode"
    [name]="name"
    [value]="value | phoneTransform : { prefix: currencyCode, maxLength: maxLength } || ''"
    [ngClass]="{
      valid: !errors && (isValid === true || status === true || status === 'VALID'),
      invalid: errors && (isValid === false || status === false || status === 'INVALID')
    }"
    (focus)="onFocus()"
    (blur)="handleBlur($event.target)"
    (ngModelChange)="onCellphoneChange($event)"
    (paste)="handlePaste($event)"
    [tabindex]="ignoreTabKey"
  />

  <!-- said input -->
  <input
    *ngIf="!dropdown && !textarea && type === 'said' && !multiple && !numberPicker && !colorPicker"
    type="text"
    [attr.id]="name | hashString : hashType.TEXT"
    [readonly]="readonly"
    pattern="[0-9]*"
    [placeholder]="title"
    appSaIdFormatMask
    [applyFocus]="applyFocus"
    appFocus
    autocomplete="off"
    [name]="name"
    [value]="value | saIdTransform : { maxLength: maxLength } || ''"
    [ngClass]="{
      valid: !errors && (isValid === true || status === true || status === 'VALID'),
      invalid: errors && (isValid === false || status === false || status === 'INVALID')
    }"
    (focus)="onFocus()"
    (ngModelChange)="onSaidChange($event)"
    (blur)="handleBlur($event.target)"
    (paste)="handlePaste($event)"
    [tabindex]="ignoreTabKey"
  />

  <!-- input text area -->
  <textarea
    *ngIf="!dropdown && textarea && !multiple && !numberPicker && !colorPicker"
    rows="5"
    [placeholder]="placeholder || title || ''"
    [name]="name"
    [attr.id]="name | hashString : hashType.TEXT"
    [readonly]="readonly"
    [applyFocus]="applyFocus"
    appFocus
    autocomplete="off"
    [value]="value || ''"
    [ngClass]="{
      valid: !errors && (isValid === true || status === true || status === 'VALID'),
      invalid: errors && (isValid === false || status === false || status === 'INVALID')
    }"
    (focus)="onFocus()"
    (keyup)="handleChange($event)"
    (paste)="handlePaste($event)"
  >
  </textarea>

  <!-- input extra label -->
  <div *ngIf="extraLabel && extraLabelTitle && !multiple" class="extra label" (click)="handleExtraLabelClick($event)">
    {{ extraLabelTitle }}
  </div>

  <!-- if a tooltip exists, add it here -->
  <i *ngIf="!!tooltip && !!showTooltip" class="labeled-input-tooltip" (click)="onCloseTooltip()">{{ tooltip }}</i>
  <div *ngIf="showLoading" class="loader mini-task-spinner"></div>

  <!-- dropdown (single) input -->
  <input
    *ngIf="!!dropdown && !multiple && !textarea && !numberPicker && !colorPicker"
    [ngClass]="{
      filled: value && !searchable ? !!getDropdownValue(value) : !!searchable ? !!value : false
    }"
    [readonly]="!searchable"
    [attr.id]="name | hashString : hashType.TEXT"
    [placeholder]="placeholder || title"
    [applyFocus]="applyFocus"
    appFocus
    autocomplete="off"
    [name]="name"
    [value]="value && !searchable ? getDropdownValue(value) : !!searchable ? value || '' : ''"
    (keyup)="handleSearchChange($event)"
    (click)="onDropdown()"
    [tabindex]="ignoreTabKey"
  />
  <ui-icon
    *ngIf="
      !readonly && !!clearable && !!value && !searchable ? !!getDropdownValue(value) : !!searchable ? !!value : false
    "
    class="clear-dropdown-data"
    (click)="onDropdownClear()"
    icon="cross"
    width="1rem"
    height="1rem"
  ></ui-icon>

  <ui-icon
    *ngIf="!readonly && !!dropdown && !value"
    class="dropdown-down-arrow"
    icon="ui-down"
    width=".8rem"
    (click)="onDropdown()"
  ></ui-icon>

  <ui-icon *ngIf="arrowRight" icon="next" width=".8rem"></ui-icon>
  <ui-icon *ngIf="arrowDown" icon="ui-down" width=".8rem"></ui-icon>

  <!-- dropdown multiple initial input -->
  <div
    *ngIf="!!dropdown && !!multiple"
    (click)="onDropdown()"
    [style.width]="'100%'"
    [style.height]="'3.125rem'"
    [style.position]="'absolute'"
  >
    <input
      *ngIf="
        value?.length > 0 &&
        !!dropdown &&
        !!multiple &&
        !textarea &&
        (!value || !value.length) &&
        !numberPicker &&
        !colorPicker
      "
      [ngClass]="{
        filled: !!dropdown && !!multiple && !textarea && !!value && value.length > 0
      }"
      [readonly]="!searchable"
      [attr.id]="name | hashString : hashType.TEXT"
      [placeholder]="placeholder || title"
      [applyFocus]="applyFocus"
      appFocus
      autocomplete="off"
      [name]="name"
      [tabindex]="ignoreTabKey"
    />
  </div>

  <!-- dropdown multiple selected values -->
  <div
    class="multiple-dropdown"
    *ngIf="!!dropdown && !!multiple && !textarea && !numberPicker && !colorPicker"
    (click)="onDropdown()"
  >
    <span class="no-value-placeholder" *ngIf="!value?.length && !!placeholder">{{ placeholder }}</span>

    <span *ngFor="let item of value" class="value" (click)="onMultipleDropdownItem(item)">
      {{ item.value }}
      <ng-container *ngIf="!item.notRemovable">
        <ui-icon class="cross" icon="ui-cross" width=".5rem" height=".5rem"></ui-icon>
      </ng-container>
    </span>

    <ng-container *ngIf="withAddValue">
      <ng-container *ngIf="!!showAddValue">
        <span class="value row">
          <input
            type="text"
            [attr.id]="'addValue' | hashString : hashType.TEXT"
            [placeholder]="value?.length > 0 && !!placeholder ? placeholder : ''"
            name="addValue"
            [value]="addValue || ''"
            [attr.maxLength]="maxLength || null"
            autocomplete="off"
            (keyup)="onAddChange($event)"
            (keyup.enter)="onAddSubmit()"
            [tabindex]="ignoreTabKey"
          />
        </span>
      </ng-container>
      <ng-container *ngIf="!showAddValue">
        <span class="text-align-right" (click)="handleAddValue()">
          <a class="small">Add</a>
        </span>
      </ng-container>
    </ng-container>
  </div>
</div>

<div
  *ngIf="!!label || !!errors"
  class="labeled-input-label"
  [ngClass]="{
    'aligned-center': !!alignedCenter
  }"
>
  <!-- label -->
  <i *ngIf="!!label" [ngClass]="{ bold: boldLabel }">{{ label }}</i>
  <!-- error -->
  <ng-container *ngIf="!!errors">
    <p class="small error" *ngFor="let errorItem of errors | inputError; trackBy: trackByErrorFn">
      {{ errorItem | formError | translate }}
    </p>
  </ng-container>
</div>

<!-- dropdown menu -->
<div #dropdownWrapper [class]="'dropdown-wrapper ' + direction">
  <div
    *ngIf="!!dropdown && !!isOpen && !forceClose && !!options && options?.length !== 0"
    class="dropdown-menu"
    [style.height]="dropdownHeight + 'px'"
    [style.max-height]="dropdownMaxHeight + 'px'"
    [style.top]="upPosition"
  >
    <!-- (mousedown) angular workaround https://blog.davidjs.com/2019/04/angular-click-not-fired-and-how-to-fix-it/ -->
    <div
      *ngFor="let item of options"
      class="dropdown-item"
      (mousedown)="onDropdownItem(item[bindId || bindValue])"
      (click)="onDropdownItem(item[bindId || bindValue])"
    >
      <p>{{ item[bindLabel] }}</p>
    </div>
  </div>
</div>
