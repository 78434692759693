/* eslint-disable no-empty */
import { loadRemoteModule } from '@angular-architects/module-federation';
import { ComponentRef, inject, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { AppConfig, LoggerService } from '../..';
import { MfeCacheNames, MfeConfigModule } from './mfe-config';
import { BuildNumberService } from '../services/build-number.service';
import { LoadRemoteModuleEsmOptions } from '@angular-architects/module-federation-runtime/lib/loader/dynamic-federation';
import { PathLocationStrategy } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MfeInjector {
  //
  private readonly buildNumberService = inject(BuildNumberService);
  private readonly pathLocationStrategy = inject(PathLocationStrategy);
  private readonly logger = inject(LoggerService);

  constructor(private injector: Injector) {}

  /**
   * Inject component from the same micro frontend.
   *
   * @param component Component class
   * @param viewContainerRef Current component template reference (it is appended if provided component's view)
   * @deprecated Take it as a sign you've put something where it doesn't belong
   */
  async injectComponent<T>(component: Type<T>, viewContainerRef: ViewContainerRef): Promise<ComponentRef<T>> {
    return viewContainerRef.createComponent(component, { injector: this.injector });
  }

  /**
   * Inject component from a different micro frontend.
   *
   * @param appConfig Current MFE config
   * @param placeholder Template reference to put component there
   * @param mfeName Target MFE name (configuration.json)
   * @param mfeComponentName Target MFE component name (configuration.json)
   * @deprecated Take it as a sign you've put something where it doesn't belong
   */
  async injectMfeModuleWithComponent<T>(
    appConfig: AppConfig,
    placeholder: ViewContainerRef,
    mfeName: string,
    mfeComponentName: string,
  ): Promise<ComponentRef<T>> {
    const mfeConfig = this.getMfeParentConfigByName(appConfig, mfeName);
    if (!mfeConfig) {
      throw new Error(`Unable to load config from configuration.json, check content for ${mfeName} mfe`);
    }

    await this.handleMfeCache(mfeConfig, mfeName);

    const mfeComponentConfig = this.getMfeComponentConfig(mfeConfig, mfeComponentName);
    let loadedComponent: Promise<any>;

    try {
      loadedComponent = await loadRemoteModule(mfeComponentConfig);
    } catch (e) {
      this.logger.logError(
        {
          name: 'LoadComponentError',
          message: `Unable to load exposed component ${mfeComponentName} from ${mfeName} mfe: ${e}`,
        },
        { error_type: 'load_component_error' },
        {},
      );
    }

    return placeholder.createComponent<T>(loadedComponent[mfeComponentName], {
      injector: this.injector,
    });
  }

  private getMfeParentConfigByName(appConfig: AppConfig, parentName: string): MfeConfigModule | undefined {
    return appConfig.mfe?.find(mfe => mfe.name === parentName);
  }

  private getMfeComponentConfig(
    parentConfig: MfeConfigModule,
    componentName: string,
  ): LoadRemoteModuleEsmOptions | undefined {
    // add remote entry url from parent to componentConfig and return concatenated result
    return {
      exposedModule: `./${componentName}`,
      remoteEntry: parentConfig.remoteEntry,
      type: 'module',
    };
  }

  private async handleMfeCache(mfeModuleConfig: MfeConfigModule, mfeModuleName: string) {
    try {
      // get the latest app version hash
      const db = await caches.open('ngsw:/:db:control');
      const latest = await db.match('/latest');
      const latestHash = JSON.parse(await new Response(latest.body).text())['latest'];
      const cacheNames = this.getCacheNames(latestHash, mfeModuleName);

      // delete the current version MFE meta cache
      try {
        await this.removeAllKeysFromCache(cacheNames.meta);
      } catch (e) {}

      // delete the current version MFE file cache
      try {
        await this.removeAllKeysFromCache(cacheNames.cache);
      } catch (e) {}
    } catch (e) {}
  }

  private async removeAllKeysFromCache(name: string): Promise<boolean[]> {
    const cache = await caches.open(name);
    const keys = await cache.keys();

    return Promise.all(keys.map(request => cache.delete(request)));
  }

  private getCacheNames(hash: string, mfeModuleName: string): MfeCacheNames {
    const baseHref = this.pathLocationStrategy.getBaseHref();
    return {
      meta: `ngsw:${baseHref}:db:${hash}:assets:${mfeModuleName}:meta`,
      cache: `ngsw:${baseHref}:${hash}:assets:${mfeModuleName}:cache`,
    };
  }
}
