import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BusinessDetailsRequest,
  IAddItemRequest,
  IAddItemResponse,
  IFetchCartSummary,
  IFetchLiveStatusResponse,
  IPayResponse,
  IUpdateDeliveryAddressRequest,
  IUpdateDeliveryInstructionsRequest,
  IUpdateDeliveryRequest,
  IUpdateItemQuantityRequest,
  IApplyPromoCodesRequest,
  ICart,
  ICartGlobal,
  ITermAgreementSuccessFailureUrls,
  IInsuranceData,
} from './cart.models';
import { AppConfig } from '../../init/app.config';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  fetchCart = () => this.httpClient.get<ICartGlobal[]>(`${this.appConfig.apiV3Url}/cart`);

  fetchCartDetail = (cartId: string) => this.httpClient.get<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}`);

  fetchCartSummary = () => this.httpClient.get<IFetchCartSummary>(`${this.appConfig.apiV3Url}/cart/summary`);

  fetchCartLiveStatus = (cartId: string) =>
    this.httpClient.get<IFetchLiveStatusResponse>(`${this.appConfig.apiV3Url}/cart/${cartId}/live-status`);

  addItem = (request: IAddItemRequest) =>
    this.httpClient.post<IAddItemResponse>(`${this.appConfig.apiV3Url}/cart/items`, request);

  updateItemQuantity = (cartId: string, cartItemId: string, request: IUpdateItemQuantityRequest) =>
    this.httpClient.patch<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/items/${cartItemId}/quantity`, request);

  updateDelivery = (cartId: string, fulfilmentId: string, request: IUpdateDeliveryRequest) =>
    this.httpClient.patch<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/${fulfilmentId}/delivery`, request);

  updateDeliveryAddress = (cartId: string, request: IUpdateDeliveryAddressRequest) =>
    this.httpClient.patch<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/delivery-address`, request);

  updateDeliveryInstructions = (cartId: string, request: IUpdateDeliveryInstructionsRequest) =>
    this.httpClient.put<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/delivery-instructions`, request);

  updateBusinessDetails = (cartId: string, request: BusinessDetailsRequest) =>
    this.httpClient.put<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/business-details`, request);

  pay = (cartId: string) => this.httpClient.post<IPayResponse>(`${this.appConfig.apiV3Url}/cart/${cartId}/pay`, {});

  deleteCart = (cartId: string) => this.httpClient.delete(`${this.appConfig.apiV3Url}/cart/${cartId}`);

  deleteFulfilment = (cartId: string, fulfilmentId: string) =>
    this.httpClient.delete<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/${fulfilmentId}`);

  applyPromoCodes = (cartId: string, promoCodes: IApplyPromoCodesRequest) =>
    this.httpClient.post<ICart>(`${this.appConfig.apiV3Url}/cart/${cartId}/promo`, promoCodes);

  payByTermAgreement = (flowId: string, request: ITermAgreementSuccessFailureUrls) => {
    return this.httpClient.post<any>(`${this.appConfig.apiUrl}/payments/v4/${flowId}/pay-term-agreement`, request);
  };

  insuranceData = (cartId: string, request: IInsuranceData) =>
    this.httpClient.put<IInsuranceData>(`${this.appConfig.apiV3Url}/cart/${cartId}/insurance/insurance-data`, request);
}
