export interface ILocationDTO {
  id: string;
  name: string;
  position: IPositionDTO;
}

export interface IPositionDTO {
  latitude: number;
  longitude: number;
  name?: string;
}

export interface ICategoryPathItem {
  categoryId: string;
  name: string;
}

export interface ISearchCategory {
  categoryId: string;
  name: string;
  imageUrl: string;
  parentPath: ICategoryPathItem[];
  slug: string;
  queryId?: string;
}

export interface IAvoInfoDTO {
  id: string;
  linkedToBank: boolean;
  loginCreated: boolean;
  walletCreated: boolean;
  displayName: string;
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
  photo?: string;
  epn?: string;
  nationality?: string;
  saId?: string;
  addressLine1?: string;
  country?: string;
}

export enum DashboardWidgetType {
  WELLNESS_CAROUSEL = 'WELLNESS_CAROUSEL',
  STATIC_QUICK_ACTIONS = 'STATIC_QUICK_ACTIONS',
  QUICK_ACTIONS = 'QUICK_ACTIONS',
  DISCOVER_AVO = 'DISCOVER_AVO',
  STORES_NEAR_ME = 'STORES_NEAR_ME',
  CONTENT_CARD_CAROUSEL = 'CONTENT_CARD_CAROUSEL',
  SHOP_BY_CATEGORY = 'SHOP_BY_CATEGORY',
  SHOP_BY_BRAND = 'SHOP_BY_BRAND',
  SHOP_BY_STORE = 'SHOP_BY_STORE',
  PRODUCT_LIST = 'PRODUCT_LIST',
  PAST_BUYS = 'PAST_BUYS',
  PAYMENT_CAROUSEL = 'PAYMENT_CAROUSEL',
  REWARD_CARD = 'REWARD_CARD',
  REWARD_PARTNER = 'REWARD_PARTNER',
  WELCOME_HEADER = 'WELCOME_HEADER',
  REDEEM_GIFT_VOUCHER = 'REDEEM_GIFT_VOUCHER',
  PREPAIDS_ICONS = 'PREPAIDS_ICONS',
  VOUCHERS_LIST = 'VOUCHERS_LIST',
  TRAVEL_BOOKINGS = 'TRAVEL_BOOKINGS',
  GREENBACKS_STATUS = 'GREENBACKS_STATUS',
  HEADERS = 'HEADERS',
  CONTENT_BUBBLE_CAROUSEL = 'CONTENT_BUBBLE_CAROUSEL',
  TILES = 'TILES',
}

export enum ImageType {
  SHOPPING_DEALS_CARD = 'SHOPPING_DEALS_CARD',
  PROMOTIONS_CARD = 'PROMOTIONS_CARD',
  FEATURED_STORE_CARD = 'FEATURED_STORE_CARD',
  SEARCH_RESULT_LIST_CARD = 'SEARCH_RESULT_LIST_CARD',
  STORE_DETAIL_COMPONENT = 'STORE_DETAIL_COMPONENT',
  SEARCH_RESULT_LIST_SUGGESTIONS = 'SEARCH_RESULT_LIST_SUGGESTIONS',
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
}

export interface IFlightBookingOrders {
  orderNumber: string;
  bookingStatus: BookingStatus;
  tripReference: string;
  bookingDate: string;
  type: string;
  bookingDateStatus: BookingDateStatus;
  flights: OrdersOverviewFlightDto[];
}
export enum BookingStatus {
  NEW = 'NEW',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}
export enum BookingStatusStrings {
  CANCELLED = 'customer.widget.travel_booking.status.CANCELLED',
  FAILED = 'booking_detail.trip.status.FAILED',
  NEW = 'booking_detail.trip.status.NEW',
  SUCCESS = 'booking_detail.trip.status.SUCCESS',
}
export enum BookingDateStatus {
  UPCOMING = 'UPCOMING',
  PREVIOUS = 'PREVIOUS',
}
export interface OrdersOverviewFlightDto {
  peopleNames: string[];
  bookingStatus: BookingStatus;
  price: Money;
  flightSectors: OrdersOverviewFlightSectorDto[];
}
export interface Money {
  formatted?: string;
  amount?: number;
  currencyCode?: string;
}
export interface OrdersOverviewFlightSectorDto {
  ticketNumber: string;
  departureAirportCode: string;
  departureAirport: string;
  departureDate: string;
  arrivalAirportCode: string;
  arrivalAirport: string;
  arrivalDate: string;
}
