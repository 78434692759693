import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtility } from '../utils/common-utility';
import { IPrepaidsProduct, PrepaidPages } from '../model/core.model';
import { UrlRoutes } from '../routes/routes';

@Pipe({ name: 'prepaidProductUrl', standalone: true })
export class PrepaidProductUrlPipe implements PipeTransform {
  transform(product: IPrepaidsProduct | undefined, currentLocation: PrepaidPages): string {
    if (currentLocation === PrepaidPages.PRODUCTS_LIST) {
      return CommonUtility.format(`${UrlRoutes.prepaids.providers.product}`, product?.sku);
    } else {
      return UrlRoutes.prepaids.home;
    }
  }
}
