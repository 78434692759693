import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AdobeAnalyticsService } from '../services/adobe-analytics.service';
import { getErrorMessageFromApiResponse } from '../utilities/adobe.utility';
import { TranslateFacade } from '@avo/shared/avo-translate';

@Injectable()
export class AdobeErrorInterceptor implements HttpInterceptor {
  private hiddenAdobeUrls = [
    /\/v\d\/customer\/currentLocation/,
    /chat\/v\d\/client\/sync/,
    /login\/refreshtoken/,
    /maintenance-main-on\.json/i,
  ];

  constructor(private adobeAnalyticsService: AdobeAnalyticsService, private translateFacade: TranslateFacade) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = getErrorMessageFromApiResponse(error);
        const isOtpError = error?.status === 428;
        const isEmittedUrl = this.hiddenAdobeUrls?.some(regex => regex.test(error?.url));
        const is401 = error?.status === 401;
        const isHashError = request?.url === '#';
        if (!isOtpError && !isEmittedUrl && !is401 && !isHashError) {
          let translate = '';
          const key = errorMessage;
          this.translateFacade
            .getTranslateByKey$(key)
            .pipe(
              take(1),
              catchError((error: HttpErrorResponse) => {
                console.error(`An error has occurred with key ${key}. Error message: ${error.message}`);
                return key;
              }),
            )
            .subscribe(response => {
              if (response === key) console.warn(`Missing translation: ${key}`);
              translate = response;
            });

          // TODO: the next line is used to catch and debug error data anomalies (2023-01-24)
          if (translate === 'Unspecified') translate = `Unspecified (${request?.url || 'missing request details'})`;
          this.adobeAnalyticsService.eventError(translate, { request, error });
        }
        return throwError(() => error);
      }),
    );
  }
}
